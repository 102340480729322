import React from "react";
import { Nav } from "shards-react";
import { connect } from "react-redux";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => {
            if (item.staffOnly)
              return this.props.isStaff && <SidebarNavItem key={idx} item={item} />
            else if (item.restaurantOnly) {
              if (item.dashboardAvailable)
                return this.props.dashboardAvailable && <SidebarNavItem key={idx} item={item} />
              else
                return this.props.isRestaurant && <SidebarNavItem key={idx} item={item} />
            } else
              return <SidebarNavItem key={idx} item={item} />
          })}
        </Nav>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  isStaff: state.user.isStaff,
  isRestaurant: state.user.isRestaurant,
  dashboardAvailable: state.user.dashboardAvailable
})

const bindActions = dispatch => ({
})

export default connect(mapStateToProps, bindActions)(SidebarNavItems);
