import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem
} from "shards-react";
import { useTranslation } from "react-i18next";

const MostViewed = ({ title, mostViewed, leastViewed, restaurant }) => {
  const { t } = useTranslation();
  if (mostViewed.length === 0) {
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <div className="text-center" style={{ width: "100%", height: 570 }}>
            <strong className="centered error-text">{t("no.info")}</strong>
          </div>
        </CardBody>
      </Card>
    );
  } else {
    return (
      <Card small>
        <CardHeader>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="mostViewedTab"
                data-toggle="tab"
                href="#mostVie"
                role="tab"
                aria-controls="mostVie"
                aria-selected="true"
              >
                Most Viewed
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="leastViewedTab"
                data-toggle="tab"
                href="#leastVie"
                role="tab"
                aria-controls="leastVie"
                aria-selected="false"
              >
                Least Viewed
              </a>
            </li>
          </ul>
        </CardHeader>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="mostVie"
            role="tabpanel"
            aria-labelledby="mostViewedTab"
          >
            <CardBody className="p-0">
              <ListGroup small flush>
                {mostViewed.map((item, idx) => (
                  <ListGroupItem key={idx} className="d-flex px-3">
                    <span className="text-semibold text-fiord-blue">
                      {item.nameEs}{" "}
                      {restaurant !== null ? "" : " - " + item.restaurantName}
                    </span>
                    <span className="ml-auto text-right text-semibold text-reagent-gray">
                      {item.numViews}
                    </span>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </div>
          <div
            className="tab-pane fade"
            id="leastVie"
            role="tabpanel"
            aria-labelledby="leastViewedTab"
          >
            <CardBody className="p-0">
              <ListGroup small flush>
                {leastViewed.map((item, idx) => (
                  <ListGroupItem key={idx} className="d-flex px-3">
                    <span className="text-semibold text-fiord-blue">
                      {item.nameEs}{" "}
                      {restaurant !== null ? "" : " - " + item.restaurantName}
                    </span>
                    <span className="ml-auto text-right text-semibold text-reagent-gray">
                      {item.numViews}
                    </span>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </div>
        </div>
      </Card>
    );
  }
};

MostViewed.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The referral data.
   */
  mostViewed: PropTypes.array,
  leastViewed: PropTypes.array,
  /**
   * Restaurant is defined
   */
  restaurant: PropTypes.any
};

MostViewed.defaultProps = {
  title: "Platos más vistos",
  mostViewed: [],
  leastViewed: [],
  restaurant: null
};

export default MostViewed;
