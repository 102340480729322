import React from "react";
import { Link } from "react-router-dom";
import { client } from '../../apolloClient';
import { withTranslation } from "react-i18next";
import { queries } from '../../graphQueries'
import {
  CardHeader,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button
} from "shards-react";

import "../../shards-dashboard/styles/login.css"
import { Query } from "react-apollo";

class CountryForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      country: {
        name: '',
        code: '',
        codeTwo: '',
        id: '',
        currency:{
          code: '',
          symbol: '',
          id: '-1'
        },
      },
      loading: false,
      isEdit: false,
    }

    this.t = this.props.t;
  }

  componentWillMount() {
    try {
      if (this.props.location.state.entity) {
        const { currency, ...restCountry} = this.props.location.state.entity
        this.setState({ country: {
          ...restCountry,
          currency:  currency || {
              code: '',
              symbol: '',
              id: '-1'
          }
        }, isEdit: true })
      }
    } catch (err) { }
  }

  handleChange = event => {
    const { name, value } = event.target;
    if (name === 'currency'){
      const id = event.target[event.target.selectedIndex].id;
      const text = event.target[event.target.selectedIndex].text;

      this.setState(prevState => ({
        country: {
          ...prevState.country,
          [name]: {
            id,
            code: text
          }
        }
      }));

    } else {
      this.setState(prevState => ({
        country: {
          ...prevState.country,
          [name]: value
        }
      }));
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    if (parseInt(this.state.country.currency.id) < 0){
      this.setState( {requiredFieldsError: true})
    } else {

      this.setState({ requiredFieldsError: false })
      if (this.state.country.code.length > 3){
        return
      }
      const mutation = this.state.isEdit ? queries.updateCountry : queries.createCountry;

      const variables = {
        name: this.state.country.name,
        code: this.state.country.code,
        codeTwo: this.state.country.codeTwo,
        currency: parseInt(this.state.country.currency.id)
      };
      if (this.state.isEdit) {
        variables.id = parseInt(this.state.country.id);
      }
      client.mutate({
        variables,
        mutation
      }).then((res, err) => {
        this.props.history.push("/countries")
      })
    }
  }

  render() {
    return (
      <Container className="pt-4">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">{this.state.isEdit ? `${this.t('form.editing')} ${this.state.country.name}` : this.t('form.newCountry')}</h6>
            <Button outline pill theme="danger" className="float-right" tag={Link} to="/countries">Back</Button>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={this.handleSubmit}>
                    <Row form>
                      <Col md="3">
                        <label htmlFor="name">{this.t('table.name')}</label>
                        <FormInput
                          id="name"
                          name="name"
                          value={this.state.country.name}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Col>
                      <Col md="3">
                        <label htmlFor="code">{this.t('table.code')}</label>
                        <FormInput
                          id="code"
                          name="code"
                          value={this.state.country.code}
                          invalid={
                            this.state.country.code &&
                            this.state.country.code.length > 3 }
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md="3">
                        <label htmlFor="codeTwo">{this.t('table.codeTwo')}</label>
                        <FormInput
                          id="codeTwo"
                          name="codeTwo"
                          value={this.state.country.codeTwo}
                          invalid={
                            this.state.country.codeTwo &&
                            this.state.country.codeTwo.length > 2 }
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col md="3">
                          <label htmlFor="currency">{this.t('restaurantForm.currency')}<span className="required-input"> *</span></label>
                          <Query query={queries.currencies}>
                            {({ loading, error, data, refetch }) => {
                              if (loading) return "Loading...";
                              if (error) return `Error ${error.message}`
                              return (
                                <FormSelect
                                  onChange={this.handleChange}
                                  required={true}
                                  id="currency" name="currency"
                                  defaultValue={this.state.isEdit && this.state.country.currency ? this.state.country.currency.code : "-1"}>
                                  <option key={-1} id={-1}>{this.t('form.selectOne')}</option>
                                  {data.currencies.map(  c => <option key={c.id} value={c.code} id={c.id}>{c.code}</option>)}
                                </FormSelect>
                              )
                            }}
                          </Query>
                        </Col>
                    </Row>
                    <Row>
                      <Col md={{ size: 12 }}>
                        <Button type="submit" className="float-left mt-2">{this.state.isEdit ? this.t('form.update') : this.t('form.create')}</Button>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="12">
                        {this.state.requiredFieldsError && <p className="error-required-icon">{this.t("requiredFieldsEmpty")}</p>}
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    )
  }
}

export default (withTranslation()(CountryForm))
