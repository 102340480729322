import React from 'react';
import "../shards-dashboard/styles/styles.css"

const CantDeleteTooltip = ({ t, message, children, condition }) => (
  condition ?
  (<div className="tooltip-custom">
    {children}
    <span className="tooltiptext-custom">{t(message)}</span>
  </div>) : (<span>{children}</span>)
)

export default CantDeleteTooltip;