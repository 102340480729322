import React from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { login } from "../../services";

import "../../shards-dashboard/styles/login.css"

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      loading: false,
      errors: { username: '', password: '' },
      formIsValid: false,
      loggedIn: false,
      isRestaurant: false,
      dashboardAvailable: false
    }

    this.t = this.props.t;
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    event.preventDefault();
    const { username, password } = this.state;
    this.validate(username, password);
    if (this.isFormValid()) {
      login(username, password).then(res => {
        if (!res.ok) {
          return alert("WRONG LOGIN");
        } else {
          this.setState({ loggedIn: true, isRestaurant: res.isRestaurant, dashboardAvailable: res.dashboardAvailable });
        }
      });
    }
  }

  async validate(username, password) {
    let errors = this.state.errors;
    errors.username = !username ? this.t("username.required") : '';
    errors.password = !password ? this.t("password.required") : '';

    await this.setState({ errors })
  }

  isFormValid() {
    return this.state.errors.username === '' && this.state.errors.password === ''
  }

  render() {
    if (this.state.loggedIn) {
      return this.state.isRestaurant ? (<Redirect to="/myMenu" />) : (<Redirect to="/" />)
    } else {
      return (
        <div className="wrapper fadeInDown">
          <div id="formContent">
            <div className="fadeIn first">
              <h4>Foodyt Admin</h4>
            </div>
            <form onSubmit={this.handleSubmit}>
              <input
                id="username"
                type="text"
                autoCapitalize="none"
                name="username"
                placeholder={this.t('username.placeholder')}
                onChange={this.handleChange}
                className="fadeIn second"
              />
              <input
                id="password"
                type="password"
                name="password"
                placeholder={this.t('password.placeholder')}
                onChange={this.handleChange}
                className="fadeIn third"
              />
              <input type="submit" className="fadeIn fourth" value={this.t('login')} />
            </form >
          </div>
        </div>


      )
    }
  }
}

export default (withTranslation()(LoginForm))