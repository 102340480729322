import gql from 'graphql-tag';

export const queries = {
  users: gql`
    {
      users {
        username
        email
        id
        userPlan
      }
    }
  `,
  currencies: gql`
    {
      currencies {
        id
        code
        symbol
        append
      }
    }
  `,
  countries: gql`
    {
      countries {
        id
        name
        code
        codeTwo
        currency {
          id
          code
          symbol
        }
      }
    }
  `,
  createCurrency: gql`
    mutation createCurrency($input: CurrencyInput!) {
      createCurrency(input: $input) {
        ok
        currency {
          id
          code
          symbol
          append
        }
      }
    }
  `,
  updateCurrency: gql`
    mutation updateCurrency($input: CurrencyInput!) {
      updateCurrency(input: $input) {
        ok
        currency {
          id
          code
          symbol
          append
        }
      }
    }
  `,
  deleteCurrency: gql`
    mutation deleteCurrency($id: Int!) {
      deleteCurrency(id: $id) {
        ok
      }
    }
  `,
  createCountry: gql`
    mutation createCountry($name: String!, $code: String!, $codeTwo: String, $currency: Int!) {
      createCountry(input: { name: $name, code: $code, codeTwo: $codeTwo, currency: $currency }) {
        ok
        country {
          id
          name
          code
          codeTwo
          currency {
            id
            code
            symbol
          }
        }
      }
    }
  `,
  updateCountry: gql`
    mutation updateCountry(
      $name: String!
      $code: String
      $codeTwo: String
      $currency: Int
      $id: Int!
    ) {
      updateCountry(
        id: $id
        input: { name: $name, code: $code, codeTwo: $codeTwo, currency: $currency }
      ) {
        ok
        country {
          id
          name
          code
          codeTwo
          currency {
            id
            code
            symbol
          }
        }
      }
    }
  `,
  deleteCountry: gql`
    mutation deleteCountry($id: Int!) {
      deleteCountry(id: $id) {
        ok
      }
    }
  `,
  cities: gql`
    {
      cities {
        id
        name
        state {
          name
          id
        }
      }
    }
  `,
  createCity: gql`
    mutation createCity($name: String!, $state: Int!) {
      createCity(input: { name: $name, state: $state }) {
        ok
        city {
          id
          name
          state {
            name
            id
          }
        }
      }
    }
  `,
  updateCity: gql`
    mutation updateCity($name: String!, $state: Int!, $id: Int!) {
      updateCity(id: $id, input: { name: $name, state: $state }) {
        ok
        city {
          id
          name
          state {
            name
            id
          }
        }
      }
    }
  `,
  deleteCity: gql`
    mutation deleteCity($id: Int!) {
      deleteCity(id: $id) {
        ok
      }
    }
  `,
  states: gql`
    {
      states {
        id
        name
        timezone
        country {
          id
          name
          code
          codeTwo
        }
      }
    }
  `,
  createState: gql`
    mutation createState($name: String!, $country: Int!, $timezone: String) {
      createState(input: { name: $name, country: $country, timezone: $timezone}) {
        ok
        state {
          id
          name
          country {
            name
            code
            codeTwo
            id
          }
        }
      }
    }
  `,
  updateState: gql`
    mutation updateState($name: String!, $country: Int!, $id: Int!, $timezone: String) {
      updateState(id: $id, input: { name: $name, country: $country, timezone: $timezone }) {
        ok
        state {
          id
          name
          country {
            name
            code
            codeTwo
            id
          }
        }
      }
    }
  `,
  deleteState: gql`
    mutation deleteState($id: Int!) {
      deleteState(id: $id) {
        ok
      }
    }
  `,
  lifestyles: gql`
    {
      lifestyles {
        id
        name
      }
    }
  `,
  createLifestyle: gql`
    mutation createLifeStyle($name: String!) {
      createLifestyle(input: { name: $name }) {
        ok
        lifeStyle {
          name
          id
        }
      }
    }
  `,
  updateLifestyle: gql`
    mutation updateLifeStyle($name: String!, $id: Int!) {
      updateLifestyle(id: $id, input: { name: $name }) {
        ok
        lifeStyle {
          id
          name
        }
      }
    }
  `,
  deleteLifestyle: gql`
    mutation deleteLifeStyle($id: Int!) {
      deleteLifestyle(id: $id) {
        ok
      }
    }
  `,
  createFullMenu: gql`
    mutation createFullMenu(
      $restaurantId: String!
      $name: String!
      $description: String
      $nameEn: String
      $nameFr: String
      $nameIt: String
      $namePt: String
      $nameDe: String
      $nameCa: String
      $nameJa: String
      $nameKo: String
      $nameZhCn: String
      $nameRu: String
      $autoTranslate: Boolean!
      $active: Boolean!
      $activeOn: [String]
    ) {
      createFullMenu(
        input: {
          name: $name
          nameEn: $nameEn
          nameFr: $nameFr
          nameIt: $nameIt
          namePt: $namePt
          nameDe: $nameDe
          nameCa: $nameCa
          nameJa: $nameJa
          nameKo: $nameKo
          nameZhCn: $nameZhCn
          nameRu: $nameRu
          restaurant: $restaurantId
          description: $description
          active: $active
          activeOn: $activeOn
        }
        autoTranslate: $autoTranslate
      ) {
        ok
        menu {
          id
          restaurant {
            id
          }
          name
          description
        }
      }
    }
  `,
  updateFullMenu: gql`
    mutation updateFullMenu(
      $id: String!
      $name: String!
      $description: String
      $nameEn: String
      $nameFr: String
      $nameIt: String
      $namePt: String
      $nameDe: String
      $nameCa: String
      $nameJa: String
      $nameKo: String
      $nameZhCn: String
      $nameRu: String
      $autoTranslate: Boolean!
      $active: Boolean!
      $activeOn: [String]
    ) {
      updateFullMenu(
        id: $id
        input: {
          name: $name
          nameEn: $nameEn
          nameFr: $nameFr
          nameIt: $nameIt
          namePt: $namePt
          nameDe: $nameDe
          nameCa: $nameCa
          nameJa: $nameJa
          nameKo: $nameKo
          nameZhCn: $nameZhCn
          nameRu: $nameRu
          description: $description
          active: $active
          activeOn: $activeOn
        }
        autoTranslate: $autoTranslate
      ) {
        ok
      }
    }
  `,
  createMenu: gql`
    mutation createMenu(
      $restaurantId: String!
      $fullMenuId: String!
      $menuOrder: Int!
      $name: String!
      $description: String
      $nameEn: String
      $nameFr: String
      $nameIt: String
      $namePt: String
      $nameDe: String
      $nameCa: String
      $nameJa: String
      $nameKo: String
      $nameZhCn: String
      $nameRu: String
      $autoTranslate: Boolean!
    ) {
      createMenu(
        input: {
          name: $name
          nameEn: $nameEn
          nameFr: $nameFr
          nameIt: $nameIt
          namePt: $namePt
          nameDe: $nameDe
          nameCa: $nameCa
          nameJa: $nameJa
          nameKo: $nameKo
          nameZhCn: $nameZhCn
          nameRu: $nameRu
          restaurant: $restaurantId
          fullMenu: $fullMenuId
          description: $description
          menuOrder: $menuOrder
        }
        autoTranslate: $autoTranslate
      ) {
        ok
        menu {
          id
          restaurant {
            id
          }
          name
          description
        }
      }
    }
  `,
  updateMenu: gql`
    mutation updateMenu(
      $id: Int!
      $name: String!
      $description: String
      $nameEn: String
      $nameFr: String
      $nameIt: String
      $namePt: String
      $nameDe: String
      $nameCa: String
      $nameJa: String
      $nameKo: String
      $nameZhCn: String
      $nameRu: String
      $autoTranslate: Boolean!
    ) {
      updateMenu(
        id: $id
        input: {
          name: $name
          nameEn: $nameEn
          nameFr: $nameFr
          nameIt: $nameIt
          namePt: $namePt
          nameDe: $nameDe
          nameCa: $nameCa
          nameJa: $nameJa
          nameKo: $nameKo
          nameZhCn: $nameZhCn
          nameRu: $nameRu
          description: $description
        }
        autoTranslate: $autoTranslate
      ) {
        ok
      }
    }
  `,
  deleteFullMenu: gql`
    mutation deleteFullMenu($id: String!) {
      deleteFullMenu(id: $id) {
        ok
      }
    }
  `,
  deleteMenu: gql`
    mutation deleteMenu($id: Int!) {
      deleteMenu(id: $id) {
        ok
      }
    }
  `,
  restaurantByName: gql`
    query restaurant($cursor: String) {
      restaurants(first: 10, after: $cursor) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
            status
            user {
              username
            }
            city {
              name
            }
            views
          }
        }
      }
    }
  `,
  restaurantByCompany: gql`
    query companyRestaurants($id: String!) {
      companyRestaurants(id: $id) {
        id
        name
      }
    }
  `,
  restaurant: gql`
    query restaurant($id: String!) {
      restaurant(id: $id) {
        id
        name
        descriptionEs
        descriptionEn
        descriptionFr
        descriptionIt
        descriptionPt
        descriptionDe
        descriptionCa
        descriptionJa
        descriptionKo
        descriptionZhCn
        descriptionRu
        description
        address
        email
        twitter
        instagram
        foodytOrders
        cuisines {
          name
          id
        }
        lifeStyle {
          name
          id
        }
        logoImage
        image
        latitude
        longitude
        postalCode
        services {
          name
          id
        }
        priceRange
        user {
          username
          id
          userPlan
          hosteltactilActive
        }
        company {
          name
          id
          userPlan
          hosteltactilActive
        }
        franchise {
          id
          name
          franchises {
            edges {
              cursor
              node {
                id
                name
              }
            }
          }
        }
        city {
          name
          id
          state {
            id
            country {
              id
              currency {
                id
                code
                symbol
              }
            }
          }
        }
        fullMenus {
          edges {
            cursor
            node {
              id
              name
              nameEn
              nameFr
              nameIt
              namePt
              nameDe
              nameCa
              nameJa
              nameKo
              nameZhCn
              nameRu
              active
              activeOn
              collapseOpen
              hasMenus
            }
          }
        }
        views
        placeId
        status
        showPriceRange
        bookUrl
        phone
        avaibleLanguages
        urlPage
        suggested
        customPrimaryColor
        customSecondaryColor
        customFont
        customFontLink
        customLink
        customLinkName
        showSearch
        htLocalId
        agoraApiUrl
        agoraApiToken
        tenedorRestaurantId
        foodytOrders
        ordersStopped
        activeOn
        paymentMethod
      }
    }
  `,
  findRestaurantsByText: gql`
    query restaurantsByText($text: String!, $notApproved: Boolean!) {
      restaurantsByText(text: $text, notApproved: $notApproved) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
            status
            descriptionEs
            description
            city {
              id
              name
            }
            views
            hasMenus
            showPriceRange
            bookUrl
          }
        }
      }
    }
  `,
  createUser: gql`
    mutation createUser(
      $username: String!
      $avatar: Upload
      $password: String!
      $firstName: String
      $lastName: String
      $email: String!
      $isRestaurant: Boolean!
      $isStaff: Boolean!
      $permission: String
      $company: String
      $restaurants: [String]
      $dashboardAvailable: Boolean
      $userPlan: String
      $hostelTactilInput: HostelTactilInput
      $agoraActive: Boolean
    ) {
      createUser(
        input: {
          username: $username
          avatar: $avatar
          password: $password
          firstName: $firstName
          lastName: $lastName
          email: $email
          isRestaurant: $isRestaurant
          isStaff: $isStaff
          permission: $permission
          company: $company
          restaurants: $restaurants
          dashboardAvailable: $dashboardAvailable
          userPlan: $userPlan
          hostelTactilInput: $hostelTactilInput
          agoraActive: $agoraActive
        }
      ) {
        ok
        user {
          id
          username
          role
        }
      }
    }
  `,
  updateUser: gql`
    mutation updateUser(
      $id: String!
      $username: String!
      $avatar: Upload
      $password: String
      $firstName: String
      $lastName: String
      $email: String!
      $isRestaurant: Boolean!
      $isStaff: Boolean!
      $permission: String
      $company: String
      $restaurants: [String]
      $dashboardAvailable: Boolean
      $userPlan: String
      $hostelTactilInput: HostelTactilInput
      $agoraActive: Boolean
    ) {
      updateUser(
        input: {
          username: $username
          avatar: $avatar
          password: $password
          firstName: $firstName
          lastName: $lastName
          email: $email
          isRestaurant: $isRestaurant
          isStaff: $isStaff
          permission: $permission
          company: $company,
          restaurants: $restaurants
          dashboardAvailable: $dashboardAvailable
          userPlan: $userPlan
          hostelTactilInput: $hostelTactilInput
          agoraActive: $agoraActive
        }
        id: $id
      ) {
        ok
        user {
          id
          firstName
          avatar
          lastName
          email
          isRestaurant
          isStaff
          username
          role
        }
      }
    }
  `,
  usersTable: gql`
    query paginatedUsers($first: Int!) {
      paginatedUsers(first: $first) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            username
            email
            role
          }
        }
      }
    }
  `,
  approveComment: gql`
    mutation approveComment($id: Int!) {
      approveComment(id: $id) {
        ok
        comment {
          id
          approved
          created
          title
          content
        }
      }
    }
  `,
  notApprovedComments: gql`
    query notApprovedComments {
      notApprovedComments {
        id
        created
        user {
          username
          id
        }
        dish {
          name
          restaurant {
            name
          }
        }
        title
        content
        approved
      }
    }
  `,
  deleteComment: gql`
    mutation deleteComment($id: Int!) {
      deleteComment(id: $id) {
        ok
      }
    }
  `,
  deleteUser: gql`
    mutation deleteUser($id: String!) {
      deleteUser(id: $id) {
        ok
      }
    }
  `,
  restaurants: gql`
    query restaurants(
      $cursor: String
      $id: ID
      $first: Int!
      $notApproved: Boolean!
    ) {
      restaurants(
        first: $first
        after: $cursor
        id: $id
        notApproved: $notApproved
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
            status
            descriptionEs
            description
            city {
              id
              name
            }
            views
            hasMenus
            showPriceRange
            bookUrl
          }
        }
      }
    }
  `,
  dashboardRestaurants: gql`
    query dashboardRestaurants {
      dashboardRestaurants {
        id
        name
      }
    }
  `,
  createDish: gql`
    mutation createDish(
      $name: String!
      $description: String
      $image: Upload
      $allergens: [String]
      $restaurantId: String!
      $menuId: String!
      $suggested: Boolean!
      $status: String!
      $prices: [PriceInput]
      $nameEs: String
      $nameEn: String
      $nameFr: String
      $nameIt: String
      $namePt: String
      $nameDe: String
      $nameCa: String
      $nameJa: String
      $nameKo: String
      $nameZhCn: String
      $nameRu: String
      $descriptionEn: String
      $descriptionFr: String
      $descriptionIt: String
      $descriptionPt: String
      $descriptionDe: String
      $descriptionCa: String
      $descriptionJa: String
      $descriptionKo: String
      $descriptionZhCn: String
      $descriptionRu: String
      $autoTranslate: Boolean
      $dishOrder: Int!
    ) {
      createDish(
        input: {
          name: $name
          description: $description
          image: $image
          allergens: $allergens
          restaurant: $restaurantId
          menu: $menuId
          suggested: $suggested
          status: $status
          nameEs: $nameEs
          nameEn: $nameEn
          nameFr: $nameFr
          nameIt: $nameIt
          namePt: $namePt
          nameDe: $nameDe
          nameCa: $nameCa
          nameJa: $nameJa
          nameKo: $nameKo
          nameZhCn: $nameZhCn
          nameRu: $nameRu
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionIt: $descriptionIt
          descriptionPt: $descriptionPt
          descriptionDe: $descriptionDe
          descriptionCa: $descriptionCa
          descriptionJa: $descriptionJa
          descriptionKo: $descriptionKo
          descriptionZhCn: $descriptionZhCn
          descriptionRu: $descriptionRu
          dishOrder: $dishOrder
        }
        prices: $prices
        autoTranslate: $autoTranslate
      ) {
        ok
        dish {
          id
          nameEs
          status
          image
        }
      }
    }
  `,
  updateDish: gql`
    mutation updateDish(
      $name: String!
      $description: String
      $image: Upload!
      $allergens: [String]
      $suggested: Boolean!
      $status: String!
      $prices: [PriceInput]
      $id: String!
      $nameEs: String
      $nameEn: String
      $nameFr: String
      $nameIt: String
      $namePt: String
      $nameDe: String
      $nameCa: String
      $nameJa: String
      $nameKo: String
      $nameZhCn: String
      $nameRu: String
      $descriptionEs: String
      $descriptionEn: String
      $descriptionFr: String
      $descriptionIt: String
      $descriptionPt: String
      $descriptionDe: String
      $descriptionCa: String
      $descriptionJa: String
      $descriptionKo: String
      $descriptionZhCn: String
      $descriptionRu: String
      $autoTranslate: Boolean
      $dishOrder: Int!
    ) {
      updateDish(
        id: $id
        input: {
          name: $name
          description: $description
          image: $image
          allergens: $allergens
          suggested: $suggested
          status: $status
          nameEs: $nameEs
          nameEn: $nameEn
          nameFr: $nameFr
          nameIt: $nameIt
          namePt: $namePt
          nameDe: $nameDe
          nameCa: $nameCa
          nameJa: $nameJa
          nameKo: $nameKo
          nameZhCn: $nameZhCn
          nameRu: $nameRu
          descriptionEs: $descriptionEs
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionIt: $descriptionIt
          descriptionPt: $descriptionPt
          descriptionDe: $descriptionDe
          descriptionCa: $descriptionCa
          descriptionJa: $descriptionJa
          descriptionKo: $descriptionKo
          descriptionZhCn: $descriptionZhCn
          descriptionRu: $descriptionRu
          dishOrder: $dishOrder
        }
        prices: $prices
        autoTranslate: $autoTranslate
      ) {
        ok
        dish {
          id
          name
          status
          description
          image
          nameEs
          nameEn
          nameFr
          nameIt
          namePt
          nameDe
          nameCa
          nameJa
          nameKo
          nameZhCn
          nameRu
          descriptionEs
          descriptionEn
          descriptionFr
          descriptionIt
          descriptionPt
          descriptionDe
          descriptionCa
          descriptionJa
          descriptionKo
          descriptionZhCn
          descriptionRu
          dishOrder
          menu {
            name
          }
          urlPage
          restaurant {
            id
            name
          }
          allergens {
            name
          }
        }
      }
    }
  `,
  dish: gql`
    query dish($id: String!) {
      dish(id: $id) {
        id
        nameEs
        nameEn
        nameFr
        nameIt
        namePt
        nameDe
        nameCa
        nameJa
        nameKo
        nameZhCn
        nameRu
        descriptionEs
        descriptionEn
        descriptionFr
        descriptionIt
        descriptionPt
        descriptionDe
        descriptionCa
        descriptionJa
        descriptionKo
        descriptionZhCn
        descriptionRu
        description
        allergens {
          name
          id
        }
        image
        dishOrder
        suggested
        restaurant {
          id
          htLocalId
          foodytOrders
          company {
            id
            userPlan
          }
          franchise {
            id
            name
            franchises {
              edges {
                cursor
                node {
                  id
                  name
                }
              }
            }
          }
          city {
            id
            state {
              id
              country {
                id
                currency {
                  id
                  code
                  symbol
                }
              }
            }
          }
        }
        prices {
          id
          name
          nameEs
          nameEn
          nameFr
          nameIt
          namePt
          nameDe
          nameCa
          nameJa
          nameKo
          nameZhCn
          nameRu
          price
          htDishId
          isWeight
          deliveryPrice {
            id
            amount
            orderType
            active
          }
          takeawayPrice {
            id
            amount
            orderType
            active
          }
          loungePrice {
            id
            amount
            orderType
            active
          }
        }
        status
      }
    }
  `,
  addDishMenu: gql`
    mutation addDishMenu($id: Int!, $dish: String!) {
      addDishMenu(id: $id, dish: $dish) {
        ok
      }
    }
  `,
  createRestaurant: gql`
    mutation createRestaurant(
      $name: String!
      $description: String!
      $address: String!
      $email: String!
      $twitter: String
      $instagram: String
      $cuisines: [String]
      $city: String!
      $lifeStyle: String
      $logoImage: Upload!
      $image: Upload!
      $latitude: String!
      $longitude: String!
      $postalCode: String!
      $services: [String]
      $user: String
      $company: String!
      $priceRange: String!
      $placeId: String
      $status: String!
      $showPriceRange: Boolean!
      $bookUrl: String
      $phone: String
      $descriptionEn: String
      $descriptionFr: String
      $descriptionIt: String
      $descriptionPt: String
      $descriptionDe: String
      $descriptionCa: String
      $descriptionJa: String
      $descriptionKo: String
      $descriptionZhCn: String
      $descriptionRu: String
      $autoTranslate: Boolean
      $avaibleLanguages: [String]!
      $urlPage: String
      $suggested: Boolean
      $showSearch: Boolean
      $agoraInput: AgoraConfigInput
      $tenedorRestaurantId: String
      $foodytOrders: Boolean
      $ordersStopped: Boolean
    ) {
      createRestaurant(
        input: {
          name: $name
          description: $description
          bookUrl: $bookUrl
          phone: $phone
          address: $address
          email: $email
          twitter: $twitter
          instagram: $instagram
          cuisines: $cuisines
          city: $city
          lifeStyle: $lifeStyle
          logoImage: $logoImage
          image: $image
          latitude: $latitude
          longitude: $longitude
          postalCode: $postalCode
          services: $services
          user: $user
          company: $company
          priceRange: $priceRange
          placeId: $placeId
          status: $status
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionIt: $descriptionIt
          descriptionPt: $descriptionPt
          descriptionDe: $descriptionDe
          descriptionCa: $descriptionCa
          descriptionJa: $descriptionJa
          descriptionKo: $descriptionKo
          descriptionZhCn: $descriptionZhCn
          descriptionRu: $descriptionRu
          showPriceRange: $showPriceRange
          avaibleLanguages: $avaibleLanguages
          urlPage: $urlPage
          suggested: $suggested
          showSearch: $showSearch
          agoraInput: $agoraInput
          tenedorRestaurantId: $tenedorRestaurantId
          foodytOrders: $foodytOrders
          ordersStopped: $ordersStopped
        }
        autoTranslate: $autoTranslate
      ) {
        ok
        restaurant {
          id
          name
          description
          address
          urlPage
          email
          twitter
          instagram
          logoImage
          image
          latitude
          longitude
          postalCode
          suggested
        }
      }
    }
  `,
  updateRestaurant: gql`
    mutation updateRestaurant(
      $name: String!
      $description: String!
      $address: String!
      $email: String!
      $twitter: String
      $instagram: String
      $cuisines: [String]
      $city: String!
      $lifeStyle: String
      $logoImage: Upload!
      $image: Upload!
      $latitude: String!
      $longitude: String!
      $postalCode: String!
      $services: [String]
      $user: String
      $company: String!
      $priceRange: String!
      $id: String!
      $placeId: String
      $status: String!
      $showPriceRange: Boolean!
      $bookUrl: String
      $phone: String
      $descriptionEn: String
      $descriptionFr: String
      $descriptionIt: String
      $descriptionPt: String
      $descriptionDe: String
      $descriptionCa: String
      $descriptionJa: String
      $descriptionKo: String
      $descriptionZhCn: String
      $descriptionRu: String
      $autoTranslate: Boolean
      $avaibleLanguages: [String]!
      $urlPage: String
      $suggested: Boolean
      $customPrimaryColor: String
      $customSecondaryColor: String
      $customFont: String
      $customFontLink: String
      $customLink: String
      $customLinkName: String
      $showSearch: Boolean
      $htUsername: String
      $htPassword: String
      $agoraInput: AgoraConfigInput
      $tenedorRestaurantId: String
      $foodytOrders: Boolean
      $ordersStopped: Boolean
      $activeOn: [String]
    ) {
      updateRestaurant(
        id: $id
        autoTranslate: $autoTranslate
        input: {
          name: $name
          description: $description
          bookUrl: $bookUrl
          phone: $phone
          address: $address
          email: $email
          twitter: $twitter
          instagram: $instagram
          cuisines: $cuisines
          city: $city
          lifeStyle: $lifeStyle
          logoImage: $logoImage
          image: $image
          latitude: $latitude
          longitude: $longitude
          postalCode: $postalCode
          services: $services
          user: $user
          company: $company
          priceRange: $priceRange
          placeId: $placeId
          status: $status
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionIt: $descriptionIt
          descriptionPt: $descriptionPt
          descriptionDe: $descriptionDe
          descriptionCa: $descriptionCa
          descriptionJa: $descriptionJa
          descriptionKo: $descriptionKo
          descriptionZhCn: $descriptionZhCn
          descriptionRu: $descriptionRu
          showPriceRange: $showPriceRange
          avaibleLanguages: $avaibleLanguages
          urlPage: $urlPage
          suggested: $suggested
          customFont: $customFont
          customFontLink: $customFontLink
          customLink: $customLink
          customPrimaryColor: $customPrimaryColor
          customSecondaryColor: $customSecondaryColor
          customLinkName: $customLinkName
          showSearch: $showSearch
          htUsername: $htUsername
          htPassword: $htPassword
          agoraInput: $agoraInput
          tenedorRestaurantId: $tenedorRestaurantId
          foodytOrders: $foodytOrders
          ordersStopped: $ordersStopped
          activeOn: $activeOn
        }
      ) {
        ok
        restaurant {
          id
          name
          description
          descriptionEn
          descriptionFr
          descriptionIt
          descriptionPt
          descriptionDe
          descriptionCa
          descriptionJa
          descriptionKo
          descriptionZhCn
          descriptionRu
          avaibleLanguages
          urlPage
          suggested
          services {
            id
          }
          cuisines {
            id
          }
          lifeStyle {
            id
          }
          priceRange
          showPriceRange
          bookUrl
          phone
          address
          urlPage
          email
          twitter
          instagram
          logoImage
          image
          latitude
          longitude
          postalCode
          status
          city {
            name
            id
          }
          user {
            id
            username
          }
        }
      }
    }
  `,
  user: gql`
    query user($id: String!) {
      user(id: $id) {
        id
        username
        firstName
        lastName
        email
        avatar
        isRestaurant
        isStaff
        permission
        company {
          id
          name
          userPlan
        }
        restaurants {
          id
          name
        }
        dashboardAvailable
        userPlan
        hosteltactilActive
        agoraActive
      }
    }
  `,
  userByUsername: gql`
    query userByUsername($username: String!) {
      userByUsername(username: $username) {
        id
        username
        firstName
        lastName
        email
        avatar
        isRestaurant
        isStaff
        dashboardAvailable
        userPlan
      }
    }
  `,
  userRestaurant: gql`
    mutation getRestaurant($userId: Int!) {
      getRestaurant(userId: $userId) {
        ok
        restaurant {
          id
          name
          description
          address
          urlPage
          email
          city {
            name
          }
          lifeStyle {
            name
          }
          logoImage
          image
          latitude
          longitude
          postalCode
          services {
            name
          }
          views
        }
      }
    }
  `,
  getFullMenu: gql`
    query getFullMenu($username: String!, $restaurantId: String) {
      getFullMenu(username: $username, restaurantId: $restaurantId) {
        id
        restaurant {
          name
        }
        name
        menuOrder
        hasDishes
        dishes {
          id
          name
          nameEs
          description
          descriptionEs
          image
          restaurant {
            id
          }
        }
      }
    }
  `,
  userRestaurants: gql`
    query userRestaurants($username: String!) {
      userRestaurants(username: $username) {
        id
        name
        created
      }
    }
  `,
  restaurantByUsername: gql`
    query restaurantByUsername($username: String!) {
      restaurantByUsername(username: $username) {
        id
        name
        descriptionEs
        descriptionEn
        descriptionFr
        descriptionIt
        descriptionPt
        descriptionDe
        descriptionCa
        descriptionJa
        descriptionKo
        descriptionZhCn
        descriptionRu
        description
        address
        email
        twitter
        instagram
        cuisines {
          name
          id
        }
        lifeStyle {
          name
          id
        }
        logoImage
        image
        latitude
        longitude
        postalCode
        services {
          name
          id
        }
        priceRange
        user {
          username
          id
          userPlan
          hostelTactil {
            accessToken
          }
        }
        city {
          name
          id
        }
        fullMenus {
          edges {
            cursor
            node {
              id
              name
              nameEn
              nameFr
              nameIt
              namePt
              nameDe
              nameCa
              nameJa
              nameKo
              nameZhCn
              nameRu
              active
              activeOn
              collapseOpen
              hasMenus
            }
          }
        }
        views
        placeId
        status
        showPriceRange
        urlPage
        bookUrl
        phone
        htLocalId
        foodytOrders
        activeOn
        paymentMethod
      }
    }
  `,
  deleteRestaurant: gql`
    mutation deleteRestaurant($id: String!) {
      deleteRestaurant(id: $id) {
        ok
      }
    }
  `,
  cloneRestaurant: gql`
    mutation cloneRestaurant($id: String!) {
      cloneRestaurant(id: $id) {
        ok
      }
    }
  `,
  deleteDish: gql`
    mutation deleteDish($id: String!, $menu: Int) {
      deleteDish(id: $id, menu: $menu) {
        ok
        dish {
          nameEs
          status
          id
        }
      }
    }
  `,
  bulkEditDishStatus: gql`
    mutation bulkEditDishStatus($ids: [String]!, $status: String!) {
      bulkEditDishStatus(ids: $ids, status: $status) {
        ok
        dishes {
          status
          id
        }
      }
    }
  `,
  bulkEditRestaurantStatus: gql`
    mutation bulkEditRestaurantStatus($ids: [String]!, $status: String!) {
      bulkEditRestaurantStatus(ids: $ids, status: $status) {
        ok
        restaurants {
          status
          id
        }
      }
    }
  `,
  notApprovedDishesByText: gql`
    query notApprovedDishesByText($text: String!) {
      notApprovedDishesByText(text: $text) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            nameEs
            nameEn
            nameFr
            nameIt
            namePt
            nameDe
            nameCa
            nameJa
            nameKo
            nameZhCn
            nameRu
            descriptionEs
            descriptionEn
            descriptionFr
            descriptionIt
            descriptionPt
            descriptionDe
            descriptionCa
            descriptionJa
            descriptionKo
            descriptionZhCn
            descriptionRu
            status
            image
            views
            menu {
              id
            }
            restaurant {
              name
              id
            }
          }
        }
      }
    }
  `,
  notApprovedDishes: gql`
    query notApprovedDishes($first: Int!, $cursor: String, $id: ID) {
      notApprovedDishes(first: $first, after: $cursor, id: $id) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            nameEs
            nameEn
            nameFr
            nameIt
            namePt
            nameDe
            nameCa
            nameJa
            nameKo
            nameZhCn
            nameRu
            descriptionEs
            descriptionEn
            descriptionFr
            descriptionIt
            descriptionPt
            descriptionDe
            descriptionCa
            descriptionJa
            descriptionKo
            descriptionZhCn
            descriptionRu
            status
            image
            views
            menu {
              id
            }
            restaurant {
              name
              id
            }
          }
        }
      }
    }
  `,
  dishesDeprecated: gql`
    query dishesDeprecated(
      $cursor: String
      $id: String
      $menuId: Int
      $fullMenuId: String
      $notApproved: Boolean!
    ) {
      dishesDeprecated(
        first: 100
        after: $cursor
        id: $id
        menuId: $menuId
        notApproved: $notApproved
        fullMenuId: $fullMenuId
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            nameEs
            nameEn
            nameFr
            nameIt
            namePt
            nameDe
            nameCa
            nameJa
            nameKo
            nameZhCn
            nameRu
            descriptionEs
            descriptionEn
            descriptionFr
            descriptionIt
            descriptionPt
            descriptionDe
            descriptionCa
            descriptionJa
            descriptionKo
            descriptionZhCn
            descriptionRu
            status
            views
            image
          }
        }
      }
    }
  `,
  findUsersByText: gql`
    query usersByText($text: String!) {
      usersByText(text: $text) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            username
            email
            role
          }
        }
      }
    }
  `,
  findDishesByText: gql`
    query dishesByText($text: String!, $menuId: Int!, $notApproved: Boolean!) {
      dishesByText(text: $text, menuId: $menuId, notApproved: $notApproved) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            nameEs
            status
          }
        }
      }
    }
  `,
  menusByRestaurant: gql`
    query menus($fullMenuId: String!) {
      menus(fullMenuId: $fullMenuId) {
        id
        name
        nameEn
        nameFr
        nameIt
        namePt
        nameDe
        nameCa
        nameJa
        nameKo
        nameZhCn
        nameRu
        name
        menuOrder
        description
        hasDishes
      }
    }
  `,
  updateRestaurantMenus: gql`
    mutation updateRestaurantMenus($ids: [String]!) {
      updateRestaurantMenus(ids: $ids) {
        ok
      }
    }
  `,
  updateDishesOrder: gql`
    mutation updateDishesOrder($ids: [String]!, $menu: Int!) {
      updateDishesOrder(ids: $ids, menu: $menu) {
        ok
      }
    }
  `,
  services: gql`
    {
      services {
        name
        icon
        id
      }
    }
  `,
  createService: gql`
    mutation createService($name: String!, $icon: Upload!) {
      createService(input: { name: $name, icon: $icon }) {
        ok
        service {
          id
          name
        }
      }
    }
  `,
  updateService: gql`
    mutation updateService($name: String!, $icon: Upload!, $id: Int!) {
      updateService(id: $id, input: { name: $name, icon: $icon }) {
        ok
        service {
          id
          name
        }
      }
    }
  `,
  deleteService: gql`
    mutation deleteService($id: Int!) {
      deleteService(id: $id) {
        ok
      }
    }
  `,
  cuisines: gql`
    {
      cuisines {
        id
        name
        icon
      }
    }
  `,
  createCuisine: gql`
    mutation createCuisine($name: String!, $icon: Upload!) {
      createCuisine(input: { name: $name, icon: $icon }) {
        ok
        cuisine {
          id
          name
        }
      }
    }
  `,
  updateCuisine: gql`
    mutation updateCuisine($name: String!, $icon: Upload!, $id: Int!) {
      updateCuisine(id: $id, input: { name: $name, icon: $icon }) {
        ok
        cuisine {
          id
          name
        }
      }
    }
  `,
  deleteCuisine: gql`
    mutation deleteCuisine($id: Int!) {
      deleteCuisine(id: $id) {
        ok
      }
    }
  `,
  allergens: gql`
    {
      allergens {
        id
        name
        icon
      }
    }
  `,
  createAllergen: gql`
    mutation createAllergen($name: String!, $icon: Upload!) {
      createAllergen(input: { name: $name, icon: $icon }) {
        ok
        allergen {
          id
          name
        }
      }
    }
  `,
  updateAllergen: gql`
    mutation updateallergen($name: String!, $icon: Upload!, $id: Int!) {
      updateAllergen(id: $id, input: { name: $name, icon: $icon }) {
        ok
        allergen {
          id
          name
        }
      }
    }
  `,
  deleteAllergen: gql`
    mutation deleteAllergen($id: Int!) {
      deleteAllergen(id: $id) {
        ok
      }
    }
  `,
  views: gql`
    query views($restaurantId: String, $fromDate: String!, $toDate: String!) {
      views(restaurantId: $restaurantId, fromDate: $fromDate, toDate: $toDate) {
        name
        qrViews
        normalViews
        totalViews
      }
    }
  `,
  viewsLanguage: gql`
    query viewsLanguage(
      $restaurantId: String
      $fromDate: String!
      $toDate: String!
    ) {
      viewsLanguage(
        restaurantId: $restaurantId
        fromDate: $fromDate
        toDate: $toDate
      ) {
        name
        totalViews
      }
    }
  `,
  kpis: gql`
    query kpis($restaurantId: String, $fromDate: String!, $toDate: String!) {
      kpis(restaurantId: $restaurantId, fromDate: $fromDate, toDate: $toDate) {
        name
        value
        subkpi {
          name
          value
          className
        }
        className
      }
    }
  `,
  commentsRestaurant: gql`
    query commentsRestaurant(
      $restaurantId: String
      $fromDate: String!
      $toDate: String!
    ) {
      commentsRestaurant(
        restaurantId: $restaurantId
        fromDate: $fromDate
        toDate: $toDate
      ) {
        id
        created
        user {
          firstName
          lastName
          username
          id
          avatar
        }
        dish {
          name
        }
        title
        content
      }
    }
  `,
  mostViewedDishes: gql`
    query mostViewedDishes(
      $restaurantId: String
      $fromDate: String!
      $toDate: String!
    ) {
      mostViewedDishes(
        restaurantId: $restaurantId
        fromDate: $fromDate
        toDate: $toDate
      ) {
        id
        name
        nameEs
        image
        views
        numViews
        restaurantName
      }
    }
  `,
  leastViewedDishes: gql`
    query leastViewedDishes(
      $restaurantId: String
      $fromDate: String!
      $toDate: String!
    ) {
      leastViewedDishes(
        restaurantId: $restaurantId
        fromDate: $fromDate
        toDate: $toDate
      ) {
        id
        name
        nameEs
        image
        views
        numViews
        restaurantName
      }
    }
  `,
  allergensUse: gql`
    query allergensUse(
      $restaurantId: String
      $fromDate: String!
      $toDate: String!
    ) {
      allergensUse(
        restaurantId: $restaurantId
        fromDate: $fromDate
        toDate: $toDate
      ) {
        name
        value
      }
    }
  `,
  favoriteDishes: gql`
    query favoriteDishes(
      $restaurantId: String
      $fromDate: String!
      $toDate: String!
    ) {
      favoriteDishes(
        restaurantId: $restaurantId
        fromDate: $fromDate
        toDate: $toDate
      ) {
        id
        name
        nameEs
        image
        views
        numWishlist
      }
    }
  `,
  importMenu: gql`
    mutation importMenu($id: String!, $file: Upload!) {
      importMenu(id: $id, file: $file) {
        ok
        error
      }
    }
  `,
  dailyMenus: gql`
    query dailyMenu($restaurantId: String!) {
      dailyMenu(restaurantId: $restaurantId) {
        id
        updated
        file
        fileType
        title
      }
    }
  `,
  creatDailyMenu: gql`
    mutation createDailyMenu(
      $restaurantId: String
      $file: Upload!
      $title: String
    ) {
      createOrUpdateDailyMenu(
        restaurant: $restaurantId
        input: { file: $file, title: $title }
      ) {
        ok
        dailyMenu {
          id
          updated
          title
          file
          fileType
        }
      }
    }
  `,
  updateDailyMenu: gql`
    mutation updateDailyMenu(
      $id: String
      $restaurantId: String
      $file: Upload!
      $title: String
    ) {
      createOrUpdateDailyMenu(
        id: $id
        restaurant: $restaurantId
        input: { file: $file, title: $title }
      ) {
        ok
        dailyMenu {
          id
          updated
          file
          title
          fileType
        }
      }
    }
  `,
  updateDailyMenuTitle: gql`
    mutation updateDailyMenuTitle(
      $id: String
      $restaurantId: String
      $title: String
    ) {
      updateDailyMenuTitle(
        id: $id
        restaurant: $restaurantId
        input: { title: $title }
      ) {
        ok
        dailyMenu {
          id
          updated
          file
          title
          fileType
        }
      }
    }
  `,
  deleteDailyMenu: gql`
    mutation deleteDailyMenu($id: Int!) {
      deleteDailyMenu(id: $id) {
        ok
      }
    }
  `,
  hostelTactilDishes: gql`
    query hostelTactilDishes($id: String!) {
      hostelTactilDishes(id: $id) {
        menuObject
      }
    }
  `,

  allOrders: gql`
    query allOrders($search: String){
      allOrders(search: $search) {
        edges {
          node {
            id
            created
            service
            orderType
            total
            deliveryPrice
            products {
              name
              unitPrice
              quantity
              commentary
              dishPrice {
                name
              }
            }
            client {
              created
              name
            }
            restaurant {
              name
              deliveryPrice
            }
            payment {
              paymentId
              paymentType
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  `,

  historicOrders: gql`
    query historicOrders($search: String, $restaurantId: String){
      historicOrders(search: $search, restaurantId: $restaurantId) {

        id
        created
        service
        orderType
        total
        products {
          name
          unitPrice
          quantity
          commentary
          dishPrice {
            name
          }
        }
        client {
          created
          name
        }
        restaurant {
          name
        }
        payment {
          paymentId
          paymentType
        }
      }
    }

  `,
  markets: gql`
    query marketsByText(
      $cursor: String
      $first: Int!
      $text: String
    ) {
      marketsByText(
        first: $first
        after: $cursor
        text: $text
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
            descriptionEs
            description
            city {
              id
              name
            }
          }
        }
      }
    }
  `,

  market: gql`
    query market($id: String) {
      market(id: $id) {
        id
        name
        descriptionEs
        descriptionEn
        descriptionFr
        descriptionIt
        descriptionPt
        descriptionDe
        descriptionCa
        descriptionJa
        descriptionKo
        descriptionZhCn
        descriptionRu
        description
        address
        email
        logoImage
        image
        latitude
        longitude
        postalCode
        phone
        urlPage
        activeOn
        ordersStopped
        stripePublicKey
        stripePrivateKey
        customPrimaryColor
        customSecondaryColor
        customFont
        customFontLink
        customLink
        customLinkName
        city {
          name
          id
        }
        restaurants {
          edges {
            cursor
            node {
              name
              id
            }
          }
        }
        deliveryConfig {
          cpList
          deliveryPrice
          commonConfig {
            id
            ordersConfigHours {
                id
                startTime
                endTime
                days
            }
            minimumOrder
            orderTime
          }
        }
        takeawayConfig {
          sendEmailClosed
          commonConfig {
            id
            ordersConfigHours {
                id
                startTime
                endTime
                days
            }
            minimumOrder
            orderTime
          }
        }
        loungeConfig {
          askPhone
          askEmail
          askName
          sendEmailClosed
          commonConfig {
            id
            ordersConfigHours {
                id
                startTime
                endTime
                days
            }
            minimumOrder
            orderTime
          }
        }
        tables {
          id
          code
          name
          zone
        }
      }
    }
  `,

  createOrUpdateMarket: gql`
    mutation createOrUpdateMarket(
      $id: String
      $name: String!
      $description: String
      $phone: String
      $address: String
      $email: String
      $city: String
      $logoImage: Upload!
      $image: Upload!
      $latitude: String
      $longitude: String
      $postalCode: String
      $user: String
      $descriptionEn: String
      $descriptionFr: String
      $descriptionIt: String
      $descriptionPt: String
      $descriptionDe: String
      $descriptionCa: String
      $descriptionJa: String
      $descriptionKo: String
      $descriptionZhCn: String
      $descriptionRu: String
      $autoTranslate: Boolean
      $restaurants: [String]
      $activeOn: [String]
      $stripePublicKey: String
      $stripePrivateKey: String
      $ordersStopped: Boolean
      $customPrimaryColor: String
      $customSecondaryColor: String
      $customFont: String
      $customFontLink: String
      $customLink: String
      $customLinkName: String
    ) {
      createOrUpdateMarket(
        id: $id
        autoTranslate: $autoTranslate
        input: { 
          name: $name
          description: $description
          phone: $phone
          address: $address
          email: $email
          city: $city
          user: $user
          logoImage: $logoImage
          image: $image
          latitude: $latitude
          longitude: $longitude
          postalCode: $postalCode
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionIt: $descriptionIt
          descriptionPt: $descriptionPt
          descriptionDe: $descriptionDe
          descriptionCa: $descriptionCa
          descriptionJa: $descriptionJa
          descriptionKo: $descriptionKo
          descriptionZhCn: $descriptionZhCn
          descriptionRu: $descriptionRu
          restaurants: $restaurants
          activeOn: $activeOn
          stripePublicKey: $stripePublicKey
          stripePrivateKey: $stripePrivateKey
          ordersStopped: $ordersStopped
          customFont: $customFont
          customFontLink: $customFontLink
          customLink: $customLink
          customPrimaryColor: $customPrimaryColor
          customSecondaryColor: $customSecondaryColor
          customLinkName: $customLinkName
        }
      ) {
        ok
        market {
          id
        }
      }
    }
  `,

  updateMarketDeliveryConfig: gql`
    mutation updateMarketDeliveryConfig(
      $market: String
      $minimumOrder: Float
      $orderTime: Int
      $deliveryPrice: Float
      $cpList: [String]
      $ordersHours: [HoursOrdersInput]
    ) {
      updateMarketDeliveryConfig(
        input: { 
          market: $market
          minimumOrder: $minimumOrder
          orderTime: $orderTime
          deliveryPrice: $deliveryPrice
          cpList: $cpList
          ordersHours: $ordersHours
        }
      ) {
        ok
        deliveryConfig {
          id
        }
      }
    }
  `,

  updateMarketTakeawayConfig: gql`
    mutation updateMarketTakeawayConfig(
      $market: String
      $minimumOrder: Float
      $orderTime: Int
      $ordersHours: [HoursOrdersInput]
      $sendEmailClosed: Boolean
    ) {
      updateMarketTakeawayConfig(
        input: { 
          market: $market
          minimumOrder: $minimumOrder
          orderTime: $orderTime
          ordersHours: $ordersHours
          sendEmailClosed: $sendEmailClosed
        }
      ) {
        ok
        takeawayConfig {
          id
        }
      }
    }
  `,

  updateMarketLoungeConfig: gql`
    mutation updateMarketLoungeConfig(
      $market: String
      $minimumOrder: Float
      $orderTime: Int
      $ordersHours: [HoursOrdersInput]
      $askName: Boolean
      $askPhone: Boolean
      $askEmail: Boolean
      $sendEmailClosed: Boolean
    ) {
      updateMarketLoungeConfig(
        input: { 
          market: $market
          minimumOrder: $minimumOrder
          orderTime: $orderTime
          ordersHours: $ordersHours
          askName: $askName
          askPhone: $askPhone
          askEmail: $askEmail
          sendEmailClosed: $sendEmailClosed
        }
      ) {
        ok
        loungeConfig {
          id
        }
      }
    }
  `,

  restaurantsMarket: gql`
    query restaurantsMarket {
      restaurantsMarket {
        id
        name
      }
    }
  `,
  restaurantsFranchise: gql`
    query restaurantsByText($text: String!, $notApproved: Boolean!) {
      restaurantsByText(text: $text, notApproved: $notApproved) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
          }
        }
      }
    }
  `,
  createMarketTable: gql`
    mutation createMarketTable($input: CreateMarketTableInput!) {
      createMarketTable(input: $input) {
        ok
        table {
          id
          code
          zone
          name
        }
      }
    }
  `,
  updateMarketTable: gql`
    mutation updateMarketTable($input: UpdateTableInput!) {
      updateMarketTable(input: $input) {
        ok
        table {
          id
          code
          zone
          name
        }
      }
    }
  `,
  deleteMarketTable: gql`
    mutation deleteMarketTable($tableId: Int!) {
      deleteMarketTable(tableId: $tableId) {
        ok
      }
    }
  `,
  franchises: gql`
  {
    franchises {
      id
      name
      user {
        id
        email
      }
      franchises {
        edges {
          cursor
          node {
            name
            id
          }
        }
      }
    }
  }
  `,
  franchise: gql`
    {
      franchise {
        id
        name
        user {
          id
          email
        }
        franchises {
          id
          name
        }
      }
    }
  `,
  createFranchise: gql`
    mutation createFranchise($input: FranchiseInput!) {
      createFranchise(input: $input) {
        ok
        franchise {
          id
          name
        }
      }
    }
  `,
  updateFranchise: gql`
    mutation updateFranchise($id: String!, $input: FranchiseInput!) {
      updateFranchise(id: $id, input: $input) {
        ok
        franchise {
          id
          name
        }
      }
    }
  `,
  deleteFranchise: gql`
    mutation deleteFranchise($id: String!) {
      deleteFranchise(id: $id) {
        ok
      }
    }
  `,
  createFranchiseDishes: gql`
    mutation createFranchiseDishes(
      $name: String!
      $description: String
      $image: Upload
      $allergens: [String]
      $restaurantId: String!
      $menuId: String!
      $suggested: Boolean!
      $status: String!
      $prices: [PriceInput]
      $nameEs: String
      $nameEn: String
      $nameFr: String
      $nameIt: String
      $namePt: String
      $nameDe: String
      $nameCa: String
      $nameJa: String
      $nameKo: String
      $nameZhCn: String
      $nameRu: String
      $descriptionEs: String
      $descriptionEn: String
      $descriptionFr: String
      $descriptionIt: String
      $descriptionPt: String
      $descriptionDe: String
      $descriptionCa: String
      $descriptionJa: String
      $descriptionKo: String
      $descriptionZhCn: String
      $descriptionRu: String
      $autoTranslate: Boolean
      $dishOrder: Int!
      $restaurants: [String]
    ) {
      createFranchiseDishes(
        input: {
          name: $name
          description: $description
          image: $image
          allergens: $allergens
          restaurant: $restaurantId
          menu: $menuId
          suggested: $suggested
          status: $status
          nameEs: $nameEs
          nameEn: $nameEn
          nameFr: $nameFr
          nameIt: $nameIt
          namePt: $namePt
          nameDe: $nameDe
          nameCa: $nameCa
          nameJa: $nameJa
          nameKo: $nameKo
          nameZhCn: $nameZhCn
          nameRu: $nameRu
          descriptionEs: $descriptionEs
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionIt: $descriptionIt
          descriptionPt: $descriptionPt
          descriptionDe: $descriptionDe
          descriptionCa: $descriptionCa
          descriptionJa: $descriptionJa
          descriptionKo: $descriptionKo
          descriptionZhCn: $descriptionZhCn
          descriptionRu: $descriptionRu
          dishOrder: $dishOrder
        }
        restaurants: $restaurants
        prices: $prices
        autoTranslate: $autoTranslate
      ) {
        ok
        dish {
          id
          nameEs
          status
          image
        }
      }
    }
  `,
  updateFranchiseDishes: gql`
    mutation updateFranchiseDishes(
      $name: String!
      $description: String
      $image: Upload!
      $allergens: [String]
      $suggested: Boolean!
      $status: String!
      $prices: [PriceInput]
      $id: String!
      $nameEs: String
      $nameEn: String
      $nameFr: String
      $nameIt: String
      $namePt: String
      $nameDe: String
      $nameCa: String
      $nameJa: String
      $nameKo: String
      $nameZhCn: String
      $nameRu: String
      $descriptionEs: String
      $descriptionEn: String
      $descriptionFr: String
      $descriptionIt: String
      $descriptionPt: String
      $descriptionDe: String
      $descriptionCa: String
      $descriptionJa: String
      $descriptionKo: String
      $descriptionZhCn: String
      $descriptionRu: String
      $autoTranslate: Boolean
      $dishOrder: Int!
      $restaurants: [String]
    ) {
      updateFranchiseDishes(
        id: $id
        input: {
          name: $name
          description: $description
          image: $image
          allergens: $allergens
          suggested: $suggested
          status: $status
          nameEs: $nameEs
          nameEn: $nameEn
          nameFr: $nameFr
          nameIt: $nameIt
          namePt: $namePt
          nameDe: $nameDe
          nameCa: $nameCa
          nameJa: $nameJa
          nameKo: $nameKo
          nameZhCn: $nameZhCn
          nameRu: $nameRu
          descriptionEs: $descriptionEs
          descriptionEn: $descriptionEn
          descriptionFr: $descriptionFr
          descriptionIt: $descriptionIt
          descriptionPt: $descriptionPt
          descriptionDe: $descriptionDe
          descriptionCa: $descriptionCa
          descriptionJa: $descriptionJa
          descriptionKo: $descriptionKo
          descriptionZhCn: $descriptionZhCn
          descriptionRu: $descriptionRu
          dishOrder: $dishOrder
        }
        restaurants: $restaurants
        prices: $prices
        autoTranslate: $autoTranslate
      ) {
        ok
        dish {
          id
          name
          status
          description
          image
          nameEs
          nameEn
          nameFr
          nameIt
          namePt
          nameDe
          nameCa
          nameJa
          nameKo
          nameZhCn
          nameRu
          descriptionEs
          descriptionEn
          descriptionFr
          descriptionIt
          descriptionPt
          descriptionDe
          descriptionCa
          descriptionJa
          descriptionKo
          descriptionZhCn
          descriptionRu
          dishOrder
          menu {
            name
          }
          urlPage
          restaurant {
            id
            name
          }
          allergens {
            name
          }
        }
      }
    }
  `,
  companies: gql`
    query companies(
      $text: String
      $first: Int
    ) {
      companies(text: $text, first: $first) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            name
            email
            id
            userPlan
            hosteltactilActive
            agoraActive
          }
        }
      }
    }
  `,
  companiesAll: gql`
    {
      companiesAll {
        name
        email
        id
        userPlan
        hosteltactilActive
        agoraActive
      }
    }
  `,
  company: gql`
    query company($id: String!) {
      company(id: $id) {
        id
        name
        email
        userPlan
        hosteltactilActive
        agoraActive
        revoActive
      }
    }
  `,
  createCompany: gql`
  mutation createCompany($input: CompanyInput!) {
    createCompany(input: $input) {
      ok
      company {
        id
        name
      }
    }
  }
`,
updateCompany: gql`
  mutation updateCompany($id: String!, $input: CompanyInput!) {
    updateCompany(id: $id, input: $input) {
      ok
      company {
        id
        name
      }
    }
  }
`,
deleteCompany: gql`
  mutation deleteCompany($id: String!) {
    deleteCompany(id: $id) {
      ok
    }
  }
`,
};
