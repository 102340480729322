import React from "react";

import { Container, Row, Col, FormSelect } from "shards-react";
import { withTranslation } from "react-i18next";
import { client } from "../apolloClient";
import { queries } from "../graphQueries";
import ViewsChart from "./../components/charts/ViewsChart";
import ViewsLanguageChart from "./../components/charts/ViewsLanguageChart";
import AllergensUseChart from "./../components/charts/AllergensUseChart";
import FavoritesDishes from "./../components/charts/FavoritesDishes";
import MostViewed from "./../components/charts/MostViewed";
import { connect } from "react-redux";
import moment from "moment";
import KPIInfo from "../components/charts/KPIInfo";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Select from "react-select";

class RestaurantDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      restaurantId: null,
      restaurants: [],
      restaurantName: this.props.t("all.restaurants"),
      chartViews: [],
      chartViewsLanguage: [],
      kpis: [],
      comments: [],
      most_viewed: [],
      least_viewed: [],
      allergensUse: [],
      favoriteDishes: [],
      fromDate: moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD"),
      toDate: moment()
        .add(1, "days")
        .format("YYYY-MM-DD"),
      showDatePicker: false
    };
  }

  componentDidMount() {
    const datePicker = document.getElementsByClassName("DateRangePicker_1")[0];

    if (this.state.showDatePicker) {
      datePicker.style.display = "inline-block";
    } else {
      datePicker.style.display = "none";
    }

    client.query({ query: queries.dashboardRestaurants }).then(res => {
      this.setState({
        loading: res.loading,
        restaurants: res.data.dashboardRestaurants
      });
      if (res.data.dashboardRestaurants.length === 1) {
        this.setState({
          restaurantId: res.data.dashboardRestaurants[0].id,
          restaurantName: res.data.dashboardRestaurants[0].name,
          loading: false
        });
      }
      this.getData();
    });
  }

  async handleRestaurantChange(val) {
    const restaurantId = val.id;
    const restaurantName = val.name;
    if (restaurantId === "-1") {
      await this.setState({
        restaurantId: null,
        restaurantName: restaurantName,
        loading: false
      });
    } else {
      await this.setState({
        restaurantId: restaurantId,
        restaurantName: restaurantName,
        loading: false
      });
    }

    this.getData();
  }

  getData() {
    const queryChart = queries.views;

    const variablesChart = {
      restaurantId: this.state.restaurantId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate
    };
    client
      .query({ query: queryChart, variables: variablesChart })
      .then((resChart, err) => {
        if (err) console.log(err);
        this.setState({ chartViews: resChart.data.views });
      });

    const queryChartLanguage = queries.viewsLanguage;
    const variablesChartLanguage = {
      restaurantId: this.state.restaurantId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate
    };
    client
      .query({ query: queryChartLanguage, variables: variablesChartLanguage })
      .then((resLanguage, err) => {
        if (err) console.log(err);
        const viewsLanguage = resLanguage.data.viewsLanguage.map(
          (value, index) => {
            value.name = this.props.t(value.name);
            return value;
          }
        );
        this.setState({ chartViewsLanguage: viewsLanguage });
      });

    const queryKpi = queries.kpis;
    const variablesKpi = {
      restaurantId: this.state.restaurantId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate
    };
    client
      .query({ query: queryKpi, variables: variablesKpi })
      .then((resKpi, err) => {
        if (err) console.log(err);
        this.setState({ kpis: resKpi.data.kpis });
      });

    const queryComments = queries.commentsRestaurant;
    const variablesComments = {
      restaurantId: this.state.restaurantId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate
    };
    client
      .query({ query: queryComments, variables: variablesComments })
      .then((resComments, err) => {
        if (err) console.log(err);
        this.setState({ comments: resComments.data.commentsRestaurant });
      });

    const queryDish = queries.mostViewedDishes;
    const variablesDish = {
      restaurantId: this.state.restaurantId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate
    };
    client
      .query({ query: queryDish, variables: variablesDish })
      .then(async (resMost, err) => {
        const mostViewed = resMost.data.mostViewedDishes;
        if (err) console.log(err);
        this.setState({ most_viewed: mostViewed });
      });

    const queryLeastDish = queries.leastViewedDishes;
    client
      .query({ query: queryLeastDish, variables: variablesDish })
      .then(async (resLeast, err) => {
        const leastViewed = resLeast.data.leastViewedDishes;
        if (err) console.log("Error : ", err);
        this.setState({ least_viewed: leastViewed });
      });

    const queryAllergens = queries.allergensUse;
    const variablesAllergens = {
      restaurantId: this.state.restaurantId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate
    };
    client
      .query({ query: queryAllergens, variables: variablesAllergens })
      .then((resDish, err) => {
        if (err) console.log(err);
        this.setState({ allergensUse: resDish.data.allergensUse });
      });

    const queryFavorite = queries.favoriteDishes;
    const variablesFavorite = {
      restaurantId: this.state.restaurantId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate
    };
    client
      .query({ query: queryFavorite, variables: variablesFavorite })
      .then((resDish, err) => {
        const favoriteDishes = resDish.data.favoriteDishes;
        if (err) console.log(err);
        this.setState({ favoriteDishes: favoriteDishes });
      });
  }

  async handleDatesChange(e) {
    const option = e.target[e.target.selectedIndex].value;
    let fromDate;
    switch (parseInt(option)) {
      case 0:
        fromDate = await moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD");

        this.setState({ fromDate: fromDate, showDatePicker: false });
        this.getData();

        break;
      case 1:
        fromDate = await moment()
          .subtract(6, "days")
          .format("YYYY-MM-DD");

        this.setState({ fromDate: fromDate, showDatePicker: false });
        this.getData();

        break;
      case 2:
        fromDate = await moment()
          .startOf("month")
          .format("YYYY-MM-DD");

        this.setState({ fromDate: fromDate, showDatePicker: false });
        this.getData();

        break;
      case 3:
        fromDate = await moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD");

        this.setState({ fromDate: fromDate, showDatePicker: false });
        this.getData();

        break;
      case 4:
        fromDate = await moment()
          .subtract(1, "year")
          .format("YYYY-MM-DD");

        this.setState({ fromDate: fromDate, showDatePicker: false });
        this.getData();

        break;
      case 5:
        await this.setState({
          showDatePicker: true
        });
        break;
      default:
        break;
    }
    const datePicker = document.getElementsByClassName("DateRangePicker_1")[0];

    if (this.state.showDatePicker) {
      datePicker.style.display = "inline-block";
    } else {
      datePicker.style.display = "none";
    }
  }

  render() {
    this.t = this.props.t;

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row className="mb-4 mt-3">
          <Col md="5">
            <h3>
              {this.t("dashboard.title") + " " + this.state.restaurantName}
            </h3>
          </Col>

          <Col md={{ size: 2 }}>
            <Select
              className="mt-2"
              defaultValue={{
                name: this.t("dashboard.selectOneRestaurant"),
                id: -1
              }}
              onChange={e => this.handleRestaurantChange(e)}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => `${option.id}`}
              options={this.state.restaurants}
            />
          </Col>

          <Col md={{ size: 2 }}>
            <FormSelect
              className="mt-2"
              onChange={e => this.handleDatesChange(e)}
              defaultValue={0}
            >
              <option value="0">{this.t("dashboard.lastMonth")}</option>
              <option value="1">{this.t("dashboard.lastWeek")}</option>
              <option value="2">{this.t("dashboard.thisMonth")}</option>
              <option value="3">{this.t("dashboard.last3months")}</option>
              <option value="4">{this.t("dashboard.thisYear")}</option>
              <option value="5">{this.t("dashboard.selectDate")}</option>
            </FormSelect>
          </Col>
          <Col md={{ size: 3 }}>
            <DateRangePicker
              startDate={moment(this.state.fromDate, "YYYY-MM-DD")} // momentPropTypes.momentObj or null,
              startDateId="start_date_picker" // PropTypes.string.isRequired,
              endDate={moment(this.state.toDate, "YYYY-MM-DD")} // momentPropTypes.momentObj or null,
              endDateId="start_date_picker" // PropTypes.string.isRequired,
              onDatesChange={async ({ startDate, endDate }) => {
                if (endDate) {
                  await this.setState({
                    fromDate: startDate.format("YYYY-MM-DD"),
                    toDate: endDate.add(1, "days").format("YYYY-MM-DD")
                  });
                  this.getData();
                }
              }} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              minDate={moment("2019-10-02", "YYYY-MM-DD")}
              maxDate={moment()}
              disabled={false}
              enableOutsideDays={false}
              horizontalMargin={0}
              isDayBlocked={day => {
                if (
                  day <= moment("2019-10-02", "YYYY-MM-DD") ||
                  day > moment()
                ) {
                  return true;
                } else {
                  return false;
                }
              }}
              isDayHighlighted={function noRefCheck() {}}
              isOutsideRange={function noRefCheck() {}}
              isRTL={false}
              keepOpenOnDateSelect={false}
              minimumNights={2}
              navNext={null}
              navPosition="navPositionTop"
              navPrev={null}
              numberOfMonths={2}
              onClose={function noRefCheck() {}}
              onNextMonthClick={function noRefCheck() {}}
              onPrevMonthClick={function noRefCheck() {}}
              orientation="horizontal"
              renderCalendarDay={undefined}
              renderDayContents={null}
              renderMonthText={null}
              reopenPickerOnClearDates={false}
              required={false}
              showClearDates={false}
              showDefaultInputIcon={false}
              firstDayOfWeek={1}
            />
          </Col>
        </Row>

        <Row>
          {this.state.kpis.map((kpi, idx) => (
            <Col className="col-lg mb-4 card-kpi" key={idx} {...kpi.attrs}>
              <KPIInfo
                id={`small-stats-${idx}`}
                variation="1"
                label={kpi.name}
                value={kpi.value}
                className={kpi.className}
                subkpis={kpi.subkpi}
              />
            </Col>
          ))}
        </Row>

        <Row className="mb-4 mt-3">
          <Col md="5">
            <h3>{this.t("dashboard.traffic")}</h3>
          </Col>
        </Row>

        <Row>
          {/* Views charts */}
          <Col lg="8" md="12" sm="12" className="mb-4 chart-views-date">
            <ViewsChart
              data={this.state.chartViews}
              title={this.t("dashboard.views")}
              fromDate={this.state.fromDate}
            />
          </Col>

          {/* Views by Language */}
          <Col lg="4" md="6" sm="12" className="mb-4 chart-views-language">
            <ViewsLanguageChart
              data={this.state.chartViewsLanguage}
              title={this.t("dashboard.viewsLanguage")}
              restaurantId={this.state.restaurantId}
            />
          </Col>
        </Row>
        <Row className="mb-4 mt-3">
          <Col md="5">
            <h3>{this.t("dashboard.dishes")}</h3>
          </Col>
        </Row>
        <Row>
          {/* Top Referrals */}
          <Col xl="6" lg="6" md="12" sm="12" className="mb-4 col-xxl-5">
            <MostViewed
              mostViewed={this.state.most_viewed}
              restaurant={this.state.restaurantId}
              leastViewed={this.state.least_viewed}
            />
          </Col>

          {/* New Draft */}
          <Col xl="6" lg="6" md="6" sm="12" className="mb-4 col-xxl-4">
            <AllergensUseChart
              data={this.state.allergensUse}
              title={this.t("dashboard.viewsAllergen")}
            />
          </Col>

          {/* Comments */}
          <Col xl="6" lg="6" md="6" sm="12" className="mb-4  col-xxl-3">
            <FavoritesDishes
              dishes={this.state.favoriteDishes}
              title={this.t("favoritos")}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  username: state.user.username
});

const bindActions = dispatch => ({});

export default connect(
  mapStateToProps,
  bindActions
)(withTranslation()(RestaurantDashboard));
