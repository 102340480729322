import {
  Button,
  Col,
  Row,
  FormSelect
} from "shards-react";

import React, { useState, useEffect } from "react";
import { Multiselect } from 'react-widgets';
import "../shards-dashboard/styles/styles.css"

const ConfigHourComponent = ({ index, orderHour, parent, removeHoursConfig, t }) => {
  /*orderHour.startHour = orderHour.startTime && orderHour.startHour === '' ? orderHour.startTime.split(":")[0] : orderHour.startHour;
  orderHour.startMinute = orderHour.startTime && orderHour.startMinute === '' ? orderHour.startTime.split(":")[1] : orderHour.startMinute;
  orderHour.endHour = orderHour.endTime && orderHour.endHour === '' ? orderHour.endTime.split(":")[0] : orderHour.endHour;
  orderHour.endMinute = orderHour.endTime && orderHour.endMinute === '' ? orderHour.endTime.split(":")[1] : orderHour.endMinute;*/
  const [state, setState] = useState({
    id: orderHour.id,
    startHour: orderHour.startTime ? orderHour.startTime.split(":")[0] : '',
    startMinute: orderHour.startTime ? orderHour.startTime.split(":")[1] : '',
    endHour: orderHour.endTime ? orderHour.endTime.split(":")[0] : '',
    endMinute: orderHour.endTime ? orderHour.endTime.split(":")[1] : '',
    days: orderHour.days,
    index: index,
  });

  const hoursOptions = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];
  const minutesOptions = ['00', '15', '30', '45'];
  const daysOptions = [
    { id: 1, name: 'Lunes' },
    { id: 2, name: 'Martes' },
    { id: 3, name: 'Miercoles' },
    { id: 4, name: 'Jueves' },
    { id: 5, name: 'Viernes' },
    { id: 6, name: 'Sábado' },
    { id: 0, name: 'Domingo' },
  ];
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
    orderHour[name] = value;
  };

  const multiOnChange = (event, type) => {
    setState((prevState) => ({
      ...prevState,
      days: event,
    }));
    orderHour.days = event;
  };

  return (
    <Row>
      <Col md={1}>
        <FormSelect
          onChange={handleChange}
          id={ `start-hour${index}`}
          name="startHour"
          defaultValue={state.startHour}
        >
          <option key={-1} id={-1}>
            {t('form.selectOne')}
          </option>
          {hoursOptions.map((hoursOption, index) => (
            <option
              key={index}
              value={hoursOption}
            >
              {hoursOption}
            </option>
          ))}
        </FormSelect>
      </Col>
      <Col md={1}>
        <FormSelect
          onChange={handleChange}
          id={ `start-minute${index}`}
          name="startMinute"
          defaultValue={state.startMinute}
        >
          <option key={-1} id={-1}>
            {t('form.selectOne')}
          </option>
          {minutesOptions.map((hoursOption, index) => (
            <option
              key={index}
              value={hoursOption}
            >
              {hoursOption}
            </option>
          ))}
        </FormSelect>
      </Col>
      <Col md={1}>
        <FormSelect
          onChange={handleChange}
          id={ `end-hour${index}`}
          name="endHour"
          defaultValue={state.endHour}
        >
          <option key={-1} id={-1}>
            {t('form.selectOne')}
          </option>
          {hoursOptions.map((hoursOption, index) => (
            <option
              key={index}
              value={hoursOption}
            >
              {hoursOption}
            </option>
          ))}
        </FormSelect>
      </Col>
      <Col md={1}>
        <FormSelect
          onChange={handleChange}
          id={ `end-minute${index}`}
          name="endMinute"
          defaultValue={state.endMinute}
        >
          <option key={-1} id={-1}>
            {t('form.selectOne')}
          </option>
          {minutesOptions.map((hoursOption, index) => (
            <option
              key={index}
              value={hoursOption}
            >
              {hoursOption}
            </option>
          ))}
        </FormSelect>
      </Col>
      <Col md="7" className="form-group">
        <Multiselect
          defaultValue={[]}
          data={daysOptions}
          value={state.days}
          onChange={(e) =>
            multiOnChange(e, 'days')
          }
          textField="name"
          valueField="id"
        />
      </Col>
      <Col md="1" className="form-group">
        <Button intent='warning' onClick={(e)=>removeHoursConfig(index, parent)}><i className="material-icons">delete_outline</i></Button>
      </Col>
    </Row>
  )
};

export default ConfigHourComponent;