import React from 'react';
import Dropzone from 'react-dropzone';
import Spinner from 'react-spinner-material';
import { Query } from 'react-apollo';
import { client } from '../../apolloClient';
import Geosuggest from 'react-geosuggest';
import { withTranslation } from 'react-i18next';
import { Multiselect } from 'react-widgets';
import { connect } from 'react-redux';
import { queries } from '../../graphQueries';
import {
  CardHeader,
  Card,
  Container,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect,
  Popover,
  PopoverBody,
  PopoverHeader,
  FormRadio,
  Collapse,
  CardBody,
  FormCheckbox,
} from 'shards-react';
import { Link } from 'react-router-dom';
import MenuDnd from '../../components/MenuDnd';
import MenuModalForm from '../../components/MenuModalForm';
import FullMenuModalForm from '../../components/FullMenuModalForm';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import {
  MEDIA_FILES_APPEND,
  restaurantsLanguagesFields,
  FLAGS_ICONS_APPEND,
  avaibleFlags,
  USER_LAMB_WESTON,
  USER_PREMIUM,
} from '../../config';
import savedOkGif from '../../assets/check.gif';

import '../../shards-dashboard/styles/geosuggest.css';
import '../../shards-dashboard/styles/styles.css';
import { ChromePicker } from 'react-color';
import UploadCsvDish from '../../components/UploadCsvDish';
import Select from 'react-select';

class RestaurantForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      restaurant: {
        name: '',
        description: '',
        address: '',
        email: '',
        linkedin: '',
        twitter: '',
        instagram: '',
        cuisines: [],
        city: {
          name: '',
          id: '-1',
        },
        lifeStyle: {
          name: '',
          id: '-1',
        },
        logoImage: [],
        image: [],
        latitude: '',
        longitude: '',
        postalCode: '',
        services: [],
        user: {
          userPlan: '',
          name: '',
          id: '-1',
          hostelTactil: {
            accessToken: '',
          },
        },
        company: {
          userPlan: '',
          name: '',
          id: '-1',
        },
        fullMenus: { edges: [] },
        priceRange: '1',
        placeId: '',
        id: '',
        status: 'WA',
        showPriceRange: true,
        bookUrl: '',
        urlPage: '',
        phone: '',
        avaibleLanguages: ['es'],
        suggested: false,
        customFont: '',
        customFontLink: '',
        customLink: '',
        customPrimaryColor: '',
        customSecondaryColor: '',
        customLinkName: '',
        showSearch: true,
        ht_username: '',
        ht_password: '',
        agoraApiUrl: null,
        agoraApiToken: null,
        tenedorRestaurantId: null,
        foodytOrders: false,
        ordersStopped: false,
        activeOn: [],
        sala: true,
        delivery: false,
        takeaway: false,
      },
      gmapsSearched: false,
      loading: true,
      isEdit: false,
      requiredFieldsError: false,
      modalToggled: false,
      modalFullToggled: false,
      modalType: '',
      newMenuOrder: '',
      isMenuEdit: false,
      isFullMenuEdit: false,
      toEditMenu: false,
      toEditFullMenu: false,
      toDeleteId: '',
      toDeleteName: '',
      deleteModalToggled: false,
      collapse: false,
      saveOk: false,
      popOver: false,
      autoTranslate: false,
      isRestaurant: false,
      restaurants: [],
      customStyleCollapse: false,
      hostelTactilCollapse: false,
      selectedFontIndex: -1,
      reloadMenuDnD: new Date(),
      menuCollapse: false,
      fullMenuId: null,
      deleteModel: 'Menu'
    };
    this.t = this.props.t;
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalFull = this.toggleModalFull.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.togglePopOver = this.togglePopOver.bind(this);
    this.loadRestaurant = this.loadRestaurant.bind(this);
    this.setSaveOk = this.setSaveOk.bind(this);
    this.fontsArray = [
      {
        name: 'Roboto',
        css: "'Roboto', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Roboto&display=swap',
      },
      {
        name: 'Open Sans',
        css: "'Open Sans', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
      },
      {
        name: 'Lato',
        css: "'Lato', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Lato&display=swap',
      },
      {
        name: 'Slabo 27px',
        css: "'Slabo 27px', serif",
        link: 'https://fonts.googleapis.com/css?family=Slabo+27px&display=swap',
      },
      {
        name: 'Oswald',
        css: "'Oswald', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Oswald&display=swap',
      },
      {
        name: 'Source Sans Pro',
        css: "'Source Sans Pro', sans-serif",
        link:
          'https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap',
      },
      {
        name: 'Montserrat',
        css: "'Montserrat', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Montserrat&display=swap',
      },
      {
        name: 'Raleway',
        css: "'Raleway', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Raleway&display=swap',
      },
      {
        name: 'PT Sans',
        css: "'PT Sans', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=PT+Sans&display=swap',
      },
      {
        name: 'Lora',
        css: "'Lora', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Lora&display=swap',
      },
      {
        name: 'Noto Sans',
        css: "'Noto Sans', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Noto+Sans&display=swap',
      },
      {
        name: 'Nunito Sans',
        css: "'Nunito Sans', sans-serif",
        link:
          'https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap',
      },
      {
        name: 'Concert One',
        css: "'Concert One', sans-serif",
        link:
          'https://fonts.googleapis.com/css?family=Concert+One&display=swap',
      },
      {
        name: 'Work Sans',
        css: "'Work Sans', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Work+Sans&display=swap',
      },
      {
        name: 'Prompt',
        css: "'Prompt', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Prompt&display=swap',
      },
    ];
    this.selectStyles = {
      control: (styles) => ({
        ...styles,
        height: '30px',
        'padding-top': '2px',
        fontSize: '12px',
      }),
    };
  }

  componentWillMount() {
    this.loadRestaurant();
  }

  componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.isRestaurant) {
        const query = queries.restaurantByUsername;
        const variables = { username: nextProps.username };
        client.query({ variables, query }).then((res, err) => {
          if (err) console.log(err);
          
          this.setState((prevState) => ({
            restaurant: {
              ...prevState.restaurant,
              ...res.data[Object.keys(res.data)[0]],
              description: res.data[Object.keys(res.data)[0]].descriptionEs,
            },
            isRestaurant: true,
            loading: false,
          }));

          res.data[Object.keys(res.data)[0]].activeOn.forEach((value) => {
            if (value === 'SA') {
              this.setState( prev => ({
                restaurant: {
                  ...prev.restaurant,
                  sala: true
                }
              }))
            } else if (value === 'DE') {
              this.setState( prev => ({
                restaurant: {
                  ...prev.restaurant,
                  delivery: true
                }
              }))
            } else if (value === 'TA') {
              this.setState( prev => ({
                restaurant: {
                  ...prev.restaurant,
                  takeaway: true
                }
              }))
            }
          });

        });
        client
          .query({
            query: queries.userRestaurants,
            variables: { username: nextProps.username },
          })
          .then((res) => {
            this.setState({
              loading: res.loading,
              restaurants: res.data.userRestaurants,
            });
          });
      }
    } catch (err) {}
  }

  loadRestaurant() {
    try {
      this.setState({ modalFullToggled: false });
      if (this.props.isStaff) {
        if (this.props.location.state.entity) {
          this.setState({ loading: true });
          const variables = { id: this.props.location.state.entity.id };
          const query = queries.restaurant;
          client
            .query({ variables, query, fetchPolicy: 'network-only' })
            .then((res) => {
              let color =
                res.data[Object.keys(res.data)[0]].company.userPlan ===
                USER_LAMB_WESTON
                  ? '#003b64'
                  : '#0DD1A9';
              if (res.data[Object.keys(res.data)[0]].customPrimaryColor) {
                color = res.data[Object.keys(res.data)[0]].customPrimaryColor;
              }

              this.setState((prevState) => ({
                restaurant: {
                  ...prevState.restaurant,
                  ...res.data[Object.keys(res.data)[0]],
                  description: res.data[Object.keys(res.data)[0]].descriptionEs,
                  customPrimaryColor: color,
                  customSecondaryColor: res.data[Object.keys(res.data)[0]]
                    .customSecondaryColor
                    ? res.data[Object.keys(res.data)[0]].customSecondaryColor
                    : '#FFFFFF',
                },
                isEdit: true,
                loading: false,
              }));

              res.data[Object.keys(res.data)[0]].activeOn.forEach((value) => {
                if (value === 'SA') {
                  this.setState( prev => ({
                    restaurant: {
                      ...prev.restaurant,
                      sala: true
                    }
                  }))
                } else if (value === 'DE') {
                  this.setState( prev => ({
                    restaurant: {
                      ...prev.restaurant,
                      delivery: true
                    }
                  }))
                } else if (value === 'TA') {
                  this.setState( prev => ({
                    restaurant: {
                      ...prev.restaurant,
                      takeaway: true
                    }
                  }))
                }
              });

              this.fontsArray.forEach((value, index) => {
                if (
                  value.css === res.data[Object.keys(res.data)[0]].customFont
                ) {
                  this.setState({ selectedFontIndex: index });
                }
              });
            });
        } else {
          this.setState({ loading: false });
        }
      } else if (this.props.isRestaurant) {
        const query = queries.restaurantByUsername;
        const variables = { username: this.props.username };
        client
          .query({ variables, query, fetchPolicy: 'network-only' })
          .then((res, err) => {
            if (err) console.log(err);
            this.setState((prevState) => ({
              restaurant: {
                ...prevState.restaurant,
                ...res.data[Object.keys(res.data)[0]],
                description: res.data[Object.keys(res.data)[0]].descriptionEs,
              },
              isEdit: true,
              isRestaurant: true,
              loading: false,
            }));
          });
        client
          .query({
            query: queries.userRestaurants,
            variables: { username: this.props.username },
          })
          .then((res) => {
            this.setState({
              loading: res.loading,
              restaurants: res.data.userRestaurants,
            });
          });
      }
    } catch (err) {}
  }

  handleRestaurantChange(e) {
    const restaurantId = e.target[e.target.selectedIndex].id;
    const graphQuery = queries.restaurant;
    const variables = { id: restaurantId };
    client.query({ query: graphQuery, variables }).then((res) => {
      this.setState((prevState) => ({
        restaurant: {
          ...prevState.restaurant,
          ...res.data[Object.keys(res.data)[0]],
          description: res.data[Object.keys(res.data)[0]].descriptionEs,
        },
        isEdit: true,
        loading: false,
      }));
    });
  }

  handleFontChange(e) {
    const fontIndex = e.target[e.target.selectedIndex].id;
    if (fontIndex > -1) {
      this.setState((prevState) => ({
        ...prevState,
        restaurant: {
          ...prevState.restaurant,
          customFont: this.fontsArray[fontIndex]['css'],
          customFontLink: this.fontsArray[fontIndex]['link'],
        },
      }));
      this.setState({ selectedFontIndex: fontIndex });
    }
  }

  toggleModal(
    fullMenuId,
    newMenuOrder,
    isEdit = false,
    toEditMenu = {},
    refetchFunction
  ) {
    this.refetchFunction = refetchFunction;
    this.setState(
      {
        fullMenuId,
        newMenuOrder,
        isMenuEdit: isEdit,
        toEditMenu,
      },
      () => this.setState({ modalToggled: !this.state.modalToggled })
    );
  }

  toggleModalFull(isEdit = false, toEditFullMenu = {}, refetchFunction) {
    this.refetchFunction = refetchFunction;
    this.setState(
      {
        isFullMenuEdit: isEdit,
        toEditFullMenu,
      },
      () => this.setState({ modalFullToggled: !this.state.modalFullToggled })
    );
  }

  toggleDeleteModal(
    toDeleteId = '',
    toDeleteName = '',
    refetchFunction,
    deleteModel = 'Menu'
  ) {
    this.refetchFunction = refetchFunction;
    if (this.state.deleteModalToggled) {
      this.setState({
        deleteModalToggled: !this.state.deleteModalToggled,
      });
    } else {
      this.setState(
        {
          toDeleteId,
          toDeleteName,
          deleteModel,
        },
        () =>
          this.setState({ deleteModalToggled: !this.state.deleteModalToggled })
      );
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== 'city' && name !== 'user' && name !== 'lifeStyle') {
      this.setState((prevState) => ({
        restaurant: {
          ...prevState.restaurant,
          [name]: value,
        },
      }));
    } else {
      const id = event.target[event.target.selectedIndex].id;
      const text = event.target[event.target.selectedIndex].text;
      if (name === 'user') {
        const email = event.target[event.target.selectedIndex].getAttribute(
          'dataemail'
        );
        let color =
          event.target[event.target.selectedIndex].getAttribute('dataplan') ===
          USER_LAMB_WESTON
            ? '#003b64'
            : '#0DD1A9';
        if (this.state.restaurant.customPrimaryColor) {
          color = this.state.restaurant.customPrimaryColor;
        }
        this.setState((prevState) => ({
          restaurant: {
            ...prevState.restaurant,
            email,
            customPrimaryColor: color,
            [name]: {
              id,
              name: text,
            },
          },
        }));
      } else {
        this.setState((prevState) => ({
          restaurant: {
            ...prevState.restaurant,
            [name]: {
              id,
              name: text,
            },
          },
        }));
      }
    }
  };

  handleUserChange(val) {
    const email = val.email;
    let color = val.userPlan === USER_LAMB_WESTON ? '#003b64' : '#0DD1A9';
    if (this.state.restaurant.customPrimaryColor) {
      color = this.state.restaurant.customPrimaryColor;
    }
    this.setState((prevState) => ({
      restaurant: {
        ...prevState.restaurant,
        email,
        customPrimaryColor: color,
        user: {
          ...prevState.restaurant.user,
          id: val.id,
          username: val.username,
          userPlan: val.userPlan,
        },
      },
    }));
  }

  handleCompanyChange(val) {
    const email = val.email;
    let color = val.userPlan === USER_LAMB_WESTON ? '#003b64' : '#0DD1A9';
    if (this.state.restaurant.customPrimaryColor) {
      color = this.state.restaurant.customPrimaryColor;
    }
    this.setState((prevState) => ({
      restaurant: {
        ...prevState.restaurant,
        email,
        customPrimaryColor: color,
        company: {
          ...prevState.restaurant.company,
          id: val.id,
          name: val.name,
          userPlan: val.userPlan,
        },
      },
    }));
  }

  multiOnChange = (event, type) => {
    if (type === 'CuisineType') {
      this.setState((prevState) => ({
        restaurant: { ...prevState.restaurant, cuisines: event },
      }));
    } else if (type === 'ServiceType') {
      this.setState((prevState) => ({
        restaurant: { ...prevState.restaurant, services: event },
      }));
    }
  };

  setSaveOk = () => {
    this.setState({ saveOk: true }, () =>
      setTimeout(() => this.setState({ saveOk: false }), 3250)
    );
  };

  onDropIcon = (icon) => {
    this.setState((prevState) => ({
      restaurant: { ...prevState.restaurant, logoImage: icon },
    }));
  };

  onDropCover = (cover) => {
    this.setState((prevState) => ({
      restaurant: { ...prevState.restaurant, image: cover },
    }));
  };

  collapseFullMenu = (index) => {
    const fullMenus = this.state.restaurant.fullMenus.edges;
    fullMenus[index].node.collapseOpen = !fullMenus[index].node.collapseOpen;
    this.setState((prevState) => ({
      restaurant: { ...prevState.restaurant, fullMenus: { edges: fullMenus } },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.someRequiredFieldIsEmpty()) {
      this.setState({ requiredFieldsError: true });
    } else {
      if (this.state.requiredFieldsError && !this.someRequiredFieldIsEmpty()) {
        this.setState({ requiredFieldsError: false });
      }
      this.setState({ loading: true });

      const activeOn = [];
      if(this.state.restaurant.sala) activeOn.push('SA');
      if(this.state.restaurant.delivery) activeOn.push('DE');
      if(this.state.restaurant.takeaway) activeOn.push('TA');

      const mutation = this.state.isEdit
        ? queries.updateRestaurant
        : queries.createRestaurant;
      const variables = {
        name: this.state.restaurant.name,
        description: this.state.restaurant.description,
        address: this.state.restaurant.address,
        email: this.state.restaurant.email,
        twitter: this.state.restaurant.twitter,
        instagram: this.state.restaurant.instagram,
        cuisines: this.state.restaurant.cuisines.map((cuisine) => cuisine.id),
        city: this.state.restaurant.city.id,
        lifeStyle: !this.state.restaurant.lifeStyle
          ? null
          : this.state.restaurant.lifeStyle.id,
        logoImage:
          typeof this.state.restaurant.logoImage === 'object'
            ? this.state.restaurant.logoImage[0]
            : this.state.restaurant.logoImage,
        image:
          typeof this.state.restaurant.image === 'object'
            ? this.state.restaurant.image[0]
            : this.state.restaurant.image,
        latitude: this.state.restaurant.latitude,
        longitude: this.state.restaurant.longitude,
        postalCode: this.state.restaurant.postalCode,
        services: this.state.restaurant.services.map((service) => service.id),
        //user: this.state.restaurant.user.id,
        company: this.state.restaurant.company.id,
        priceRange: this.state.restaurant.priceRange || '1',
        placeId: this.state.restaurant.placeId,
        status: this.state.restaurant.status,
        showPriceRange: this.state.restaurant.showPriceRange,
        descriptionEs: this.state.restaurant.description,
        descriptionEn: this.state.restaurant.descriptionEn,
        descriptionFr: this.state.restaurant.descriptionFr,
        descriptionIt: this.state.restaurant.descriptionIt,
        descriptionPt: this.state.restaurant.descriptionPt,
        descriptionDe: this.state.restaurant.descriptionDe,
        descriptionCa: this.state.restaurant.descriptionCa,
        descriptionJa: this.state.restaurant.descriptionJa,
        descriptionKo: this.state.restaurant.descriptionKo,
        descriptionZhCn: this.state.restaurant.descriptionZhCn,
        descriptionRu: this.state.restaurant.descriptionRu,
        autoTranslate: this.state.autoTranslate,
        bookUrl: this.state.restaurant.bookUrl,
        phone: this.state.restaurant.phone,
        avaibleLanguages: this.state.restaurant.avaibleLanguages,
        urlPage: this.state.restaurant.urlPage,
        suggested: this.state.restaurant.suggested,
        customPrimaryColor: this.state.restaurant.customPrimaryColor,
        customSecondaryColor: this.state.restaurant.customSecondaryColor,
        customFont: this.state.restaurant.customFont,
        customFontLink: this.state.restaurant.customFontLink,
        customLink: this.state.restaurant.customLink,
        customLinkName: this.state.restaurant.customLinkName,
        showSearch: this.state.restaurant.showSearch,
        htPassword: this.state.restaurant.ht_password,
        htUsername: this.state.restaurant.ht_username,
        agoraInput: {
          agoraApiUrl: this.state.restaurant.agoraApiUrl,
          agoraApiToken: this.state.restaurant.agoraApiToken,
        },
        tenedorRestaurantId: this.state.restaurant.tenedorRestaurantId,
        foodytOrders: this.state.restaurant.foodytOrders,
        ordersStopped: this.state.restaurant.ordersStopped,
        activeOn: activeOn
      };

      if (this.state.isEdit) {
        variables.id = this.state.restaurant.id;
      }

      client
        .mutate({
          variables,
          mutation,
        })
        .then((res, err) => {
          if (err) console.log(err);
          if (!this.state.isRestaurant) {
            this.props.history.push({
              pathname: '/restaurants',
              state: { noRefetch: true },
            });
            return;
          }
          this.setState({ loading: false });
        });
    }
  };

  changeStatus = (status) => {
    if (this.state.isEdit) {
      this.setState((prevState) => ({
        restaurant: { ...prevState.restaurant, status },
      }));
    }
  };

  onSuggestSelect = (suggest) => {
    this.setState(
      (prevState) => ({
        gmapsSearched: true,
        restaurant: {
          ...prevState.restaurant,
          name: suggest.gmaps.name,
          address: suggest.gmaps.formatted_address,
          latitude: suggest.location.lat,
          longitude: suggest.location.lng,
          postalCode: suggest.gmaps.address_components.filter((e) =>
            e.types.includes('postal_code')
          )[0].long_name,
          placeId: suggest.placeId,
          phone: suggest.gmaps.formatted_phone_number,
          city: {
            ...prevState.city,
            name: suggest.gmaps.address_components.filter((e) =>
              e.types.includes('locality')
            )[0].long_name,
          },
        },
      }),
      () => {
        const cityOptionIndex = document.getElementById('city').selectedIndex;
        const cityOptions = document.getElementById('city').options;
        this.setState((prevState) => ({
          restaurant: {
            ...prevState.restaurant,
            city: {
              ...prevState.city,
              id: cityOptions[cityOptionIndex].id,
            },
          },
        }));
      }
    );
  };

  togglePopOver() {
    this.setState({
      popOver: !this.state.popOver,
    });
  }

  handleLanguageChange(e, prop) {
    const newValue = e.target.value;
    this.setState((prevState) => ({
      restaurant: { ...prevState.restaurant, [prop]: newValue },
    }));
  }

  someRequiredFieldIsEmpty = () => {
    let res =
      this.state.restaurant.cuisines.length === 0 ||
      !this.state.restaurant.logoImage ||
      !this.state.restaurant.image ||
      !this.state.restaurant.city;

    if (this.state.restaurant.company) {
      res = res || this.state.restaurant.company === '-1';
    } else {
      res = true;
    }

    return res;
  };

  handleFlagChange = (flag, flagIndex) => {
    if (!this.state.isRestaurant) {
      if (
        this.state.restaurant.company.userPlan === 'FRE' &&
        this.state.restaurant.avaibleLanguages.length >= 1 &&
        !this.state.restaurant.avaibleLanguages.includes(flag)
      ) {
        return;
      }
      if (flagIndex > -1) {
        const avaibleLanguages = this.state.restaurant.avaibleLanguages;
        avaibleLanguages.splice(flagIndex, 1);
        this.setState((prevState) => ({
          restaurant: { ...prevState.restaurant, avaibleLanguages },
        }));
      } else {
        this.setState((prevState) => ({
          restaurant: {
            ...prevState.restaurant,
            avaibleLanguages: prevState.restaurant.avaibleLanguages
              ? [...prevState.restaurant.avaibleLanguages, flag]
              : [flag],
          },
        }));
      }
    }
  };

  handleChangeCompleteFont = (color) => {
    this.setState((prevState) => ({
      restaurant: {
        ...prevState.restaurant,
        customSecondaryColor: color.hex,
      },
    }));
  };

  handleChangeCompleteCustomColor = (color) => {
    this.setState((prevState) => ({
      restaurant: {
        ...prevState.restaurant,
        customPrimaryColor: color.hex,
      },
    }));
  };

  render() {
    if (!this.state.loading) {
      return (
        <Container className="pt-4">
          <Card className="pb-3">
            <CardHeader>
              <Row>
                <Col md="4">
                  <h3 className="float-left">
                    {this.state.isEdit
                      ? `${this.t('form.editing')} ${
                          this.state.restaurant.name
                        }`
                      : this.state.isRestaurant
                      ? this.state.restaurant.name
                      : this.t('form.newRestaurant')}
                  </h3>
                </Col>
                <Col className="d-flex flex-row align-items-center" md="4">
                  <i
                    style={{ fontSize: '1.4rem', marginRight: 4 }}
                    onClick={this.togglePopOver}
                    id="popover-info"
                    className="cursor-pointer material-icons"
                  >
                    info
                  </i>
                  <Popover
                    placement="bottom"
                    open={this.state.popOver}
                    toggle={this.togglePopOver}
                    target="#popover-info"
                  >
                    <PopoverHeader>
                      {this.t('info.popoverheader')}
                    </PopoverHeader>
                    <PopoverBody>{this.t('info.popoverbody')}</PopoverBody>
                  </Popover>
                  <Geosuggest
                    disabled={this.state.isRestaurant}
                    className="w-100 m-0"
                    inputClassName="p-1"
                    country="ES"
                    types={['establishment']}
                    minLength={5}
                    placeDetailFields={[
                      'address_components',
                      'formatted_address',
                      'name',
                      'place_id',
                      'formatted_phone_number',
                    ]}
                    onSuggestSelect={this.onSuggestSelect}
                  />
                </Col>
                <Col md={{ size: 2, offset: 2 }}>
                  {!this.state.isRestaurant && (
                    <Button
                      tag={Link}
                      disabled={this.state.isRestaurant}
                      outline
                      theme="danger"
                      className="mt-1 float-right"
                      to={{
                        pathname: `/restaurants`,
                        state: {
                          noRefetch: true,
                        },
                      }}
                    >
                      {this.t('back')}
                    </Button>
                  )}
                  {this.state.isRestaurant && (
                    <FormSelect
                      className="mt-3"
                      onChange={(e) => this.handleRestaurantChange(e)}
                      defaultValue={this.state.restaurantName}
                    >
                      <option key={-1} id={-1}>
                        {this.t('form.selectOneRestaurant')}
                      </option>
                      {this.state.restaurants.map((restaurant, index) => (
                        <option key={index} id={restaurant.id}>
                          {restaurant.name}
                        </option>
                      ))}
                    </FormSelect>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <Row>
              <Col md="8">
                <Col md="12">
                  <Form onSubmit={this.handleSubmit}>
                    <Card small className="ml-2">
                      <CardHeader className="border-bottom">
                        <h6 className="m-0 float-left">
                          {this.t('restaurantForm.info')}
                        </h6>
                      </CardHeader>
                      <Row className="m-2">
                        <Col md="5" className="form-group">
                          <label htmlFor="name">
                            {this.t('restaurantForm.name')}
                            <span className="required-input"> *</span>
                          </label>
                          <FormInput
                            disabled={this.state.isRestaurant}
                            id="name"
                            onChange={this.handleChange}
                            required={true}
                            name="name"
                            value={this.state.restaurant.name}
                          />
                        </Col>
                        <Col md="7">
                          <label htmlFor="description">
                            {this.t('restaurantForm.description')}
                            <span className="required-input"> *</span>
                          </label>
                          <FormTextarea
                            id="description"
                            onChange={this.handleChange}
                            value={this.state.restaurant.description || ''}
                            required={true}
                            name="description"
                          />
                        </Col>
                      </Row>
                      <Row className="m-2">
                        <Col md="8">
                          <label htmlFor="address">
                            {this.t('restaurantForm.address')}
                            <span className="required-input"> *</span>
                          </label>
                          <FormInput
                            id="address"
                            onChange={this.handleChange}
                            value={this.state.restaurant.address}
                            required={true}
                            name="address"
                          />
                        </Col>
                        <Col md="4" style={{ marginTop: 33 }}>
                          <FormCheckbox
                            disabled={
                              this.state.isRestaurant ||
                              this.state.restaurant.company.userPlan === 'FRE'
                            }
                            checked={this.state.autoTranslate}
                            onChange={() =>
                              this.setState({
                                autoTranslate: !this.state.autoTranslate,
                              })
                            }
                          >
                            {this.t('dishForm.autoTranslate')}
                          </FormCheckbox>
                        </Col>
                      </Row>
                      <Row className="m-2">
                        <Col md="4">
                          <label htmlFor="company">
                            {this.t('restaurantForm.company')}
                            <span className="required-input"> *</span>
                          </label>
                          {this.props.isStaff && (
                            <Query query={queries.companiesAll}>
                              {({ loading, error, data }) => {
                                if (loading) return 'Loading...';
                                if (error) return `Error ${error.message}`;
                                return (
                                  <Select
                                    id="company"
                                    name="company"
                                    styles={this.selectStyles}
                                    small={true}
                                    defaultValue={
                                      this.state.isEdit && this.state.restaurant
                                        ? {
                                            name: this.state.restaurant.company.name,
                                            id: this.state.restaurant.company.id,
                                          }
                                        : {
                                            name: this.t('form.selectOne'),
                                            id: -1,
                                          }
                                    }
                                    onChange={(e) => this.handleCompanyChange(e)}
                                    getOptionLabel={(option) =>
                                      `${option.name}`
                                    }
                                    getOptionValue={(option) => `${option.id}`}
                                    options={data.companiesAll}
                                  />
                                );
                              }}
                            </Query>
                          )}
                          {this.props.isRestaurant && (
                            <FormSelect
                              disabled={this.state.iurlPagesRestaurant}
                              onChange={this.handleChange}
                              id="user"
                              name="user"
                              defaultValue={this.props.username}
                            >
                              <option key={-1} id={-1}>
                                {this.props.username}
                              </option>
                            </FormSelect>
                          )}
                        </Col>
                        <Col md="4">
                          <label htmlFor="twitter">Twitter</label>
                          <FormInput
                            disabled={this.state.isRestaurant}
                            id="twitter"
                            onChange={this.handleChange}
                            value={this.state.restaurant.twitter || ''}
                            name="twitter"
                          />
                        </Col>
                        <Col md="4">
                          <label htmlFor="instagram">Instagram</label>
                          <FormInput
                            disabled={this.state.isRestaurant}
                            id="instagram"
                            value={this.state.restaurant.instagram || ''}
                            onChange={this.handleChange}
                            name="instagram"
                          />
                        </Col>
                      </Row>
                      <Row className="m-2">
                        <Col md="4">
                          <label htmlFor="city">
                            {this.t('table.city')}
                            <span className="required-input"> *</span>
                          </label>
                          <Query query={queries.cities}>
                            {({ loading, error, data, refetch }) => {
                              if (loading) return 'Loading...';
                              if (error) return `Error ${error.message}`;
                              return (
                                <FormSelect
                                  disabled={this.state.isRestaurant}
                                  onChange={this.handleChange}
                                  id="city"
                                  name="city"
                                  defaultValue={
                                    (this.state.isEdit ||
                                      this.state.gmapsSearched) &&
                                    this.state.restaurant.city
                                      ? this.state.restaurant.city.name
                                      : 0
                                  }
                                >
                                  <option key={-1} id={-1}>
                                    {this.t('form.selectOne')}
                                  </option>
                                  {data.cities.map((city, index) => (
                                    <option
                                      key={index}
                                      value={city.name}
                                      id={city.id}
                                    >
                                      {city.name}
                                    </option>
                                  ))}
                                </FormSelect>
                              );
                            }}
                          </Query>
                        </Col>
                        <Col md="4">
                          <label htmlFor="latitude">
                            {this.t('restaurantForm.latitude')}
                            <span className="required-input"> *</span>
                          </label>
                          <FormInput
                            disabled={this.state.isRestaurant}
                            id="latitude"
                            onChange={this.handleChange}
                            required={true}
                            name="latitude"
                            value={this.state.restaurant.latitude}
                            placeholder="36.762108"
                          />
                        </Col>
                        <Col md="4">
                          <label htmlFor="longitude">
                            {this.t('restaurantForm.longitude')}
                            <span className="required-input"> *</span>
                          </label>
                          <FormInput
                            disabled={this.state.isRestaurant}
                            id="longitude"
                            onChange={this.handleChange}
                            required={true}
                            name="longitude"
                            value={this.state.restaurant.longitude}
                            placeholder="-6.375854"
                          />
                        </Col>
                      </Row>
                      <Row className="m-2">
                        <Col md="4">
                          <label htmlFor="postalCode">
                            {this.t('restaurantForm.postalCode')}
                            <span className="required-input"> *</span>
                          </label>
                          <FormInput
                            disabled={this.state.isRestaurant}
                            id="postalCode"
                            onChange={this.handleChange}
                            required={true}
                            name="postalCode"
                            value={
                              this.state.restaurant.postalCode === 'null' ||
                              !this.state.restaurant.postalCode
                                ? ''
                                : this.state.restaurant.postalCode
                            }
                            placeholder="11540"
                          />
                        </Col>
                        <Col md="4">
                          <label htmlFor="priceRange">
                            {this.t('restaurantForm.priceRange')}
                            <span className="required-input"> *</span>
                          </label>
                          <FormSelect
                            disabled={this.state.isRestaurant}
                            onChange={this.handleChange}
                            id="priceRange"
                            name="priceRange"
                            defaultValue={
                              this.state.isEdit
                                ? this.state.restaurant.priceRange
                                : '1'
                            }
                          >
                            {['1', '2', '3', '4', '5'].map((range, index) => (
                              <option key={index} id={range}>
                                {range}
                              </option>
                            ))}
                          </FormSelect>
                        </Col>
                        <Col md="4" style={{ marginTop: 33 }}>
                          <FormCheckbox
                            disabled={this.state.isRestaurant}
                            checked={this.state.restaurant.showPriceRange}
                            onChange={() =>
                              this.setState((prevState) => ({
                                restaurant: {
                                  ...prevState.restaurant,
                                  showPriceRange: !this.state.restaurant
                                    .showPriceRange,
                                },
                              }))
                            }
                          >
                            {this.t('restaurantForm.showPriceRange')}
                          </FormCheckbox>
                        </Col>
                      </Row>
                      <Row className="m-2">
                        <Col md="4">
                          <label htmlFor="email">
                            {this.t('restaurantForm.email')}
                            <span className="required-input"> *</span>
                          </label>
                          <FormInput
                            disabled={this.state.isRestaurant}
                            id="email"
                            onChange={this.handleChange}
                            value={this.state.restaurant.email}
                            required={true}
                            name="email"
                            type="email"
                          />
                        </Col>
                        <Col md="4">
                          <label htmlFor="phone">
                            {this.t('restaurantForm.phone')}
                          </label>
                          <FormInput
                            id="phone"
                            onChange={this.handleChange}
                            required={false}
                            name="phone"
                            value={this.state.restaurant.phone || ''}
                          />
                        </Col>
                        <Col md="4">
                          <label htmlFor="bookUrl">
                            {this.t('restaurantForm.bookUrl')}
                          </label>
                          <FormInput
                            disabled={this.state.isRestaurant}
                            type="url"
                            id="bookUrl"
                            onChange={this.handleChange}
                            required={false}
                            name="bookUrl"
                            value={this.state.restaurant.bookUrl || ''}
                          />
                        </Col>
                      </Row>
                      <Row className="m-2">
                        <Col md="4">
                          <FormCheckbox
                            disabled={
                              this.state.isRestaurant ||
                              this.state.restaurant.company.userPlan === 'FRE'
                            }
                            checked={this.state.restaurant.suggested}
                            onChange={() =>
                              this.setState((prevState) => ({
                                restaurant: {
                                  ...prevState.restaurant,
                                  suggested: !this.state.restaurant.suggested,
                                },
                              }))
                            }
                          >
                            { this.t('restaurantForm.suggested') }
                          </FormCheckbox>
                        </Col>
                        <Col md="4">
                          <FormCheckbox
                            disabled={
                              this.state.isRestaurant ||
                              this.state.restaurant.company.userPlan === 'FRE'
                            }
                            checked={this.state.restaurant.foodytOrders}
                            onChange={() =>
                              this.setState((prevState) => ({
                                restaurant: {
                                  ...prevState.restaurant,
                                  foodytOrders: !this.state.restaurant.foodytOrders,
                                },
                              }))
                            }
                          >
                            { this.t('restaurantForm.foodytOrders') }
                          </FormCheckbox>
                        </Col>
                        <Col md="4">
                          <label htmlFor="tenedorRestaurantId">
                            {this.t('restaurantForm.tenedorRestaurantId')}
                          </label>
                          <FormInput
                            id="tenedorRestaurantId"
                            onChange={this.handleChange}
                            required={false}
                            name="tenedorRestaurantId"
                            value={this.state.restaurant.tenedorRestaurantId || ''}
                          />
                        </Col>
                      </Row>
                      { this.state.restaurant.foodytOrders &&(
                        <Row className="m-2">
                          
                          <Col md="4" className="pt-5">
                            <FormCheckbox
                              disabled={
                                !this.state.restaurant.foodytOrders
                              }
                              checked={this.state.restaurant.ordersStopped}
                              onChange={() =>
                                this.setState((prevState) => ({
                                  restaurant: {
                                    ...prevState.restaurant,
                                    ordersStopped: !this.state.restaurant.ordersStopped,
                                  },
                                }))
                              }
                            >
                              { this.t('restaurantForm.ordersStopped') }
                            </FormCheckbox>
                          </Col>
                        </Row>
                      )}
                      { this.state.restaurant.foodytOrders &&(
                        <Row className="m-2">
                          <Col md="12">
                            <h6>{this.t("dishForm.ordersType")}</h6>
                          </Col>
                          <Col md="4" >
                            <FormCheckbox
                              checked={this.state.restaurant.sala}
                              onChange={() =>
                                this.setState( prev => ({
                                  restaurant: {
                                    ...prev.restaurant,
                                    sala: !prev.restaurant.sala
                                  }
                                }))
                              }
                            >
                              {this.t("dishForm.sala")}
                            </FormCheckbox>
                          </Col>
                          <Col md="4">
                            <FormCheckbox
                              checked={this.state.restaurant.delivery}
                              onChange={() =>
                                this.setState( prev => ({
                                  restaurant: {
                                    ...prev.restaurant,
                                    delivery: !prev.restaurant.delivery
                                  }
                                }))
                              }
                            >
                              {this.t("dishForm.delivery")}
                            </FormCheckbox>
                          </Col>
                          <Col md="4" >
                            <FormCheckbox
                              checked={this.state.restaurant.takeaway}
                              onChange={() =>
                                this.setState( prev => ({
                                  restaurant: {
                                    ...prev.restaurant,
                                    takeaway: !prev.restaurant.takeaway
                                  }
                                }))
                              }
                            >
                              {this.t("dishForm.takeaway")}
                            </FormCheckbox>
                          </Col>
                        </Row>
                      )}
                      
                      <Row className="m-2">
                        <Col md="8">
                          <label htmlFor="cuisines">
                            {this.t('restaurantForm.cuisines')}
                            <span className="required-input"> *</span>
                          </label>
                          <Query query={queries.cuisines}>
                            {({ loading, error, data }) => {
                              if (loading) return 'Loading...';
                              if (error) return `Error ${error.message}`;
                              return (
                                <Multiselect
                                  data={data.cuisines.filter(
                                    (service) =>
                                      this.state.restaurant.cuisines
                                        .map((s) => s.name)
                                        .indexOf(service.name) < 0
                                  )}
                                  defaultValue={this.state.restaurant.cuisines}
                                  onChange={(e) =>
                                    this.multiOnChange(e, 'CuisineType')
                                  }
                                  textField="name"
                                  valueField="id"
                                />
                              );
                            }}
                          </Query>
                        </Col>
                        <Col md="4">
                          <label htmlFor="lifeStyle">
                            {this.t('restaurantForm.lifestyle')}
                          </label>
                          <Query query={queries.lifestyles}>
                            {({ loading, error, data }) => {
                              if (loading) return 'Loading...';
                              if (error) return `Error ${error.message}`;
                              return (
                                <FormSelect
                                  disabled={this.state.isRestaurant}
                                  onChange={this.handleChange}
                                  id="lifeStyle"
                                  name="lifeStyle"
                                  defaultValue={
                                    this.state.isEdit &&
                                    this.state.restaurant.lifeStyle
                                      ? this.state.restaurant.lifeStyle.name
                                      : 0
                                  }
                                >
                                  <option key={-1} id={-1}>
                                    {this.t('form.selectOne')}
                                  </option>
                                  {data.lifestyles.map((lifeStyle, index) => (
                                    <option
                                      key={index}
                                      text={lifeStyle.name}
                                      id={lifeStyle.id}
                                    >
                                      {lifeStyle.name}
                                    </option>
                                  ))}
                                </FormSelect>
                              );
                            }}
                          </Query>
                        </Col>
                      </Row>
                      <Row className="m-2">
                        <Col md="6">
                          <label htmlFor="services">
                            {this.t('restaurantForm.services')}
                          </label>
                          <Query query={queries.services}>
                            {({ loading, error, data }) => {
                              if (loading) return 'Loading...';
                              if (error) return `Error ${error.message}`;
                              return (
                                <Multiselect
                                  disabled={this.state.isRestaurant}
                                  data={data.services.filter(
                                    (service) =>
                                      this.state.restaurant.services
                                        .map((s) => s.name)
                                        .indexOf(service.name) < 0
                                  )}
                                  defaultValue={this.state.restaurant.services.map(
                                    (service) => service.name
                                  )}
                                  onChange={(e) =>
                                    this.multiOnChange(e, 'ServiceType')
                                  }
                                  textField="name"
                                  valueField="id"
                                />
                              );
                            }}
                          </Query>
                        </Col>
                        <Col md="6">
                          <label htmlFor="urlPage">
                            {this.t('restaurantForm.urlPage')}
                          </label>
                          <FormInput
                            disabled={this.state.isRestaurant}
                            id="urlPage"
                            onChange={this.handleChange}
                            required={false}
                            name="urlPage"
                            value={this.state.restaurant.urlPage || ''}
                          />
                        </Col>
                      </Row>
                      <Row className="m-2">
                        <Col md="6">
                          <label>
                            {this.t('restaurantForm.logoImage')}
                            <span className="required-input"> *</span>
                          </label>
                          <Dropzone
                            accept="image/jpeg, image/png"
                            multiple={false}
                            onDrop={this.onDropIcon}
                          >
                            {({ getRootProps, getInputProps }) => {
                              let src = null;

                              if (this.state.restaurant.logoImage) {
                                if (
                                  typeof this.state.restaurant.logoImage ===
                                  'string'
                                ) {
                                  src = `${MEDIA_FILES_APPEND}${this.state.restaurant.logoImage}`;
                                } else if (
                                  typeof this.state.restaurant.logoImage ===
                                  'object'
                                ) {
                                  src = window.URL.createObjectURL(
                                    new File(this.state.restaurant.logoImage, {
                                      type: 'image/png',
                                    })
                                  );
                                }
                              }

                              const image = !!src && (
                                <img
                                  style={{ width: '50px' }}
                                  src={src}
                                  alt=""
                                />
                              );

                              return (
                                <section>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {!image && (
                                      <p className="dropzone-solid-blue">
                                        {this.t('uploadText')}
                                      </p>
                                    )}
                                    {Array.isArray(
                                      this.state.restaurant.logoImage
                                    ) &&
                                      this.state.restaurant.logoImage.length ===
                                        0 && (
                                        <p className="dropzone-solid-blue">
                                          {this.t('uploadText')}
                                        </p>
                                      )}
                                    {image}
                                  </div>
                                </section>
                              );
                            }}
                          </Dropzone>
                        </Col>
                        <Col md="6">
                          <label>
                            {this.t('restaurantForm.image')}
                            <span className="required-input"> *</span>
                          </label>
                          <Dropzone
                            accept="image/jpeg, image/png"
                            multiple={false}
                            onDrop={this.onDropCover}
                          >
                            {({ getRootProps, getInputProps }) => {
                              let src = null;

                              if (this.state.restaurant.image) {
                                if (
                                  typeof this.state.restaurant.image ===
                                  'string'
                                ) {
                                  src = `${MEDIA_FILES_APPEND}${this.state.restaurant.image}`;
                                } else if (
                                  typeof this.state.restaurant.image ===
                                  'object'
                                ) {
                                  src = window.URL.createObjectURL(
                                    new File(this.state.restaurant.image, {
                                      type: 'image/png',
                                    })
                                  );
                                }
                              }

                              const image = !!src && (
                                <img
                                  style={{ width: '140px' }}
                                  src={src}
                                  alt=""
                                />
                              );

                              return (
                                <section>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {!image && (
                                      <p className="dropzone-solid-blue">
                                        {this.t('uploadText')}
                                      </p>
                                    )}
                                    {Array.isArray(
                                      this.state.restaurant.image
                                    ) &&
                                      this.state.restaurant.image.length ===
                                        0 && (
                                        <p className="dropzone-solid-blue">
                                          {this.t('uploadText')}
                                        </p>
                                      )}
                                    {image}
                                  </div>
                                </section>
                              );
                            }}
                          </Dropzone>
                        </Col>
                        <Col md="12">
                          <Card small>
                            <CardHeader
                              onClick={() =>
                                this.setState({
                                  collapse: !this.state.collapse,
                                })
                              }
                              className="cursor-pointer border-bottom"
                            >
                              <h6 className="float-left m-0">
                                {this.t('language.settings')}
                              </h6>
                              {!this.state.collapse && (
                                <i className="material-icons float-right">
                                  expand_more
                                </i>
                              )}
                              {this.state.collapse && (
                                <i className="material-icons float-right">
                                  expand_less
                                </i>
                              )}
                            </CardHeader>
                            <Collapse open={this.state.collapse}>
                              <CardBody>
                                <Row>
                                  {restaurantsLanguagesFields.map(
                                    (language, index) => {
                                      const prop = `${language.field}${language.language}`; // name_en, description_cat
                                      const snakeCaseLang = language.language
                                        .split(/(?=[A-Z])/)
                                        .join('-')
                                        .toLowerCase();
                                      return (
                                        <Col
                                          key={index}
                                          md="6"
                                          className="mt-1"
                                        >
                                          <label htmlFor={prop}>
                                            {this.t(`form.${prop}`)}
                                          </label>
                                          {language.field === 'name' && (
                                            <FormInput
                                              disabled={
                                                this.state.isRestaurant ||
                                                (this.state.restaurant.company.userPlan === 'FRE' &&
                                                  !this.state.avaibleLanguages.includes(
                                                    snakeCaseLang
                                                  ))
                                              }
                                              name={prop}
                                              id={prop}
                                              onChange={(e) =>
                                                this.handleLanguageChange(
                                                  e,
                                                  prop
                                                )
                                              }
                                              value={
                                                this.state.restaurant[prop]
                                              }
                                            />
                                          )}
                                          {language.field === 'description' && (
                                            <FormTextarea
                                              disabled={
                                                this.state.isRestaurant ||
                                                (this.state.restaurant.company.userPlan === 'FRE' &&
                                                  !this.state.restaurant.avaibleLanguages.includes(
                                                    snakeCaseLang
                                                  ))
                                              }
                                              name={prop}
                                              id={prop}
                                              onChange={(e) =>
                                                this.handleLanguageChange(
                                                  e,
                                                  prop
                                                )
                                              }
                                              value={
                                                this.state.restaurant[prop] ||
                                                ''
                                              }
                                            />
                                          )}
                                        </Col>
                                      );
                                    }
                                  )}
                                </Row>
                              </CardBody>
                            </Collapse>
                          </Card>
                        </Col>
                        <Col md="12">
                          <Card small className="mt-2">
                            <CardHeader
                              onClick={() =>
                                this.setState({
                                  languageCollapse: !this.state
                                    .languageCollapse,
                                })
                              }
                              className="cursor-pointer border-bottom"
                            >
                              <h6 className="float-left m-0">
                                {this.t('language.avaibleLanguages')}
                              </h6>
                              {!this.state.languageCollapse && (
                                <i className="material-icons float-right">
                                  expand_more
                                </i>
                              )}
                              {this.state.languageCollapse && (
                                <i className="material-icons float-right">
                                  expand_less
                                </i>
                              )}
                            </CardHeader>
                            <Collapse open={this.state.languageCollapse}>
                              <CardBody>
                                <Row className="p-3">
                                  {avaibleFlags.map((flag, index) => {
                                    const indexOfFlag = this.state.restaurant
                                      .avaibleLanguages
                                      ? this.state.restaurant.avaibleLanguages.indexOf(
                                          flag
                                        )
                                      : -1;
                                    return (
                                      <FormCheckbox
                                        key={index}
                                        disabled={this.state.isRestaurant}
                                        className="d-inline mr-2"
                                        checked={indexOfFlag > -1}
                                        onChange={() =>
                                          this.handleFlagChange(
                                            flag,
                                            indexOfFlag
                                          )
                                        }
                                      >
                                        <img
                                          className={
                                            indexOfFlag > -1
                                              ? 'allergen-icon selected-flag'
                                              : 'allergen-icon not-selected-img'
                                          }
                                          src={
                                            FLAGS_ICONS_APPEND + flag + '.svg'
                                          }
                                          alt=""
                                        />
                                      </FormCheckbox>
                                    );
                                  })}
                                </Row>
                                {this.state.restaurant.company.userPlan ===
                                  'FRE' && (
                                  <Row className="p-3">
                                    <span className="">
                                      *
                                      {this.t(
                                        'restaurantForm.limitedToFreeUser'
                                      )}
                                    </span>
                                  </Row>
                                )}
                              </CardBody>
                            </Collapse>
                          </Card>
                        </Col>
                        <Col
                          md="12"
                          style={
                            (this.state.isRestaurant &&
                              this.state.restaurant.company.userPlan ===
                                USER_PREMIUM) ||
                            (!this.state.isRestaurant &&
                              (this.state.restaurant.company.userPlan ===
                                USER_PREMIUM ||
                                this.state.restaurant.company.userPlan ===
                                  USER_LAMB_WESTON))
                              ? { display: 'block' }
                              : { display: 'none' }
                          }
                        >
                          <Card small className="mt-2">
                            <CardHeader
                              onClick={() =>
                                this.setState({
                                  customStyleCollapse: !this.state
                                    .customStyleCollapse,
                                })
                              }
                              className="cursor-pointer border-bottom"
                            >
                              <h6 className="float-left m-0">
                                {this.t('restaurantForm.customStyleCollapse')}
                              </h6>
                              {!this.state.customStyleCollapse && (
                                <i className="material-icons float-right">
                                  expand_more
                                </i>
                              )}
                              {this.state.customStyleCollapse && (
                                <i className="material-icons float-right">
                                  expand_less
                                </i>
                              )}
                            </CardHeader>
                            <Collapse open={this.state.customStyleCollapse}>
                              <CardBody>
                                <Row>
                                  <Col md="6" sm="12" className="mt-1">
                                    <label>
                                      {this.t('restaurantForm.mainColor')}
                                    </label>
                                    {(!this.state.isRestaurant ||
                                      this.state.restaurant.company.userPlan ===
                                        USER_PREMIUM) && (
                                      <ChromePicker
                                        disableAlpha={true}
                                        color={
                                          this.state.restaurant
                                            .customPrimaryColor
                                        }
                                        onChangeComplete={
                                          this.handleChangeCompleteCustomColor
                                        }
                                      />
                                    )}
                                  </Col>

                                  <Col md="6" sm="12" className="mt-1">
                                    <label>
                                      {this.t('restaurantForm.fontColor')}
                                    </label>
                                    {(!this.state.isRestaurant ||
                                      this.state.restaurant.company.userPlan ===
                                        USER_PREMIUM) && (
                                      <ChromePicker
                                        disableAlpha={true}
                                        color={
                                          this.state.restaurant
                                            .customSecondaryColor
                                        }
                                        onChangeComplete={
                                          this.handleChangeCompleteFont
                                        }
                                      />
                                    )}
                                  </Col>
                                  <Col md="6" sm="12" className="mt-1">
                                    <label>
                                      {this.t('restaurantForm.link')}
                                    </label>
                                    <FormInput
                                      disabled={this.state.isRestaurant}
                                      id="customLink"
                                      onChange={this.handleChange}
                                      required={false}
                                      name="customLink"
                                      value={
                                        this.state.restaurant.customLink || ''
                                      }
                                    />
                                  </Col>
                                  <Col md="6" sm="12" className="mt-1">
                                    <label>
                                      {this.t('restaurantForm.linkName')}
                                    </label>
                                    <FormInput
                                      disabled={this.state.isRestaurant}
                                      id="customLinkName"
                                      onChange={this.handleChange}
                                      required={false}
                                      name="customLinkName"
                                      value={
                                        this.state.restaurant.customLinkName ||
                                        ''
                                      }
                                    />
                                  </Col>
                                  <Col md="6" sm="12" className="mt-1">
                                    <label>
                                      {this.t('restaurantForm.font')}
                                    </label>

                                    <FormSelect
                                      className="mt-3"
                                      onChange={(e) => this.handleFontChange(e)}
                                      defaultValue={
                                        this.state.selectedFontIndex
                                      }
                                    >
                                      <option key={-1} id={-1}>
                                        {this.t('restaurantForm.fontSelect')}
                                      </option>
                                      {this.fontsArray.map((font, index) => (
                                        <option
                                          key={index}
                                          id={index}
                                          selected={
                                            index ===
                                            this.state.selectedFontIndex
                                              ? true
                                              : false
                                          }
                                        >
                                          {font.name}
                                        </option>
                                      ))}
                                    </FormSelect>
                                  </Col>
                                  <Col md="6" sm="12">
                                    <FormCheckbox
                                      disabled={this.state.isRestaurant}
                                      name="showSearch"
                                      id="showSearch"
                                      className="mt-5"
                                      checked={this.state.restaurant.showSearch}
                                      onChange={() =>
                                        this.setState((prevState) => ({
                                          restaurant: {
                                            ...prevState.restaurant,
                                            showSearch: !this.state.restaurant
                                              .showSearch,
                                          },
                                        }))
                                      }
                                    >
                                      {this.t('restaurantForm.showSearch')}
                                    </FormCheckbox>
                                  </Col>
                                  <Col md="12">
                                    <p
                                      className=""
                                      style={{
                                        display:
                                          this.state.selectedFontIndex >= 0
                                            ? 'block'
                                            : 'none',
                                        fontFamily: this.state.restaurant
                                          .customFont,
                                      }}
                                    >
                                      Esto es un texto de prueba con la
                                      tipografía.
                                    </p>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Collapse>
                          </Card>
                        </Col>
                        <Col md="12">
                          <Card small className="mt-2">
                            <CardHeader
                              onClick={() =>
                                this.setState({
                                  hostelTactilCollapse: !this.state
                                    .hostelTactilCollapse,
                                })
                              }
                              className="cursor-pointer border-bottom"
                            >
                              <h6 className="float-left m-0">
                                {this.t('hostelTactil.restaurant')}
                              </h6>
                              {!this.state.hostelTactilCollapse && (
                                <i className="material-icons float-right">
                                  expand_more
                                </i>
                              )}
                              {this.state.hostelTactilCollapse && (
                                <i className="material-icons float-right">
                                  expand_less
                                </i>
                              )}
                            </CardHeader>

                            <Collapse open={this.state.hostelTactilCollapse}>
                              <CardBody>
                                <Row className="p-3">
                                  <Col md="12 mb-3">
                                    {this.state.restaurant.htLocalId
                                      ? `This restaurant is conected with local: ${this.state.restaurant.htLocalId}`
                                      : `Put your local user and password to connect with the local of HostelTactil`}
                                  </Col>
                                  <Col md="6">
                                    <label htmlFor="ht_username">
                                      {this.props.t(
                                        'restaurantForm.ht_username'
                                      )}
                                    </label>
                                    <FormInput
                                      id="ht_username"
                                      name="ht_username"
                                      onChange={this.handleChange}
                                      value={this.state.restaurant.ht_username}
                                    />
                                  </Col>
                                  <Col md="6">
                                    <label htmlFor="ht_password">
                                      {this.props.t(
                                        'restaurantForm.ht_password'
                                      )}
                                    </label>
                                    <FormInput
                                      id="ht_password"
                                      name="ht_password"
                                      type="password"
                                      onChange={this.handleChange}
                                      value={this.state.restaurant.ht_password}
                                    />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Collapse>
                          </Card>
                        </Col>
                        <Col md="12">
                          <Card small className="mt-2">
                            <CardHeader
                              onClick={() =>
                                this.setState({
                                  agoraCollapse: !this.state
                                    .agoraCollapse,
                                })
                              }
                              className="cursor-pointer border-bottom"
                            >
                              <h6 className="float-left m-0">
                                {this.t('agora.restaurant')}
                              </h6>
                              {!this.state.agoraCollapse && (
                                <i className="material-icons float-right">
                                  expand_more
                                </i>
                              )}
                              {this.state.agoraCollapse && (
                                <i className="material-icons float-right">
                                  expand_less
                                </i>
                              )}
                            </CardHeader>

                            <Collapse open={this.state.agoraCollapse}>
                              <CardBody>
                                <Row className="p-3">
                                  <Col md="6">
                                    <label htmlFor="agora_api_url">
                                      {this.props.t(
                                        'restaurantForm.agora_api_url'
                                      )}
                                    </label>
                                    <FormInput
                                      id="agora_api_url"
                                      name="agoraApiUrl"
                                      onChange={this.handleChange}
                                      value={this.state.restaurant.agoraApiUrl}
                                    />
                                  </Col>
                                  <Col md="6">
                                    <label htmlFor="agora_api_token">
                                      {this.props.t(
                                        'restaurantForm.agora_api_token'
                                      )}
                                    </label>
                                    <FormInput
                                      id="agora_api_token"
                                      name="agoraApiToken"
                                      type="agora_api_token"
                                      onChange={this.handleChange}
                                      value={this.state.restaurant.agoraApiToken}
                                    />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Collapse>
                          </Card>
                        </Col>
                        <Col md="12">
                          {this.state.requiredFieldsError && (
                            <p className="error-required-icon">
                              {this.t('requiredFieldsEmpty')}
                            </p>
                          )}
                        </Col>
                      </Row>
                      <Row className="m-2">
                        <Col>
                          <Button type="submit" className="float-left">
                            {this.state.isEdit
                              ? this.t('form.update')
                              : this.t('form.create')}
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Form>
                </Col>
              </Col>

              <Col md="4" className="pl-0">
                <h4>{this.props.t('Cartas')}</h4>
                {this.state.restaurant.fullMenus.edges.map(
                  (fullMenu, index) => (
                    <Col md="12" className="pl-0 mb-2">
                      <Card small className="mr-2">
                        <CardHeader className="cursor-pointer border-bottom">
                          <h6
                            className="float-left m-0"
                            onClick={() => this.collapseFullMenu(index)}
                          >
                            {fullMenu.node.name}
                          </h6>
                          {!this.state.isRestaurant && (
                            <UploadCsvDish
                              fullMenu={fullMenu.node.id}
                              hasConfirm
                              accept="*"
                              t={this.props.t}
                              onConfirm={() =>
                                this.setState({ reloadMenuDnD: new Date() })
                              }
                            />
                          )}
                          {this.state.saveOk && (
                            <img
                              className="float-right"
                              src={savedOkGif}
                              style={{ height: 24, width: 24 }}
                              alt=""
                            />
                          )}
                          <button
                            className="menu-options float-right"
                            disabled={fullMenu.node.hasMenus}
                            onClick={() =>
                              this.toggleDeleteModal(
                                fullMenu.node.id,
                                fullMenu.node.name,
                                this.loadRestaurant,
                                'FullMenu'
                              )
                            }
                          >
                            <i className="material-icons mr-1">delete</i>
                          </button>
                          <button
                            disabled={
                              this.props.isRestaurant && !this.props.isEdit
                            }
                            className="menu-options float-right"
                            onClick={() =>
                              this.toggleModalFull(true, fullMenu.node)
                            }
                          >
                            <i className="material-icons">edit</i>
                          </button>
                          {!this.state.restaurant.fullMenus.edges[index].node
                            .collapseOpen && (
                            <i
                              className="material-icons float-right mr-1"
                              onClick={() => this.collapseFullMenu(index)}
                            >
                              expand_more
                            </i>
                          )}
                          {this.state.restaurant.fullMenus.edges[index].node
                            .collapseOpen && (
                            <i
                              className="material-icons float-right mr-1"
                              onClick={() => this.collapseFullMenu(index)}
                            >
                              expand_less
                            </i>
                          )}
                        </CardHeader>

                        <Collapse
                          className="p-2"
                          open={
                            this.state.restaurant.fullMenus.edges[index].node
                              .collapseOpen
                          }
                        >
                          <MenuDnd
                            reload={this.state.reloadMenuDnD}
                            isRestaurant={this.state.isRestaurant}
                            isEdit={this.state.isEdit}
                            setSaveOk={this.setSaveOk}
                            t={this.props.t}
                            toggleDeleteModal={this.toggleDeleteModal}
                            restaurantId={this.state.restaurant.id}
                            fullMenuId={fullMenu.node.id}
                            toggleModal={this.toggleModal}
                          />
                        </Collapse>
                      </Card>
                    </Col>
                  )
                )}
                <Col md="12" className="mt-3 pr-4 text-right">
                  <Button
                    pill
                    theme="info"
                    onClick={() => this.toggleModalFull(false, {})}
                  >
                    <i className="material-icons mr-1">add</i>
                    {this.t('create.full.menu')}
                  </Button>
                </Col>
                {!this.state.isRestaurant && (
                  <Col md="12" className="pl-0">
                    <Card small className="mr-2 mt-4">
                      <CardHeader className="border-bottom">
                        <h6 className="float-left m-0">
                          {this.t('dishForm.status')}
                        </h6>
                      </CardHeader>
                      <div className="m-2">
                        <FormRadio
                          name="status"
                          checked={this.state.restaurant.status === 'WA'}
                          onChange={() => this.changeStatus('WA')}
                        >
                          {this.t('waiting')}
                        </FormRadio>
                        <FormRadio
                          name="status"
                          disabled={!this.state.isEdit}
                          checked={this.state.restaurant.status === 'AP'}
                          onChange={() => this.changeStatus('AP')}
                        >
                          {this.t('approved')}
                        </FormRadio>
                        <FormRadio
                          name="status"
                          disabled={!this.state.isEdit}
                          checked={this.state.restaurant.status === 'RJ'}
                          onChange={() => this.changeStatus('RJ')}
                        >
                          {this.t('rejected')}
                        </FormRadio>
                      </div>
                    </Card>
                  </Col>
                )}
              </Col>
            </Row>
          </Card>
          <MenuModalForm
            refetchFunction={this.refetchFunction}
            t={this.t}
            isEdit={this.state.isMenuEdit}
            menuToEdit={this.state.toEditMenu}
            modalToggled={this.state.modalToggled}
            toggleModal={this.toggleModal}
            restaurantId={this.state.restaurant.id}
            fullMenuId={this.state.fullMenuId}
            userPlan={this.state.restaurant.company.userPlan}
            avaibleLanguages={this.state.restaurant.avaibleLanguages}
            menuOrder={this.state.newMenuOrder}
          />
          <FullMenuModalForm
            refetchFunction={this.loadRestaurant}
            t={this.t}
            isEdit={this.state.isFullMenuEdit}
            fullMenuToEdit={this.state.toEditFullMenu}
            modalToggled={this.state.modalFullToggled}
            toggleModal={this.toggleModalFull}
            restaurantId={this.state.restaurant.id}
            userPlan={this.state.restaurant.company.userPlan}
            avaibleLanguages={this.state.restaurant.avaibleLanguages}
            menuOrder={this.state.newMenuOrder}
          />
          <ConfirmDeleteModal
            cancelClickFunction={this.toggleDeleteModal}
            entity={this.state.deleteModel}
            open={this.state.deleteModalToggled}
            toggleFunction={this.toggleDeleteModal}
            toDeleteId={this.state.toDeleteId}
            refetchFunction={this.refetchFunction}
            toDeleteName={this.state.toDeleteName}
            t={this.t}
          />
        </Container>
      );
    } else {
      return (
        <div className="spinner-loader">
          <Spinner
            size={80}
            spinnerColor={'#007BFF'}
            spinnerWidth={2}
            visible={true}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  isStaff: state.user.isStaff,
  isRestaurant: state.user.isRestaurant,
  username: state.user.username,
});

const bindActions = (dispatch) => ({});

export default connect(
  mapStateToProps,
  bindActions
)(withTranslation()(RestaurantForm));
