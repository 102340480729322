import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

import en_translation from "./translations/en_translation";
import es_translation from "./translations/es_translation";

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en_translation
      },
      es: {
        translation: es_translation
      }
    },
    debug: false,
    keySeparator: false,

    interpolation: {
      formatSeparator: ","
    },

    react: {
      wait: true
    }
  });

export default i18n;

