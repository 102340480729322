import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  Row,
  FormInput,
  Col,
} from "shards-react";
import Spinner from "react-spinner-material";
import { queries } from "../graphQueries";
import { client } from "../apolloClient";
import React from "react";

import "../shards-dashboard/styles/styles.css";

class TableModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        code: "",
        zone: "",
        name: ""
      },
      marketId: props.marketId,
      collapse: false,
      requiredFieldsError: false,
      isEdit: false,
      loading: false
    };
    this.t = this.props.t;
    this.baseState = this.state;
    console.log(props);
  }

  componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.isEdit) {
        this.setState({
          table: { ...nextProps.tableToEdit },
          isEdit: true,
        });
      } else {
        this.setState({
          marketId: nextProps.marketId,
          ...this.baseState,
        });
        console.log(this.state);
      }
    } catch (err) {}
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      table: {
        ...prevState.table,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    const mutation = this.state.isEdit
      ? queries.updateMarketTable
      : queries.createMarketTable;
    const variables = {
      name: this.state.table.name,
      code: this.state.table.code,
      zone: this.state.table.zone
    };
    if (this.state.isEdit) {
      variables.tableId = this.state.table.id;
    } else {
      variables.marketId = this.state.marketId;
    }
    client
      .mutate({
        variables: { input:variables },
        mutation
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.props.refetchFunction();
        this.setState({ loading: false }, () => this.props.toggleModal());
      });
  };

  render() {
    return (
      <Modal
        centered={true}
        open={this.props.modalToggled}
        toggle={this.props.toggleModal}
      >
        <ModalHeader>{this.props.t("menuForm.newMenu")}</ModalHeader>
        <ModalBody className="pt-0 modal-menu">
          {!this.state.loading && (
            <Form onSubmit={this.handleSubmit}>
              <Row className="m-2">
                <Col md="4">
                  <label htmlFor="code">
                    {this.props.t("tableForm.code")}
                    <span className="required-input"> *</span>
                  </label>
                  <FormInput
                    id="code"
                    onChange={this.handleChange}
                    required={true}
                    name="code"
                    value={this.state.table.code}
                  />
                </Col>
                <Col md="4">
                  <label htmlFor="name">
                    {this.props.t("tableForm.name")}
                  </label>
                  <FormInput
                    id="name"
                    onChange={this.handleChange}
                    name="name"
                    value={this.state.table.name}
                  />
                </Col>
                <Col md="4">
                  <label htmlFor="zone">
                    {this.props.t("tableForm.zone")}
                  </label>
                  <FormInput
                    id="zone"
                    onChange={this.handleChange}
                    required={false}
                    name="zone"
                    value={this.state.table.zone}
                  />
                </Col>
              </Row>
              <div className="mt-3">
                <Button type="submit" className="float-left">
                  {this.state.isEdit
                    ? this.props.t("form.update")
                    : this.props.t("form.create")}
                </Button>
                <Button
                  outline
                  pill
                  theme="info"
                  className="float-right"
                  onClick={() => this.props.toggleModal()}
                >
                  <i className="material-icons mr-1">cancel</i>
                  {this.props.t("cancel")}
                </Button>
              </div>
            </Form>
          )}
          {this.state.loading && (
            <div className="spinner-loader-dnd">
              <Spinner
                size={80}
                spinnerColor={"#007BFF"}
                spinnerWidth={2}
                visible={true}
              />
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}
export default TableModalForm;
