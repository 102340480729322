import axios from 'axios';
import { API_URL } from './config'

export const login = async (username, password) => {
  try {
    const res = await axios.post(`${API_URL}/auth/`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        username,
        password
      });
    setToken(res.data.token);
    return {ok: true, isRestaurant: res.data.user.is_restaurant, dashboardAvailable: res.data.user.dashboardAvailable};
  } catch (err) {
    console.log(err);
    return false;
  }
}

export const loggedIn = () => {
  const token = getToken();
  return !!token;
}

export const verifyLogin = async () => {
  const token = getToken();
  try {
    const res = await axios.post(`${API_URL}/auth-verify/`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        token
      });
    return res.data;
  } catch (err) {
    return false;
  }

}

const setToken = token => {
  localStorage.setItem("id_token", token);
}

export const getToken = () => {
  return localStorage.getItem("id_token");
}

export const logout = () => {
  localStorage.removeItem("id_token");
}

