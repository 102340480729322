import React from 'react';
import { Link } from 'react-router-dom';
import { client } from '../../apolloClient';
import { withTranslation } from 'react-i18next';
import { queries } from '../../graphQueries';
import {
  CardHeader,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormCheckbox,
} from 'shards-react';

import '../../shards-dashboard/styles/login.css';

class CurrencyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currency: {
        code: '',
        symbol: '',
        id: '',
        append: true,
      },
      loading: false,
      isEdit: false,
    };

    this.t = this.props.t;
  }

  componentWillMount() {
    try {
      if (this.props.location.state.entity) {
        console.log(this.props.location.state.entity);
        this.setState({
          currency: { ...this.props.location.state.entity },
          isEdit: true,
        });
      }
    } catch (err) {}
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      currency: {
        ...prevState.currency,
        [name]: value,
      },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const mutation = this.state.isEdit
      ? queries.updateCurrency
      : queries.createCurrency;
    const variables = {
      code: this.state.currency.code,
      symbol: this.state.currency.symbol,
      append: this.state.currency.append,
    };
    if (this.state.isEdit) {
      variables.id = parseInt(this.state.currency.id);
    }
    client
      .mutate({
        variables: {
          input: variables,
        },
        mutation,
      })
      .then((res, err) => {
        this.props.history.push('/currencies');
      });
  };

  render() {
    return (
      <Container className="pt-4">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">
              {this.state.isEdit
                ? `${this.t('form.editing')} ${this.state.currency.code}`
                : this.t('form.newCurrency')}
            </h6>
            <Button
              outline
              pill
              theme="danger"
              className="float-right"
              tag={Link}
              to="/currencies"
            >
              Back
            </Button>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={this.handleSubmit}>
                    <Row form>
                      <Col md="4">
                        <label htmlFor="code">{this.t('table.code')}</label>
                        <FormInput
                          id="code"
                          name="code"
                          value={this.state.currency.code}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Col>
                      <Col md="4">
                        <label htmlFor="symbol">{this.t('table.symbol')}</label>
                        <FormInput
                          id="symbol"
                          name="symbol"
                          value={this.state.currency.symbol}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Col>
                      <Col md="4" className="pt-4">
                        <FormCheckbox
                          toggle
                          checked={this.state.currency.append}
                          onChange={() =>
                            this.setState((prevState) => ({
                              currency: {
                                ...prevState.currency,
                                append: !this.state.currency.append,
                              },
                            }))
                          }
                        >
                          {this.t('append.currency')}
                        </FormCheckbox>
                      </Col>
                      <Col md={{ size: 2, offset: 1 }}>
                        <Button type="submit" className="float-left mt-2">
                          {this.state.isEdit
                            ? this.t('form.update')
                            : this.t('form.create')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    );
  }
}

export default withTranslation()(CurrencyForm);
