import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
  FormInput,
  FormCheckbox
} from "shards-react";
import React from "react";
import { DELIVERY, TAKEAWAY, LOUNGE } from '../config';

import "../shards-dashboard/styles/styles.css"

class PriceModalForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      price: {
        id: '',
        nameEs: '',
        nameEn: '',
        nameFr: '',
        nameIt: '',
        namePt: '',
        nameDe: '',
        nameCa: '',
        nameJa: '',
        nameKo: '',
        nameZhCn: '',
        nameRu: '',
        price: 0.0,
        htDishId: null,
        isWeight: false,
        loungePrice: {
          orderType: LOUNGE,
          amount: 0,
          active: true,
        },
        takeawayPrice: {
          orderType: TAKEAWAY,
          amount: 0,
          active: true,
        },
        deliveryPrice: {
          orderType: DELIVERY,
          amount: 0,
          active: true,
        }
      },
      ordersActive: false,
      requiredFieldsError: false,
      isEdit: false,
      modalToggled: false
    }
    this.t = this.props.t;
    this.baseState = this.state;
    this.addPriceToList = this.addPriceToList.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.isEdit) {
        this.setState({
          price: {
            ...nextProps.priceToEdit,
            deliveryPrice: nextProps.priceToEdit.deliveryPrice ? nextProps.priceToEdit.deliveryPrice: this.baseState.price.deliveryPrice,
            takeawayPrice: nextProps.priceToEdit.takeawayPrice ? nextProps.priceToEdit.takeawayPrice: this.baseState.price.takeawayPrice,
            loungePrice: nextProps.priceToEdit.loungePrice ? nextProps.priceToEdit.loungePrice: this.baseState.price.loungePrice
          },
          isEdit: true,
          ordersActive: nextProps.ordersActive
        });
      } else {
        this.setState({...this.baseState, ordersActive: nextProps.ordersActive});
      }
    } catch (err) { }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      price: {
        ...prevState.price,
        [name]: value.toString()
      }
    }));
  }

  handleChangeLounge = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      price: {
        ...prevState.price,
        loungePrice: {
          ...prevState.price.loungePrice,
          [name]: value.toString()
        }
      }
    }));
  }

  handleChangeDelivery = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      price: {
        ...prevState.price,
        deliveryPrice: {
          ...prevState.price.deliveryPrice,
          [name]: value.toString()
        }
      }
    }));
  }

  handleChangeTakeaway = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      price: {
        ...prevState.price,
        takeawayPrice: {
          ...prevState.price.takeawayPrice,
          [name]: value.toString()
        }
      }
    }));
  }

  addPriceToList(event, price, index = -1) {
    console.log(price);
    this.props.addPriceToList(event, price, this.props.editIndex)
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    await this.setState(prevState => ({
      price: {
        ...prevState.price,
        price: prevState.price.loungePrice.amount
      }
    }));
    console.log(this.state);
    this.addPriceToList(e, this.state.price);
  }

  render() {
    return (
      <Modal centered={true} open={this.props.modalToggled} toggle={this.props.toggleModal}>
        <ModalHeader>{this.t('priceForm.newPrice')}</ModalHeader>
        <ModalBody className="pt-0">
          <Row className="m-2">
            <Col>
              <label htmlFor="name">{this.t('priceForm.name')}<span className="required-input"> *</span></label>
              <FormInput maxLength="255" id="nameEs" name="nameEs" required={true} onChange={this.handleChange} value={this.state.price.nameEs} />
            </Col>
            <Col>
              <label htmlFor="name_en">{this.t('form.nameEn')}</label>
              <FormInput maxLength="255" id="nameEn" name="nameEn" onChange={this.handleChange} value={this.state.price.nameEn} />
            </Col>
          </Row>
          <Row className="m-2">
            <Col>
              <FormCheckbox
                  toggle
                  checked={this.state.price.isWeight}
                  onChange={() =>
                    this.setState((prevState) => ({
                      price: {
                        ...prevState.price,
                        isWeight: !this.state.price.isWeight,
                      },
                    }))
                  }
                >
                  {this.t('priceForm.weight')}
                </FormCheckbox>
            </Col>
          </Row>
          
          <Row className="m-2">
            <Col>
              { this.props.ordersActive && (
                <FormCheckbox
                  toggle
                  checked={this.state.price.loungePrice.active}
                  onChange={() =>
                    this.setState((prevState) => ({
                      price: {
                        ...prevState.price,
                        loungePrice: {
                          ...prevState.price.loungePrice,
                          active: !this.state.price.loungePrice.active,
                        }
                        
                      },
                    }))
                  }
                >
                  {this.t('priceForm.loungeActive')}
                </FormCheckbox>
              )}
              <label htmlFor="loungePrice">{this.t('priceForm.loungePrice') + " " + (this.props.currencySymbol || "€") }<span className="required-input"> *</span></label>
              <FormInput id="loungePrice" step="any" type="number" min={0} onChange={this.handleChangeLounge} required={true} name="amount" value={this.state.price.loungePrice.amount} />
            </Col>
          </Row>
          { this.props.ordersActive && (
            <Row className="m-2">
              <Col>
                <FormCheckbox
                  toggle
                  checked={this.state.price.takeawayPrice.active}
                  onChange={() =>
                    this.setState((prevState) => ({
                      price: {
                        ...prevState.price,
                        takeawayPrice: {
                          ...prevState.price.takeawayPrice,
                          active: !this.state.price.takeawayPrice.active,
                        }
                        
                      },
                    }))
                  }
                >
                  {this.t('priceForm.takeawayActive')}
                </FormCheckbox>
                <label htmlFor="takeawayPrice">{this.t('priceForm.takeawayPrice') + " " + (this.props.currencySymbol || "€") }<span className="required-input"> *</span></label>
                <FormInput id="takeawayPrice" step="any" type="number" min={0} onChange={this.handleChangeTakeaway} required={true} name="amount" value={this.state.price.takeawayPrice.amount} />
              </Col>
            </Row>
          )}
          { this.props.ordersActive && (
            <Row className="m-2">
              <Col>
                <FormCheckbox
                  toggle
                  checked={this.state.price.deliveryPrice.active}
                  onChange={() =>
                    this.setState((prevState) => ({
                      price: {
                        ...prevState.price,
                        deliveryPrice: {
                          ...prevState.price.deliveryPrice,
                          active: !this.state.price.deliveryPrice.active,
                        }
                        
                      },
                    }))
                  }
                >
                  {this.t('priceForm.deliveryActive')}
                </FormCheckbox>
                <label htmlFor="deliveryPrice">{this.t('priceForm.deliveryPrice') + " " + (this.props.currencySymbol || "€") }<span className="required-input"> *</span></label>
                <FormInput id="deliveryPrice" step="any" type="number" min={0} onChange={this.handleChangeDelivery} required={true} name="amount" value={this.state.price.deliveryPrice.amount} />
              </Col>
            </Row>
          )}    
          
          { this.props.htActive && (
            <Row className="m-2">
              <label htmlFor="htDishId">{this.t('priceForm.htDishId') }</label>
              <FormInput id="htDishId" onChange={this.handleChange} name="htDishId" value={this.state.price.htDishId} />
            </Row>
          )}
          <div className="mt-3">
            <Button onClick={(e) => this.handleSubmit(e)} className="float-left">{this.state.isEdit ? this.t('form.update') : this.t('form.create')}</Button>
            <Button outline pill theme="info" className="float-right" onClick={(e) => this.props.toggleModal(e)}><i className="material-icons mr-1">cancel</i>{this.t('cancel')}</Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}
export default PriceModalForm;
