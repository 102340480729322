import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "shards-react";
import { Link } from 'react-router-dom';
import React from "react";

import "../shards-dashboard/styles/styles.css"

const ConfirmDeleteModal = ({ open, toggleFunction, restaurantId, isRestaurant, t }) => {

  return (
    <Modal size="sm" centered={true} open={open} toggle={toggleFunction}>
      <ModalHeader>{t("unsavedChanges")}</ModalHeader>
      <ModalBody>
        <p>{t("confirmBack")}</p>
        <Button outline theme="danger"
          className="float-left btn mr-2"
          tag={Link}
          to={{ pathname: `/restaurant`, state: { entity: { fetch: true, id: restaurantId }, isRestaurant } }}>{t('back')}</Button>
        <Button outline pill theme="info" className="float-right" onClick={() => toggleFunction()}><i className="material-icons mr-1">cancel</i>{t('close')}</Button>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmDeleteModal;