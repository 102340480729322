import React from 'react';
import Spinner from 'react-spinner-material';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormInput,
} from 'shards-react';
import { withTranslation } from 'react-i18next';
import headers from '../data/tables_headers/headers';
import { queries } from '../graphQueries';
import { MEDIA_FILES_APPEND, API_URL } from '../config';
import { client } from '../apolloClient';
import { getToken } from '../services';

import '../shards-dashboard/styles/styles.css';

class PaginatedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      toDeleteName: '',
      toDeleteIndex: '',
      toDeleteId: '',
      sortingColumn: '',
      reverseSorting: false,
      searchList: false,
      search: '',
      loading: false,
    };

    this.displaySearchList = _.debounce(this.displaySearchList, 500);
  }

  componentDidMount() {
  }

  sortColumn = (column, data) => {
    const prop = column;
    if (this.state.sortingColumn === column) {
      data[Object.keys(data)[0]].edges.reverse();
      this.setState({ reverseSorting: !this.state.reverseSorting });
    } else {
      const sort = _.sortBy(data[Object.keys(data)[0]].edges, (o) =>
        typeof o.node[prop] === 'string'
          ? o.node[prop].toLowerCase()
          : o.node[prop]
      );
      data[Object.keys(data)[0]].edges = sort;
      if (this.state.reverseSorting) {
        this.setState({ reverseSorting: !this.state.reverseSorting });
      }
    }
    return data;
  };

  onClickDownloadCsv(event) {
    fetch(`${API_URL}/obtener-csv`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        authorization: `${getToken()}`,
      },
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (
          !contentType ||
          !(
            contentType.includes("text/csv") ||
            contentType.includes("text/plain")
          )
        ) {
          throw new TypeError("Oops, we haven't got CSV!");
        }
        return response.text();
      })
      .then((data) => {
        let filename = "orders";
        this.downloadCsvFile(data, filename);

      })
      .catch((error) => console.error(error));
  }

  downloadCsvFile = async (data, fileName) => {
    const blob = new Blob([data], { type: "text/csv" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  search(event) {
    if (!event.target.value) {
      this.setState({ search: '', loading: false, searchList: false });
    } else {
      this.setState({ search: event.target.value });
      if (this.state.search.length > 2) {
        this.displaySearchList();
      } else {
        this.setState({ searchList: false });
      }
    }
  }

  displaySearchList() {
    this.setState({ searchList: this.state.search.length > 2 });
  }

  render() {
    this.t = this.props.t;
    let graphQuery;
    let variables;
    if (!this.state.searchList) {
      graphQuery = queries.allOrders;
      variables = { first: 10 };
    } else {
      graphQuery = queries.allOrders;
      variables = { search: this.state.search };
    }
    variables.notApproved = this.state.statusFilter;
    const tableHeaders = headers[this.props.tableType].headers;
    const dataKeys = headers[this.props.tableType].data;
    const capitalize = (s) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    return (
      <Container fluid className="main-content-container px-4">
        <Row
          style={{ justifyContent: 'space-between' }}
          noGutters
          className="ml-3 page-header py-4 align-items-center space-justify-content-between"
        >
          <h3>{this.t(this.props.title)}</h3>
          <Col md={{ size: 3 }}>
            <FormInput
              placeholder={this.t('searchPlaceholder')}
              name="search"
              id="search"
              onChange={(e) => this.search(e)}
              value={this.state.search}
            />
          </Col>
        </Row>
        <Row
          style={{ justifyContent: 'space-between' }}
          noGutters
          className="ml-3 page-header pb-3 align-items-center space-justify-content-between"
        >
          <Col md={{ size: 9 }}></Col>
          <Col md={{ size: 3 }}>
            <Button theme="info" onClick={()=>this.onClickDownloadCsv()}>
              { this.t("download.orders")}
            </Button>
          </Col>
        </Row>
        <Query query={graphQuery} variables={variables}>
          {({ loading, error, data, refetch, fetchMore }) => {
            if (loading || this.state.loading)
              return (
                <div className="spinner-loader-listing">
                  <Spinner
                    size={80}
                    spinnerColor={'#007BFF'}
                    spinnerWidth={2}
                    visible={true}
                  />
                </div>
              );
            if (error) return `Error ${error.message}`;
            this.refetch = refetch;
            return (
              <Container fluid>
                <Row>
                  <Col>
                    <Card small className="mb-4">
                      <CardBody className="p-0">
                        <table className="table mb-0">
                          <thead className="bg-light">
                            <tr className="table-hover">
                              <th scope="col" className="border-0">
                                #
                              </th>
                              <th scope="col" className="border-0">
                                {this.t("table.clientName")}
                              </th>
                              <th scope="col" className="border-0">
                                {this.t("table.restaurantName")}
                              </th>
                              {tableHeaders.map((header, index) => (
                                <th
                                  key={index}
                                  scope="col"
                                  className="border-0 cursor-pointer"
                                  onClick={() => {
                                    const key = header.text.split('.')[1];
                                    this.setState({ loading: true });
                                    this.sortColumn(key, data);
                                    this.setState({
                                      loading: false,
                                      sortingColumn: key,
                                    });
                                  }}
                                >
                                  {this.state.sortingColumn ===
                                    header.text.split('.')[1] &&
                                    !this.state.reverseSorting && (
                                      <i className="material-icons">
                                        arrow_drop_down
                                      </i>
                                    )}
                                  {this.state.sortingColumn ===
                                    header.text.split('.')[1] &&
                                    this.state.reverseSorting && (
                                      <i className="material-icons">
                                        arrow_drop_up
                                      </i>
                                    )}
                                  {this.t(header.text)}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {data[Object.keys(data)[0]].edges.map(
                              (cursor, index) => {
                                return (
                                  <tr key={index} className="table-hover">
                                    <td>{index + 1}</td>
                                    <td >{cursor.node.client[0].name}</td>
                                    <td >{cursor.node.restaurant.name}</td>
                                    <td >{cursor.node.orderType}</td>
                                    <td >{cursor.node.payment[0] ? this.t(cursor.node.payment[0].paymentType) : ''}</td>
                                    { cursor.node.orderType === "delivery" && 
                                      (<td>
                                        {cursor.node.total + 
                                        (cursor.node.deliveryPrice ? cursor.node.deliveryPrice: cursor.node.restaurant.deliveryPrice)}
                                      </td>) 
                                    } 
                                    { cursor.node.orderType !== "delivery" && (<td key={index}>{cursor.node.total}</td>) } 
                                    <td >{cursor.node.created}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {data[Object.keys(data)[0]].pageInfo.hasNextPage && (
                  <Row className="pb-3">
                    <Col className="text-center" md="12">
                      <Button
                        className=""
                        theme="info"
                        onClick={() =>
                          fetchMore({
                            variables: {
                              first: 10,
                              cursor:
                                data[Object.keys(data)[0]].pageInfo.endCursor,
                            },
                            updateQuery: (
                              previousResult,
                              { fetchMoreResult }
                            ) => {
                              const newEdges =
                                fetchMoreResult.paginatedUsers.edges;
                              const pageInfo =
                                fetchMoreResult.paginatedUsers.pageInfo;

                              return newEdges.length
                                ? {
                                    paginatedUsers: {
                                      __typename:
                                        previousResult.paginatedUsers
                                          .__typename,
                                      edges: [
                                        ...previousResult.paginatedUsers.edges,
                                        ...newEdges,
                                      ],
                                      pageInfo,
                                    },
                                  }
                                : previousResult;
                            },
                          })
                        }
                      >
                        {this.t('loadMore')}
                      </Button>
                      {[25, 50, 100, 250, 500].reverse().map((e, index) => (
                        <p
                          key={index}
                          className="btn btn-info float-right mr-3"
                          onClick={() => {
                            this.setState({ loading: true });
                            fetchMore({
                              variables: {
                                first: e,
                                cursor:
                                  data[Object.keys(data)[0]].pageInfo.endCursor,
                              },
                              updateQuery: (
                                previousResult,
                                { fetchMoreResult }
                              ) => {
                                const newEdges =
                                  fetchMoreResult.paginatedUsers.edges;
                                const pageInfo =
                                  fetchMoreResult.paginatedUsers.pageInfo;

                                return newEdges.length
                                  ? {
                                      paginatedUsers: {
                                        __typename:
                                          previousResult.paginatedUsers
                                            .__typename,
                                        edges: [
                                          ...previousResult.paginatedUsers
                                            .edges,
                                          ...newEdges,
                                        ],
                                        pageInfo,
                                      },
                                    }
                                  : previousResult;
                              },
                            });
                            this.setState({ loading: false });
                          }}
                        >
                          {e}
                        </p>
                      ))}
                    </Col>
                  </Row>
                )}
              </Container>
            );
          }}
        </Query>
      </Container>
    );
  }
}
export default withTranslation()(PaginatedTable);
