import React from "react";
import { Link } from "react-router-dom";
import { client } from '../../apolloClient';
import { withTranslation } from "react-i18next";
import { queries } from '../../graphQueries'
import { Query } from 'react-apollo';
import { Multiselect } from 'react-widgets';
import {
  CardHeader,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormSelect
} from "shards-react";

import "../../shards-dashboard/styles/login.css"

class FranchiseForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      franchise: {
        name: '',
        user: {
          name: '',
          id: ''
        },
        id: '',
        restaurants: [],
      },
      stateRequired: false,
      loading: false,
      isEdit: false,
      restaurantSearch: ''
    }

    this.t = this.props.t;
  }

  componentWillMount() {
    try {
      
      if (this.props.location.state.entity) {
        console.log(this.props.location.entity)
        this.setState({
          franchise: {
            ...this.props.location.state.entity,
            restaurants: this.props.location.state.entity.franchises.edges.map((value) => value.node),
          },
          isEdit: true 
        })
      }
    } catch (err) { console.log(err) }
  }

  handleChange = event => {
    const { name, value } = event.target;
    if (name !== 'user') {
      this.setState(prevState => ({
        franchise: {
          ...prevState.franchise,
          [name]: value
        }
      }));
    } else {
      const id = event.target[event.target.selectedIndex].id;
      this.setState(prevState => ({
        franchise: {
          ...prevState.franchise,
          user: {
            name: value,
            id
          }
        }
      }));
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const mutation = this.state.isEdit ? queries.updateFranchise : queries.createFranchise;
    const variables = {
      input: {
        name: this.state.franchise.name,
        user: this.state.franchise.user ? this.state.franchise.user.id: null,
        restaurants: this.state.franchise.restaurants.reduce((previousValue, currentValue) => {
          return previousValue.concat(currentValue.id);
        }, []),
      }
    };

    if (this.state.isEdit) {
      variables.id = parseInt(this.state.franchise.id);
    }
    client.mutate({
      variables,
      mutation
    }).then((res, err) => {
      this.props.history.push("/franchises")
    })
  }

  multiOnChange = (event, type) => {
    if (type === 'RestaurantType') {
      this.setState((prevState) => ({
        franchise: { ...prevState.franchise, restaurants: event },
      }));
    }
  };

  render() {
    return (
      <Container className="pt-4">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="float-left m-0">{this.state.isEdit ? `${this.t('form.editing')} ${this.state.franchise.name}` : this.t('form.newFranchise')}</h6>
            <Button outline pill theme="danger" className="float-right" tag={Link} to="/franchises">Back</Button>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={this.handleSubmit}>
                    <Row form>
                      <Col md="6">
                        <label htmlFor="name">{this.t('table.name')}</label>
                        <FormInput
                          id="name"
                          name="name"
                          value={this.state.franchise.name}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Col>
                      <Col md="6">
                        <label htmlFor="user">{this.t('table.user')}</label>
                        <Query query={queries.users}>
                          {({ loading, error, data, refetch }) => {
                            if (loading) return "Loading...";
                            if (error) return `Error ${error.message}`
                            return (
                              <FormSelect onChange={this.handleChange} id="user" name="user" defaultValue={this.state.isEdit && this.state.franchise.user ? this.state.franchise.user.name : 0}>
                                <option key={-1} id={-1}>{this.t('form.selectOne')}</option>
                                {data.users.map((user, index) => <option key={index} id={user.id} value={user.id}>{user.email}</option>)}
                              </FormSelect>
                            )
                          }}
                        </Query>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label htmlFor="restaurants">
                          {this.props.t('franchiseForm.restaurants')}
                        </label>
                        <Query query={queries.restaurantsFranchise} variables={{notApproved: false, text:''}}>
                          {({ loading, error, data, refetch }) => {
                            this.refetch = refetch;
                            if (loading) return 'Loading...';
                            if (error) return `Error ${error.message}`;
                            return (
                              <Multiselect
                                data={data.restaurantsByText.edges.reduce(
                                  (acc, restaurant) => {
                                    if (this.state.franchise.restaurants.map((s) => s.id)
                                      .indexOf(restaurant.node.id)<0)

                                      acc.push(restaurant.node);
                                    
                                    return acc;
                                  }
                                , [])}
                                value={this.state.franchise.restaurants}
                                onChange={(e) =>
                                  this.multiOnChange(e, 'RestaurantType')
                                }
                                textField="name"
                                valueField="id"
                              />
                            );
                          }}
                        </Query>
                      </Col>
                    </Row>
                    <Row form className="mt-2">
                      <Col md="2">
                        <Button type="submit">{this.state.isEdit ? this.t('form.update') : this.t('form.create')}</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    )
  }
}

export default (withTranslation()(FranchiseForm))