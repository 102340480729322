import { Modal, ModalBody, ModalHeader, Button } from "shards-react";
import { queries } from "../graphQueries";
import { client } from "../apolloClient";
import React from "react";

import "../shards-dashboard/styles/styles.css";

const ConfirmDeleteModal = ({
  open,
  toggleFunction,
  toDeleteName,
  cancelClickFunction,
  entity,
  refetchFunction,
  toDeleteId,
  t,
  secondaryId
}) => {
  const deleteItem = id => {
    const mutation = queries[`delete${entity}`];
    let variables = { id: id };
    if (secondaryId) variables = { id: id, menu: secondaryId };

    client
      .mutate({
        variables: variables,
        mutation
      })
      .then((res, err) => {
        client.resetStore().then(res => {
          toggleFunction();
          if (refetchFunction) {
            refetchFunction();
          }
        });
      });
  };

  return (
    <Modal size="sm" centered={true} open={open} toggle={toggleFunction}>
      <ModalHeader>
        {t("deleteModalHeader") + " " + toDeleteName + "?"}
      </ModalHeader>
      <ModalBody>
        <p>{t("confirmDelete")}</p>
        <Button
          theme="danger"
          className="float-left"
          onClick={() => deleteItem(toDeleteId)}
        >
          <i className="material-icons mr-1">delete</i>
          {t("table.delete")}
        </Button>
        <Button
          outline
          pill
          theme="info"
          className="float-right"
          onClick={() => toggleFunction()}
        >
          <i className="material-icons mr-1">cancel</i>
          {t("cancel")}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmDeleteModal;
