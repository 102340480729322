const headers =
{
  currencies: {
    headers: [
      "table.code",
      "table.symbol"
    ],
    data: [
      "code",
      "symbol"
    ],
    formComponent: "currency",
    deleteMutation: "deleteCurrency"
  },
  cities: {
    headers: [
      "table.name",
      "cityTable.state"
    ],
    data: [
      "name",
      "state.name"
    ],
    formComponent: "city",
    deleteMutation: "deleteCity"
  },
  states: {
    headers: [
      "table.name",
      "table.country"
    ],
    data: [
      "name",
      "country.name"
    ],
    formComponent: "state",
    deleteMutation: "deleteState"
  },
  lifestyles: {
    headers: [
      "table.name"
    ],
    data: [
      "name"
    ],
    formComponent: "lifestyle",
    deleteMutation: "deleteLifestyle"
  },
  services: {
    headers: [
      "table.name",
      "table.icon",
    ],
    data: [
      "name",
      "icon"
    ],
    formComponent: "service",
    deleteMutation: "deleteService"
  },
  allergens: {
    headers: [
      "table.name",
      "table.icon",
    ],
    data: [
      "name",
      "icon"
    ],
    formComponent: "allergen",
    deleteMutation: "deleteAllergen"
  },
  cuisines: {
    headers: [
      "table.name",
      "table.icon",
    ],
    data: [
      "name",
      "icon"
    ],
    formComponent: "cuisine",
    deleteMutation: "deleteCuisine"
  },
  notApprovedComments: {
    headers: [
      "table.date",
      "table.user",
      "restaurantForm.nameRes",
      "plato",
      "table.title",
      "table.comment",
    ],
    data: [
      "created",
      "user.username",
      "dish.restaurant.name",
      "dish.name",
      "title",
      "content"
    ],
    deleteMutation: "deleteComment"
  },
  countries: {
    headers: [
      "table.name",
      "table.code",
      "table.currency"
    ],
    data: [
      "name",
      "code",
      "currency.code"
    ],
    formComponent: "country",
    deleteMutation: "deleteCountry"
  },
  restaurants: {
    headers: [
      {
        text: "table.name",
        sortable: true
      },
      {
        text: "dishForm.status",
        sortable: false
      },
      {
        text: "table.city",
        sortable: true
      },
      {
        text: "table.views",
        sortable: true
      },
    ],
    data: [
      "name",
      "status",
      "city.name",
      "views"
    ],
    formComponent: "restaurant",
    deleteMutation: "deleteRestaurant"
  },
  dishes: {
    headers: [
      {
        text: "table.name",
        sortable: true
      },
      {
        text: "dishForm.status",
        sortable: true
      },
      {
        text: "disForm.image",
        sortable: false
      }
    ],
    data: [
      "nameEs",
      "status",
      "image"
    ],
    formComponent: "dish",
    deleteMutation: "deleteDish"
  },
  users: {
    headers: [
      {
        text: "table.name",
        sortable: true
      },
      {
        text: "restaurantForm.email",
        sortable: true
      },
      {
        text: "table.role",
        sortable: true
      },
    ],
    data: [
      "username",
      "email",
      "role"
    ],
    formComponent: "user",
    deleteMutation: "deleteUser"
  },
  notApprovedDishes: {
    headers: [
      {
        text: "table.name",
        sortable: true
      },
      {
        text: "dishForm.image",
        sortable: false
      },
      {
        text: "restaurantForm.nameRes",
        sortable: true
      },
      {
        text: "dishForm.status",
        sortable: true
      }
    ],
    data: [
      "nameEs",
      "image",
      "restaurant.name",
      "status"
    ],
    formComponent: "dish",
    deleteMutation: "deleteDish"
  },
  orders: {
    headers: [
      {
        text: "order.orderType",
        sortable: true
      },
      {
        text: "order.paymentType",
        sortable: true
      },
      {
        text: "table.total",
        sortable: true
      },
      {
        text: "table.created",
        sortable: true
      },
    ],
    data: [
      "orderType",
      "total",
      "created"
    ],
  },
  markets: {
    headers: [
      {
        text: "table.name",
        sortable: true
      },
      {
        text: "table.city",
        sortable: true
      },
    ],
    data: [
      "name",
      "city.name",
    ],
    formComponent: "market",
    deleteMutation: "deleteRestaurant"
  },
  franchises: {
    headers: [
      "table.name",
    ],
    data: [
      "name",
    ],
    formComponent: "franchise",
    deleteMutation: "deleteFranchise"
  },
  companies: {
    headers: [
      {
        text: "table.name",
        sortable: true
      },
      {
        text: "restaurantForm.email",
        sortable: true
      },
    ],
    data: [
      "name",
      "email"
    ],
    formComponent: "company",
    deleteMutation: "deleteCompany"
  }
}

module.exports = headers
