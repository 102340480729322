import React from 'react';
import Dropzone from 'react-dropzone';
import { Query } from 'react-apollo';
import { client } from '../../apolloClient';
import Geosuggest from 'react-geosuggest';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { queries } from '../../graphQueries';
import {
  CardHeader,
  Card,
  Container,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect,
  CardBody,
  FormCheckbox,
  Collapse
} from 'shards-react';
import { Link, Redirect } from 'react-router-dom';
import {
  MEDIA_FILES_APPEND,
} from '../../config';
import { Multiselect } from 'react-widgets';
import ConfigHourComponent from '../../components/ConfigHourComponent';
import '../../shards-dashboard/styles/geosuggest.css';
import '../../shards-dashboard/styles/styles.css';
import TableModalForm from '../../components/TableModalForm';
import axios from 'axios';
import { API_URL } from '../../config';
import { ChromePicker } from 'react-color';

class MarketForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      market: {
        id: null,
        name: '',
        description: '',
        address: '',
        email: '',
        city: {
          name: '',
          id: '-1',
        },
        logoImage: [],
        image: [],
        latitude: '',
        longitude: '',
        postalCode: '',
        placeId: '',
        restaurants: [],
        stripePublicKey: '',
        stripePrivateKey: '',
        delivery: false,
        takeaway: false,
        lounge: false,
        ordersStopped: false,
        customFont: '',
        customFontLink: '',
        customLink: '',
        customPrimaryColor: '',
        customSecondaryColor: '',
        customLinkName: '',
        deliveryConfig: {
          cpList: [],
          deliveryPrice: '',
          commonConfig: {
            minimumOrder: '',
            orderTime: '',
            ordersConfigHours: []
          }
        },
        takeawayConfig: {
          sendEmailClosed: false,
          commonConfig: {
            minimumOrder: '',
            orderTime: '',
            ordersConfigHours: []
          }
        },
        loungeConfig: {
          askPhone: true,
          askEmail: true,
          askName: true,
          sendEmailClosed: false,
          commonConfig: {
            minimumOrder: '',
            orderTime: '',
            ordersConfigHours: [],
          }
        },
        tables: []
      },
      isEdit: false,
      loading: false,
      redirect: false,
      gmapsSearched: false,
      marketOrderConfigCollapse: false,
      deliveryConfigCollapse: false,
      loungeConfigCollapse: false,
      takeawayConfigCollapse: false,
      cpList: [],
      modalToggled: false,
      isTableEdit: false,
      selectedFontIndex: -1,
      toEditTable: {}
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.qrSize = [200, 200];
    this.fontsArray = [
      {
        name: 'Roboto',
        css: "'Roboto', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Roboto&display=swap',
      },
      {
        name: 'Open Sans',
        css: "'Open Sans', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Open+Sans&display=swap',
      },
      {
        name: 'Lato',
        css: "'Lato', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Lato&display=swap',
      },
      {
        name: 'Slabo 27px',
        css: "'Slabo 27px', serif",
        link: 'https://fonts.googleapis.com/css?family=Slabo+27px&display=swap',
      },
      {
        name: 'Oswald',
        css: "'Oswald', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Oswald&display=swap',
      },
      {
        name: 'Source Sans Pro',
        css: "'Source Sans Pro', sans-serif",
        link:
          'https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap',
      },
      {
        name: 'Montserrat',
        css: "'Montserrat', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Montserrat&display=swap',
      },
      {
        name: 'Raleway',
        css: "'Raleway', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Raleway&display=swap',
      },
      {
        name: 'PT Sans',
        css: "'PT Sans', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=PT+Sans&display=swap',
      },
      {
        name: 'Lora',
        css: "'Lora', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Lora&display=swap',
      },
      {
        name: 'Noto Sans',
        css: "'Noto Sans', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Noto+Sans&display=swap',
      },
      {
        name: 'Nunito Sans',
        css: "'Nunito Sans', sans-serif",
        link:
          'https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap',
      },
      {
        name: 'Concert One',
        css: "'Concert One', sans-serif",
        link:
          'https://fonts.googleapis.com/css?family=Concert+One&display=swap',
      },
      {
        name: 'Work Sans',
        css: "'Work Sans', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Work+Sans&display=swap',
      },
      {
        name: 'Prompt',
        css: "'Prompt', sans-serif",
        link: 'https://fonts.googleapis.com/css?family=Prompt&display=swap',
      },
    ];
  }

  componentDidMount() {
    try {
      if (this.props.location.state.entity) {
        this.loadMarket();
      }
    } catch (err) {
      console.log(err);
    }
  }

  loadMarket = () => {
    this.setState({ loading: true });
    const variables = { id: this.props.location.state.entity.id };
    const query = queries.market;
    client
      .query({ variables, query, fetchPolicy: 'network-only' })
      .then((res) => {
        let color =
          res.data[Object.keys(res.data)[0]].customPrimaryColor
            ? res.data[Object.keys(res.data)[0]].customPrimaryColor
            : '#0DD1A9';

        this.setState((prevState) => ({
          market: {
            ...prevState.market,
            ...res.data.market,
            delivery: res.data.market.activeOn.includes("DE"),
            takeaway: res.data.market.activeOn.includes("TA"),
            lounge: res.data.market.activeOn.includes("SA"),
            restaurants: res.data.market.restaurants.edges.map((value) => value.node),
            loungeConfig: {...prevState.market.loungeConfig, ...res.data.market.loungeConfig},
            takeawayConfig: {...prevState.market.takeawayConfig, ...res.data.market.takeawayConfig},
            deliveryConfig: {...prevState.market.deliveryConfig, ...res.data.market.deliveryConfig},
            customPrimaryColor: color,
            customSecondaryColor: res.data[Object.keys(res.data)[0]]
              .customSecondaryColor
              ? res.data[Object.keys(res.data)[0]].customSecondaryColor
              : '#FFFFFF',
          },
          isEdit: true,
          loading: false,
        }));
        this.fontsArray.forEach((value, index) => {
          if (
            value.css === res.data[Object.keys(res.data)[0]].customFont
          ) {
            this.setState({ selectedFontIndex: index });
          }
        });
      });
  }

  multiOnChange = (event, type) => {
    if (type === 'RestaurantType') {
      this.setState((prevState) => ({
        market: { ...prevState.market, restaurants: event },
      }));
    } else if(type === 'CPType') {
      this.setState((prevState) => ({
        market: {
          ...prevState.market,
          deliveryConfig: {
            ...prevState.market.deliveryConfig,
            cpList: event,
          }
        },
      }));
    }
  };

  handleSubmitMarket = async (e) => {
    e.preventDefault();
    let activeOn = [];
    if (this.state.market.delivery) activeOn.push('DE');
    if (this.state.market.lounge) activeOn.push('SA');
    if (this.state.market.takeaway) activeOn.push('TA');
    this.setState({ loading: true });
      const mutation = queries.createOrUpdateMarket;
      const variables = {
        name: this.state.market.name,
        description: this.state.market.description,
        address: this.state.market.address,
        email: this.state.market.email,
        city: this.state.market.city.id ? this.state.market.city.id: this.state.market.city,
        logoImage:
          typeof this.state.market.logoImage === 'object'
            ? this.state.market.logoImage[0]
            : this.state.market.logoImage,
        image:
          typeof this.state.market.image === 'object'
            ? this.state.market.image[0]
            : this.state.market.image,
        latitude: this.state.market.latitude,
        longitude: this.state.market.longitude,
        postalCode: this.state.market.postalCode,
        //user: this.state.user.id,
        placeId: this.state.market.placeId,
        autoTranslate: this.state.autoTranslate,
        phone: this.state.market.phone,
        restaurants: this.state.market.restaurants.reduce((previousValue, currentValue) => {
          return previousValue.concat(currentValue.id);
        }, []),
        stripePublicKey: this.state.market.stripePublicKey,
        stripePrivateKey: this.state.market.stripePrivateKey,
        ordersStopped: this.state.market.ordersStopped,
        activeOn: activeOn,
        customPrimaryColor: this.state.market.customPrimaryColor,
        customSecondaryColor: this.state.market.customSecondaryColor,
        customFont: this.state.market.customFont,
        customFontLink: this.state.market.customFontLink,
        customLink: this.state.market.customLink,
        customLinkName: this.state.market.customLinkName,
      };

      if (this.state.isEdit) {
        variables.id = this.state.market.id;
      }
      client
        .mutate({
          variables,
          mutation,
        })
        .then((res, err) => {
          if (err) console.log(err);
          this.setState({ loading: false, redirect: true });
        });
  }

  handleFontChange(e) {
    const fontIndex = e.target[e.target.selectedIndex].id;
    if (fontIndex > -1) {
      this.setState((prevState) => ({
        ...prevState,
        market: {
          ...prevState.market,
          customFont: this.fontsArray[fontIndex]['css'],
          customFontLink: this.fontsArray[fontIndex]['link'],
        },
      }));
      this.setState({ selectedFontIndex: fontIndex });
    }
  }

  handleChangeCompleteFont = (color) => {
    this.setState((prevState) => ({
      market: {
        ...prevState.market,
        customSecondaryColor: color.hex,
      },
    }));
  };

  handleChangeCompleteCustomColor = (color) => {
    this.setState((prevState) => ({
      market: {
        ...prevState.market,
        customPrimaryColor: color.hex,
      },
    }));
  };

  handleSubmitDeliveryConfigMarket = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    const mutation = queries.updateMarketDeliveryConfig;
    const ordersHours = this.state.market.deliveryConfig.commonConfig.ordersConfigHours.map((value) => {
      console.log(value);
      return {
        id: value.id ? value.id : null,
        startTime: `${value.startHour ? value.startHour: value.startTime.split(':')[0]}:${value.startMinute ? value.startMinute: value.startTime.split(':')[1]}`,
        endTime: `${value.endHour ? value.endHour: value.endTime.split(':')[0]}:${value.endMinute ? value.endMinute: value.endTime.split(':')[1]}`,
        days: value.days.map((value)=> typeof value.id !== 'undefined' ? value.id: value)
      }
    });
    const variables = {
      cpList: this.state.market.deliveryConfig.cpList,
      minimumOrder: this.state.market.deliveryConfig.commonConfig.minimumOrder,
      deliveryPrice: this.state.market.deliveryConfig.deliveryPrice,
      orderTime: this.state.market.deliveryConfig.commonConfig.orderTime,
      ordersHours: ordersHours,
    };

    if (this.state.isEdit) {
      variables.market = this.state.market.id;
    }
    client
      .mutate({
        variables,
        mutation,
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.setState({ loading: false, redirect: true });
      });
  }

  handleSubmitOrderConfigMarket = async (e, parent) => {
    e.preventDefault();

    this.setState({ loading: true });
    let mutation = queries.updateMarketLoungeConfig;
    if (parent === 'takeawayConfig') {
      mutation = queries.updateMarketTakeawayConfig;
    }
    
    const ordersHours = this.state.market[parent].commonConfig.ordersConfigHours.map((value) => {
      return {
        id: value.id ? value.id : null,
        startTime: `${value.startHour ? value.startHour: value.startTime.split(':')[0]}:${value.startMinute ? value.startMinute: value.startTime.split(':')[1]}`,
        endTime: `${value.endHour ? value.endHour: value.endTime.split(':')[0]}:${value.endMinute ? value.endMinute: value.endTime.split(':')[1]}`,
        days: value.days.map((value)=> typeof value.id !== 'undefined' ? value.id: value)
      }
    });
    const variables = {
      minimumOrder: this.state.market[parent].commonConfig.minimumOrder,
      orderTime: this.state.market[parent].commonConfig.orderTime,
      ordersHours: ordersHours,
      sendEmailClosed: this.state.market[parent].sendEmailClosed
    };

    if(parent === 'loungeConfig') {
      variables.askEmail = this.state.market.loungeConfig.askEmail;
      variables.askName = this.state.market.loungeConfig.askName;
      variables.askPhone = this.state.market.loungeConfig.askPhone;
    }

    if (this.state.isEdit) {
      variables.market = this.state.market.id;
    }
    client
      .mutate({
        variables,
        mutation,
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.setState({ loading: false, redirect: true });
      });
  }

  addHoursConfig = (parent) => {
    const hours = this.state.market[parent].commonConfig.ordersConfigHours.slice();
    hours.push({
      startHour: '',
      startMinute: '',
      endHour: '',
      endMinute: '',
      days: []
    });
    this.setState((prevState) => ({
      ...prevState,
      market:{
        ...prevState.market,
        [parent]: {
          ...prevState.market[parent],
          commonConfig: {
            ...prevState.market[parent].commonConfig,
            ordersConfigHours: hours,
          }
        }
      },
    }));
  };

  removeHoursConfig = (index, parent) => {
    const hours = this.state.market[parent].commonConfig.ordersConfigHours.slice();
    hours.splice(index, 1);

    this.setState((prevState) => ({
      ...prevState,
      market:{
        ...prevState.market,
        [parent]: {
          ...prevState.market[parent],
          commonConfig: {
            ...prevState.market[parent].commonConfig,
            ordersConfigHours: hours,
          }
        }
      },
    }));
  };

  handleSelectHoursConfig = (orderHour, index, parent) => {
    const hours = this.state.market[parent].commonConfig.ordersConfigHours;
    hours[index] = orderHour;

    this.setState((prevState) => ({
      ...prevState,
      market:{
        ...prevState.market,
        [parent]: {
          ...prevState.market[parent],
          commonConfig: {
            ...prevState.market[parent].commonConfig,
            ordersConfigHours: hours,
          }
        }
      },
    }));
  };

  renderOrderHours = (parent) => {
    return (this.state.market[parent].commonConfig.ordersConfigHours.map((orderHour, idx)=> {
      return (
        <ConfigHourComponent
          orderHour={orderHour} parent={parent} index={idx} handleSelectHoursConfig={this.handleSelectHoursConfig}
          removeHoursConfig={this.removeHoursConfig} t={this.props.t} 
        />
      )
    }));
  }

  onDropIcon = (icon) => {
    this.setState((prevState) => ({
      market: { ...prevState.market, logoImage: icon },
    }));
  };

  onDropCover = (cover) => {
    this.setState((prevState) => ({
      market: { ...prevState.market, image: cover },
    }));
  };

  onSuggestSelect = (suggest) => {
    this.setState(
      (prevState) => ({
        gmapsSearched: true,
        market: {
          ...prevState.market,
          name: suggest.gmaps.name,
          address: suggest.gmaps.formatted_address,
          latitude: suggest.location.lat,
          longitude: suggest.location.lng,
          postalCode: suggest.gmaps.address_components.filter((e) =>
            e.types.includes('postal_code')
          )[0].long_name,
          placeId: suggest.placeId,
          phone: suggest.gmaps.formatted_phone_number,
          city: {
            ...prevState.city,
            name: suggest.gmaps.address_components.filter((e) =>
              e.types.includes('locality')
            )[0].long_name,
          },
        },
      }),
      () => {
        const cityOptionIndex = document.getElementById('city').selectedIndex;
        const cityOptions = document.getElementById('city').options;
        this.setState((prevState) => ({
          restaurant: {
            ...prevState.market,
            city: {
              ...prevState.city,
              id: cityOptions[cityOptionIndex].id,
            },
          },
        }));
      }
    );
  };

  handleChangeMarket = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      market: {
        ...prevState.market,
        [name]: value,
      },
    }));
  };

  handleChangeDeliveryConfig = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      market: {
        ...prevState.market,
        deliveryConfig: {
          ...prevState.market.deliveryConfig,
          [name]: value,
        }
      },
    }));
  };

  handleChangeCommonConfig = (event, parent) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      market: {
        ...prevState.market,
        [parent]: {
          ...prevState.market[parent],
          commonConfig: {
            ...prevState.market[parent].commonConfig,
            [name]: value,
          }
        }
      },
    }));
  };

  handleChangeLoungeConfigCheckbox = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      market: {
        ...prevState.market,
        loungeConfig: {
          ...prevState.market.loungeConfig,
          [name]: !prevState.market.loungeConfig[name],
        }
      },
    }));
  };

  handleChangeTakeawayConfigCheckbox = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      market: {
        ...prevState.market,
        takeawayConfig: {
          ...prevState.market.takeawayConfig,
          [name]: !prevState.market.takeawayConfig[name],
        }
      },
    }));
  };

  handleCreate = (name) => {
    this.setState((prevState) => ({
      ...prevState,
      cpList: [...prevState.cpList, name]
    }));
  }

  toggleModal(
    isEdit = false,
    toEditTable = {}
  ) {
    this.setState(
      {
        isTableEdit: isEdit,
        toEditTable,
      },
      () => this.setState({ modalToggled: !this.state.modalToggled })
    );
  }

  readBlobAsync = (blob) => {
    console.log("Blob")
    return new Promise((resolve, reject) => {
      const fReader = new FileReader();

      fReader.onload = (evt) => {
        resolve(evt.target.result);
      };
      fReader.onerror = reject;

      fReader.readAsDataURL(blob);
    });
  }

  qrURL(market, table) {
    const encodedString = btoa(table);

    return `${API_URL}/markets/${market}/?table=${encodedString}`;
  }

  getImage = async (market, table) => {
    // TODO: Add table code into image, could use canvas
    const apiURL = 'https://api.qrserver.com/v1/create-qr-code/';
    const size = `${this.qrSize[0]}x${this.qrSize[1]}`;
    const url = `${apiURL}?data=${this.qrURL(
      market,
      table
    )}&amp;size=${size}`;
    try {
      const res = await axios.get(url,
        {
          responseType: 'blob'
      });
      const file = await this.readBlobAsync(res.data);
      this.generateQRImageWithText(file, table);
      
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  generateQRImageWithText(baseImage, code) {
    const link = document.createElement('a');
    const canvas = document.createElement('canvas');
    canvas.width = 320;
    canvas.height = 320;

    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = baseImage;
    image.onload = () => {
      ctx.lineWidth = 1;
      // add background rect
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      // add image
      ctx.drawImage(image, 35, 35);
      // add text
      ctx.font = '18px sans-serif';
      ctx.fillStyle = '#272727';
      ctx.fillText(this.props.t('app.table') + ' ' + code, 35, 25);
    };

    // Add timeout to give canvas time to draw itself
    setTimeout(() => {
      link.href = canvas.toDataURL();
      link.download = `qr_${code}.png`;
      link.click();
      link.remove();

      this.setState((prevState) => ({
        ...prevState,
        loading: false
      }));

    }, 2000);
  }

  handleDownload(info) {
    this.setState((prevState) => ({
      ...prevState,
      loading: true
    }));
    this.getImage(this.state.market.urlPage, info.code);
  }

  render() {
    if (!this.state.loading) {
      if (this.state.redirect) {
        return <Redirect to="/markets" />;
      }
    }
    return (
      <Container className="pt-4">
        <Card className="pb-3">
          <CardHeader
            onClick={() =>
              this.setState({
                marketCollapse: !this.state.marketCollapse,
              })
            }
            className="cursor-pointer border-bottom"
          >
            <h6 className="float-left m-0">
              {this.props.t('user.market')}
            </h6>
            {!this.state.isRestaurant && (
              <Button
                tag={Link}
                disabled={this.state.isRestaurant}
                outline
                theme="danger"
                className="mt-1 float-right"
                to={{
                  pathname: `/markets`,
                  state: {
                    noRefetch: true,
                  },
                }}
              >
                {this.props.t('back')}
              </Button>
            )}
          </CardHeader>
          <CardBody>
            <Form onSubmit={this.handleSubmitMarket}>
              <Row>
                <Col sm="12">
                  <Geosuggest
                    
                    className="w-100 m-0"
                    inputClassName="p-1"
                    country="ES"
                    types={['establishment']}
                    minLength={5}
                    placeDetailFields={[
                      'address_components',
                      'formatted_address',
                      'name',
                      'place_id',
                      'formatted_phone_number',
                    ]}
                    onSuggestSelect={this.onSuggestSelect}
                  />
                </Col>
              </Row>
              <Row className="m-2">
                <Col md="5" className="form-group">
                  <label htmlFor="name">
                    {this.props.t('marketForm.name')}
                    <span className="required-input"> *</span>
                  </label>
                  <FormInput
                    id="name"
                    onChange={this.handleChangeMarket}
                    required={true}
                    name="name"
                    value={this.state.market.name}
                  />
                </Col>
                <Col md="7">
                  <label htmlFor="description">
                    {this.props.t('restaurantForm.description')}
                    <span className="required-input"> *</span>
                  </label>
                  <FormTextarea
                    id="description"
                    onChange={this.handleChangeMarket}
                    value={this.state.market.description || ''}
                    required={true}
                    name="description"
                  />
                </Col>
              </Row>
              <Row className="m-2">
                <Col md="8">
                  <label htmlFor="address">
                    {this.props.t('restaurantForm.address')}
                    <span className="required-input"> *</span>
                  </label>
                  <FormInput
                    id="address"
                    onChange={this.handleChangeMarket}
                    value={this.state.market.address}
                    required={true}
                    name="address"
                  />
                </Col>
                <Col md="4" style={{ marginTop: 33 }}>
                  <FormCheckbox
                    disabled={
                      this.state.isRestaurant
                    }
                    checked={this.state.autoTranslate}
                    onChange={() =>
                      this.setState({
                        autoTranslate: !this.state.autoTranslate,
                      })
                    }
                  >
                    {this.props.t('dishForm.autoTranslate')}
                  </FormCheckbox>
                </Col>
              </Row>
              <Row className="m-2">
                <Col md="4">
                  <label htmlFor="city">
                    {this.props.t('table.city')}
                    <span className="required-input"> *</span>
                  </label>
                  <Query query={queries.cities}>
                    {({ loading, error, data, refetch }) => {
                      if (loading) return 'Loading...';
                      if (error) return `Error ${error.message}`;
                      return (
                        <FormSelect
                          onChange={this.handleChangeMarket}
                          id="city"
                          name="city"
                          defaultValue={
                            (this.state.isEdit ||
                              this.state.gmapsSearched) &&
                            this.state.market.city
                              ? this.state.market.city.id
                              : 0
                          }
                        >
                          <option key={-1} id={-1}>
                            {this.props.t('form.selectOne')}
                          </option>
                          {data.cities.map((city, index) => (
                            <option
                              key={index}
                              value={city.id}
                              id={city.id}
                            >
                              {city.name}
                            </option>
                          ))}
                        </FormSelect>
                      );
                    }}
                  </Query>
                </Col>
                <Col md="4">
                  <label htmlFor="latitude">
                    {this.props.t('restaurantForm.latitude')}
                    <span className="required-input"> *</span>
                  </label>
                  <FormInput
                    id="latitude"
                    onChange={this.handleChangeMarket}
                    required={true}
                    name="latitude"
                    value={this.state.market.latitude}
                    placeholder="36.762108"
                  />
                </Col>
                <Col md="4">
                  <label htmlFor="longitude">
                    {this.props.t('restaurantForm.longitude')}
                    <span className="required-input"> *</span>
                  </label>
                  <FormInput
                    id="longitude"
                    onChange={this.handleChangeMarket}
                    required={true}
                    name="longitude"
                    value={this.state.market.longitude}
                    placeholder="-6.375854"
                  />
                </Col>
              </Row>
              <Row className="m-2">
                <Col md="4">
                  <label htmlFor="postalCode">
                    {this.props.t('restaurantForm.postalCode')}
                    <span className="required-input"> *</span>
                  </label>
                  <FormInput
                    id="postalCode"
                    onChange={this.handleChangeMarket}
                    required={true}
                    name="postalCode"
                    value={
                      this.state.market.postalCode === 'null' ||
                      !this.state.market.postalCode
                        ? ''
                        : this.state.market.postalCode
                    }
                    placeholder="11540"
                  />
                </Col>
                <Col md="4">
                  <label htmlFor="email">
                    {this.props.t('restaurantForm.email')}
                    <span className="required-input"> *</span>
                  </label>
                  <FormInput
                    id="email"
                    onChange={this.handleChangeMarket}
                    value={this.state.market.email}
                    required={true}
                    name="email"
                    type="email"
                  />
                </Col>
                <Col md="4">
                  <label htmlFor="phone">
                    {this.props.t('restaurantForm.phone')}
                  </label>
                  <FormInput
                    id="phone"
                    onChange={this.handleChangeMarket}
                    required={false}
                    name="phone"
                    value={this.state.market.phone || ''}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <label htmlFor="restaurants">
                    {this.props.t('marketForm.restaurants')}
                    <span className="required-input"> *</span>
                  </label>
                  <Query query={queries.restaurantsMarket}>
                    {({ loading, error, data }) => {
                      if (loading) return 'Loading...';
                      if (error) return `Error ${error.message}`;
                      return (
                        <Multiselect
                          data={data.restaurantsMarket.filter(
                            (service) =>
                              this.state.market.restaurants
                                .map((s) => s.id)
                                .indexOf(service.id) < 0
                          )}
                          value={this.state.market.restaurants}
                          onChange={(e) =>
                            this.multiOnChange(e, 'RestaurantType')
                          }
                          textField="name"
                          valueField="id"
                        />
                      );
                    }}
                  </Query>
                </Col>
              </Row>
              <Row className="m-2">
                <Col md="6">
                  <label>
                    {this.props.t('restaurantForm.logoImage')}
                    <span className="required-input"> *</span>
                  </label>
                  <Dropzone
                    accept="image/jpeg, image/png"
                    multiple={false}
                    onDrop={this.onDropIcon}
                  >
                    {({ getRootProps, getInputProps }) => {
                      let src = null;

                      if (this.state.market.logoImage) {
                        if (
                          typeof this.state.market.logoImage ===
                          'string'
                        ) {
                          src = `${MEDIA_FILES_APPEND}${this.state.market.logoImage}`;
                        } else if (
                          typeof this.state.market.logoImage ===
                          'object'
                        ) {
                          src = window.URL.createObjectURL(
                            new File(this.state.market.logoImage, {
                              type: 'image/png',
                            })
                          );
                        }
                      }

                      const image = !!src && (
                        <img
                          style={{ width: '50px' }}
                          src={src}
                          alt=""
                        />
                      );

                      return (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!image && (
                              <p className="dropzone-solid-blue">
                                {this.props.t('uploadText')}
                              </p>
                            )}
                            {Array.isArray(
                              this.state.market.logoImage
                            ) &&
                              this.state.market.logoImage.length ===
                                0 && (
                                <p className="dropzone-solid-blue">
                                  {this.props.t('uploadText')}
                                </p>
                              )}
                            {image}
                          </div>
                        </section>
                      );
                    }}
                  </Dropzone>
                </Col>
                <Col md="6">
                  <label>
                    {this.props.t('restaurantForm.image')}
                    <span className="required-input"> *</span>
                  </label>
                  <Dropzone
                    accept="image/jpeg, image/png"
                    multiple={false}
                    onDrop={this.onDropCover}
                  >
                    {({ getRootProps, getInputProps }) => {
                      let src = null;

                      if (this.state.market.image) {
                        if (
                          typeof this.state.market.image ===
                          'string'
                        ) {
                          src = `${MEDIA_FILES_APPEND}${this.state.market.image}`;
                        } else if (
                          typeof this.state.market.image ===
                          'object'
                        ) {
                          src = window.URL.createObjectURL(
                            new File(this.state.market.image, {
                              type: 'image/png',
                            })
                          );
                        }
                      }

                      const image = !!src && (
                        <img
                          style={{ width: '140px' }}
                          src={src}
                          alt=""
                        />
                      );

                      return (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!image && (
                              <p className="dropzone-solid-blue">
                                {this.props.t('uploadText')}
                              </p>
                            )}
                            {Array.isArray(
                              this.state.market.image
                            ) &&
                              this.state.market.image.length ===
                                0 && (
                                <p className="dropzone-solid-blue">
                                  {this.props.t('uploadText')}
                                </p>
                              )}
                            {image}
                          </div>
                        </section>
                      );
                    }}
                  </Dropzone>
                </Col>
              </Row>
              <Row className="m-2 mt-2">
                <Col md="3">
                  <Button type="submit">
                    {this.state.market.id
                      ? this.props.t('form.update')
                      : this.props.t('form.create')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <Card small className="mt-2">
          <CardHeader
            onClick={() =>
              this.setState({
                customStyleCollapse: !this.state
                  .customStyleCollapse,
              })
            }
            className="cursor-pointer border-bottom"
          >
            <h6 className="float-left m-0">
              {this.props.t('restaurantForm.customStyleCollapse')}
            </h6>
            {!this.state.customStyleCollapse && (
              <i className="material-icons float-right">
                expand_more
              </i>
            )}
            {this.state.customStyleCollapse && (
              <i className="material-icons float-right">
                expand_less
              </i>
            )}
          </CardHeader>
          <Collapse open={this.state.customStyleCollapse}>
            <CardBody>
              <Row>
                <Col md="6" sm="12" className="mt-1">
                  <label>
                    {this.props.t('restaurantForm.mainColor')}
                  </label>
                  <ChromePicker
                    disableAlpha={true}
                    color={
                      this.state.market
                        .customPrimaryColor
                    }
                    onChangeComplete={
                      this.handleChangeCompleteCustomColor
                    }
                  />
                </Col>
                <Col md="6" sm="12" className="mt-1">
                  <label>
                    {this.props.t('restaurantForm.fontColor')}
                  </label>

                  <ChromePicker
                    disableAlpha={true}
                    color={
                      this.state.market
                        .customSecondaryColor
                    }
                    onChangeComplete={
                      this.handleChangeCompleteFont
                    }
                  />
                  
                </Col>
                <Col md="6" sm="12" className="mt-1">
                  <label>
                    {this.props.t('restaurantForm.link')}
                  </label>
                  <FormInput
                    id="customLink"
                    onChange={this.handleChange}
                    required={false}
                    name="customLink"
                    value={
                      this.state.market.customLink || ''
                    }
                  />
                </Col>
                <Col md="6" sm="12" className="mt-1">
                  <label>
                    {this.props.t('restaurantForm.linkName')}
                  </label>
                  <FormInput
                    id="customLinkName"
                    onChange={this.handleChange}
                    required={false}
                    name="customLinkName"
                    value={
                      this.state.market.customLinkName ||
                      ''
                    }
                  />
                </Col>
                <Col md="6" sm="12" className="mt-1">
                  <label>
                    {this.props.t('restaurantForm.font')}
                  </label>

                  <FormSelect
                    className="mt-3"
                    onChange={(e) => this.handleFontChange(e)}
                    defaultValue={
                      this.state.selectedFontIndex
                    }
                  >
                    <option key={-1} id={-1}>
                      {this.props.t('restaurantForm.fontSelect')}
                    </option>
                    {this.fontsArray.map((font, index) => (
                      <option
                        key={index}
                        id={index}
                        selected={
                          index ===
                          this.state.selectedFontIndex
                            ? true
                            : false
                        }
                      >
                        {font.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
                <Col md="12">
                  <p
                    className=""
                    style={{
                      display:
                        this.state.selectedFontIndex >= 0
                          ? 'block'
                          : 'none',
                      fontFamily: this.state.market
                        .customFont,
                    }}
                  >
                    Esto es un texto de prueba con la
                    tipografía.
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Collapse>
        </Card>
        <Card small className="mt-2">
          <CardHeader
            onClick={() =>
              this.setState({
                marketOrderConfigCollapse: !this.state
                  .marketOrderConfigCollapse,
              })
            }
            className="cursor-pointer border-bottom"
          >
            <h6 className="float-left m-0">
              {this.props.t('marketForm.marketOrderConfigCollapse')}
            </h6>
            {!this.state.marketOrderConfigCollapse && (
              <i className="material-icons float-right">
                expand_more
              </i>
            )}
            {this.state.marketOrderConfigCollapse && (
              <i className="material-icons float-right">
                expand_less
              </i>
            )}
          </CardHeader>
          <Collapse open={this.state.marketOrderConfigCollapse}>
            <CardBody>
              <form
                onSubmit={this.handleSubmitMarket}
                autocomplete="off"
              >
                <Row className="m-2">
                  <Col md="6" className="form-group">
                    <label htmlFor="name">
                      {this.props.t('marketForm.stripePublicKey')}
                    </label>
                    <FormInput
                      id="stripePublicKey"
                      onChange={this.handleChangeMarket}
                      required={false}
                      name="stripePublicKey"
                      value={this.state.market.stripePublicKey}
                    />
                  </Col>
                  <Col md="6">
                    <label htmlFor="description">
                      {this.props.t('marketForm.stripePrivateKey')}
                    </label>
                    <FormTextarea
                      id="stripePrivateKey"
                      onChange={this.handleChangeMarket}
                      value={this.state.market.stripePrivateKey || ''}
                      required={false}
                      name="stripePrivateKey"
                    />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col md="3" >
                    <FormCheckbox
                      checked={this.state.market.delivery}
                      onChange={() =>
                        this.setState({
                          market: {...this.state.market, delivery: !this.state.market.delivery},
                        })
                      }
                    >
                      {this.props.t('dishForm.delivery')}
                    </FormCheckbox>
                  </Col>
                  <Col md="3">
                    <FormCheckbox
                      checked={this.state.market.takeaway}
                      onChange={() =>
                        this.setState({
                          market: {...this.state.market, takeaway: !this.state.market.takeaway},
                        })
                      }
                    >
                      {this.props.t('dishForm.takeaway')}
                    </FormCheckbox>
                  </Col>
                  <Col md="3">
                    <FormCheckbox
                      checked={this.state.market.lounge}
                      onChange={() =>
                        this.setState({
                          market: {...this.state.market, lounge: !this.state.market.lounge},
                        })
                      }
                    >
                      {this.props.t('dishForm.sala')}
                    </FormCheckbox>
                  </Col>
                  <Col md="3">
                    <FormCheckbox
                      checked={this.state.market.ordersStopped}
                      onChange={() =>
                        this.setState({
                          market: {...this.state.market, ordersStopped: !this.state.market.ordersStopped},
                        })
                      }
                    >
                      {this.props.t('restaurantForm.ordersStopped')}
                    </FormCheckbox>
                  </Col>
                
                </Row>
              </form>
            </CardBody>
          </Collapse>
        </Card>
        <Card small className="mt-2">
          <CardHeader
            onClick={() =>
              this.setState({
                deliveryConfigCollapse: !this.state
                  .deliveryConfigCollapse,
              })
            }
            className="cursor-pointer border-bottom"
          >
            <h6 className="float-left m-0">
              {this.props.t('marketForm.deliveryConfigCollapse')}
            </h6>
            {!this.state.deliveryConfigCollapse && (
              <i className="material-icons float-right">
                expand_more
              </i>
            )}
            {this.state.deliveryConfigCollapse && (
              <i className="material-icons float-right">
                expand_less
              </i>
            )}
          </CardHeader>
          <Collapse open={this.state.deliveryConfigCollapse}>
            <CardBody>
              <form onSubmit={this.handleSubmitDeliveryConfigMarket}>
                <Row className="m-2">
                  <Col md="12" className="form-group">
                    <label >
                      {this.props.t('restaurantForm.ordersPostalCodes')}
                    </label>
                  
                    <Multiselect
                      defaultValue={[]}
                      data={this.state.cpList}
                      value={this.state.market.deliveryConfig.cpList}
                      onChange={(e) =>
                        this.multiOnChange(e, 'CPType')
                      }
                      allowCreate={true}
                      onCreate={this.handleCreate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4" className="form-group">
                    <label htmlFor="minimumOrder">
                      {this.props.t('marketForm.minimumOrder')}
                    </label>
                    <FormInput
                      id="minimumOrder"
                      onChange={(e)=>this.handleChangeCommonConfig(e, 'deliveryConfig')}
                      required={true}
                      name="minimumOrder"
                      value={this.state.market.deliveryConfig.commonConfig.minimumOrder}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="deliveryPrice">
                      {this.props.t('marketForm.deliveryPrice')}
                    </label>
                    <FormInput
                      id="deliveryPrice"
                      onChange={this.handleChangeDeliveryConfig}
                      required={true}
                      name="deliveryPrice"
                      value={this.state.market.deliveryConfig.deliveryPrice}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="orderTime">
                      {this.props.t('marketForm.orderTime')}
                    </label>
                    <FormInput
                      id="orderTime"
                      onChange={(e)=>this.handleChangeCommonConfig(e, 'deliveryConfig')}
                      required={true}
                      name="orderTime"
                      value={this.state.market.deliveryConfig.commonConfig.orderTime}
                    />
                  </Col>
                </Row>
                { this.renderOrderHours('deliveryConfig') }
                <Row>
                  <Col>
                    <Button className="mb-3" intent='primary' onClick={()=>this.addHoursConfig('deliveryConfig')}>
                      {this.props.t('marketForm.addOrderHours')}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button type="submit">
                      {this.state.market.id
                        ? this.props.t('form.update')
                        : this.props.t('form.create')}
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Collapse>
        </Card>
        <Card small className="mt-2">
          <CardHeader
            onClick={() =>
              this.setState({
                takeawayConfigCollapse: !this.state
                  .takeawayConfigCollapse,
              })
            }
            className="cursor-pointer border-bottom"
          >
            <h6 className="float-left m-0">
              {this.props.t('marketForm.takeawayConfigCollapse')}
            </h6>
            {!this.state.takeawayConfigCollapse && (
              <i className="material-icons float-right">
                expand_more
              </i>
            )}
            {this.state.takeawayConfigCollapse && (
              <i className="material-icons float-right">
                expand_less
              </i>
            )}
          </CardHeader>
          <Collapse open={this.state.takeawayConfigCollapse}>
            <CardBody>
              <form onSubmit={(e)=>this.handleSubmitOrderConfigMarket(e, 'takeawayConfig')}>
                <Row>
                  <Col md="4" className="form-group">
                    <label htmlFor="takeaway-minimumOrder">
                      {this.props.t('marketForm.minimumOrder')}
                    </label>
                    <FormInput
                      id="takeaway-minimumOrder"
                      onChange={(e)=>this.handleChangeCommonConfig(e, 'takeawayConfig')}
                      required={true}
                      name="minimumOrder"
                      value={this.state.market.takeawayConfig.commonConfig.minimumOrder}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="takeaway-orderTime">
                      {this.props.t('marketForm.orderTime')}
                    </label>
                    <FormInput
                      id="takeaway-orderTime"
                      onChange={(e)=>this.handleChangeCommonConfig(e, 'takeawayConfig')}
                      required={true}
                      name="orderTime"
                      value={this.state.market.takeawayConfig.commonConfig.orderTime}
                    />
                  </Col>
                  <Col md="4" className="pt-4">
                    <FormCheckbox
                      name="sendEmailClosed"
                      checked={this.state.market.takeawayConfig.sendEmailClosed}
                      onChange={(e)=>this.handleChangeTakeawayConfigCheckbox(e)}
                    >
                      {this.props.t('marketForm.sendEmailClosed')}
                    </FormCheckbox>
                  </Col>
                </Row>
                { this.renderOrderHours('takeawayConfig') }
                <Row>
                  <Col>
                    <Button className="mb-3" intent='primary' onClick={()=>this.addHoursConfig('takeawayConfig')}>
                      {this.props.t('marketForm.addOrderHours')}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button type="submit">
                      {this.state.market.id
                        ? this.props.t('form.update')
                        : this.props.t('form.create')}
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Collapse>
        </Card>
        <Card small className="mt-2">
          <CardHeader
            onClick={() =>
              this.setState({
                loungeConfigCollapse: !this.state
                  .loungeConfigCollapse,
              })
            }
            className="cursor-pointer border-bottom"
          >
            <h6 className="float-left m-0">
              {this.props.t('marketForm.loungeConfigCollapse')}
            </h6>
            {!this.state.loungeConfigCollapse && (
              <i className="material-icons float-right">
                expand_more
              </i>
            )}
            {this.state.loungeConfigCollapse && (
              <i className="material-icons float-right">
                expand_less
              </i>
            )}
          </CardHeader>
          <Collapse open={this.state.loungeConfigCollapse}>
            <CardBody>
              <form onSubmit={(e)=>this.handleSubmitOrderConfigMarket(e, 'loungeConfig')}>
                <Row>
                  <Col md="4" className="form-group">
                    <label htmlFor="lounge-minimumOrder">
                      {this.props.t('marketForm.minimumOrder')}
                    </label>
                    <FormInput
                      id="lounge-minimumOrder"
                      onChange={(e)=>this.handleChangeCommonConfig(e, 'loungeConfig')}
                      required={true}
                      name="minimumOrder"
                      value={this.state.market.loungeConfig.commonConfig.minimumOrder}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="lounge-orderTime">
                      {this.props.t('marketForm.orderTime')}
                    </label>
                    <FormInput
                      id="lounge-orderTime"
                      onChange={(e)=>this.handleChangeCommonConfig(e, 'loungeConfig')}
                      required={true}
                      name="orderTime"
                      value={this.state.market.loungeConfig.commonConfig.orderTime}
                    />
                  </Col>
                  <Col md="4" className="pt-4">
                    <FormCheckbox
                      name="sendEmailClosed"
                      checked={this.state.market.loungeConfig.sendEmailClosed}
                      onChange={(e)=>this.handleChangeLoungeConfigCheckbox(e)}
                    >
                      {this.props.t('marketForm.sendEmailClosed')}
                    </FormCheckbox>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormCheckbox
                      name="askName"
                      checked={this.state.market.loungeConfig.askName}
                      onChange={(e)=>this.handleChangeLoungeConfigCheckbox(e)}
                    >
                      {this.props.t('marketForm.askName')}
                    </FormCheckbox>
                  </Col>
                  <Col md="4">
                    <FormCheckbox
                      name="askPhone"
                      checked={this.state.market.loungeConfig.askPhone}
                      onChange={(e)=>this.handleChangeLoungeConfigCheckbox(e)}
                    >
                      {this.props.t('marketForm.askPhone')}
                    </FormCheckbox>
                  </Col>
                  <Col md="4">
                    <FormCheckbox
                      name="askEmail"
                      checked={this.state.market.loungeConfig.askEmail}
                      onChange={(e)=>this.handleChangeLoungeConfigCheckbox(e)}
                    >
                      {this.props.t('marketForm.askEmail')}
                    </FormCheckbox>
                  </Col>
                </Row>
                { this.renderOrderHours('loungeConfig') }
                <Row>
                  <Col>
                    <Button className="mb-3" intent='primary' onClick={()=>this.addHoursConfig('loungeConfig')}>
                      {this.props.t('marketForm.addOrderHours')}
                    </Button>
                  </Col>
                </Row>
                { this.props.location.state.entity && (
                <Row>
                  <Col md="12">
                    <Button
                      pill
                      theme="info"
                      className="mb-2"
                      onClick={() => this.toggleModal(false, {})}
                    >
                      <i className="material-icons mr-1">add</i>
                      {this.props.t('create.table')}
                    </Button>
                  </Col>
                  <Col md="12">
                    <table className="table mb-2">
                      <thead className="bg-light">
                        <tr className="table-hover">
                          <th>{this.props.t('tableForm.code')}</th>
                          <th>{this.props.t('tableForm.name')}</th>
                          <th>{this.props.t('tableForm.zone')}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.market.tables.map((table, index) => (
                          <tr key={index} className="table-hover">
                            <td>
                              {table.code}
                            </td>
                            <td>
                              {table.name}
                            </td>
                            <td>
                              {table.zone}
                            </td>
                            <td>
                              <Button
                                className="btn mr-2"
                                onClick={() => this.toggleModal(true, table)}
                              >
                                <i className="material-icons mr-1">edit</i>
                                {this.props.t('table.edit')}
                              </Button>
                              <Button
                                className="btn mr-2"
                                onClick={() => this.handleDownload(table)}
                                theme='info'
                              >
                                <i className="material-icons mr-1">download</i>
                                {this.props.t('table.qr')}
                              </Button>
                              <Button
                                  className="btn btn-danger"
                                >
                                  <i className="material-icons mr-1">delete</i>
                                  {this.props.t('table.delete')}
                                </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>)}
                <Row>
                  <Col>
                    <Button type="submit">
                      {this.state.market.id
                        ? this.props.t('form.update')
                        : this.props.t('form.create')}
                    </Button>
                  </Col>
                </Row>
              </form>

            </CardBody>
          </Collapse>
        </Card>
        { this.props.location.state.entity && (
          <TableModalForm
            refetchFunction={this.loadMarket}
            t={this.props.t}
            isEdit={this.state.isTableEdit}
            tableToEdit={this.state.toEditTable}
            modalToggled={this.state.modalToggled}
            toggleModal={this.toggleModal}
            marketId={this.props.location.state.entity.id}
          />)
        }
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isStaff: state.user.isStaff,
  isRestaurant: state.user.isRestaurant,
  username: state.user.username,
});

const bindActions = (dispatch) => ({});

export default connect(
  mapStateToProps,
  bindActions
)(withTranslation()(MarketForm));