import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  MEDIA_FILES_APPEND,
  ADMIN,
  EDITOR,
  READ_ONLY
} from '../config';
import { client } from '../apolloClient';
import {
  Container,
  Form,
  FormCheckbox,
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  FormInput,
  FormSelect,
} from 'shards-react';
import { Link, Redirect } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { queries } from '../graphQueries';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import { Multiselect } from 'react-widgets';

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        id: '',
        username: '',
        avatar: [],
        isRestaurant: true,
        isStaff: false,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        dashboardAvailable: false,
        userPlan: '',
        hostelTactil: { active: false },
        agoraActive: false,
        permission: '',
        company: {
          userPlan: '',
          name: '',
          id: '-1',
        },
        restaurants:[]
      },
      isEdit: false,
      loading: false,
      redirect: false,
      notEqualsPasswords: false,
      gmapsSearched: false,
    };
  }

  componentDidMount() {
    try {
      if (this.props.location.state.entity) {
        this.setState({ loading: true });
        const variables = { id: this.props.location.state.entity.id };
        const query = queries.user;
        client
          .query({ variables, query, fetchPolicy: 'network-only' })
          .then((res) => {
            this.setState((prevState) => ({
              user: {
                ...prevState.user,
                ...res.data.user,
                company: {
                  id: res.data.user.company ? res.data.user.company.id: '-1',
                  name: res.data.user.company ? res.data.user.company.name : '',
                  userPlan: res.data.user.company ? res.data.user.company.userPlan : '',
                }
              },
              isEdit: true,
              loading: false,
            }));
            //this.handleCompanyChange(this.state.user.company);
          });
      }
    } catch (err) {}
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.user.password !== this.state.user.repeatPassword) {
      this.setState({ notEqualsPasswords: true });
      return;
    }

    this.setState({ loading: true });

    const mutation = this.state.isEdit
      ? queries.updateUser
      : queries.createUser;

    const variables = {
      username: this.state.user.username,
      avatar:
        typeof this.state.user.avatar === 'object'
          ? this.state.user.avatar[0]
          : this.state.user.avatar,
      isRestaurant: this.state.user.isRestaurant,
      isStaff: this.state.user.isStaff,
      firstName: this.state.user.firstName,
      lastName: this.state.user.lastName,
      email: this.state.user.email,
      permission: this.state.user.permission,
      company: this.state.user.company && this.state.user.company.id != -1 ? this.state.user.company.id : null,
      restaurants: this.state.user.restaurants.reduce((previousValue, currentValue) => {
        return previousValue.concat(currentValue.id);
      }, []),
      dashboardAvailable: this.state.user.dashboardAvailable,
      userPlan: this.state.user.userPlan,
      hostelTactilInput: {
        active: this.state.user.hostelTactil.active,
      },
      agoraActive: this.state.user.agoraActive,
    };

    if (this.state.user.password) {
      variables.password = this.state.user.password;
    }

    if (this.state.isEdit) {
      variables.id = this.state.user.id;
    }

    client
      .mutate({
        variables,
        mutation,
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.setState({ loading: false, redirect: true });
      });
  };

  onDropAvatar = (avatar) => {
    this.setState((prevState) => ({ user: { ...prevState.user, avatar } }));
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));
  };

  handleChangeHostelTactil = (event) => {
    let { name, value } = event.target;
    name = name.split('_')[1];

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        hostelTactil: {
          ...prevState.user.hostelTactil,
          [name]: value,
        },
      },
    }));
  };

  handleCompanyChange(val) {
    if (!val)
      val= {
        userPlan: '',
        name: '',
        id: '-1',
      };
    console.log(val);
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        company: {
          ...prevState.user.company,
          id: val.id,
          name: val.name,
          userPlan: val.userPlan,
        },
      },
    }));
  }

  multiOnChange = (event, type) => {
    if (type === 'RestaurantType') {
      this.setState((prevState) => ({
        user: { ...prevState.user, restaurants: event },
      }));
    }
  };

  render() {
    if (!this.state.loading) {
      if (this.state.redirect) {
        return <Redirect to="/users" />;
      }
    }
    return (
      <Container className="pt-4">
        <Card className="pb-3">
          <CardHeader className="border-bottom">
            <Row>
              <Col md="10">
                <h3 className="float-left title-ellipsis">
                  {this.state.isEdit
                    ? `${this.props.t('form.editing')} ${
                        this.state.user.username
                      }`
                    : this.props.t('form.newUser')}
                </h3>
              </Col>
              <Col md="2">
                <Button
                  tag={Link}
                  outline
                  theme="danger"
                  className="mt-1 float-right"
                  to="/users"
                >
                  {this.props.t('back')}
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <Form onSubmit={this.handleSubmit}>
            <Row className="m-2">
              <Col md="4">
                <label htmlFor="username">
                  {this.props.t('userForm.username')}
                  <span className="required-input"> *</span>
                </label>
                <FormInput
                  id="username"
                  name="username"
                  onChange={this.handleChange}
                  required={true}
                  value={this.state.user.username}
                />
              </Col>
              <Col md="4">
                <label htmlFor="password">
                  {this.props.t('userForm.password')}
                  {!this.state.isEdit && (
                    <span className="required-input"> *</span>
                  )}
                </label>
                <FormInput
                  type="password"
                  id="password"
                  name="password"
                  onChange={this.handleChange}
                  required={!this.state.isEdit ? true : false}
                  value={this.state.user.password}
                />
              </Col>
              <Col md="4">
                <label htmlFor="repeatPassword">
                  {this.props.t('userForm.repeatPassword')}
                  {!this.state.isEdit && (
                    <span className="required-input"> *</span>
                  )}
                </label>
                <FormInput
                  type="password"
                  id="repeatPassword"
                  name="repeatPassword"
                  onChange={this.handleChange}
                  required={!this.state.isEdit ? true : false}
                  value={this.state.user.repeatPassword}
                />
              </Col>
            </Row>
            <Row className="m-2">
              <Col md="4">
                <label htmlFor="firstName">
                  {this.props.t('userForm.firstName')}
                </label>
                <FormInput
                  id="firstName"
                  name="firstName"
                  onChange={this.handleChange}
                  value={this.state.user.firstName}
                />
              </Col>
              <Col md="4">
                <label htmlFor="lastName">
                  {this.props.t('userForm.lastName')}
                </label>
                <FormInput
                  id="lastName"
                  name="lastName"
                  onChange={this.handleChange}
                  value={this.state.user.lastName}
                />
              </Col>
              <Col md="4">
                <label htmlFor="email">
                  {this.props.t('userForm.email')}
                  <span className="required-input"> *</span>
                </label>
                <FormInput
                  required={true}
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.user.email}
                />
              </Col>
            </Row>
            <Row className="m-2">
              <Col md="6">
                <label htmlFor="company">
                  {this.props.t('restaurantForm.company')}
                  {this.state.user.isRestaurant && <span className="required-input"> *</span>}
                </label>
                {this.props.isStaff && (
                  <Query query={queries.companiesAll}>
                    {({ loading, error, data }) => {
                      if (loading) return 'Loading...';
                      if (error) return `Error ${error.message}`;
                      return (
                        <>
                          <Select
                            id="company"
                            name="company"
                            styles={this.selectStyles}
                            small={true}
                            defaultValue={
                              this.state.isEdit && this.state.user.company && this.state.user.company.id !== -1
                                ? {
                                    name: this.state.user.company.name,
                                    id: this.state.user.company.id,
                                  }
                                : {
                                    name: this.props.t('form.selectOne'),
                                    id: -1,
                                  }
                            }
                            value={{
                              name: this.state.user.company.name,
                              id: this.state.user.company.id
                            }}
                            onChange={(e) => this.handleCompanyChange(e)}
                            getOptionLabel={(option) =>
                              `${option.name}`
                            }
                            getOptionValue={(option) => `${option.id}`}
                            options={data.companiesAll}
                          />
                            <input
                              tabIndex={-1}
                              autoComplete="off"
                              style={{ opacity: 0, height: 0 }}
                              value={this.state.user.company.id == -1 ? '' : 'filled'}
                              required={this.state.user.isRestaurant}
                            />
                        </>
                      );
                    }}
                  </Query>
                )}
              </Col>
              <Col md="6">
                <label htmlFor="permission">
                  {this.props.t('userForm.rol')}
                  {this.state.user.isRestaurant && (<span className="required-input"> *</span>)}
                </label>
                <FormSelect
                  onChange={this.handleChange}
                  id="permission"
                  name="permission"
                  value={this.state.user.permission}
                  required={this.state.user.isRestaurant}
                >
                  <option key={0} id={0} value="">
                    {this.props.t('userForm.plan')}
                  </option>
                  <option key={1} id={1} value={ADMIN}>
                    {this.props.t('userForm.admin')}
                  </option>
                  <option key={2} id={2} value={EDITOR}>
                    {this.props.t('userForm.editor')}
                  </option>
                  <option key={3} id={3} value={READ_ONLY}>
                    {this.props.t('userForm.readOnly')}
                  </option>
                </FormSelect>
              </Col>
            </Row>
            <Row className="m-2">
              <Col md="6" className="mt-3">
                <label>{this.props.t('userForm.avatar')}</label>
                <Dropzone
                  accept="image/jpeg, image/png"
                  multiple={false}
                  onDrop={this.onDropAvatar}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {((Array.isArray(this.state.user.avatar) &&
                          this.state.user.avatar.length === 0) ||
                          !this.state.user.avatar) && (
                          <p className="dropzone-solid-blue">
                            {this.props.t('uploadText')}
                          </p>
                        )}
                        {typeof this.state.user.avatar === 'object' && (
                          <img
                            style={{ width: '50px' }}
                            src={window.URL.createObjectURL(
                              new File(this.state.user.avatar, {
                                type: 'image/png',
                              })
                            )}
                            alt=""
                          />
                        )}
                        {typeof this.state.user.avatar === 'string' && (
                          <img
                            style={{ width: '50px' }}
                            src={MEDIA_FILES_APPEND + this.state.user.avatar}
                            alt=""
                          />
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Col>
              <Col md="3" style={{ marginTop: 33 }}>
                <FormCheckbox
                  checked={this.state.user.isRestaurant}
                  onChange={() =>
                    this.setState((prevState) => ({
                      user: {
                        ...prevState.user,
                        isRestaurant: !this.state.user.isRestaurant,
                      },
                    }))
                  }
                >
                  {this.props.t('userForm.isRestaurant')}
                </FormCheckbox>
              </Col>
              <Col md="3" style={{ marginTop: 33 }}>
                <FormCheckbox
                  checked={this.state.user.isStaff}
                  onChange={() =>
                    this.setState((prevState) => ({
                      user: {
                        ...prevState.user,
                        isStaff: !this.state.user.isStaff,
                      },
                    }))
                  }
                >
                  <i className="material-icons mr-2" style={{ color: 'red' }}>
                    report_problem
                  </i>
                  {this.props.t('userForm.isStaff')}
                </FormCheckbox>
              </Col>
            </Row>
            { this.state.user.company && this.state.user.company.id !== '-1' && (
              <Row className="m-2 mt-2">
                <Col md="12">
                  <label htmlFor="restaurants">
                    {this.props.t('userForm.restaurants')}
                  </label>
                  <Query query={queries.restaurantByCompany} variables={{id: this.state.user.company.id}}>
                    {({ loading, error, data }) => {
                      if (loading) return 'Loading...';
                      if (error) return `Error ${error.message}`;
                      return (
                        <Multiselect
                          data={data.companyRestaurants.filter(
                            (service) =>
                              this.state.user.restaurants
                                .map((s) => s.id)
                                .indexOf(service.id) < 0
                          )}
                          value={this.state.user.restaurants}
                          onChange={(e) =>
                            this.multiOnChange(e, 'RestaurantType')
                          }
                          textField="name"
                          valueField="id"
                        />
                      );
                    }}
                  </Query>
                </Col>
              </Row>
            )}
            <Row className="m-2 mt-2">
              <Col md="3">
                <Button type="submit">
                  {this.state.isEdit
                    ? this.props.t('form.update')
                    : this.props.t('form.create')}
                </Button>
              </Col>
              <Col md="9">
                {this.state.notEqualsPasswords && (
                  <p className="error-required-icon">
                    {this.props.t('passwordsNotEquals')}
                  </p>
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isStaff: state.user.isStaff,
  isRestaurant: state.user.isRestaurant,
  username: state.user.username,
});

const bindActions = (dispatch) => ({});

export default connect(
  mapStateToProps,
  bindActions
)(withTranslation()(UserForm));
