import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  Row,
  FormInput,
  FormTextarea,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  FormCheckbox
} from "shards-react";
import Spinner from "react-spinner-material";
import { menuLanguagesFields } from "../config";
import { queries } from "../graphQueries";
import { client } from "../apolloClient";
import React from "react";
import { USER_FREE } from "../config";

import "../shards-dashboard/styles/styles.css";

class FullMenuModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: {
        id: "",
        name: "",
        description: "",
        active: true,
        activeOn: [],
        sala: true,
        delivery: false,
        takeaway: false,
      },
      autoTranslate: this.props.userPlan !== USER_FREE,
      collapse: false,
      requiredFieldsError: false,
      isEdit: false,
      loading: false
    };

    this.t = this.props.t;
    this.baseState = this.state;
  }

  componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.isEdit) {
        this.setState({
          menu: { ...nextProps.fullMenuToEdit },
          isEdit: true,
          autoTranslate: false
        });
        if (nextProps.fullMenuToEdit) {

          nextProps.fullMenuToEdit.activeOn.forEach((value) => {
            if (value === 'SA') {
              this.setState( prev => ({
                menu: {
                  ...prev.menu,
                  sala: true
                }
              }))
            } else if (value === 'DE') {
              this.setState( prev => ({
                menu: {
                  ...prev.menu,
                  delivery: true
                }
              }))
            } else if (value === 'TA') {
              this.setState( prev => ({
                menu: {
                  ...prev.menu,
                  takeaway: true
                }
              }))
            }
          });
        }
      } else {
        this.setState({
          ...this.baseState,
          autoTranslate: this.props.userPlan !== USER_FREE
        });
      }
    } catch (err) {}
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      menu: {
        ...prevState.menu,
        [name]: value
      }
    }));
  };

  handleLanguageChange(e, prop) {
    const newValue = e.target.value;
    this.setState(prevState => ({
      menu: { ...prevState.menu, [prop]: newValue }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    const mutation = this.state.isEdit
      ? queries.updateFullMenu
      : queries.createFullMenu;

    // process activeOn
    const activeOn = [];
    if(this.state.menu.sala) activeOn.push('SA');
    if(this.state.menu.delivery) activeOn.push('DE');
    if(this.state.menu.takeaway) activeOn.push('TA');

    const variables = {
      restaurantId: this.props.restaurantId,
      name: this.state.menu.name,
      description: this.state.menu.description,
      nameEn: this.state.menu.nameEn,
      nameFr: this.state.menu.nameFr,
      nameIt: this.state.menu.nameIt,
      namePt: this.state.menu.namePt,
      nameDe: this.state.menu.nameDe,
      nameCa: this.state.menu.nameCa,
      nameJa: this.state.menu.nameJa,
      nameKo: this.state.menu.nameKo,
      nameZhCn: this.state.menu.nameZhCn,
      nameRu: this.state.menu.nameRu,
      active: this.state.menu.active,
      activeOn: activeOn,
      autoTranslate: this.state.autoTranslate
    };
    if (this.state.isEdit) {
      variables.id = this.state.menu.id;
    }
    client
      .mutate({
        variables,
        mutation
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.props.refetchFunction();
        this.setState({ loading: false }, () => this.props.toggleModal());
      });
  };

  render() {
    return (
      <Modal
        centered={true}
        open={this.props.modalToggled}
        toggle={this.props.toggleModal}
      >
        <ModalHeader>{this.t("menuForm.newFullMenu")}</ModalHeader>
        <ModalBody className="pt-0 modal-menu">
          {!this.state.loading && (
            <Form onSubmit={this.handleSubmit}>
              <Row className="m-2">
                <Col md="12">
                  <label htmlFor="name">
                    {this.t("fullMenuForm.name")}
                    <span className="required-input"> *</span>
                  </label>
                  <FormInput
                    id="name"
                    onChange={this.handleChange}
                    required={true}
                    name="name"
                    value={this.state.menu.name}
                  />
                </Col>
                <Col md="6" style={{ marginTop: 33 }}>
                  <FormCheckbox
                    disabled={this.props.userPlan === USER_FREE}
                    checked={this.state.autoTranslate}
                    onChange={() =>
                      this.setState({
                        autoTranslate: !this.state.autoTranslate
                      })
                    }
                  >
                    {this.t("dishForm.autoTranslate")}
                  </FormCheckbox>
                </Col>
                <Col md="6" style={{ marginTop: 33 }}>
                  <FormCheckbox
                    checked={this.state.menu.active}
                    onChange={() =>
                      this.setState( prev => ({
                        menu: {
                          ...prev.menu,
                          active: !prev.menu.active
                        }
                      }))
                    }
                  >
                    {this.t("dishForm.active")}
                  </FormCheckbox>
                </Col>

                <Col md="4" style={{ marginTop: 33 }}>
                  <FormCheckbox
                    checked={this.state.menu.sala}
                    onChange={() =>
                      this.setState( prev => ({
                        menu: {
                          ...prev.menu,
                          sala: !prev.menu.sala
                        }
                      }))
                    }
                  >
                    {this.t("dishForm.sala")}
                  </FormCheckbox>
                </Col>
                <Col md="4" style={{ marginTop: 33 }}>
                  <FormCheckbox
                    checked={this.state.menu.delivery}
                    onChange={() =>
                      this.setState( prev => ({
                        menu: {
                          ...prev.menu,
                          delivery: !prev.menu.delivery
                        }
                      }))
                    }
                  >
                    {this.t("dishForm.delivery")}
                  </FormCheckbox>
                </Col>
                <Col md="4" style={{ marginTop: 33 }}>
                  <FormCheckbox
                    checked={this.state.menu.takeaway}
                    onChange={() =>
                      this.setState( prev => ({
                        menu: {
                          ...prev.menu,
                          takeaway: !prev.menu.takeaway
                        }
                      }))
                    }
                  >
                    {this.t("dishForm.takeaway")}
                  </FormCheckbox>
                </Col>
              </Row>
              <Row className="m-2">
                <Col>
                  <label htmlFor="description">
                    {this.t("restaurantForm.description")}
                  </label>
                  <FormTextarea
                    id="description"
                    onChange={this.handleChange}
                    value={this.state.menu.description}
                    name="description"
                  />
                </Col>
              </Row>
              <Col md="12">
                <Card small>
                  <CardHeader
                    onClick={() =>
                      this.setState({ collapse: !this.state.collapse })
                    }
                    className="cursor-pointer border-bottom"
                  >
                    <h6 className="float-left m-0">
                      {this.t("language.settings")}
                    </h6>
                    {!this.state.collapse && (
                      <i className="material-icons float-right">expand_more</i>
                    )}
                    {this.state.collapse && (
                      <i className="material-icons float-right">expand_less</i>
                    )}
                  </CardHeader>
                  <Collapse open={this.state.collapse}>
                    <CardBody>
                      <Row>
                        {menuLanguagesFields.map((language, index) => {
                          const prop = `${language.field}${language.language}`; // name_en, description_cat
                          const snakeCaseLang = language.language
                            .split(/(?=[A-Z])/)
                            .join("-")
                            .toLowerCase();

                          return (
                            <Col key={index} md="6" className="mt-1">
                              <label htmlFor={prop}>
                                {this.t(`form.${prop}`)}
                              </label>
                              <FormInput
                                name={prop}
                                id={prop}
                                disabled={
                                  this.props.userPlan === "FRE" &&
                                  !this.props.avaibleLanguages.includes(
                                    snakeCaseLang
                                  )
                                }
                                onChange={e =>
                                  this.handleLanguageChange(e, prop)
                                }
                                value={this.state.menu[prop]}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>

              <div className="mt-3">
                <Button type="submit" className="float-left">
                  {this.state.isEdit
                    ? this.t("form.update")
                    : this.t("form.create")}
                </Button>
                <Button
                  outline
                  pill
                  theme="info"
                  className="float-right"
                  onClick={() => this.props.toggleModal()}
                >
                  <i className="material-icons mr-1">cancel</i>
                  {this.t("cancel")}
                </Button>
              </div>
            </Form>
          )}
          {this.state.loading && (
            <div className="spinner-loader-dnd">
              <Spinner
                size={80}
                spinnerColor={"#007BFF"}
                spinnerWidth={2}
                visible={true}
              />
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}
export default FullMenuModalForm;
