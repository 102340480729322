import React from "react";
import { Link } from "react-router-dom";
import { client } from '../../apolloClient';
import { withTranslation } from "react-i18next";
import { queries } from '../../graphQueries'
import { Query } from 'react-apollo';
import {
  CardHeader,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormSelect
} from "shards-react";
import { TIMEZONES } from '../../config';

import "../../shards-dashboard/styles/login.css"

class StateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: {
        name: '',
        country: {
          name: '',
          code: '',
          codeTwo: '',
          id: ''
        },
        timezone: '',
        id: '',
      },
      countryRequired: false,
      loading: false,
      isEdit: false,
    }
    this.timezones = [];
    this.t = this.props.t;
  }

  componentWillMount() {
    try {
      if (this.props.location.state && this.props.location.state.entity) {
        this.setState({ state: { ...this.props.location.state.entity }, isEdit: true })
        const codeTwo = this.props.location.state.entity.country.codeTwo ?
         this.props.location.state.entity.country.codeTwo
         : this.props.location.state.entity.country.code.slice(0,2)
        console.log(codeTwo);
        this.timezones = TIMEZONES[codeTwo]
      } else {
        this.timezones = Object.entries(TIMEZONES).reduce((acc, value, index) => {
          return acc.concat(value[1]);
        }, []);
      }
    } catch (err) {
      console.log(err);
     }
  }

  handleChange = event => {
    const { name, value } = event.target;
    if (name !== 'country') {
      this.setState(prevState => ({
        state: {
          ...prevState.state,
          [name]: value
        }
      }));
    } else {
      const id = event.target[event.target.selectedIndex].id;
      this.setState(prevState => ({
        state: {
          ...prevState.state,
          country: {
            name: value,
            id
          }
        }
      }));
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const mutation = this.state.isEdit ? queries.updateState : queries.createState;
    let noCountry = false;
    if (!this.state.state.country) {
      noCountry = true;
    }
    const variables = { name: this.state.state.name, country: noCountry ? -1 : this.state.state.country.id, timezone: this.state.state.timezone };
    if (!variables.country || variables.country === -1) {
      this.setState({ countryRequired: true })
    } else {
      if (this.state.isEdit) {
        variables.id = parseInt(this.state.state.id);
      }
      client.mutate({
        variables,
        mutation
      }).then((res, err) => {
        this.props.history.push("/states")
      })
    }
  }

  render() {
    return (
      <Container className="pt-4">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">{this.state.isEdit ? `${this.t('form.editing')} ${this.state.state.name}` : this.t('form.newState')}</h6>
            <Button outline pill theme="danger" className="float-right" tag={Link} to="/states">Back</Button>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={this.handleSubmit}>
                    <Row form>
                      <Col md="4">
                        <label htmlFor="name">{this.t('table.name')}</label>
                        <FormInput
                          id="name"
                          name="name"
                          value={this.state.state.name}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Col>
                      <Col md="6">
                        <label htmlFor="country">{this.t('table.country')}</label>
                        <Query query={queries.countries}>
                          {({ loading, error, data, refetch }) => {
                            if (loading) return "Loading...";
                            if (error) return `Error ${error.message}`
                            return (
                              <FormSelect onChange={this.handleChange} id="country" name="country" defaultValue={this.state.isEdit && this.state.state.country ? this.state.state.country.name : 0}>
                                <option key={-1} id={-1}>{this.t('form.selectOne')}</option>
                                {data.countries.map((country, index) => <option key={index} id={country.id}>{country.name}</option>)}
                              </FormSelect>
                            )
                          }}
                        </Query>
                        {this.state.countryRequired && <p className="error-required-icon">{this.t('countryRequired')}</p>}
                      </Col>
                      <Col md="10">
                        <label htmlFor="timezone">{this.t('table.timezone')}</label>
                        <FormSelect onChange={this.handleChange} id="timezone" name="timezone" defaultValue={this.state.isEdit && this.state.state.timezone ? this.state.state.timezone : 0}>
                          <option key={-1} id={-1}>{this.t('form.selectOne')}</option>
                          {this.timezones.map((timezone, index) => <option key={index} value={timezone}>{timezone}</option>)}
                        </FormSelect>
  
                      </Col>
                      <Col md="12">
                        <Button type="submit" className="float-left mt-3">{this.state.isEdit ? this.t('form.update') : this.t('form.create')}</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    )
  }
}

export default (withTranslation()(StateForm))