import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import AuthRequired from "./authRequired";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

export default () => (
  <Router basename={""}>
    <div>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={(props) => {
              return <AuthRequired
                render={
                  <route.layout {...props}>
                    <route.component {...props} {...route.props} />
                  </route.layout>
                } />
            }
            }
          />
        );
      })}
    </div>
  </Router>
);
