import React, { useRef, useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "shards-react";
import { client } from "../apolloClient";
import { queries } from "../graphQueries";
import Spinner from "react-spinner-material";

const MODAL_ERROR = 0;
const MODAL_CONFIRMATION = 1;

const UploadCsvDish = ({
  fullMenu,
  accept,
  disabled,
  hasConfirm,
  t,
  onConfirm
}) => {
  const fileUploader = useRef();
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [toggleModal, setToggleModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalBody, setModalBody] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  const handleClick = () => {
    if (!isDisabled) {
      fileUploader.current.click();
    }
  };

  const handleOnChange = evt => {
    evt.preventDefault();
    setIsDisabled(true);

    let file = evt.target.files[0];
    let type = MODAL_CONFIRMATION;
    let content = t("upload_csv.body.confirm", { file: file.name });

    if (accept !== "*" && file.type !== accept) {
      type = MODAL_ERROR;
      content = t("upload_csv.body.error_format", {
        expected_file: accept,
        selected_file: file.type
      });
      file = null;
    }

    setSelectedFile(file);
    setModalType(type);
    setModalBody(content);
    if (type === MODAL_ERROR || (type === MODAL_CONFIRMATION && hasConfirm)) {
      setToggleModal(true);
    }
  };

  const handleCancel = () => {
    setToggleModal(false);
    setModalType(null);
    setModalBody(null);
    setIsDisabled(false);
    setSelectedFile(null);
    setLoading(false)
    fileUploader.current.value = "";
  };

  const handleOnConfirm = () => {
    handleCancel();
    setLoading(true)
    client
      .mutate({
        variables: {
          id: fullMenu,
          file: selectedFile
        },
        mutation: queries.importMenu
      })
      .then(res => {
        onConfirm(res);
        handleCancel();
      })
      .catch(error => {
        console.error(error);
        setModalType(MODAL_ERROR);
        setModalBody(
          t("upload_csv.body.error_unespected", { file: selectedFile.name })
        );
        setToggleModal(true);
      });
  };

  return (
    <>
      {loading
        ? (
          <div className="menu-options float-right">
            <Spinner
              radius={22}
              color={"#007BFF"}
              visible={true}
            />
          </div>
        )
        : (
          <button
              className="menu-options float-right"
              onClick={handleClick}
              disabled={isDisabled} >
            <i className="material-icons">publish</i>
            <input
              style={{ display: "none" }}
              accept={accept}
              type="file"
              ref={fileUploader}
              onChange={handleOnChange} />
          </button>
        )}
      <Modal size="sm" centered={true} open={toggleModal}>
        <ModalHeader>
          {t(
            `upload_csv.title.${
              modalType === MODAL_ERROR ? "error" : "success"
            }`
          )}
        </ModalHeader>
        <ModalBody>
          <p
            style={{
              whiteSpace: "pre-line"
            }}
          >
            {modalBody}
          </p>
          {modalType !== MODAL_ERROR && (
            <Button
              theme="success"
              className="float-left"
              onClick={() => handleOnConfirm()}
            >
              <i className="material-icons mr-1">send</i>
              {t("confirm")}
            </Button>
          )}
          <Button
            outline
            theme="info"
            className="float-right"
            onClick={() => handleCancel()}
          >
            {modalType !== MODAL_ERROR && (
              <i className="material-icons mr-1">cancel</i>
            )}
            {t(`action.${modalType !== MODAL_ERROR ? "cancel" : "accept"}`)}
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

UploadCsvDish.defaultProps = {
  accept: "text/csv",
  disabled: false,
  hasConfirm: false,
  t: value => value,
  onConfirm: () => null
};

export default UploadCsvDish;
