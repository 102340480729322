import React from "react";
import { Link } from "react-router-dom";
import { client } from '../../apolloClient';
import { withTranslation } from "react-i18next";
import { queries } from '../../graphQueries'
import { Query } from 'react-apollo';
import {
  CardHeader,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormSelect
} from "shards-react";

import "../../shards-dashboard/styles/login.css"

class CityForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      city: {
        name: '',
        state: {
          name: '',
          id: ''
        },
        id: '',
      },
      stateRequired: false,
      loading: false,
      isEdit: false,
    }

    this.t = this.props.t;
  }

  componentWillMount() {
    try {
      if (this.props.location.state.entity) {
        this.setState({ city: { ...this.props.location.state.entity }, isEdit: true })
      }
    } catch (err) { }
  }

  handleChange = event => {
    const { name, value } = event.target;
    if (name !== 'state') {
      this.setState(prevState => ({
        city: {
          ...prevState.city,
          [name]: value
        }
      }));
    } else {
      const id = event.target[event.target.selectedIndex].id;
      this.setState(prevState => ({
        city: {
          ...prevState.city,
          state: {
            name: value,
            id
          }
        }
      }));
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const mutation = this.state.isEdit ? queries.updateCity : queries.createCity;
    let noState = false;
    if (!this.state.city.state) {
      noState = true;
    }
    const variables = { name: this.state.city.name, state: noState ? -1 : this.state.city.state.id };
    if (!variables.state || variables.state === -1) {
      this.setState({ stateRequired: true })
    } else {
      if (this.state.isEdit) {
        variables.id = parseInt(this.state.city.id);
      }
      client.mutate({
        variables,
        mutation
      }).then((res, err) => {
        this.props.history.push("/cities")
      })
    }
  }

  render() {
    return (
      <Container className="pt-4">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="float-left m-0">{this.state.isEdit ? `${this.t('form.editing')} ${this.state.city.name}` : this.t('form.newCity')}</h6>
            <Button outline pill theme="danger" className="float-right" tag={Link} to="/cities">Back</Button>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={this.handleSubmit}>
                    <Row form>
                      <Col md="4">
                        <label htmlFor="name">{this.t('table.name')}</label>
                        <FormInput
                          id="name"
                          name="name"
                          value={this.state.city.name}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Col>
                      <Col md="6">
                        <label htmlFor="state">{this.t('table.state')}</label>
                        <Query query={queries.states}>
                          {({ loading, error, data, refetch }) => {
                            if (loading) return "Loading...";
                            if (error) return `Error ${error.message}`
                            return (
                              <FormSelect onChange={this.handleChange} id="state" name="state" defaultValue={this.state.isEdit && this.state.city.state ? this.state.city.state.name : 0}>
                                <option key={-1} id={-1}>{this.t('form.selectOne')}</option>
                                {data.states.map((state, index) => <option key={index} id={state.id}>{state.name}</option>)}
                              </FormSelect>
                            )
                          }}
                        </Query>
                        {this.state.stateRequired && <p className="error-required-icon">{this.t('stateRequired')}</p>}
                      </Col>
                      <Col md="2">
                        <Button type="submit">{this.state.isEdit ? this.t('form.update') : this.t('form.create')}</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    )
  }
}

export default (withTranslation()(CityForm))