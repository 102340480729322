// Layout Types
import { DefaultLayout, BlankLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import LoginForm from "./views/forms/LoginForm";
import LifestyleForm from "./views/forms/LifestyleForm";
import AllergenForm from "./views/forms/AllergenForm";
import CountryForm from "./views/forms/CountryForm";
import StateForm from "./views/forms/StateForm";
import CuisineForm from "./views/forms/CuisineForm";
import CityForm from "./views/forms/CityForm";
import ServiceForm from "./views/forms/ServiceForm";
import RestaurantForm from "./views/forms/RestaurantForm";
import TestComponent from "./views/TestComponent";
import DishForm from "./views/DishForm";
import Table from "./components/Table";
import PaginatedTable from "./components/PaginatedTable";
import UsersTable from "./components/UsersTable";
import OrdersTable from "./components/OrdersTable";
import UserForm from "./components/UserForm";
import DishList from "./components/DishList";
import NotApprovedDishes from "./components/NotApprovedDishes";
import FullMenuView from "./views/FullMenuView";
import HomeRedirector from "./components/HomeRedirector";
import RestaurantDashboard from "./views/RestaurantDashboard";
import Dashboard from "./views/Dashboard";
import CurrencyForm from "./views/forms/CurrencyForm";
import DailyMenuForm from "./views/DailyMenuForm";
import MarketForm from "./views/forms/MarketForm";
import FranchiseForm from "./views/forms/FranchiseForm";
import CompanyForm from "./views/forms/CompanyForm";

export default [
  {
    path: "/",
    exact: true,
    layout: BlankLayout,
    component: HomeRedirector
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/test",
    exact: true,
    layout: BlankLayout,
    component: TestComponent
  },
  {
    path: "/login",
    exact: true,
    layout: BlankLayout,
    component: LoginForm
  },
  {
    path: "/countries",
    layout: DefaultLayout,
    component: Table,
    props: { title: "countryTable.title", tableTitle: "countryTable.tableTitle", tableType: "countries", entity: "country" }
  },
  {
    path: "/country",
    layout: DefaultLayout,
    component: CountryForm
  },
  {
    path: "/cities",
    layout: DefaultLayout,
    component: Table,
    props: { title: "cityTable.title", tableTitle: "cityTable.tableTitle", tableType: "cities", entity: "city" }
  },
  {
    path: "/currencies",
    layout: DefaultLayout,
    component: Table,
    props: { title: "currencyTable.title", tableTitle: "currencyTable.tableTitle", tableType: "currencies", entity: "currency" }
  },
  {
    path: "/currency",
    layout: DefaultLayout,
    component: CurrencyForm
  },
  {
    path: "/city",
    layout: DefaultLayout,
    component: CityForm
  },
  {
    path: "/states",
    layout: DefaultLayout,
    component: Table,
    props: { title: "stateTable.title", tableTitle: "stateTable.tableTitle", tableType: "states", entity: "state" }
  },
  {
    path: "/state",
    layout: DefaultLayout,
    component: StateForm
  },
  {
    path: "/lifestyles",
    layout: DefaultLayout,
    component: Table,
    props: { title: "lifestyleTable.title", tableTitle: "lifestyleTable.tableTitle", tableType: "lifestyles", entity: "lifestyle" }
  },
  {
    path: "/lifestyle",
    layout: DefaultLayout,
    component: LifestyleForm
  },
  {
    path: "/allergens",
    layout: DefaultLayout,
    component: Table,
    props: { title: "allergenTable.title", tableTitle: "allergenTable.tableTitle", tableType: "allergens", entity: "allergen" }
  },
  {
    path: "/allergen",
    layout: DefaultLayout,
    component: AllergenForm,
  },
  {
    path: "/services",
    layout: DefaultLayout,
    component: Table,
    props: { title: "serviceTable.title", tableTitle: "serviceTable.tableTitle", tableType: "services", entity: "service" }
  },
  {
    path: "/service",
    layout: DefaultLayout,
    component: ServiceForm,
  },
  {
    path: "/cuisines",
    layout: DefaultLayout,
    component: Table,
    props: { title: "cuisineTable.title", tableTitle: "cuisineTable.tableTitle", tableType: "cuisines", entity: "cuisine" }
  },
  {
    path: "/cuisine",
    layout: DefaultLayout,
    component: CuisineForm,
  },
  {
    path: "/restaurants",
    layout: DefaultLayout,
    component: PaginatedTable,
    props: { title: "restaurantTable.title", tableTitle: "restaurantTable.tableTitle", tableType: "restaurants", entity: "restaurant" }
  },
  {
    path: "/restaurant",
    layout: DefaultLayout,
    component: RestaurantForm,
  },
  {
    path: "/dishes",
    layout: DefaultLayout,
    component: DishList,
    props: { title: "dishesTable.title", tableTitle: "dishTable.tableTitle", tableType: "dishes", entity: "dish" }
  },
  {
    path: "/dish",
    layout: DefaultLayout,
    component: DishForm
  },
  {
    path: "/myDailyMenu",
    layout: DefaultLayout,
    component: DailyMenuForm
  },
  {
    path: "/myRestaurant",
    layout: DefaultLayout,
    component: RestaurantForm,
    props: { isRestaurant: true }
  },
  {
    path: "/myDashboard",
    layout: DefaultLayout,
    component: RestaurantDashboard,
    props: { isRestaurant: true, dashboardAvailable: true }
  },
  {
    path: "/users",
    layout: DefaultLayout,
    component: UsersTable,
    props: { title: "usersTable.title", tableTitle: "usersTable.tableTitle", tableType: "users", entity: "user" }
  },
  {
    path: "/user",
    layout: DefaultLayout,
    component: UserForm,
  },
  {
    path: "/myMenu",
    layout: DefaultLayout,
    component: FullMenuView,
    props: { title: "fullMenu.title", tableTitle: "fullMenu.tableTitle", tableType: "dishes", entity: "dish" }
  },
  {
    path: "/NADishes",
    layout: DefaultLayout,
    component: NotApprovedDishes
  },
  {
    path: "/unapprovedComments",
    layout: DefaultLayout,
    component: Table,
    props: { title: "notApprovedComments.title", tableTitle: "notApprovedComments.tableTitle", tableType: "notApprovedComments", entity: "comment" }
  },
  {
    path: "/orders",
    layout: DefaultLayout,
    component: OrdersTable,
    props: { title: "ordersTable.title", tableTitle: "ordersTable.tableTitle", tableType: "orders", entity: "order" }
  },
  {
    path: "/markets",
    layout: DefaultLayout,
    component: PaginatedTable,
    props: { title: "marketTable.title", tableTitle: "marketTable.tableTitle", tableType: "markets", entity: "market" }
  },
  {
    path: "/market",
    layout: DefaultLayout,
    component: MarketForm,
  },
  {
    path: "/franchises",
    layout: DefaultLayout,
    component: Table,
    props: { title: "franchiseTable.title", tableTitle: "franchiseTable.tableTitle", tableType: "franchises", entity: "franchise" }
  },
  {
    path: "/franchise",
    layout: DefaultLayout,
    component: FranchiseForm,
  },
  {
    path: "/companies",
    layout: DefaultLayout,
    component: PaginatedTable,
    props: { title: "companyTable.title", tableTitle: "companyTable.tableTitle", tableType: "companies", entity: "company" }
  },
  {
    path: "/company",
    layout: DefaultLayout,
    component: CompanyForm,
  },
];
