import React from "react";
import { Link } from "react-router-dom";
import { client } from '../../apolloClient';
import { withTranslation } from "react-i18next";
import { queries } from '../../graphQueries'
import {
  CardHeader,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from "shards-react";

import "../../shards-dashboard/styles/login.css"

class LifestyleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lifestyle: {
        name: '',
        id: '',
      },
      loading: false,
      isEdit: false,
    }

    this.t = this.props.t;
  }

  componentWillMount() {
    try {
      if (this.props.location.state.entity) {
        this.setState({ lifestyle: { ...this.props.location.state.entity }, isEdit: true })
      }
    } catch (err) { }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      lifestyle: {
        ...prevState.lifestyle,
        [name]: value
      }
    }));
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const mutation = this.state.isEdit ? queries.updateLifestyle : queries.createLifestyle;
    const variables = { name: this.state.lifestyle.name };
    if (this.state.isEdit) {
      variables.id = parseInt(this.state.lifestyle.id);
    }
    client.mutate({
      variables,
      mutation
    }).then((res, err) => {
      this.props.history.push("/lifestyles")
    })
  }

  render() {
    return (
      <Container className="pt-4">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">{this.state.isEdit ? `${this.t('form.editing')} ${this.state.lifestyle.name}` : this.t('form.newLifestyle')}</h6>
            <Button outline pill theme="danger" className="float-right" tag={Link} to="/lifestyles">Back</Button>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={this.handleSubmit}>
                    <Row form>
                      <Col md="4">
                        <label htmlFor="name">{this.t('table.name')}</label>
                        <FormInput
                          id="name"
                          name="name"
                          value={this.state.lifestyle.name}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Col>
                      <Col md={{ size: 2, offset: 1 }}>
                        <Button type="submit" className="float-left mt-2">{this.state.isEdit ? this.t('form.update') : this.t('form.create')}</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    )
  }
}

export default (withTranslation()(LifestyleForm))