import React from 'react';
import Spinner from 'react-spinner-material';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
  CardImg,
  CardTitle,
  Button,
} from 'shards-react';
import { withTranslation } from 'react-i18next';
import headers from '../data/tables_headers/headers';
import { queries } from '../graphQueries';
import { MEDIA_FILES_APPEND } from '../config';
import { connect } from 'react-redux';
import { client } from '../apolloClient';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import UnsavedChangesModal from '../components/UnsavedChangesModal';

import '../shards-dashboard/styles/styles.css';

class FullMenuView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      toDeleteName: '',
      toDeleteIndex: '',
      toDeleteId: '',
      restaurantId: '',
      loading: true,
      items: [],
      canUpdate: false,
      restaurants: [],
      unsavedModalOpened: false,
      restaurantName: '',
    };
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleUnsavedModal = this.toggleUnsavedModal.bind(this);
  }

  componentDidMount() {
    const graphQuery = queries.getFullMenu;
    const variables = { username: this.props.username };

    client.query({ query: graphQuery, variables }).then((res) => {
      this.setState({
        loading: res.loading,
        items: res.data.getFullMenu,
        restaurantName: res.data.getFullMenu[0]
          ? res.data.getFullMenu[0].restaurant.name
          : '',
      });
    });
    client
      .query({
        query: queries.userRestaurants,
        variables: { username: this.props.username },
      })
      .then((res) => {
        this.setState({
          loading: res.loading,
          restaurants: res.data.userRestaurants,
        });
      });
    this.getItems();
  }

  getItems() {
    const graphQuery = queries.getFullMenu;
    const variables = { username: this.props.username };
    client.query({ query: graphQuery, variables }).then((res) => {
      this.setState({
        loading: res.loading,
        items: res.data.getFullMenu,
        restaurantName: res.data.getFullMenu[0]
          ? res.data.getFullMenu[0].restaurant.name
          : '',
      });
    });
  }

  handleRestaurantChange(e) {
    const restaurantId = e.target[e.target.selectedIndex].id;
    const graphQuery = queries.getFullMenu;
    const variables = { username: this.props.username, restaurantId };
    client.query({ query: graphQuery, variables }).then((res) => {
      this.setState({
        loading: res.loading,
        items: res.data.getFullMenu,
        restaurantName: res.data.getFullMenu[0]
          ? res.data.getFullMenu[0].restaurant.name
          : '',
      });
    });
  }

  toggleDeleteModal = (
    toDeleteIndex = '',
    toDeleteId = '',
    toDeleteName = ''
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  toggleUnsavedModal = () => {
    this.setState({ unsavedModalOpened: !this.state.unsavedModalOpened });
  };

  async deleteItem(index, id) {
    const mutation = queries[headers[this.props.tableType].deleteMutation];
    client
      .mutate({
        variables: { id },
        mutation,
      })
      .then((res, err) => {
        this.toggleDeleteModal();
        this.getItems();
      });
  }

  updateItems = () => {
    // TODO
    const variables = { ids: this.state.items.map((item) => item.id) };
    const mutation = queries.updateDishesOrder;
    client
      .mutate({ variables, mutation })
      .then((res) =>
        this.setState({ canUpdate: false }, () => this.getItems())
      );
  };

  setDefaultImage(event) {
    const { target } = event;
    target.src = process.env.PUBLIC_URL + '/images/placeholder-image.png';
  }

  render() {
    this.t = this.props.t;
    const capitalize = (s) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    return (
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col md="6">
            <h3>{this.t('dishesOfMenu') + ' ' + this.state.restaurantName}</h3>
          </Col>
          <Col md={{ size: 4, offset: -1 }}>
            <FormSelect
              className="mt-2"
              onChange={(e) => this.handleRestaurantChange(e)}
              defaultValue={this.state.restaurantName}
            >
              <option key={-1} id={-1}>
                {this.t('form.selectOneRestaurant')}
              </option>
              {this.state.restaurants.map((restaurant, index) => (
                <option key={index} id={restaurant.id}>
                  {restaurant.name}
                </option>
              ))}
            </FormSelect>
          </Col>
        </Row>
        {this.state.loading && (
          <div className="spinner-loader-listing">
            <Spinner
              size={80}
              spinnerColor={'#007BFF'}
              spinnerWidth={2}
              visible={true}
            />
          </div>
        )}
        <Container fluid>
          <Card small className="mb-4">
            <CardBody>
              {this.state.items.map((cursor, index) => (
                <div key={index}>
                  <h4>{cursor.name}</h4>
                  <Row>
                    {cursor.dishes.map((dish, index) => {
                      return (
                        <Col key={index} className="mb-2" md="4">
                          <Card>
                            <CardHeader>
                              <CardTitle className="fullMenuTitle">
                                {dish.name}
                              </CardTitle>
                            </CardHeader>
                            <CardImg
                              className="fullMenu-pic"
                              src={MEDIA_FILES_APPEND + dish.image}
                              onError={this.setDefaultImage}
                            />
                            <CardBody>
                              <Button
                                className="btn mr-2 float-right"
                                tag={Link}
                                to={{
                                  pathname: `/${
                                    headers[this.props.tableType].formComponent
                                  }`,
                                  state: {
                                    extraInfo: {
                                      menuId: cursor.id,
                                      restaurantId: cursor.restaurant.id,
                                      menuName: cursor.name,
                                    },
                                    entity: { fetch: true, id: dish.id },
                                    isRestaurant: true,
                                    fromFullList: true,
                                  },
                                }}
                              >
                                <i className="material-icons mr-1">edit</i>
                                {this.t('table.edit')}
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              ))}
            </CardBody>
          </Card>
        </Container>
        <ConfirmDeleteModal
          cancelClickFunction={this.toggleDeleteModal}
          entity={capitalize(this.props.entity)}
          open={this.state.modalOpened}
          toggleFunction={this.toggleDeleteModal}
          toDeleteId={this.state.toDeleteId}
          toDeleteName={this.state.toDeleteName}
          refetchFunction={this.getItems}
          t={this.t}
        />
        <UnsavedChangesModal
          open={this.state.unsavedModalOpened}
          toggleFunction={this.toggleUnsavedModal}
          restaurantId={this.state.restaurantId}
          isRestaurant={this.state.isRestaurant}
          t={this.t}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.user.username,
});

const bindActions = (dispatch) => ({});

export default connect(
  mapStateToProps,
  bindActions
)(withTranslation()(FullMenuView));
