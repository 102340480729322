import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Card, CardBody } from "shards-react";
import { withTranslation } from "react-i18next";

class KPIInfo extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  render() {
    const { label, value, className, subkpis } = this.props;

    const cardClasses = classNames(
      "stats-small stats-small--1 card card-small",
      className
    );

    const cardBodyClasses = classNames(
      subkpis.length > 0 ? "pt-3 pb-2" : "px-0 pb-2"
    );

    const innerWrapperClasses = classNames("d-flex flex-column m-auto");

    const dataFieldClasses = classNames("stats-small__data");

    const labelClasses = classNames("stats-small__label text-uppercase mb-1");

    const valueClasses = classNames("stats-small__value count my-3");

    return (
      <Card small className={cardClasses}>
        <CardBody className={cardBodyClasses}>
          <div className={innerWrapperClasses}>
            <div className={dataFieldClasses}>
              <span className={labelClasses}>{this.props.t(label)}</span>
              <h6 className={valueClasses}>{value}</h6>
            </div>
          </div>
          <div className="subkpis p-0 d-flex">
            {subkpis.map((subkpi, idx) => (
              <div key={idx} className={dataFieldClasses}>
                <h6 className="stats-small__subvalue count mb-0 text-uppercase">
                  {subkpi.className === "none" ? (
                    subkpi.name + ": "
                  ) : (
                    <span className={"subkpi-img " + subkpi.className}></span>
                  )}
                  {subkpi.value}
                </h6>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    );
  }
}

KPIInfo.propTypes = {
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The Subvalues.
   */
  subkpis: PropTypes.array,
  /**
   * The className.
   */
  className: PropTypes.string
};

KPIInfo.defaultProps = {
  value: 0,
  label: "Label",
  subkpis: [],
  className: "stats"
};

export default withTranslation()(KPIInfo);
