export default {
  searchBar: 'Busca',
  'username.placeholder': 'Email/Nombre de usuario',
  'username.required': 'Nombre de usuario requerido',
  'password.placeholder': 'Contraseña',
  'password.required': 'Contraseña requerida',
  login: 'Login',
  'table.edit': 'Editar',
  'table.delete': 'Eliminar',
  'table.options': 'Opciones',
  'cityTable.title': 'Ciudades',
  'currencyTable.title': 'Monedas',
  'serviceTable.title': 'Servicios',
  'form.newService': 'Nuevo servicio',
  'form.newCuisine': 'Nueva cocina',
  'cityTable.tabletitle': 'Ciudades',
  'cityTable.subtitle': 'Todas las ciudades',
  'currencyTable.tabletitle': 'Monedas',
  'currencyTable.subtitle': 'Todas las monedas',
  'allergenTable.title': 'Alérgenos',
  'table.name': 'Nombre',
  'table.icon': 'Icono',
  'table.code': 'Código',
  'table.symbol': 'Símbolo',
  deleteModalHeader: 'Eliminar',
  'cuisineTable.title': 'Cocinas',
  'cityTable.state': 'Estado',
  'allergen.iconUpload': 'Icono (Clica aquí para cambiarlo)',
  uploadText: 'Arrastra y suelta aquí o pulsa para seleccionar',
  uploadRequired: '¡Debe subir un icono para el alérgeno!',
  'form.newAllergen': 'Nuevo alérgeno',
  'form.newCurrency': 'Nueva moneda',
  'table.currency': 'Moneda',
  'form.newCountry': 'Nuevo país',
  'table.country': 'País',
  'countryTable.title': 'Paises',
  'lifestyleTable.title': 'Estilos de vidad',
  'form.newLifestyle': 'Nuevo estilo de vida',
  'stateTable.title': 'Provincia',
  'form.editing': 'Editar',
  back: 'Atrás',
  'form.update': 'Actualizar',
  'form.create': 'Crear',
  'form.newState': 'Nueva provincia',
  'form.selectOne': 'Selecciona una de las siguientes',
  'form.newCity': 'Nueva ciudad',
  'table.state': 'Provincia',
  'table.client': 'Cliente',
  'table.city': 'Ciudad',
  'table.views': 'Visitas',
  'restaurantTable.title': 'Restaurantes',
  countryRequired: 'Debe seleccionar un país para crear una provincia',
  stateRequired: 'Debe seleccionar una provincia para crear una ciudad',
  new: 'Nuevo',
  'restaurantForm.info': 'Información',
  'restaurantForm.name': 'Nombre del restaurante',
  'restaurantForm.description': 'Descripción',
  'restaurantForm.address': 'Dirección',
  'restaurantForm.email': 'Email',
  'restaurantForm.latitude': 'Latitud',
  'restaurantForm.longitude': 'Longitud',
  'restaurantForm.postalCode': 'Código postal',
  'restaurantForm.priceRange': 'Rango de precio',
  'restaurantForm.user': 'Usuario',
  'restaurantForm.cuisines': 'Cocinas',
  'restaurantForm.currency': 'Moneda',
  'restaurantForm.lifestyle': 'Lifestyle',
  'restaurantForm.services': 'Servicios',
  'restaurantForm.logoImage': 'Logo de Restaurant',
  'restaurantForm.image': 'Banner',
  'restaurantForm.phone': 'Teléfono',
  'restaurantForm.suggested': 'Restaurante sugerido',
  'restaurantForm.dashboardAvailable': 'Acceso a dashboard',
  'restaurantForm.customStyleCollapse': 'Customización estilos premium',
  'restaurantForm.mainColor': 'Color principal',
  'restaurantForm.fontColor': 'Color de fuente',
  'restaurantForm.link': 'Enlace personalizado',
  'restaurantForm.linkName': 'Nombre enlace personalizado',
  'restaurantForm.font': 'Tipo de fuente',
  'restaurantForm.fontSelect': 'Seleccione tipo de fuente',
  'restaurantForm.showSearch': 'Restaurante aparece en búsquedas por Foodyt',
  'restaurantForm.limitedToFreeUser':
    'Limitado a 1 para usuarios con plan Free',
  'restaurantForm.ht_username': 'HostelTactil usuario del local',
  'restaurantForm.ht_password': 'HostelTactil contraseña del local',
  'restaurantForm.agora_api_url': 'Agora API url',
  'restaurantForm.agora_api_token': 'Agora API token',
  'restaurantForm.foodytOrders': 'Pedidos de Foodyt',
  'restaurantForm.ordersPostalCodes': 'Códigos postales donde reparten',
  'restaurantForm.ordersStopped': 'Pedidos parados',
  'restaurantForm.tenedorRestaurantId': 'Id del restaurante',
  requiredFieldsEmpty:
    'Algunos de los campos requeridos están vacíos, asegúrate que están rellebis todos los campos marcados con *',
  'menuForm.name': 'Nombre de la sección del menú',
  'modal.newMenu': 'Nueva sección',
  cancel: 'Cancelar',
  'menuForm.season': 'Estación del año',
  spring: 'Primavera',
  summer: 'Verano',
  fall: 'Otoño',
  winter: 'Invierno',
  'menuForm.newMenu': 'Nueva sección',
  'searchPlaces.placeholder': 'Select tu restaurante',
  'restaurantForm.menus': 'Secciones de la carta',
  'tooltip.cantdeletemenu': 'Esta sección todavía no tiene platos',
  dishesOfMenu: 'Platos de',
  confirmDelete: '¿Está seguro de que desea eliminar el elemento?',
  'dishForm.suggested': 'Sugerencia Foodyt',
  'form.newDish': 'Nuevo plato',
  'dishForm.image': 'Imagen del plato',
  'dishForm.name': 'Nombre',
  'table.cuisine': 'Cocina',
  'dishForm.allergens': 'Selecciona los alérgenos',
  'dishForm.description': 'Descripción',
  'dishForm.prices': 'Precios del plato',
  size: 'Plato',
  price: 'Precio',
  actions: 'Llamadas/Reservas',
  'priceForm.newPrice': 'Nuevo precio',
  'priceForm.name': 'Tamaño',
  'priceForm.price': 'Precio',
  tapa: 'Tapa',
  media: 'Media',
  racion: 'Ración',
  plato: 'Plato',
  copa: 'Copa',
  botella: 'Botella',
  'tooltip.cantdeleterestaurant':
    'Este restaurante todavía contiene secciones con platos!',
  'menu.nodishes':
    "Esta carta todavía no contiene secciones! Clica en 'Crear' para crear una nueva!",
  'form.newRestaurant': 'Nuevo restaurante',
  'dishForm.status': 'Estado',
  waiting: 'En espera',
  approved: 'Aprobado',
  rejected: 'Rechazado',
  'info.popoverheader': 'Encuentra en Google el restaurante',
  'info.popoverbody':
    'Busca y clica el lugar que estás buscando para rellenar algunos campos',
  'Ajustes de idioma': 'Ajustes multilingues',
  'form.nameEn': 'Nombre en inglés',
  'form.nameFr': 'Nombre en francés',
  'form.nameIt': 'Nombre en italiano',
  'form.namePt': 'Nombre en portugués',
  'form.nameDe': 'Nombre en alemán',
  'form.nameCa': 'Nombre en catalán',
  'form.nameJa': 'Nombre en japonés',
  'form.nameKo': 'Nombre en coreano',
  'form.nameZhCn': 'Nombre en chino',
  'form.nameRu': 'Nombre en ruso',
  'form.descriptionEn': 'Descripción en inglés',
  'form.descriptionFr': 'Descripción en francés',
  'form.descriptionIt': 'Descripción en italiano',
  'form.descriptionPt': 'Descripción en portugués',
  'form.descriptionDe': 'Descripción en alemán',
  'form.descriptionCa': 'Descripción en catalán',
  'form.descriptionJa': 'Descripción en japonés',
  'form.descriptionKo': 'Descripción en coreano',
  'form.descriptionZhCn': 'Descripción en chino',
  'form.descriptionRu': 'Descripción en ruso',
  'form.notUpdated': 'Hubo un problema. Cambios no realizados.',
  'dishForm.autoTranslate': 'Traducción automática',
  'menuForm.nameEn': 'Nombre en inglés',
  'restaurantForm.showPriceRange': 'Mostrar rango de precios',
  'restaurantForm.bookUrl': 'URL reserva',
  'restaurantForm.ht_username': 'HostelTactil Local user',
  'restaurantForm.ht_password': 'HostelTactil Local user password',
  'restaurantForm.agora_api_url': 'Agora API url',
  'restaurantForm.agora_api_token': 'Agora API token',
  'hostelTactil.restaurant': 'Configuration HostelTactil restaurant',
  'agora.restaurant': 'Configuración sistemas Agora',
  'menu.noSavedRestaurant':
    'Debes guardar el restaurante antes de guardar secciones',
  loadMore: 'Cargar más',
  statusFilter: 'Mostrar los No aprobados',
  searchPlaceholder: 'Buscar',
  'usersTable.title': 'Usuarios',
  'table.role': 'Rol',
  'form.newUser': 'Nuevo usuario',
  'userForm.username': 'Nombre de usuario',
  'userForm.password': 'Contraseña',
  'userForm.repeatPassword': 'Repetir contraseña',
  'userForm.firstName': 'Nombre',
  'userForm.lastName': 'Apellidos',
  'userForm.email': 'Email',
  'userForm.isRestaurant': 'Marcar como restaurante',
  'userForm.isStaff': 'Marcar como staff',
  'userForm.avatar': 'Avatar',
  'userForm.plan': 'Plan de usuario',
  'userForm.basic': 'Básico',
  'userForm.pro': 'Pro',
  'userForm.free': 'Free',
  'userForm.premium': 'Premium',
  'userForm.ht_username': 'HostelTactil usuario username',
  'userForm.ht_password': 'HostelTactil usuario password',
  'userForm.ht_activo': 'HostelTactil usuario activo',
  'userForm.agoraActive': 'Agora usuario activo',
  'hosteltactil.user.config': 'Configuración sistemas de reserva',
  passwordsNotEquals: 'Las contraseñas no coinciden',
  'restaurantForm.nameRes': 'Restaurante',
  notApprovedDishes: 'Platos no aprobados',
  'table.date': 'Fecha',
  'table.user': 'Usuario',
  'table.title': 'Título',
  'table.comment': 'Comentario',
  'table.approve': 'Aprobar',
  'notApprovedComments.title': 'Comentarios no aprobados',
  AP: 'Aprobado',
  WA: 'En espera',
  RJ: 'Rechazado',
  'language.avaibleLanguages': 'Idiomas disponibles',
  close: 'Cerrar',
  unsavedChanges: '¿Estás seguro?',
  confirmBack:
    'No has guardado los cambios, cierra y clica en actualizar para guardar o ve atrás para quitarlo',
  'language.settings': 'Multiidioma',
  'form.selectOneRestaurant': 'Selecciona otro restaurante para cambiar',
  'restaurantForm.urlPage': 'Permalink',
  'dashboard.selectOneRestaurant': 'Todos tus restaurantes',
  'dashboard.title': 'Panel de control de',
  'dashboard.traffic': 'Tráfico',
  'dashboard.dishes': 'Platos',
  'dashboard.views': 'Visitas',
  'dashboard.totalViews': 'Visualizaciones totales',
  'dashboard.normalViews': 'Visualizaciones WEB',
  'dashboard.qrViews': 'Visualizaciones por QR',
  'dashboard.viewsLanguage': 'Visitas por idioma',
  'dashboard.viewsAllergen': 'Uso filtro alérgenos',
  'dashboard.mostViewed': 'Platos más vistos',
  'dashboard.leastViewed': 'Platos menos vistos',
  'dashboard.lastMonth': 'Últimos 30 días',
  'dashboard.lastWeek': 'Últimos 7 días',
  'dashboard.thisMonth': 'Este mes',
  'dashboard.last3months': 'Últimos 90 días',
  'dashboard.thisYear': 'Este año',
  'dashboard.selectDate': 'Seleccionar fecha',
  confirmClone: '¿Está seguro de que desea clonar el elemento?',
  'table.clone': 'Clonar',
  cloneModalHeader: 'Clonar',
  'action.accept': 'Aceptar',
  'action.cancel': 'Cancelar',
  'upload_csv.title.success': 'Operación exitosa',
  'upload_csv.title.error': 'Error al subir archivo',
  'upload_csv.body.confirm':
    '¿Esta seguro que desea subir el archivo {{file}}?',
  'upload_csv.body.error_format':
    'El fichero seleccionado es invalido. \nSe esperaba un {{- expected_file }} y se recibió un {{- selected_file }}.',
  'upload_csv.body.error_unespected':
    'Error importando el archivo {{ file }}. \nRevise el formato del archivo y/o los datos.',
  'dashboard.daily_menu': 'Menú del día',
  'dashboard.daily_menu.new': 'Añadir nuevo menú del día',
  'dashboard.daily_menu.edit': 'Cambiar el menú del día',
  'menuForm.newFullMenu': 'Nuevo menu',
  'dishForm.active': 'Activo',
  'create.full.menu': 'Crear nueva carta',
  'add.dish': 'Añadir plato',
  'select.dish': 'Seleccione un plato',
  'fullMenuForm.name': 'Nombre de la carta',
  'userForm.lamb.weston': 'Usuario Lamb Weston',
  dashboard: 'Analíticas',
  'digital.menu': 'Mi carta digital',
  'daily.menu': 'Mi menú del día',
  'my.restaurant': 'Mi restaurante',
  'dish.review': 'Revisión platos',
  'comment.review': 'Revisión comentarios',
  Visitas: 'Visitas',
  'Platos favoritos': 'Platos favoritos',
  'Filtro alérgenos': 'Filtro alérgenos',
  Acciones: 'Acciones',
  Seguidores: 'Seguidores',
  Compartidos: 'Compartidos',
  favoritos: 'Favoritos',
  'no.info': 'Información no disponible',
  'all.restaurants': 'todos los restaurantes',
  Español: 'Español',
  Inglés: 'Inglés',
  Alemán: 'Alemán',
  Francés: 'Francés',
  Italiano: 'Italiano',
  Portugués: 'Portugués',
  Chinese: 'Chino',
  Catalonian: 'Catalán',
  Rusia: 'Ruso',
  Koreano: 'Koreano',
  Japonés: 'Japonés',
  'append.currency': 'Símbolo moneda al final',
  'ordersTable.title': 'Listado pedidos',
  'table.clientName': 'Nombre cliente',
  'order.orderType': 'Tipo pedido',
  'table.total': 'Total',
  'table.created': 'Creado',
  'table.restaurantName': 'Nombre restaurante',
  'dishForm.sala': 'Lounge',
  'dishForm.takeaway': 'Take away',
  'dishForm.delivery': 'Delivery',
  'dishForm.ordersType': 'Pedidos activos',
  'dishForm.paymentMethod': 'Formas de pago',
  'dishForm.card': 'Tarjeta',
  'dishForm.cash': 'Efectivo',
  'order.paymentType': 'Tipo de pago',
  'PP': 'PayPal',
  'CA': 'Efectivo',
  'CC': 'Tarjeta',
  'htDishId': 'Hosteltactil dish id',
  'download.orders': 'Descargar pedidos',
  'marketTable.title': 'Mercados',
  'marketForm.name': 'Nombre mercado',
  'marketForm.restaurants': 'Puestos del mercado',
  'marketForm.marketOrderConfigCollapse': 'Configuraciones pedidos mercado',
  'marketForm.deliveryConfigCollapse': 'Configuración pedidos a domicilio',
  'marketForm.takeawayConfigCollapse': 'Configuración pedidos a recoger',
  'marketForm.loungeConfigCollapse': 'Configuración pedidos en mercado',
  'marketForm.stripePublicKey': 'Clave pública stripe',
  'marketForm.stripePrivateKey': 'Clave privada stripe',
  'marketForm.minimumOrder': 'Pedido mínimo',
  'marketForm.deliveryPrice': 'Precio envío',
  'marketForm.orderTime': 'Tiempo medio de pedido',
  'marketForm.addOrderHours': 'Añadir periodo de horas apertura',
  'user.market': 'Mercado',
  'marketForm.askName': 'Pedir nombre',
  'marketForm.askPhone': 'Pedir teléfono',
  'marketForm.askEmail': 'Pedir email',
  'create.table': 'Crear mesa',
  'table.qr': "Descargar QR",
  'tableForm.code': "Código",
  'tableForm.name': "Nombre",
  'tableForm.zone': "Zona",
  'userForm.userMarket': 'Mercado',
  'franchiseForm.restaurants': 'Restaurantes asociados',
  'franchiseTable.title': 'Franquicias',
  'franchises': 'Franquicias',
  'markets': 'Mercados',
  'form.newFranchise': 'Nueva franquicia',
  'form.createAllFranchise': 'Crear en todas las franquicias',
  'form.updateAllFranchise': 'Actualizar en todas las franquicias',
  'marketForm.sendEmailClosed': 'Enviar correo al cerrar pedido',
  'table.timezone': 'Franja horaria',
  'table.codeTwo': 'Código de dos carácteres',
  'app.table': 'Mesa',
  'priceForm.weight': 'Por peso',
  'priceForm.loungeActive': 'Activo sala',
  'priceForm.takeawayActive': 'Activo recoger',
  'priceForm.deliveryActive': 'Activo a domicilio',
  'priceForm.loungePrice': 'Precio sala',
  'priceForm.takeawayPrice': 'Precio recoger',
  'priceForm.deliveryPrice': 'Precio a domicilio',
  'companyTable.title': 'Compañias',
  'companies': 'Compañias',
  'restaurantForm.company': 'Compañia',
  'userForm.rol': 'Rol',
  'userForm.admin': 'Administrador',
  'userForm.editor': 'Editor',
  'userForm.readOnly': 'Solo lectura',
  'userForm.restaurants': 'Restaurantes',
  'userForm.revoActive': 'Activo en revo'
};
