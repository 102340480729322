import React from 'react';
import Spinner from 'react-spinner-material';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Container, Row, Col, Card, CardBody, Button } from 'shards-react';
import { withTranslation } from 'react-i18next';
import headers from '../data/tables_headers/headers';
import { queries } from '../graphQueries';
import { MEDIA_FILES_APPEND } from '../config';
import { client } from '../apolloClient';
import PageTitle from '../components/common/PageTitle';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';

import '../shards-dashboard/styles/styles.css';

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      toDeleteName: '',
      toDeleteIndex: '',
      toDeleteId: '',
    };
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
  }

  componentDidMount() {
    if (this.refetch) {
      this.refetch();
    }
  }

  toggleDeleteModal = (
    toDeleteIndex = '',
    toDeleteId = '',
    toDeleteName = ''
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  async deleteItem(index, id) {
    const mutation = queries[headers[this.props.tableType].deleteMutation];
    client
      .mutate({
        variables: { id },
        mutation,
      })
      .then((res, err) => {
        this.toggleDeleteModal();
        this.refetch();
      });
  }

  render() {
    this.t = this.props.t;
    const graphQuery = queries[this.props.tableType];
    const tableHeaders = headers[this.props.tableType].headers;
    const dataKeys = headers[this.props.tableType].data;
    const capitalize = (s) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title={this.t(this.props.title)}
            className="text-sm-left"
          />
        </Row>
        <Query query={graphQuery}>
          {({ loading, error, data, refetch }) => {
            if (loading)
              return (
                <div className="spinner-loader-listing">
                  <Spinner
                    size={80}
                    spinnerColor={'#007BFF'}
                    spinnerWidth={2}
                    visible={true}
                  />
                </div>
              );
            if (error) return `Error ${error.message}`;
            this.refetch = refetch;
            return (
              <Row>
                <Col>
                  <Card small className="mb-4">
                    <CardBody className="p-0">
                      <table className="table mb-0">
                        <thead className="bg-light">
                          <tr className="table-hover">
                            <th scope="col" className="border-0">
                              #
                            </th>
                            {tableHeaders.map((header, index) => (
                              <th
                                key={index}
                                scope="col"
                                className="border-0"
                                style={{
                                  width:
                                    header === 'table.comment' ? '39%' : '',
                                }}
                              >
                                {this.t(header)}
                              </th>
                            ))}
                            <th scope="col" className="border-0 text-center">
                              {this.props.tableType !==
                                'notApprovedComments' && (
                                <Button
                                  tag={Link}
                                  className="btn-success float-right"
                                  to={`/${
                                    headers[this.props.tableType].formComponent
                                  }`}
                                >
                                  {this.t('new')}
                                </Button>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data[this.props.tableType].map((city, index) => (
                            <tr key={index} className="table-hover">
                              <td>{index + 1}</td>
                              {dataKeys.map((key, index) => {
                                let info = '';
                                try {
                                  info = key
                                    .split('.')
                                    .reduce(function (c, prop) {
                                      return c[prop];
                                    }, city);
                                } catch (err) {}
                                if (
                                  typeof info === 'string' &&
                                  info.endsWith('png')
                                )
                                  return (
                                    <td key={index}>
                                      <img
                                        className="allergen-icon"
                                        src={MEDIA_FILES_APPEND + info}
                                        alt=""
                                      />
                                    </td>
                                  );
                                else if (key === 'created')
                                  return (
                                    <td key={index}>
                                      {new Date(info).toLocaleDateString()}
                                    </td>
                                  );
                                else return <td key={index}>{info}</td>;
                              })}
                              <td className="text-right">
                                {this.props.tableType ===
                                'notApprovedComments' ? (
                                  <Button
                                    className="btn mr-2"
                                    onClick={() =>
                                      client
                                        .mutate({
                                          mutation: queries.approveComment,
                                          variables: {
                                            id:
                                              data[this.props.tableType][index]
                                                .id,
                                          },
                                        })
                                        .then((res) => this.refetch())
                                    }
                                  >
                                    <i className="material-icons mr-1">check</i>
                                    {this.t('table.approve')}
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn mr-2"
                                    tag={Link}
                                    to={{
                                      pathname: `/${
                                        headers[this.props.tableType]
                                          .formComponent
                                      }`,
                                      state: {
                                        entity:
                                          data[this.props.tableType][index],
                                      },
                                    }}
                                  >
                                    <i className="material-icons mr-1">edit</i>
                                    {this.t('table.edit')}
                                  </Button>
                                )}
                                <Button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    this.toggleDeleteModal(
                                      index,
                                      parseInt(
                                        data[this.props.tableType][index].id
                                      ),
                                      data[this.props.tableType][index].name
                                    )
                                  }
                                >
                                  <i className="material-icons mr-1">delete</i>
                                  {this.t('table.delete')}
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            );
          }}
        </Query>
        <ConfirmDeleteModal
          cancelClickFunction={this.toggleDeleteModal}
          entity={capitalize(this.props.entity)}
          open={this.state.modalOpened}
          toggleFunction={this.toggleDeleteModal}
          toDeleteId={this.state.toDeleteId}
          refetchFunction={this.refetch}
          toDeleteName={this.state.toDeleteName}
          t={this.t}
        />
      </Container>
    );
  }
}
export default withTranslation()(Table);
