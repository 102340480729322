const env = (window || {})['__env'] || {};

function getValue(name, default_value) {
  if (env[name]) {
    return env[name];
  } else if (process.env && process.env['REACT_APP_' + name]) {
    return process.env['REACT_APP_' + name];
  } else {
    return default_value;
  }
}

module.exports = {
  API_URL: getValue('API_URL', 'http://web:8000'),
  MEDIA_FILES_APPEND: getValue('MEDIA_FILES_APPEND', 'http://web:8000/media/'),
  FLAGS_ICONS_APPEND: getValue(
    'FLAGS_ICONS_APPEND',
    'http://web:8000/static/img/flags/4x3/'
  ),
  APP_GAID: getValue('APP_GAID', 'UA-115105611-2'),
  avaibleFlags: [
    'es',
    'en',
    'fr',
    'it',
    'pt',
    'de',
    'ca',
    'ja',
    'ko',
    'zh-cn',
    'ru',
  ],
  dishesLanguagesFields: [
    {
      field: 'name',
      language: 'En',
    },
    {
      field: 'description',
      language: 'En',
    },
    {
      field: 'name',
      language: 'Fr',
    },
    {
      field: 'description',
      language: 'Fr',
    },
    {
      field: 'name',
      language: 'It',
    },
    {
      field: 'description',
      language: 'It',
    },
    {
      field: 'name',
      language: 'Pt',
    },
    {
      field: 'description',
      language: 'Pt',
    },
    {
      field: 'name',
      language: 'De',
    },
    {
      field: 'description',
      language: 'De',
    },
    {
      field: 'name',
      language: 'Ca',
    },
    {
      field: 'description',
      language: 'Ca',
    },
    {
      field: 'name',
      language: 'Ja',
    },
    {
      field: 'description',
      language: 'Ja',
    },
    {
      field: 'name',
      language: 'Ko',
    },
    {
      field: 'description',
      language: 'Ko',
    },
    {
      field: 'name',
      language: 'ZhCn',
    },
    {
      field: 'description',
      language: 'ZhCn',
    },
    {
      field: 'name',
      language: 'Ru',
    },
    {
      field: 'description',
      language: 'Ru',
    },
  ],
  restaurantsLanguagesFields: [
    {
      field: 'description',
      language: 'En',
    },
    {
      field: 'description',
      language: 'Fr',
    },
    {
      field: 'description',
      language: 'It',
    },
    {
      field: 'description',
      language: 'Pt',
    },
    {
      field: 'description',
      language: 'De',
    },
    {
      field: 'description',
      language: 'Ca',
    },
    {
      field: 'description',
      language: 'Ja',
    },
    {
      field: 'description',
      language: 'Ko',
    },
    {
      field: 'description',
      language: 'ZhCn',
    },
    {
      field: 'description',
      language: 'Ru',
    },
  ],
  menuLanguagesFields: [
    {
      field: 'name',
      language: 'En',
    },
    {
      field: 'name',
      language: 'Fr',
    },
    {
      field: 'name',
      language: 'It',
    },
    {
      field: 'name',
      language: 'Pt',
    },
    {
      field: 'name',
      language: 'De',
    },
    {
      field: 'name',
      language: 'Ca',
    },
    {
      field: 'name',
      language: 'Ja',
    },
    {
      field: 'name',
      language: 'Ko',
    },
    {
      field: 'name',
      language: 'ZhCn',
    },
    {
      field: 'name',
      language: 'Ru',
    },
  ],
  USER_FREE: 'FRE',
  USER_BASIC: 'BAS',
  USER_PREMIUM: 'PRE',
  USER_PRO: 'PRO',
  USER_LAMB_WESTON: 'LWE',
  USER_MARKET: 'MA',
  DELIVERY: 'DE',
  LOUNGE: 'SA',
  TAKEAWAY: 'TA',
  ADMIN: 'AD',
  EDITOR: 'ED',
  READ_ONLY: 'RE',
  TIMEZONES: {
    "AD": [
        "Europe/Andorra"
    ],
    "AE": [
        "Asia/Dubai"
    ],
    "AF": [
        "Asia/Kabul"
    ],
    "AG": [
        "America/Antigua"
    ],
    "AI": [
        "America/Anguilla"
    ],
    "AL": [
        "Europe/Tirane"
    ],
    "AM": [
        "Asia/Yerevan"
    ],
    "AO": [
        "Africa/Luanda"
    ],
    "AQ": [
        "Antarctica/McMurdo",
        "Antarctica/Casey",
        "Antarctica/Davis",
        "Antarctica/DumontDUrville",
        "Antarctica/Mawson",
        "Antarctica/Palmer",
        "Antarctica/Rothera",
        "Antarctica/Syowa",
        "Antarctica/Troll",
        "Antarctica/Vostok"
    ],
    "AR": [
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Cordoba",
        "America/Argentina/Salta",
        "America/Argentina/Jujuy",
        "America/Argentina/Tucuman",
        "America/Argentina/Catamarca",
        "America/Argentina/La_Rioja",
        "America/Argentina/San_Juan",
        "America/Argentina/Mendoza",
        "America/Argentina/San_Luis",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Ushuaia"
    ],
    "AS": [
        "Pacific/Pago_Pago"
    ],
    "AT": [
        "Europe/Vienna"
    ],
    "AU": [
        "Australia/Lord_Howe",
        "Antarctica/Macquarie",
        "Australia/Hobart",
        "Australia/Melbourne",
        "Australia/Sydney",
        "Australia/Broken_Hill",
        "Australia/Brisbane",
        "Australia/Lindeman",
        "Australia/Adelaide",
        "Australia/Darwin",
        "Australia/Perth",
        "Australia/Eucla"
    ],
    "AW": [
        "America/Aruba"
    ],
    "AX": [
        "Europe/Mariehamn"
    ],
    "AZ": [
        "Asia/Baku"
    ],
    "BA": [
        "Europe/Sarajevo"
    ],
    "BB": [
        "America/Barbados"
    ],
    "BD": [
        "Asia/Dhaka"
    ],
    "BE": [
        "Europe/Brussels"
    ],
    "BF": [
        "Africa/Ouagadougou"
    ],
    "BG": [
        "Europe/Sofia"
    ],
    "BH": [
        "Asia/Bahrain"
    ],
    "BI": [
        "Africa/Bujumbura"
    ],
    "BJ": [
        "Africa/Porto-Novo"
    ],
    "BL": [
        "America/St_Barthelemy"
    ],
    "BM": [
        "Atlantic/Bermuda"
    ],
    "BN": [
        "Asia/Brunei"
    ],
    "BO": [
        "America/La_Paz"
    ],
    "BQ": [
        "America/Kralendijk"
    ],
    "BR": [
        "America/Noronha",
        "America/Belem",
        "America/Fortaleza",
        "America/Recife",
        "America/Araguaina",
        "America/Maceio",
        "America/Bahia",
        "America/Sao_Paulo",
        "America/Campo_Grande",
        "America/Cuiaba",
        "America/Santarem",
        "America/Porto_Velho",
        "America/Boa_Vista",
        "America/Manaus",
        "America/Eirunepe",
        "America/Rio_Branco"
    ],
    "BS": [
        "America/Nassau"
    ],
    "BT": [
        "Asia/Thimphu"
    ],
    "BW": [
        "Africa/Gaborone"
    ],
    "BY": [
        "Europe/Minsk"
    ],
    "BZ": [
        "America/Belize"
    ],
    "CA": [
        "America/St_Johns",
        "America/Halifax",
        "America/Glace_Bay",
        "America/Moncton",
        "America/Goose_Bay",
        "America/Blanc-Sablon",
        "America/Toronto",
        "America/Nipigon",
        "America/Thunder_Bay",
        "America/Iqaluit",
        "America/Pangnirtung",
        "America/Atikokan",
        "America/Winnipeg",
        "America/Rainy_River",
        "America/Resolute",
        "America/Rankin_Inlet",
        "America/Regina",
        "America/Swift_Current",
        "America/Edmonton",
        "America/Cambridge_Bay",
        "America/Yellowknife",
        "America/Inuvik",
        "America/Creston",
        "America/Dawson_Creek",
        "America/Fort_Nelson",
        "America/Whitehorse",
        "America/Dawson",
        "America/Vancouver"
    ],
    "CC": [
        "Indian/Cocos"
    ],
    "CD": [
        "Africa/Kinshasa",
        "Africa/Lubumbashi"
    ],
    "CF": [
        "Africa/Bangui"
    ],
    "CG": [
        "Africa/Brazzaville"
    ],
    "CH": [
        "Europe/Zurich"
    ],
    "CI": [
        "Africa/Abidjan"
    ],
    "CK": [
        "Pacific/Rarotonga"
    ],
    "CL": [
        "America/Santiago",
        "America/Punta_Arenas",
        "Pacific/Easter"
    ],
    "CM": [
        "Africa/Douala"
    ],
    "CN": [
        "Asia/Shanghai",
        "Asia/Urumqi"
    ],
    "CO": [
        "America/Bogota"
    ],
    "CR": [
        "America/Costa_Rica"
    ],
    "CU": [
        "America/Havana"
    ],
    "CV": [
        "Atlantic/Cape_Verde"
    ],
    "CW": [
        "America/Curacao"
    ],
    "CX": [
        "Indian/Christmas"
    ],
    "CY": [
        "Asia/Nicosia",
        "Asia/Famagusta"
    ],
    "CZ": [
        "Europe/Prague"
    ],
    "DE": [
        "Europe/Berlin",
        "Europe/Busingen"
    ],
    "DJ": [
        "Africa/Djibouti"
    ],
    "DK": [
        "Europe/Copenhagen"
    ],
    "DM": [
        "America/Dominica"
    ],
    "DO": [
        "America/Santo_Domingo"
    ],
    "DZ": [
        "Africa/Algiers"
    ],
    "EC": [
        "America/Guayaquil",
        "Pacific/Galapagos"
    ],
    "EE": [
        "Europe/Tallinn"
    ],
    "EG": [
        "Africa/Cairo"
    ],
    "EH": [
        "Africa/El_Aaiun"
    ],
    "ER": [
        "Africa/Asmara"
    ],
    "ES": [
        "Europe/Madrid",
        "Africa/Ceuta",
        "Atlantic/Canary"
    ],
    "ET": [
        "Africa/Addis_Ababa"
    ],
    "FI": [
        "Europe/Helsinki"
    ],
    "FJ": [
        "Pacific/Fiji"
    ],
    "FK": [
        "Atlantic/Stanley"
    ],
    "FM": [
        "Pacific/Chuuk",
        "Pacific/Pohnpei",
        "Pacific/Kosrae"
    ],
    "FO": [
        "Atlantic/Faroe"
    ],
    "FR": [
        "Europe/Paris"
    ],
    "GA": [
        "Africa/Libreville"
    ],
    "GB": [
        "Europe/London"
    ],
    "GD": [
        "America/Grenada"
    ],
    "GE": [
        "Asia/Tbilisi"
    ],
    "GF": [
        "America/Cayenne"
    ],
    "GG": [
        "Europe/Guernsey"
    ],
    "GH": [
        "Africa/Accra"
    ],
    "GI": [
        "Europe/Gibraltar"
    ],
    "GL": [
        "America/Nuuk",
        "America/Danmarkshavn",
        "America/Scoresbysund",
        "America/Thule"
    ],
    "GM": [
        "Africa/Banjul"
    ],
    "GN": [
        "Africa/Conakry"
    ],
    "GP": [
        "America/Guadeloupe"
    ],
    "GQ": [
        "Africa/Malabo"
    ],
    "GR": [
        "Europe/Athens"
    ],
    "GS": [
        "Atlantic/South_Georgia"
    ],
    "GT": [
        "America/Guatemala"
    ],
    "GU": [
        "Pacific/Guam"
    ],
    "GW": [
        "Africa/Bissau"
    ],
    "GY": [
        "America/Guyana"
    ],
    "HK": [
        "Asia/Hong_Kong"
    ],
    "HN": [
        "America/Tegucigalpa"
    ],
    "HR": [
        "Europe/Zagreb"
    ],
    "HT": [
        "America/Port-au-Prince"
    ],
    "HU": [
        "Europe/Budapest"
    ],
    "ID": [
        "Asia/Jakarta",
        "Asia/Pontianak",
        "Asia/Makassar",
        "Asia/Jayapura"
    ],
    "IE": [
        "Europe/Dublin"
    ],
    "IL": [
        "Asia/Jerusalem"
    ],
    "IM": [
        "Europe/Isle_of_Man"
    ],
    "IN": [
        "Asia/Kolkata"
    ],
    "IO": [
        "Indian/Chagos"
    ],
    "IQ": [
        "Asia/Baghdad"
    ],
    "IR": [
        "Asia/Tehran"
    ],
    "IS": [
        "Atlantic/Reykjavik"
    ],
    "IT": [
        "Europe/Rome"
    ],
    "JE": [
        "Europe/Jersey"
    ],
    "JM": [
        "America/Jamaica"
    ],
    "JO": [
        "Asia/Amman"
    ],
    "JP": [
        "Asia/Tokyo"
    ],
    "KE": [
        "Africa/Nairobi"
    ],
    "KG": [
        "Asia/Bishkek"
    ],
    "KH": [
        "Asia/Phnom_Penh"
    ],
    "KI": [
        "Pacific/Tarawa",
        "Pacific/Kanton",
        "Pacific/Kiritimati"
    ],
    "KM": [
        "Indian/Comoro"
    ],
    "KN": [
        "America/St_Kitts"
    ],
    "KP": [
        "Asia/Pyongyang"
    ],
    "KR": [
        "Asia/Seoul"
    ],
    "KW": [
        "Asia/Kuwait"
    ],
    "KY": [
        "America/Cayman"
    ],
    "KZ": [
        "Asia/Almaty",
        "Asia/Qyzylorda",
        "Asia/Qostanay",
        "Asia/Aqtobe",
        "Asia/Aqtau",
        "Asia/Atyrau",
        "Asia/Oral"
    ],
    "LA": [
        "Asia/Vientiane"
    ],
    "LB": [
        "Asia/Beirut"
    ],
    "LC": [
        "America/St_Lucia"
    ],
    "LI": [
        "Europe/Vaduz"
    ],
    "LK": [
        "Asia/Colombo"
    ],
    "LR": [
        "Africa/Monrovia"
    ],
    "LS": [
        "Africa/Maseru"
    ],
    "LT": [
        "Europe/Vilnius"
    ],
    "LU": [
        "Europe/Luxembourg"
    ],
    "LV": [
        "Europe/Riga"
    ],
    "LY": [
        "Africa/Tripoli"
    ],
    "MA": [
        "Africa/Casablanca"
    ],
    "MC": [
        "Europe/Monaco"
    ],
    "MD": [
        "Europe/Chisinau"
    ],
    "ME": [
        "Europe/Podgorica"
    ],
    "MF": [
        "America/Marigot"
    ],
    "MG": [
        "Indian/Antananarivo"
    ],
    "MH": [
        "Pacific/Majuro",
        "Pacific/Kwajalein"
    ],
    "MK": [
        "Europe/Skopje"
    ],
    "ML": [
        "Africa/Bamako"
    ],
    "MM": [
        "Asia/Yangon"
    ],
    "MN": [
        "Asia/Ulaanbaatar",
        "Asia/Hovd",
        "Asia/Choibalsan"
    ],
    "MO": [
        "Asia/Macau"
    ],
    "MP": [
        "Pacific/Saipan"
    ],
    "MQ": [
        "America/Martinique"
    ],
    "MR": [
        "Africa/Nouakchott"
    ],
    "MS": [
        "America/Montserrat"
    ],
    "MT": [
        "Europe/Malta"
    ],
    "MU": [
        "Indian/Mauritius"
    ],
    "MV": [
        "Indian/Maldives"
    ],
    "MW": [
        "Africa/Blantyre"
    ],
    "MX": [
        "America/Mexico_City",
        "America/Cancun",
        "America/Merida",
        "America/Monterrey",
        "America/Matamoros",
        "America/Mazatlan",
        "America/Chihuahua",
        "America/Ojinaga",
        "America/Hermosillo",
        "America/Tijuana",
        "America/Bahia_Banderas"
    ],
    "MY": [
        "Asia/Kuala_Lumpur",
        "Asia/Kuching"
    ],
    "MZ": [
        "Africa/Maputo"
    ],
    "NA": [
        "Africa/Windhoek"
    ],
    "NC": [
        "Pacific/Noumea"
    ],
    "NE": [
        "Africa/Niamey"
    ],
    "NF": [
        "Pacific/Norfolk"
    ],
    "NG": [
        "Africa/Lagos"
    ],
    "NI": [
        "America/Managua"
    ],
    "NL": [
        "Europe/Amsterdam"
    ],
    "NO": [
        "Europe/Oslo"
    ],
    "NP": [
        "Asia/Kathmandu"
    ],
    "NR": [
        "Pacific/Nauru"
    ],
    "NU": [
        "Pacific/Niue"
    ],
    "NZ": [
        "Pacific/Auckland",
        "Pacific/Chatham"
    ],
    "OM": [
        "Asia/Muscat"
    ],
    "PA": [
        "America/Panama"
    ],
    "PE": [
        "America/Lima"
    ],
    "PF": [
        "Pacific/Tahiti",
        "Pacific/Marquesas",
        "Pacific/Gambier"
    ],
    "PG": [
        "Pacific/Port_Moresby",
        "Pacific/Bougainville"
    ],
    "PH": [
        "Asia/Manila"
    ],
    "PK": [
        "Asia/Karachi"
    ],
    "PL": [
        "Europe/Warsaw"
    ],
    "PM": [
        "America/Miquelon"
    ],
    "PN": [
        "Pacific/Pitcairn"
    ],
    "PR": [
        "America/Puerto_Rico"
    ],
    "PS": [
        "Asia/Gaza",
        "Asia/Hebron"
    ],
    "PT": [
        "Europe/Lisbon",
        "Atlantic/Madeira",
        "Atlantic/Azores"
    ],
    "PW": [
        "Pacific/Palau"
    ],
    "PY": [
        "America/Asuncion"
    ],
    "QA": [
        "Asia/Qatar"
    ],
    "RE": [
        "Indian/Reunion"
    ],
    "RO": [
        "Europe/Bucharest"
    ],
    "RS": [
        "Europe/Belgrade"
    ],
    "RU": [
        "Europe/Kaliningrad",
        "Europe/Moscow",
        "Europe/Kirov",
        "Europe/Volgograd",
        "Europe/Astrakhan",
        "Europe/Saratov",
        "Europe/Ulyanovsk",
        "Europe/Samara",
        "Asia/Yekaterinburg",
        "Asia/Omsk",
        "Asia/Novosibirsk",
        "Asia/Barnaul",
        "Asia/Tomsk",
        "Asia/Novokuznetsk",
        "Asia/Krasnoyarsk",
        "Asia/Irkutsk",
        "Asia/Chita",
        "Asia/Yakutsk",
        "Asia/Khandyga",
        "Asia/Vladivostok",
        "Asia/Ust-Nera",
        "Asia/Magadan",
        "Asia/Sakhalin",
        "Asia/Srednekolymsk",
        "Asia/Kamchatka",
        "Asia/Anadyr"
    ],
    "UA": [
        "Europe/Simferopol",
        "Europe/Kiev",
        "Europe/Uzhgorod",
        "Europe/Zaporozhye"
    ],
    "RW": [
        "Africa/Kigali"
    ],
    "SA": [
        "Asia/Riyadh"
    ],
    "SB": [
        "Pacific/Guadalcanal"
    ],
    "SC": [
        "Indian/Mahe"
    ],
    "SD": [
        "Africa/Khartoum"
    ],
    "SE": [
        "Europe/Stockholm"
    ],
    "SG": [
        "Asia/Singapore"
    ],
    "SH": [
        "Atlantic/St_Helena"
    ],
    "SI": [
        "Europe/Ljubljana"
    ],
    "SJ": [
        "Arctic/Longyearbyen"
    ],
    "SK": [
        "Europe/Bratislava"
    ],
    "SL": [
        "Africa/Freetown"
    ],
    "SM": [
        "Europe/San_Marino"
    ],
    "SN": [
        "Africa/Dakar"
    ],
    "SO": [
        "Africa/Mogadishu"
    ],
    "SR": [
        "America/Paramaribo"
    ],
    "SS": [
        "Africa/Juba"
    ],
    "ST": [
        "Africa/Sao_Tome"
    ],
    "SV": [
        "America/El_Salvador"
    ],
    "SX": [
        "America/Lower_Princes"
    ],
    "SY": [
        "Asia/Damascus"
    ],
    "SZ": [
        "Africa/Mbabane"
    ],
    "TC": [
        "America/Grand_Turk"
    ],
    "TD": [
        "Africa/Ndjamena"
    ],
    "TF": [
        "Indian/Kerguelen"
    ],
    "TG": [
        "Africa/Lome"
    ],
    "TH": [
        "Asia/Bangkok"
    ],
    "TJ": [
        "Asia/Dushanbe"
    ],
    "TK": [
        "Pacific/Fakaofo"
    ],
    "TL": [
        "Asia/Dili"
    ],
    "TM": [
        "Asia/Ashgabat"
    ],
    "TN": [
        "Africa/Tunis"
    ],
    "TO": [
        "Pacific/Tongatapu"
    ],
    "TR": [
        "Europe/Istanbul"
    ],
    "TT": [
        "America/Port_of_Spain"
    ],
    "TV": [
        "Pacific/Funafuti"
    ],
    "TW": [
        "Asia/Taipei"
    ],
    "TZ": [
        "Africa/Dar_es_Salaam"
    ],
    "UG": [
        "Africa/Kampala"
    ],
    "UM": [
        "Pacific/Midway",
        "Pacific/Wake"
    ],
    "US": [
        "America/New_York",
        "America/Detroit",
        "America/Kentucky/Louisville",
        "America/Kentucky/Monticello",
        "America/Indiana/Indianapolis",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Indiana/Marengo",
        "America/Indiana/Petersburg",
        "America/Indiana/Vevay",
        "America/Chicago",
        "America/Indiana/Tell_City",
        "America/Indiana/Knox",
        "America/Menominee",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/North_Dakota/Beulah",
        "America/Denver",
        "America/Boise",
        "America/Phoenix",
        "America/Los_Angeles",
        "America/Anchorage",
        "America/Juneau",
        "America/Sitka",
        "America/Metlakatla",
        "America/Yakutat",
        "America/Nome",
        "America/Adak",
        "Pacific/Honolulu"
    ],
    "UY": [
        "America/Montevideo"
    ],
    "UZ": [
        "Asia/Samarkand",
        "Asia/Tashkent"
    ],
    "VA": [
        "Europe/Vatican"
    ],
    "VC": [
        "America/St_Vincent"
    ],
    "VE": [
        "America/Caracas"
    ],
    "VG": [
        "America/Tortola"
    ],
    "VI": [
        "America/St_Thomas"
    ],
    "VN": [
        "Asia/Ho_Chi_Minh"
    ],
    "VU": [
        "Pacific/Efate"
    ],
    "WF": [
        "Pacific/Wallis"
    ],
    "WS": [
        "Pacific/Apia"
    ],
    "YE": [
        "Asia/Aden"
    ],
    "YT": [
        "Indian/Mayotte"
    ],
    "ZA": [
        "Africa/Johannesburg"
    ],
    "ZM": [
        "Africa/Lusaka"
    ],
    "ZW": [
        "Africa/Harare"
    ]
  }
};
