import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  FormSelect,
  Card,
  CardBody,
  CardHeader,
  Button,
} from 'shards-react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { client } from '../apolloClient';
import { queries } from '../graphQueries';
import Spinner from 'react-spinner-material';
import Dropzone from 'react-dropzone';
import _ from 'lodash';

import { MEDIA_FILES_APPEND } from '../config';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';

const DailyMenuView = ({ type, file = null }) => {
  let src = null;

  if (file) {
    if (typeof file === 'string') {
      src = `${MEDIA_FILES_APPEND}${file}`;
    } else if (typeof file === 'object') {
      src = URL.createObjectURL(
        new File(file, {
          type: type === 'PDF' ? 'application/pdf' : 'image/png',
        })
      );
    }
  }

  return (
    <>
      {type === 'IMG' && (
        <img src={src} alt="" className="mx-auto border d-block" />
      )}
      {/* { type === 'PDF' && <PDFViewer file={src} className="mx-auto" />} */}
      {type === 'PDF' && (
        <>
          <div
            id="dailymenu-wrapper"
            style={{ maxWidth: '600px', margin: 'auto', width: '90%' }}
          >
            <div
              className="pdf-wrapper"
              style={{
                position: 'relative',
                paddingTop: '141%',
                width: '100%',
              }}
            >
              <object
                data={src}
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                }}
                aria-label="docs"
              ></object>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const DailyMenuForm = ({ username }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currRestaurant, setCurrRestaurant] = useState({});
  const [title, setTitle] = useState('');
  const [menuFile, setMenuFile] = useState(null);
  const [menuFileType, setMenuFileType] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [toDeleteName, setToDeleteName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    client
      .query({
        query: queries.userRestaurants,
        variables: {
          username,
        },
      })
      .then((res) => {
        setRestaurants(res.data.userRestaurants);
        selectRestaurant(res.data.userRestaurants[0]);
      });
  }, []);

  const handleRestauranChange = (evt) => {
    const { id } = evt.target[evt.target.selectedIndex];
    let restaurant = restaurants[0];
    if (Number(id) !== -1) {
      restaurant = restaurants.find(({ id: _id }) => id === _id);
    }
    selectRestaurant(restaurant);
  };

  const selectRestaurant = (restaurant) => {
    setCurrRestaurant(restaurant);
    setIsLoading(true);

    client
      .query({
        query: queries.dailyMenus,
        fetchPolicy: 'network-only',
        variables: {
          restaurantId: restaurant.id,
        },
      })
      .then((res) => {
        if (res.data && res.data.dailyMenu && res.data.dailyMenu.length > 0) {
          const { id, file, fileType, title } = res.data.dailyMenu[0];
          setId(id);
          setTitle(title);
          setMenuFile(file);
          setMenuFileType(fileType);
        } else {
          setId(null);
          setTitle('');
          setMenuFile(null);
          setMenuFileType(null);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleDromMenu = ([file]) => {
    if (!!file) {
      createOrUpdate(file);
    }
  };

  const createOrUpdate = (file) => {
    const mutation = !!id ? queries.updateDailyMenu : queries.creatDailyMenu;
    const variables = {
      file,
      title,
      restaurantId: currRestaurant.id,
    };

    if (!!id) {
      variables.id = id;
    }

    setIsLoading(true);

    client
      .mutate({
        variables,
        mutation,
      })
      .then((res) => {
        const {
          file,
          fileType,
          id
        } = res.data.createOrUpdateDailyMenu.dailyMenu;
        setId(id);
        setMenuFile(file);
        setMenuFileType(fileType);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const updateTitle = (title) => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    client
      .mutate({
        variables: {
          id,
          title,
          restaurantId: currRestaurant.id,
        },
        mutation: queries.updateDailyMenuTitle,
      })
      .then((res) => {
        const {
          file,
          fileType,
          title,
          id
        } = res.data.updateDailyMenuTitle.dailyMenu;
        setId(id);
        setMenuFile(file);
        setTitle(title);
        setMenuFileType(fileType);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => console.log(err.message));
  };

  const mutateOnTitleChange = React.useRef(null);
  React.useEffect(
    (value) => {
      mutateOnTitleChange.current = _.debounce((value) => {
        if (currRestaurant) {
          updateTitle(value);
        }
      }, 900);
    },
    [id, currRestaurant]
  );

  const handleTitleChange = ({ target: { value } }) => {
    setTitle(value);
    mutateOnTitleChange.current(value);
  };

  const resetRestaurant = () => {
    window.location.reload(false);
  };

  const toggleDeleteModal = (
    toDeleteIndex = '',
    toDeleteId = '',
    toDeleteName = ''
  ) => {
    if (modalOpened) {
      setModalOpened(!modalOpened);
    } else {
      setToDeleteId(toDeleteId);
      setToDeleteName(toDeleteName);
      setModalOpened(!modalOpened);
    }
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Container className="pt-4">
        <Card small className="pb-3">
          <CardHeader>
            <Row className="mb-4 mt-3">
              <Col md="5">
                <h4>{t('dashboard.daily_menu') + ' ' + currRestaurant.name}</h4>
              </Col>
              <Col md={{ size: 4, offset: -1 }}>
                <FormSelect
                  className="mt-2"
                  onChange={(e) => handleRestauranChange(e)}
                  defaultValue={currRestaurant.name}
                >
                  <option key={-1} id={-1}>
                    {t('form.selectOneRestaurant')}
                  </option>
                  {restaurants.map((restaurant, index) => (
                    <option key={restaurant.id} id={restaurant.id}>
                      {restaurant.name}
                    </option>
                  ))}
                </FormSelect>
              </Col>
              {id && (
                <Col md={{ size: 3 }} className="text-right">
                  <Button
                    className="btn btn-danger mt-2 ml-2"
                    onClick={() =>
                      toggleDeleteModal(
                        0,
                        id,
                        `menú del día de ${currRestaurant.name}`
                      )
                    }
                  >
                    <i className="material-icons mr-1">delete</i>
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                {isLoading ? (
                  <div className="mx-auto">
                    <Spinner
                      size={80}
                      spinnerColor={'#007BFF'}
                      spinnerWidth={2}
                      visible={true}
                    />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col className="form-group">
                        <div>
                          <label htmlFor="name">
                            {t('table.title')}
                            <span className="required-input"> *</span>
                          </label>
                        </div>
                        <input
                          id="name"
                          className="w-100"
                          type="text"
                          onChange={handleTitleChange}
                          required={true}
                          name="title"
                          value={title}
                        />
                      </Col>
                    </Row>
                    <Dropzone
                      accept={[
                        'image/*',
                        'application/pdf',
                        'application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      ]}
                      multiple={false}
                      onDrop={handleDromMenu}
                    >
                      {({
                        getRootProps: rootProps,
                        getInputProps: inputProps,
                      }) => (
                        <div {...rootProps()}>
                          <input {...inputProps()} />
                          <p className="dropzone-solid-blue">
                            {t('uploadText')}
                          </p>
                          <DailyMenuView type={menuFileType} file={menuFile} />
                        </div>
                      )}
                    </Dropzone>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <ConfirmDeleteModal
        cancelClickFunction={toggleDeleteModal}
        entity="DailyMenu"
        open={modalOpened}
        toggleFunction={toggleDeleteModal}
        toDeleteId={toDeleteId}
        toDeleteName={toDeleteName}
        refetchFunction={resetRestaurant}
        t={t}
      />
    </Container>
  );
};

DailyMenuForm.defaultProps = {};

const mapStateToProps = (state) => ({
  username: state.user.username,
});

const bindActions = (dispatch) => ({});

export default connect(mapStateToProps, bindActions)(DailyMenuForm);
