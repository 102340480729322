import React from 'react';
import Spinner from 'react-spinner-material';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormCheckbox,
  Form,
} from 'shards-react';
import { withTranslation } from 'react-i18next';
import headers from '../data/tables_headers/headers';
import { queries } from '../graphQueries';
import { MEDIA_FILES_APPEND } from '../config';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { client } from '../apolloClient';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import UnsavedChangesModal from '../components/UnsavedChangesModal';
import Select from 'react-select';

import '../shards-dashboard/styles/styles.css';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'white' : '',
  border: '1px solid #007BFF',
  borderRadius: '1em',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '' : '',
  padding: grid,
});

class DishList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      toDeleteName: '',
      toDeleteIndex: '',
      toDeleteId: '',
      menuId: '',
      restaurantId: '',
      menuName: '',
      loading: true,
      statusFilter: false,
      isRestaurant: false,
      canRefetch: true,
      items: [],
      canUpdate: false,
      unsavedModalOpened: false,
      dish: null,
      restaurantDishes: [],
    };
    this.getItems = this.getItems.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleUnsavedModal = this.toggleUnsavedModal.bind(this);
    this.displaySearchList = _.debounce(this.displaySearchList, 500);
  }

  componentDidMount() {
    this.setState(
      {
        restaurantId: this.props.location.state.restaurantId,
        menuName: this.props.location.state.menuName,
        isRestaurant: this.props.location.state.isRestaurant,
        menuId: this.props.location.state.menuId,
        fullMenuId: this.props.location.state.fullMenuId,
      },
      () => {
        this.getItems();
        this.getDishes();
      }
    );
  }

  getItems() {
    const graphQuery = queries.dishesDeprecated;
    const variables = { menuId: this.state.menuId };
    variables.notApproved = this.state.statusFilter;
    client
      .query({ query: graphQuery, variables, fetchPolicy: 'no-cache' })
      .then((res) => {
        this.setState({
          loading: res.loading,
          items: res.data.dishesDeprecated.edges.map((edge) => edge.node),
        });
      })
      .catch((err) => {
        //pass
      });
  }

  getDishes() {
    const graphQuery = queries.dishesDeprecated;
    const variables = {
      menuId: this.state.menuId,
      fullMenuId: this.state.fullMenuId,
      id: this.state.restaurantId,
    };

    variables.notApproved = true;

    client
      .query({ query: graphQuery, variables, fetchPolicy: 'no-cache' })
      .then((res) => {
        this.setState({
          restaurantDishes: res.data.dishesDeprecated.edges.map(
            (edge) => edge.node
          ),
        });
      })
      .catch((err) => {
        //pass
      });
  }

  toggleDeleteModal = (
    toDeleteIndex = '',
    toDeleteId = '',
    toDeleteName = ''
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  toggleUnsavedModal = () => {
    this.setState({ unsavedModalOpened: !this.state.unsavedModalOpened });
  };

  search(event) {
    if (!event.target.value) {
      this.setState({ textToSearch: '', loading: false, searchList: false });
    } else {
      this.setState({ textToSearch: event.target.value });
      if (this.state.textToSearch.length > 2) {
        this.displaySearchList();
      } else {
        this.setState({ searchList: false });
      }
    }
  }

  displaySearchList() {
    this.setState({ searchList: this.state.textToSearch.length > 2 });
  }

  async deleteItem(index, id) {
    const mutation = queries[headers[this.props.tableType].deleteMutation];
    client
      .mutate({
        variables: { id },
        mutation,
      })
      .then((res, err) => {
        this.toggleDeleteModal();
        this.refetch();
      });
  }

  updateItems = () => {
    const variables = {
      ids: this.state.items.map((item) => item.id),
      menu: this.state.menuId,
    };
    const mutation = queries.updateDishesOrder;
    client
      .mutate({ variables, mutation })
      .then((res) =>
        this.setState({ canUpdate: false }, () => this.getItems())
      );
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState(
      {
        items,
      },
      () => this.setState({ canUpdate: true })
    );
  }

  handleDishChange(val) {
    const dish = val.id;
    this.setState({
      dish: dish,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const mutation = queries.addDishMenu;
    const variables = { id: this.state.menuId, dish: this.state.dish };
    client
      .mutate({
        variables,
        mutation,
      })
      .then((res, err) => {
        if (res.data.addDishMenu.ok) {
          this.getItems();
          this.getDishes();
          this.setState({ dish: null });
        }
      });
  };

  render() {
    this.t = this.props.t;
    const capitalize = (s) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    const stateForLinks =
      this.props.tableType === 'dishes'
        ? {
            menuId: this.props.location.state.menuId,
            restaurantId: this.state.restaurantId,
            menuName: this.state.menuName,
          }
        : {};
    return (
      <Container fluid className="main-content-container px-4">
        <Row
          style={{ justifyContent: 'space-between' }}
          noGutters
          className="ml-3 page-header py-4 align-items-center space-justify-content-between"
        >
          <h3 className="">
            {this.props.tableType === 'dishes'
              ? this.t('dishesOfMenu') + ' ' + this.state.menuName
              : this.t(this.props.title)}
          </h3>
          <Col md={{ size: 2 }}>
            <Button
              tag={Link}
              className="btn-success float-right"
              to={{
                pathname: `/${headers[this.props.tableType].formComponent}`,
                state: {
                  ...stateForLinks,
                  dishOrder: this.state.items.length,
                  isRestaurant: this.state.isRestaurant,
                },
              }}
            >
              {this.t('new')}
            </Button>
          </Col>
          <Col md={{ size: 3 }} style={{ marginTop: 8 }}>
            <FormCheckbox
              toggle
              checked={this.state.statusFilter}
              onChange={() =>
                this.setState({ statusFilter: !this.state.statusFilter }, () =>
                  this.getItems()
                )
              }
            >
              {this.t('statusFilter')}
            </FormCheckbox>
          </Col>
        </Row>

        <Container fluid>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardBody className="p-0">
                  {this.state.loading && (
                    <Row>
                      <Col sm={12} className="p-4">
                        <div className="spinner-center">
                          <Spinner
                            size={80}
                            spinnerColor={'#007BFF'}
                            spinnerWidth={2}
                            visible={true}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm={12}>
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {this.state.items.map((cursor, index) => (
                                <Draggable
                                  key={cursor.id}
                                  draggableId={cursor.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="row"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <Col md="6">
                                        <span>{index + 1}.</span>
                                        <i className="material-icons menu-options">
                                          swap_vert
                                        </i>
                                        <span className="menu-title">
                                          {cursor.nameEs}
                                        </span>
                                      </Col>
                                      <Col md="2">
                                        <span>
                                          <img
                                            className="allergen-icon"
                                            src={
                                              MEDIA_FILES_APPEND + cursor.image
                                            }
                                            alt=""
                                          />
                                        </span>
                                      </Col>
                                      <Col md="1">
                                        <span className="">
                                          <i className="material-icons">
                                            visibility
                                          </i>
                                          {cursor.views}
                                        </span>
                                      </Col>
                                      <Col md="1">
                                        <span className="">
                                          {cursor.status}
                                        </span>
                                      </Col>
                                      <Col
                                        md="2
                                    "
                                      >
                                        <Button
                                          className="btn btn-danger float-right"
                                          disabled={this.state.isRestaurant}
                                          onClick={() =>
                                            this.toggleDeleteModal(
                                              index,
                                              cursor.id,
                                              cursor.nameEs
                                            )
                                          }
                                        >
                                          <i className="material-icons mr-1">
                                            delete
                                          </i>
                                          {this.t('table.delete')}
                                        </Button>
                                        <Button
                                          className="btn mr-2 float-right"
                                          tag={Link}
                                          to={{
                                            pathname: `/${
                                              headers[this.props.tableType]
                                                .formComponent
                                            }`,
                                            state: {
                                              extraInfo: { ...stateForLinks },
                                              entity: {
                                                fetch: true,
                                                id: cursor.id,
                                              },
                                              isRestaurant: this.state
                                                .isRestaurant,
                                            },
                                          }}
                                        >
                                          <i className="material-icons mr-1">
                                            edit
                                          </i>
                                          {this.t('table.edit')}
                                        </Button>
                                      </Col>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Col>
                  </Row>
                  {!this.state.isRestaurant && (
                    <Form onSubmit={this.handleSubmit}>
                      <Row className="p-4">
                        <Col sm={9}>
                          <Select
                            className="mt-2"
                            defaultValue={{
                              nameEs: this.t('select.dish'),
                              id: -1,
                            }}
                            onChange={(e) => this.handleDishChange(e)}
                            getOptionLabel={(option) => `${option.nameEs}`}
                            getOptionValue={(option) => `${option.id}`}
                            options={this.state.restaurantDishes}
                          />
                        </Col>
                        <Col sm={3} className="pt-2">
                          <Button
                            pill
                            theme="success"
                            onClick={this.handleSubmit}
                          >
                            <i className="material-icons mr-1">add</i>
                            {this.t('add.dish')}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.canUpdate && (
            <Button
              outline
              theme="info"
              className="float-left"
              onClick={() => this.updateItems()}
            >
              {this.t('form.update')}
            </Button>
          )}
          {this.props.tableType === 'dishes' && !this.state.canUpdate && (
            <Button
              outline
              theme="danger"
              className="float-right btn mr-2"
              tag={Link}
              to={{
                pathname: `/restaurant`,
                state: {
                  entity: { fetch: true, id: this.state.restaurantId },
                  isRestaurant: this.state.isRestaurant,
                },
              }}
            >
              {this.t('back')}
            </Button>
          )}
          {this.props.tableType === 'dishes' && this.state.canUpdate && (
            <Button
              outline
              theme="danger"
              className="float-right btn mr-2"
              onClick={() => this.toggleUnsavedModal()}
            >
              {this.t('back')}
            </Button>
          )}
        </Container>
        <ConfirmDeleteModal
          cancelClickFunction={this.toggleDeleteModal}
          entity={capitalize(this.props.entity)}
          open={this.state.modalOpened}
          toggleFunction={this.toggleDeleteModal}
          toDeleteId={this.state.toDeleteId}
          toDeleteName={this.state.toDeleteName}
          refetchFunction={this.getItems}
          t={this.t}
          secondaryId={parseInt(this.state.menuId)}
        />
        <UnsavedChangesModal
          open={this.state.unsavedModalOpened}
          toggleFunction={this.toggleUnsavedModal}
          restaurantId={this.state.restaurantId}
          isRestaurant={this.state.isRestaurant}
          t={this.t}
        />
      </Container>
    );
  }
}
export default withTranslation()(DishList);
