import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
} from "shards-react";
import { queries } from '../graphQueries';
import { client } from '../apolloClient';
import React from "react";

import "../shards-dashboard/styles/styles.css"

const ConfirmCloneModal = ({ open, toggleFunction, toCloneName, cancelClickFunction, entity, refetchFunction, toCloneId, t }) => {

  const cloneItem = (id) => {
    const mutation = queries[`clone${entity}`]
    client.mutate({
      variables: { id },
      mutation
    }).then((res, err) => {
      client.resetStore().then(res => {
        toggleFunction();
        if (refetchFunction) {
          refetchFunction();
        }
      })
    })
  }

  return (
    <Modal size="sm" centered={true} open={open} toggle={toggleFunction}>
      <ModalHeader>{t("cloneModalHeader") + " " + toCloneName + "?"}</ModalHeader>
      <ModalBody>
        <p>{t("confirmClone")}</p>
      </ModalBody>
      <ModalFooter>
        <Button outline theme="info" onClick={() => toggleFunction()}><i className="material-icons mr-1">cancel</i>{t('cancel')}</Button>
        <Button theme="info"  onClick={() => cloneItem(toCloneId)}><i className="material-icons mr-1">filter_none</i>{t('table.clone')}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmCloneModal