import React from "react";
import { Link } from "react-router-dom";
import { client } from '../../apolloClient';
import { withTranslation } from "react-i18next";
import { queries } from '../../graphQueries'
import { Query } from 'react-apollo';
import { Multiselect } from 'react-widgets';
import {
  CardHeader,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormCheckbox,
  FormSelect
} from "shards-react";
import {
  USER_BASIC,
  USER_FREE,
  USER_PRO,
  USER_PREMIUM,
  USER_LAMB_WESTON,
  USER_MARKET
} from '../../config';

import "../../shards-dashboard/styles/login.css"

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: {
        id: '',
        name: '',
        email: '',
        userPlan: '',
        hosteltactilActive: false,
        agoraActive: false,
        revoActive: false,
      },
      stateRequired: false,
      loading: false,
      isEdit: false,
    }

    this.t = this.props.t;
  }

  componentWillMount() {
    try {
      
      if (this.props.location.state && this.props.location.state.entity) {
        console.log(this.props.location.state);
        const variables = { id: this.props.location.state.entity.id };
        const query = queries.company;
        client.query({ variables, query, fetchPolicy: 'network-only' }).then((res) => {
          this.setState({
            company: {
              ...res.data.company,
            },
            isEdit: true 
          });
        });
        
      }
    } catch (err) { console.log(err) }
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState(prevState => ({
      company: {
        ...prevState.company,
        [name]: value
      }
    }));
    
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const mutation = this.state.isEdit ? queries.updateCompany : queries.createCompany;
    const variables = {
      input: {
        name: this.state.company.name,
        email: this.state.company.email,
        userPlan: this.state.company.userPlan,
        hosteltactilActive: this.state.company.hosteltactilActive,
        agoraActive: this.state.company.agoraActive,
        revoActive: this.state.company.revoActive
      }
    };

    if (this.state.isEdit) {
      variables.id = this.state.company.id;
    }
    client.mutate({
      variables,
      mutation
    }).then((res, err) => {
      this.props.history.push("/companies")
    })
  }


  render() {
    return (
      <Container className="pt-4">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="float-left m-0">{this.state.isEdit ? `${this.t('form.editing')} ${this.state.company.name}` : this.t('form.newFranchise')}</h6>
            <Button outline pill theme="danger" className="float-right" tag={Link} to="/companies">Back</Button>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={this.handleSubmit}>
                    <Row form>
                      <Col md="6">
                        <label htmlFor="name">{this.t('table.name')}</label>
                        <FormInput
                          id="name"
                          name="name"
                          value={this.state.company.name}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Col>
                      <Col md="6">
                        <label htmlFor="email">{this.t('userForm.email')}</label>
                        <FormInput
                          id="email"
                          name="email"
                          type="email"
                          value={this.state.company.email}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="3">
                        <label htmlFor="userPlan">
                          {this.props.t('userForm.plan')}
                          <span className="required-input"> *</span>
                        </label>
                        <FormSelect
                          onChange={this.handleChange}
                          id="user-plan"
                          name="userPlan"
                          value={this.state.company.userPlan}
                        >
                          <option key={0} id={0} value="">
                            {this.props.t('userForm.plan')}
                          </option>
                          <option key={1} id={1} value={USER_BASIC}>
                            {this.props.t('userForm.basic')}
                          </option>
                          <option key={2} id={2} value={USER_PRO}>
                            {this.props.t('userForm.pro')}
                          </option>
                          <option key={3} id={3} value={USER_PREMIUM}>
                            {this.props.t('userForm.premium')}
                          </option>
                          <option key={4} id={4} value={USER_FREE}>
                            {this.props.t('userForm.free')}
                          </option>
                          <option key={5} id={5} value={USER_LAMB_WESTON}>
                            {this.props.t('userForm.lamb.weston')}
                          </option>
                          <option key={6} id={6} value={USER_MARKET}>
                            {this.props.t('userForm.userMarket')}
                          </option>
                        </FormSelect>
                      </Col>
                      <Col md="3" className="mt-4">
                        <FormCheckbox
                          checked={this.state.company.hosteltactilActive}
                          onChange={() =>
                            this.setState((prevState) => ({
                              company: {
                                ...prevState.company,
                                hosteltactilActive: !this.state.company.hosteltactilActive,
                              },
                            }))
                          }
                        >
                          {this.props.t('userForm.ht_activo')}
                        </FormCheckbox>
                      </Col>
                      <Col md="3" className="mt-4">
                        <FormCheckbox
                          checked={this.state.company.agoraActive}
                          onChange={() =>
                            this.setState((prevState) => ({
                              company: {
                                ...prevState.company,
                                agoraActive: !this.state.company.agoraActive,
                              },
                            }))
                          }
                        >
                          {this.props.t('userForm.agoraActive')}
                        </FormCheckbox>
                      </Col>
                      <Col md="3" className="mt-4">
                        <FormCheckbox
                          checked={this.state.company.revoActive}
                          onChange={() =>
                            this.setState((prevState) => ({
                              company: {
                                ...prevState.company,
                                revoActive: !this.state.company.revoActive,
                              },
                            }))
                          }
                        >
                          {this.props.t('userForm.revoActive')}
                        </FormCheckbox>
                      </Col>
                    </Row>
                    <Row form className="mt-2">
                      <Col md="2">
                        <Button type="submit">{this.state.isEdit ? this.t('form.update') : this.t('form.create')}</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    )
  }
}

export default (withTranslation()(CompanyForm))