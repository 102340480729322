import React from 'react';
import Spinner from 'react-spinner-material';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormInput,
  FormCheckbox,
  FormSelect,
} from 'shards-react';
import { withTranslation } from 'react-i18next';
import headers from '../data/tables_headers/headers';
import { queries } from '../graphQueries';
import { MEDIA_FILES_APPEND } from '../config';
import { client } from '../apolloClient';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import ConfirmCloneModal from '../components/ConfirmCloneModal';
import CantDeleteTooltip from '../components/CantDeleteTooltip';

import '../shards-dashboard/styles/styles.css';

class PaginatedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      toDeleteName: '',
      toDeleteIndex: '',
      toDeleteId: '',
      modalCloneOpened: false,
      toCloneName: '',
      toCloneIndex: '',
      toCloneId: '',
      restaurantId: '',
      menuName: '',
      sortingColumn: '',
      reverseSorting: false,
      searchList: false,
      textToSearch: '',
      loading: false,
      statusFilter: false,
      bulkEditIds: [],
      isRestaurant: false,
    };
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleCloneModal = this.toggleCloneModal.bind(this);
    this.displaySearchList = _.debounce(this.displaySearchList, 500);
  }

  componentDidMount() {
    if (this.props.tableType === 'dishes') {
      this.setState({
        restaurantId: this.props.location.state.restaurantId,
        menuName: this.props.location.state.menuName,
        isRestaurant: this.props.location.state.isRestaurant,
      });
    }
  }

  toggleDeleteModal = (
    toDeleteIndex = '',
    toDeleteId = '',
    toDeleteName = ''
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  toggleCloneModal = (toCloneIndex = '', toCloneId = '', toCloneName = '') => {
    if (this.state.modalCloneOpened) {
      this.setState({
        modalCloneOpened: !this.state.modalCloneOpened,
      });
    } else {
      this.setState(
        {
          toCloneIndex,
          toCloneId,
          toCloneName,
        },
        () => this.setState({ modalCloneOpened: !this.state.modalCloneOpened })
      );
    }
  };

  sortColumn = (column, data) => {
    const prop =
      column === 'name' && this.props.tableType === 'dishes'
        ? 'nameEs'
        : column;
    if (this.state.sortingColumn === column) {
      data[Object.keys(data)[0]].edges.reverse();
      this.setState({ reverseSorting: !this.state.reverseSorting });
    } else {
      let nabo;
      if (column !== 'city') {
        nabo = _.sortBy(data[Object.keys(data)[0]].edges, (o) =>
          typeof o.node[prop] === 'string'
            ? o.node[prop].toLowerCase()
            : o.node[prop]
        );
      } else {
        nabo = _.sortBy(data[Object.keys(data)[0]].edges, (o) =>
          o.node.city ? o.node.city.name : null
        );
      }
      data[Object.keys(data)[0]].edges = nabo;
      if (this.state.reverseSorting) {
        this.setState({ reverseSorting: !this.state.reverseSorting });
      }
    }
    return data;
  };

  search(event) {
    if (!event.target.value) {
      this.setState({ textToSearch: '', loading: false, searchList: false });
    } else {
      this.setState({ textToSearch: event.target.value });
      if (this.state.textToSearch.length > 2) {
        this.displaySearchList();
      } else {
        this.setState({ searchList: false });
      }
    }
  }

  bulkStatusChange() {
    const statusArray = ['AP', 'WA', 'RJ'];
    const index = document.getElementById('bulkStatus').selectedIndex;
    client
      .mutate({
        variables: { ids: this.state.bulkEditIds, status: statusArray[index] },
        mutation:
          this.props.entity === 'dish'
            ? queries.bulkEditDishStatus
            : queries.bulkEditRestaurantStatus,
      })
      .then((res, err) => {
        this.setState({ bulkEditIds: [] });
      });
  }

  displaySearchList() {
    this.setState({ searchList: this.state.textToSearch.length > 2 });
  }

  async deleteItem(index, id) {
    const mutation = queries[headers[this.props.tableType].deleteMutation];
    client
      .mutate({
        variables: { id },
        mutation,
      })
      .then((res, err) => {
        this.toggleDeleteModal();
        this.refetch();
      });
  }

  render() {
    this.t = this.props.t;
    let graphQuery;
    let variables;
    if (!this.state.searchList) {
      graphQuery = queries[this.props.tableType];
      variables =
        this.props.tableType !== 'dishes'
          ? { first: 10 }
          : { menuId: this.props.location.state.menuId };
    } else {
      if (this.props.tableType === 'restaurants') {
        graphQuery = queries.findRestaurantsByText
        variables = { text: this.state.textToSearch };
      } else if(this.props.tableType === 'dishes') {
        graphQuery = queries.findDishesByText;
        variables = { text: this.state.textToSearch };
      } else {
        graphQuery = queries[this.props.tableType];
        variables = { text: this.state.textToSearch, first: 10 };
      }
        
      if (this.props.tableType === 'dishes') {
        variables.menuId = this.props.location.state.menuId;
      }
    }
    if (this.props.tableType !== 'markets')
      variables.notApproved = this.state.statusFilter;
    const tableHeaders = headers[this.props.tableType].headers;
    const dataKeys = headers[this.props.tableType].data;
    const capitalize = (s) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    const stateForLinks =
      this.props.tableType === 'dishes'
        ? {
            menuId: this.props.location.state.menuId,
            restaurantId: this.state.restaurantId,
            menuName: this.state.menuName,
          }
        : {};
    return (
      <Container fluid className="main-content-container px-4">
        <Row
          style={{ justifyContent: 'space-between' }}
          noGutters
          className="ml-3 page-header py-4 align-items-center space-justify-content-between"
        >
          <Row>
            <Col md={{ size: 3 }}>
              <h3 className="mr-5">
                {this.props.tableType === 'dishes'
                  ? this.t('dishesOfMenu') + ' ' + this.state.menuName
                  : this.t(this.props.title)}
              </h3>
            </Col>
            <Col md={{ size: 6, offset: 3 }}>
              <span
                style={{
                  visibility:
                    this.state.bulkEditIds.length > 0 ? 'visible' : 'hidden',
                  justifySelf: 'flex-start',
                }}
              >
                <FormSelect
                  style={{ width: 125 }}
                  className="mr-2 d-inline"
                  id="bulkStatus"
                  name="bulkStatus"
                  defaultValue={0}
                >
                  {[this.t('AP'), this.t('WA'), this.t('RJ')].map(
                    (status, index) => (
                      <option key={index} value={status} id={index}>
                        {status}
                      </option>
                    )
                  )}
                </FormSelect>
                <Button onClick={() => this.bulkStatusChange()}>
                  <i className="material-icons">save</i>
                </Button>
              </span>
            </Col>
          </Row>
          <Col md={{ size: 3 }}>
            <FormInput
              placeholder={this.t('searchPlaceholder')}
              name="search"
              id="search"
              onChange={(e) => this.search(e)}
              value={this.state.textToSearch}
            />
          </Col>
          { this.props.tableType !=='markets' && this.props.tableType !=='companies' && (
            <Col md={{ size: 3 }} style={{ marginTop: 8 }}>
              <FormCheckbox
                toggle
                checked={this.state.statusFilter}
                onChange={() =>
                  this.setState({ statusFilter: !this.state.statusFilter })
                }
              >
                {this.t('statusFilter')}
              </FormCheckbox>
            </Col>
          )}
        </Row>
        <Query
          query={graphQuery}
          variables={variables}
          fetchPolicy="network-only"
        >
          {({ loading, error, data, refetch, fetchMore }) => {
            if (loading || this.state.loading)
              return (
                <div className="spinner-loader-listing">
                  <Spinner
                    size={80}
                    spinnerColor={'#007BFF'}
                    spinnerWidth={2}
                    visible={true}
                  />
                </div>
              );
            if (error) return `Error ${error.message}`;
            this.refetch = refetch;
            return (
              <Container fluid>
                <Row>
                  <Col>
                    <Card small className="mb-4">
                      <CardBody className="p-0">
                        <table className="table mb-0">
                          <thead className="bg-light">
                            <tr className="table-hover">
                              <th scope="col" className="border-0">
                                #
                              </th>
                              {tableHeaders.map((header, index) => (
                                <th
                                  key={index}
                                  scope="col"
                                  className="border-0 cursor-pointer"
                                  onClick={() => {
                                    const key = header.text.split('.')[1];
                                    this.setState({ loading: true });
                                    this.sortColumn(key, data);
                                    this.setState({
                                      loading: false,
                                      sortingColumn: key,
                                    });
                                  }}
                                >
                                  {this.state.sortingColumn ===
                                    header.text.split('.')[1] &&
                                    !this.state.reverseSorting && (
                                      <i className="material-icons">
                                        arrow_drop_down
                                      </i>
                                    )}
                                  {this.state.sortingColumn ===
                                    header.text.split('.')[1] &&
                                    this.state.reverseSorting && (
                                      <i className="material-icons">
                                        arrow_drop_up
                                      </i>
                                    )}
                                  {this.t(header.text)}
                                </th>
                              ))}
                              <th scope="col" className="border-0 text-center">
                                <Button
                                  tag={Link}
                                  className="btn-success float-right"
                                  to={{
                                    pathname: `/${
                                      headers[this.props.tableType]
                                        .formComponent
                                    }`,
                                    state: stateForLinks,
                                  }}
                                >
                                  {this.t('new')}
                                </Button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data[Object.keys(data)[0]].edges.map(
                              (cursor, index) => {
                                const bulkEditId = this.state.bulkEditIds.indexOf(
                                  cursor.node.id
                                );
                                return (
                                  <tr key={index} className="table-hover">
                                    <td>
                                      {index + 1}
                                      {!this.state.isRestaurant && (
                                        <FormCheckbox
                                          className="d-inline ml-2"
                                          checked={bulkEditId > -1}
                                          onChange={() => {
                                            if (bulkEditId > -1) {
                                              const newArray = this.state
                                                .bulkEditIds;
                                              newArray.splice(bulkEditId, 1);
                                              this.setState((prevState) => ({
                                                bulkEditIds: newArray,
                                              }));
                                            } else {
                                              this.setState((prevState) => ({
                                                bulkEditIds: [
                                                  ...this.state.bulkEditIds,
                                                  cursor.node.id,
                                                ],
                                              }));
                                            }
                                          }}
                                        />
                                      )}
                                    </td>
                                    {dataKeys.map((key, index) => {
                                      let info = '';
                                      try {
                                        info = key
                                          .split('.')
                                          .reduce(function (c, prop) {
                                            return c[prop];
                                          }, cursor.node);
                                      } catch (err) {}
                                      if (
                                        typeof info === 'string' &&
                                        info.endsWith('png')
                                      )
                                        return (
                                          <td key={index}>
                                            <img
                                              className="allergen-icon"
                                              src={MEDIA_FILES_APPEND + info}
                                              alt=""
                                            />
                                          </td>
                                        );
                                      else return <td key={index}>{info}</td>;
                                    })}
                                    <td className="text-right">
                                      <Button
                                        className="btn mr-2"
                                        tag={Link}
                                        to={{
                                          pathname: `/${
                                            headers[this.props.tableType]
                                              .formComponent
                                          }`,
                                          state: {
                                            extraInfo: { ...stateForLinks },
                                            entity: {
                                              fetch: true,
                                              id: data[Object.keys(data)[0]].edges[index].node.id,
                                            },
                                            isRestaurant: this.state.isRestaurant,
                                          },
                                        }}
                                      >
                                        <i className="material-icons mr-1">
                                          edit
                                        </i>
                                        {this.t('table.edit')}
                                      </Button>
                                      {this.props.tableType ===
                                        'restaurants' && (
                                        <Button
                                          className="btn btn-info mr-2"
                                          onClick={() =>
                                            this.toggleCloneModal(
                                              index,
                                              data[Object.keys(data)[0]].edges[
                                                index
                                              ].node.id,
                                              data[Object.keys(data)[0]].edges[
                                                index
                                              ].node.name
                                            )
                                          }
                                        >
                                          <i className="material-icons mr-1">
                                            filter_none
                                          </i>
                                          {this.t('table.clone')}
                                        </Button>
                                      )}
                                      <CantDeleteTooltip
                                        t={this.props.t}
                                        message={'tooltip.cantdeleterestaurant'}
                                        condition={cursor.node.hasMenus}
                                        children={
                                          <Button
                                            className="btn btn-danger"
                                            onClick={() =>
                                              this.toggleDeleteModal(
                                                index,
                                                data[Object.keys(data)[0]]
                                                  .edges[index].node.id,
                                                data[Object.keys(data)[0]]
                                                  .edges[index].node.name
                                              )
                                            }
                                          >
                                            <i className="material-icons mr-1">
                                              delete
                                            </i>
                                            {this.t('table.delete')}
                                          </Button>
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {this.props.tableType === 'dishes' && (
                  <Button
                    outline
                    theme="danger"
                    className="float-right btn mr-2"
                    tag={Link}
                    to={{
                      pathname: `/restaurant`,
                      state: {
                        entity: { fetch: true, id: this.state.restaurantId },
                        isRestaurant: this.state.isRestaurant,
                      },
                    }}
                  >
                    {this.t('back')}
                  </Button>
                )}

                {data[Object.keys(data)[0]].pageInfo.hasNextPage && (
                  <Row className="pb-3">
                    <Col className="text-center" md="12">
                      <Button
                        className=""
                        theme="info"
                        onClick={() =>
                          fetchMore({
                            variables: {
                              first: 10,
                              cursor:
                                data[Object.keys(data)[0]].pageInfo.endCursor,
                            },
                            updateQuery: (
                              previousResult,
                              { fetchMoreResult }
                            ) => {
                              const newEdges =
                                fetchMoreResult[this.props.tableType].edges;
                              const pageInfo =
                                fetchMoreResult[this.props.tableType].pageInfo;

                              return newEdges.length
                                ? {
                                    [this.props.tableType]: {
                                      __typename:
                                        previousResult[this.props.tableType]
                                          .__typename,
                                      edges: [
                                        ...previousResult[this.props.tableType]
                                          .edges,
                                        ...newEdges,
                                      ],
                                      pageInfo,
                                    },
                                  }
                                : previousResult;
                            },
                          })
                        }
                      >
                        {this.t('loadMore')}
                      </Button>
                      {[25, 50, 100, 250, 500].reverse().map((e, index) => (
                        <p
                          key={index}
                          className="btn btn-info float-right mr-3"
                          onClick={() => {
                            this.setState({ loading: true });
                            fetchMore({
                              variables: {
                                first: e,
                                cursor:
                                  data[Object.keys(data)[0]].pageInfo.endCursor,
                              },
                              updateQuery: (
                                previousResult,
                                { fetchMoreResult }
                              ) => {
                                const newEdges =
                                  fetchMoreResult[this.props.tableType].edges;
                                const pageInfo =
                                  fetchMoreResult[this.props.tableType]
                                    .pageInfo;

                                return newEdges.length
                                  ? {
                                      [this.props.tableType]: {
                                        __typename:
                                          previousResult[this.props.tableType]
                                            .__typename,
                                        edges: [
                                          ...previousResult[
                                            this.props.tableType
                                          ].edges,
                                          ...newEdges,
                                        ],
                                        pageInfo,
                                      },
                                    }
                                  : previousResult;
                              },
                            });
                            this.setState({ loading: false });
                          }}
                        >
                          {e}
                        </p>
                      ))}
                    </Col>
                  </Row>
                )}
              </Container>
            );
          }}
        </Query>
        <ConfirmCloneModal
          cancelClickFunction={this.toggleCloneModal}
          entity={capitalize(this.props.entity)}
          open={this.state.modalCloneOpened}
          toggleFunction={this.toggleCloneModal}
          toCloneId={this.state.toCloneId}
          refetchFunction={this.refetch}
          toCloneName={this.state.toCloneName}
          t={this.t}
        />
        <ConfirmDeleteModal
          cancelClickFunction={this.toggleDeleteModal}
          entity={capitalize(this.props.entity)}
          open={this.state.modalOpened}
          toggleFunction={this.toggleDeleteModal}
          toDeleteId={this.state.toDeleteId}
          refetchFunction={this.refetch}
          toDeleteName={this.state.toDeleteName}
          t={this.t}
        />
      </Container>
    );
  }
}
export default withTranslation()(PaginatedTable);
