import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
  FormCheckbox
} from "shards-react";
import React from "react";

import "../shards-dashboard/styles/styles.css"

class FranchiseModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurants: this.props.restaurants.map((value)=>value.node.id),
      franchises: this.props.restaurants.map((value)=>value.node),
    }
    this.t = this.props.t;
    this.baseState = this.state;
    //this.addRestaurantsToList = this.addRestaurantsToList.bind(this);
  }

  handleChange = event => {
    const { name, value } = event.target;
      this.setState(prevState => ({
        price: {
          ...prevState.price,
          [name]: value.toString()
        }
      }));
  }

  addRestaurantList(event, restaurants) {
    console.log(restaurants);
    this.props.addRestaurantsToList(event, restaurants);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.addRestaurantList(e, this.state.restaurants);
  }

  handleFranchiseOnChange = (restaurant, restaurantIndex) => {
    if (restaurantIndex > -1) {
      const restaurants = this.state.restaurants;
      restaurants.splice(restaurantIndex, 1);
      this.setState((prevState) => ({
        ...prevState,
        restaurants:  restaurants,
      }));
    } else {
      this.setState((prevState) => ({
        restaurants: [
          ...prevState.restaurants,
          restaurant.id,
        ],
      }));
    }
  };

  render() {
    return (
      <Modal centered={true} open={this.props.modalToggled} toggle={this.props.toggleModal}>
        <ModalHeader>{this.t('priceForm.selectFranchises')}</ModalHeader>
        <ModalBody className="pt-0">
          {this.props.restaurants.map((restaurant, index) => {
            const indexOfRestaurant = this.state.restaurants.indexOf(restaurant.node.id);
              return (
                <Row>
                  <Col>
                    <FormCheckbox
                      key={`franchise-${index}`}
                      className="d-inline mr-2"
                      checked={indexOfRestaurant > -1}
                      onChange={() =>
                        this.handleFranchiseOnChange(
                          restaurant.node,
                          indexOfRestaurant
                        )
                      }
                    >
                      {restaurant.node.name}
                    </FormCheckbox>
                  </Col>
                </Row>
              );
            })
          }
          <div className="mt-3">
            <Button onClick={(e) => this.handleSubmit(e)} className="float-left">{this.t('form.save')}</Button>
            <Button outline pill theme="info" className="float-right" onClick={(e) => this.props.toggleModal(e)}><i className="material-icons mr-1">cancel</i>{this.t('cancel')}</Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}
export default FranchiseModalForm;
