import React from 'react';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { client } from '../../apolloClient';
import { withTranslation } from 'react-i18next';
import { queries } from '../../graphQueries';
import {
  CardHeader,
  Card,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
} from 'shards-react';
import { MEDIA_FILES_APPEND } from '../../config';

import '../../shards-dashboard/styles/login.css';

class AllergenForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allergen: {
        name: '',
        icon: [],
        id: '',
      },
      loading: false,
      isEdit: false,
      errorRequiredIcon: false,
    };
    this.onDrop = this.onDrop.bind(this);
    this.t = this.props.t;
  }

  componentWillMount() {
    try {
      if (this.props.location.state.entity) {
        this.setState({
          allergen: { ...this.props.location.state.entity },
          isEdit: true,
        });
      }
    } catch (err) {}
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      allergen: {
        ...prevState.allergen,
        [name]: value,
      },
    }));
  };

  onDrop = (icon) => {
    this.setState((prevState) => ({
      errorRequiredIcon: false,
      allergen: {
        ...prevState.allergen,
        icon: icon,
      },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.allergen.icon.length === 0) {
      this.setState({ errorRequiredIcon: true });
    } else {
      if (this.state.errorRequiredIcon) {
        this.setState({ errorRequiredIcon: false });
      }
      const mutation = this.state.isEdit
        ? queries.updateAllergen
        : queries.createAllergen;
      const variables = {
        name: this.state.allergen.name,
        icon:
          typeof this.state.allergen.icon === 'object'
            ? this.state.allergen.icon[0]
            : this.state.allergen.icon,
      };
      if (this.state.isEdit) {
        variables.id = parseInt(this.state.allergen.id);
      }
      client
        .mutate({
          variables,
          mutation,
        })
        .then((res, err) => {
          this.props.history.push('/allergens');
        });
    }
  };

  render() {
    return (
      <Container className="pt-4">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">
              {this.state.isEdit
                ? `${this.t('form.editing')} ${this.state.allergen.name}`
                : this.t('form.newAllergen')}
            </h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={this.handleSubmit}>
                    <Row form>
                      <Col md="4">
                        <label htmlFor="name">{this.t('table.name')}</label>
                        <FormInput
                          id="name"
                          name="name"
                          value={this.state.allergen.name}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </Col>
                      <Col md={{ size: 7, offset: 1 }}>
                        <label>{this.t('allergen.iconUpload')}</label>
                        <Dropzone
                          accept="image/jpeg, image/png"
                          multiple={false}
                          onDrop={this.onDrop}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {this.state.allergen.icon.length === 0 && (
                                  <p className="dropzone-solid-blue">
                                    {this.t('uploadText')}
                                  </p>
                                )}
                                {typeof this.state.allergen.icon ===
                                  'object' && (
                                  <img
                                    style={{ width: '50px' }}
                                    src={window.URL.createObjectURL(
                                      new File(this.state.allergen.icon, {
                                        type: 'image/png',
                                      })
                                    )}
                                    alt=""
                                  />
                                )}
                                {typeof this.state.allergen.icon ===
                                  'string' && (
                                  <img
                                    src={
                                      MEDIA_FILES_APPEND +
                                      this.state.allergen.icon
                                    }
                                    alt=""
                                  />
                                )}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {this.state.errorRequiredIcon && (
                          <p className="error-required-icon">
                            {this.t('uploadRequired')}
                          </p>
                        )}
                      </Col>
                    </Row>
                    <Button type="submit" className="float-left mt-2">
                      {this.state.isEdit
                        ? this.t('form.update')
                        : this.t('form.create')}
                    </Button>
                    <Button
                      className="btn-danger float-right mt-2"
                      tag={Link}
                      to="/allergens"
                    >
                      {this.t('back')}
                    </Button>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    );
  }
}

export default withTranslation()(AllergenForm);
