import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { verifyLogin } from './services';
import { setUserData } from './actions/userActions';

class AuthRequired extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      continue: false,
      loading: false
    }
  }

  async componentWillMount() {
    let redirectToLogin;
    this.setState({ loading: true });
    const verifyRes = await verifyLogin();
    // Compruebo que no esta en /login para no crear un loop infinito de reloads
    if (!verifyRes.token && this.props.render.props.location.pathname !== '/login') {
      redirectToLogin = true;
      this.setState({ redirectToLogin, loading: false });
    } else if (verifyRes.token) {
      this.props.setUserData(verifyRes.user);
      this.setState({ loading: false });
    } else {
      this.setState({loading: false})
    }
  }

  render() {
    if (this.state.loading) {
      return (<div></div>)    
    } else {
      if (this.state.redirectToLogin) {
        return (<Redirect to='/login' />);
      } else {
        return (this.props.render);
      }
    }
  }
}

const mapStateToProps = state => ({
  // userEmail: state.user.email,
  // username: state.user.username
  done: state.user.done
})

const bindActions = dispatch => ({
  setUserData: userData => dispatch(setUserData(userData))
})

export default connect(mapStateToProps, bindActions)(AuthRequired);