import React, { Component } from "react";
import Spinner from "react-spinner-material";
import { client } from "../apolloClient";
import { Button } from "shards-react";
import { queries } from "../graphQueries";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import CantDeleteTooltip from "../components/CantDeleteTooltip";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "white" : "",
  border: "1px solid #007BFF",
  borderRadius: "1em",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "" : "",
  padding: grid
});

class MenuDnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      canUpdate: false,
      loading: true,
      saveOk: false
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.setSaveOk = this.setSaveOk.bind(this);
  }

  componentWillMount() {
    this.getItems();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.fullMenuId !== this.props.fullMenuId ||
      this.props.reload !== nextProps.reload
    ) {
      this.setState({ loading: true });
      const variables = { fullMenuId: nextProps.fullMenuId };
      const query = queries.menusByRestaurant;
      client
        .query({ variables, query, fetchPolicy: "network-only" })
        .then(res => {
          this.setState({
            items: res.data.menus.sort((a, b) => a.menuOrder - b.menuOrder),
            loading: false
          });
        })
        .catch(err => {
          this.setState({ loading: false });
        });
    }
  }

  setSaveOk() {
    this.props.setSaveOk();
  }

  toggleDeleteModal(toDeleteId, toDeleteName) {
    this.props.toggleDeleteModal(toDeleteId, toDeleteName, this.getItems);
  }

  toggleModal(toEditMenu, toEditMenuIndex) {
    if (toEditMenu) {
      this.props.toggleModal(
        this.props.fullMenuId,
        this.state.items.length,
        true,
        this.state.items[toEditMenuIndex],
        this.getItems
      );
    } else {
      this.props.toggleModal(
        this.props.fullMenuId,
        this.state.items.length,
        false,
        {},
        this.getItems
      );
    }
  }

  getItems = () => {
    this.setState({ loading: true });
    const variables = { fullMenuId: this.props.fullMenuId };
    const query = queries.menusByRestaurant;
    client
      .query({ variables, query, fetchPolicy: "network-only" })
      .then(res => {
        this.setState({
          items: res.data.menus.sort((a, b) => a.menuOrder - b.menuOrder),
          loading: false
        });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  updateMenus = () => {
    const variables = { ids: this.state.items.map(item => item.id) };
    const mutation = queries.updateRestaurantMenus;
    client.mutate({ variables, mutation }).then(res => {
      // this.setState({ saveOk: true }, () => setTimeout(() => this.setState({ saveOk: false }), 2500));
      this.setSaveOk();
    });
  };

  deleteMenu = menuId => {
    const variables = { id: parseInt(menuId) };
    const mutation = queries.deleteMenu;
    client.mutate({ variables, mutation }).then(res => {
      // this.setState({ saveOk: true }, () => setTimeout(() => this.setState({ saveOk: false }), 2500));
      this.setSaveOk();
    });
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState(
      {
        items
      },
      () => this.updateMenus()
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="spinner-loader-dnd">
          <Spinner
            size={80}
            spinnerColor={"#007BFF"}
            spinnerWidth={2}
            visible={true}
          />
        </div>
      );
    } else if (this.state.items.length > 0) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={this.props.isRestaurant}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <i className="material-icons menu-options">swap_vert</i>
                        <span className="menu-title">{item.name}</span>
                        <div className="float-right">
                          <Link
                            className="menu-options"
                            to={{
                              pathname: "/dishes",
                              state: {
                                isRestaurant: this.props.isRestaurant,
                                menuId: item.id,
                                restaurantId: this.props.restaurantId,
                                menuName: item.name,
                                fullMenuId: this.props.fullMenuId,

                              }
                            }}
                          >
                            <i className="material-icons">visibility</i>
                          </Link>
                          <button
                            disabled={
                              this.props.isRestaurant && !this.props.isEdit
                            }
                            className="menu-options"
                            onClick={() => this.toggleModal(true, index)}
                          >
                            <i className="material-icons">edit</i>
                          </button>
                          <CantDeleteTooltip
                            t={this.props.t}
                            message={"tooltip.cantdeletemenu"}
                            condition={item.hasDishes}
                            children={
                              <button
                                className="menu-options"
                                disabled={item.hasDishes}
                                onClick={() =>
                                  this.toggleDeleteModal(item.id, item.name)
                                }
                              >
                                <i className="material-icons mr-1">delete</i>
                              </button>
                            }
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {this.props.isEdit && (
                  <Button
                    className="float-right"
                    onClick={() => this.toggleModal(false)}
                  >
                    {this.props.t("form.create")}
                  </Button>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    } else if (!this.props.isEdit) {
      return (
        <p className="text-center m-2">
          {this.props.t("menu.noSavedRestaurant")}
        </p>
      );
    } else {
      return (
        <div>
          <p className="text-center m-2">{this.props.t("menu.nodishes")}</p>
          <Button
            className=" m-2 float-right"
            onClick={() => this.toggleModal(false)}
          >
            {this.props.t("form.create")}
          </Button>
        </div>
      );
    }
  }
}

export default MenuDnd;
