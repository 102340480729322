import React from 'react';
import Spinner from 'react-spinner-material';
import { withTranslation } from 'react-i18next';
import { MEDIA_FILES_APPEND, dishesLanguagesFields } from '../config';
import { Query } from 'react-apollo';
import { client } from '../apolloClient';
import { queries } from '../graphQueries';
import {
  Container,
  Collapse,
  CardBody,
  Form,
  FormTextarea,
  FormCheckbox,
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  FormInput,
  FormRadio,
  Modal,
  ModalBody,
} from 'shards-react';
import { Link, Redirect } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import PriceModalForm from '../components/PriceModalForm';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import FranchiseModalForm from '../components/FranchiseModalForm';

class DishForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userPlan: '',
      error: '',
      avaibleLanguages: [],
      currency: null,
      dish: {
        id: '',
        name: '',
        description: '',
        image: [],
        allergens: [],
        restaurantId: '',
        menuId: '',
        prices: [],
        suggested: false,
        status: 'WA',
        dishOrder: '',
        restaurant: { htLocalId: null, ordersActive: false },
      },
      franchises: [],
      menuName: '',
      isEdit: false,
      requiredFieldsError: false,
      redirect: false,
      collapse: false,
      autoTranslate: true,
      isRestaurant: false,
      editIndex: 0,
      franchiseModalToggled: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.addPriceToList = this.addPriceToList.bind(this);
    this.addRestaurantsToList = this.addRestaurantsToList.bind(this);
    this.t = this.props.t;
  }

  toggleModal(event, isEdit = false, toEditPrice = {}, editIndex = -1) {
    try {
      event.preventDefault();
    } catch (err) {
      this.setState(
        {
          isPriceEdit: isEdit,
          toEditPrice,
          editIndex,
        },
        () => this.setState({ modalToggled: !this.state.modalToggled })
      );
    }
    this.setState(
      {
        isPriceEdit: isEdit,
        toEditPrice,
        editIndex,
      },
      () => this.setState({ modalToggled: !this.state.modalToggled })
    );
  }

  toggleFranchiseModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      franchiseModalToggled: !this.state.franchiseModalToggled,
    }));
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      dish: {
        ...prevState.dish,
        [name]: value,
      },
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const mutation = this.state.isEdit
      ? queries.updateDish
      : queries.createDish;
    const toCleanPrices = this.state.dish.prices;
    const variables = {
      name: this.state.dish.name,
      description: this.state.dish.description,
      dishOrder: this.state.dish.dishOrder,
      image:
        typeof this.state.dish.image === 'object'
          ? this.state.dish.image[0]
          : this.state.dish.image,
      allergens: this.state.dish.allergens.map((allergen) => allergen.id),
      restaurantId: this.state.dish.restaurantId,
      menuId: this.state.dish.menuId,
      suggested: this.state.dish.suggested,
      status: this.state.dish.status,
      prices: this.formatPrices(toCleanPrices),
      nameEs: this.state.dish.name,
      nameEn: this.state.dish.nameEn,
      nameFr: this.state.dish.nameFr,
      nameIt: this.state.dish.nameIt,
      namePt: this.state.dish.namePt,
      nameDe: this.state.dish.nameDe,
      nameCa: this.state.dish.nameCa,
      nameJa: this.state.dish.nameJa,
      nameKo: this.state.dish.nameKo,
      nameZhCn: this.state.dish.nameZhCn,
      nameRu: this.state.dish.nameRu,
      descriptionEs: this.state.dish.description,
      descriptionEn: this.state.dish.descriptionEn,
      descriptionFr: this.state.dish.descriptionFr,
      descriptionIt: this.state.dish.descriptionIt,
      descriptionPt: this.state.dish.descriptionPt,
      descriptionDe: this.state.dish.descriptionDe,
      descriptionCa: this.state.dish.descriptionCa,
      descriptionJa: this.state.dish.descriptionJa,
      descriptionKo: this.state.dish.descriptionKo,
      descriptionZhCn: this.state.dish.descriptionZhCn,
      descriptionRu: this.state.dish.descriptionRu,
      autoTranslate: this.state.autoTranslate,
    };

    if (this.state.isEdit) {
      variables.id = this.state.dish.id;
    }
    client
      .mutate({
        variables,
        mutation,
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.setState({ loading: false, redirect: true });
      })
      .catch((err) => {
        //console.log(err)
        this.setState({
          loading: false,
          error: this.t('form.notUpdated'),
        });
      });
  };

  formatPrices = (prices) => {
    const formatedPrices =  prices.map((value) => {
      let formatedPrice = {...value};
      formatedPrice.prices = [];
      if (formatedPrice.deliveryPrice) {
        formatedPrice.prices.push(formatedPrice.deliveryPrice);
      }
      delete formatedPrice.deliveryPrice;
      if (formatedPrice.takeawayPrice) {
        formatedPrice.prices.push(formatedPrice.takeawayPrice);
      }
      delete formatedPrice.takeawayPrice;
      if (formatedPrice.loungePrice) {
        formatedPrice.prices.push(formatedPrice.loungePrice);
      }
      delete formatedPrice.loungePrice;

      return formatedPrice;
    });

    function stripTypenames(value) {
      if (Array.isArray(value)) {
        return value.map(stripTypenames);
      } else if (value !== null && typeof value === 'object') {
        const newObject = {};
        for (const property in value) {
          if (property !== '__typename') {
            newObject[property] = stripTypenames(value[property]);
          }
        }
        return newObject;
      } else {
        return value;
      }
    }

    return stripTypenames(formatedPrices);
  }

  handleSubmitFranchise = async () => {
    this.setState({ loading: true });
    const mutation = this.state.isEdit
      ? queries.updateFranchiseDishes
      : queries.createFranchiseDishes;
    const toCleanPrices = this.state.dish.prices;
    const variables = {
      name: this.state.dish.name,
      description: this.state.dish.description,
      dishOrder: this.state.dish.dishOrder,
      image:
        typeof this.state.dish.image === 'object'
          ? this.state.dish.image[0]
          : this.state.dish.image,
      allergens: this.state.dish.allergens.map((allergen) => allergen.id),
      restaurantId: this.state.dish.restaurantId,
      menuId: this.state.dish.menuId,
      suggested: this.state.dish.suggested,
      status: this.state.dish.status,
      prices: this.formatPrices(toCleanPrices),
      nameEs: this.state.dish.name,
      nameEn: this.state.dish.nameEn,
      nameFr: this.state.dish.nameFr,
      nameIt: this.state.dish.nameIt,
      namePt: this.state.dish.namePt,
      nameDe: this.state.dish.nameDe,
      nameCa: this.state.dish.nameCa,
      nameJa: this.state.dish.nameJa,
      nameKo: this.state.dish.nameKo,
      nameZhCn: this.state.dish.nameZhCn,
      nameRu: this.state.dish.nameRu,
      descriptionEs: this.state.dish.description,
      descriptionEn: this.state.dish.descriptionEn,
      descriptionFr: this.state.dish.descriptionFr,
      descriptionIt: this.state.dish.descriptionIt,
      descriptionPt: this.state.dish.descriptionPt,
      descriptionDe: this.state.dish.descriptionDe,
      descriptionCa: this.state.dish.descriptionCa,
      descriptionJa: this.state.dish.descriptionJa,
      descriptionKo: this.state.dish.descriptionKo,
      descriptionZhCn: this.state.dish.descriptionZhCn,
      descriptionRu: this.state.dish.descriptionRu,
      autoTranslate: this.state.autoTranslate,
      restaurants: this.state.franchises,
    };

    if (this.state.isEdit) {
      variables.id = this.state.dish.id;
    }
    client
      .mutate({
        variables,
        mutation,
      })
      .then((res, err) => {
        if (err) console.log(err);
        this.setState({ loading: false, redirect: true });
      })
      .catch((err) => {
        //console.log(err)
        this.setState({
          loading: false,
          error: this.t('form.notUpdated'),
        });
      });
  };

  handleLanguageChange(e, prop) {
    const newValue = e.target.value;
    this.setState((prevState) => ({
      dish: { ...prevState.dish, [prop]: newValue },
    }));
  }

  onDropIcon = (icon) => {
    this.setState((prevState) => ({
      dish: { ...prevState.dish, image: icon },
    }));
  };

  handleAllergenOnChange = (allergen, allergenIndex) => {
    if (allergenIndex > -1) {
      const allergens = this.state.dish.allergens;
      allergens.splice(allergenIndex, 1);
      this.setState((prevState) => ({
        dish: { ...prevState.dish, allergens },
      }));
    } else {
      this.setState((prevState) => ({
        dish: {
          ...prevState.dish,
          allergens: [
            ...prevState.dish.allergens,
            {
              name: allergen.name,
              id: allergen.id,
              __typename: allergen.__typename,
            },
          ],
        },
      }));
    }
  };

  addPriceToList = (e, price, index) => {
    const prices = this.state.dish.prices;
    if (index === -1) {
      prices.push({ ...price });
    } else {
      prices[index] = {
        ...prices[index],
        ...price
      };
    }
    this.setState((prevState) => ({
      dish: { ...prevState.dish, prices },
    }));
    this.toggleModal(e);
  };

  addPriceToList = (e, price, index) => {
    const prices = this.state.dish.prices;
    if (index === -1) {
      prices.push({ ...price });
    } else {
      prices[index] = {
        ...prices[index],
        ...price
      };
    }
    this.setState((prevState) => ({
      dish: { ...prevState.dish, prices },
    }));
    this.toggleModal(e);
  };

  addRestaurantsToList = async (e, restaurants) => {
    await this.setState((prevState) => ({
      ...prevState,
      franchises: [...restaurants],
    }));
    this.toggleFranchiseModal();
    this.handleSubmitFranchise();
  }

  changeStatus = (status) => {
    this.setState((prevState) => ({ dish: { ...prevState.dish, status } }));
  };

  removeFromPrices = (event, index) => {
    event.preventDefault();
    const prices = this.state.dish.prices;
    prices.splice(index, 1);
    this.setState((prevState) => ({
      dish: { ...prevState.dish, prices },
    }));
  };

  componentDidMount() {
    try {
      if (this.props.location.state.entity) {
        this.setState(
          {
            loading: true,
            isRestaurant: this.props.location.state.isRestaurant,
          },
          () => {
            if (this.state.isRestaurant) {
              this.setState((prevState) => ({
                dish: { ...prevState.dish, status: 'WA' },
              }));
            }
          }
        );
        const variables = { id: this.props.location.state.entity.id };
        const query = queries.dish;
        client.query({ variables, query }).then((res) => {
          const userPlan = res.data.dish.restaurant.company.userPlan || '';
          this.setState((prevState) => ({
            userPlan,
            avaibleLanguages: res.data.dish.restaurant.avaibleLanguages || [],
            currency:
              (res.data.dish.restaurant &&
                res.data.dish.restaurant.city &&
                res.data.dish.restaurant.city.state &&
                res.data.dish.restaurant.city.state.country &&
                res.data.dish.restaurant.city.state.country.currency) ||
              '',
            dish: {
              ...prevState.dish,
              ...res.data.dish,
              ...this.props.location.state.extraInfo,
              name: res.data.dish.nameEs,
              description: res.data.dish.descriptionEs,
            },
            isEdit: true,
            autoTranslate: false,
            loading: false,
          }));

          if (res.data.dish.restaurant.htLocalId) {
            // this.queryRestaurantHostelMenuData();
          }
        });
      } else {
        this.setState(
          (prevState) => ({
            dish: {
              ...prevState.dish,
              menuId: this.props.location.state.menuId,
              restaurantId: this.props.location.state.restaurantId,
              menuName: this.props.location.state.menuName,
              dishOrder: this.props.location.state.dishOrder,
            },
            isRestaurant: this.props.location.state.isRestaurant,
            loading: false,
          }),
          this.queryRestaurantData()
        );
      }
    } catch (err) {
      console.log('ERROR');
    }
  }

  queryRestaurantData() {
    client
      .query({
        query: queries.restaurant,
        variables: { id: this.props.location.state.restaurantId },
      })
      .then((res) => {
        if (res.data && res.data.restaurant) {
          const userPlan = res.data.restaurant.company.userPlan || '';
          this.setState((prevState) => ({
            ...prevState,
            userPlan,
            autoTranslate: !(res.data.restaurant.company.userPlan === 'FRE'),
            avaibleLanguages: res.data.restaurant.avaibleLanguages || [],
            dish: {
              ...prevState.dish,
              status: userPlan === 'FRE' ? 'AP' : prevState.dish.status,
              restaurant: { ...res.data.restaurant }
            },
            currency:
              (res.data.restaurant &&
                res.data.restaurant.city &&
                res.data.restaurant.city.state &&
                res.data.restaurant.city.state.country &&
                res.data.restaurant.city.state.country.currency) ||
              '',
          }));
          if (res.data.restaurant.htLocalId) {
            //this.queryRestaurantHostelMenuData();
          }
        }
      });
  }

  queryRestaurantHostelMenuData() {
    client
      .query({
        query: queries.hostelTactilDishes,
        variables: { id: this.state.dish.restaurantId },
      })
      .then((res) => {
        if (res.data) {
          console.log(res.data.hostelTactilDishes.menuObject);
        }
      });
  }

  toggleDeleteModal = (
    toDeleteIndex = '',
    toDeleteId = '',
    toDeleteName = ''
  ) => {
    if (this.state.modalOpened) {
      this.setState({
        modalOpened: !this.state.modalOpened,
      });
    } else {
      this.setState(
        {
          toDeleteIndex,
          toDeleteId,
          toDeleteName,
        },
        () => this.setState({ modalOpened: !this.state.modalOpened })
      );
    }
  };

  redirectToMenu = () => {
    this.setState({
      redirect: true,
    });
  };

  render() {
    if (!this.state.loading) {
      if (this.state.redirect) {
        return (
          <Redirect
            to={{
              pathname: this.props.location.state.fromNotApproved
                ? '/NADishes'
                : this.props.location.state.fromFullList
                ? 'myMenu'
                : '/dishes',
              state: {
                menuId: this.state.dish.menuId,
                restaurantId: this.state.dish.restaurantId,
                menuName: this.state.dish.menuName,
                isRestaurant: this.state.isRestaurant,
              },
            }}
          />
        );
      } else {
        return (
          <Container className="pt-4">
            <Card className="pb-3">
              <CardHeader className="border-bottom">
                <Row>
                  <Col md="10">
                    <h3 className="float-left title-ellipsis">
                      {this.state.isEdit
                        ? `${this.t('form.editing')} ${this.state.dish.name}`
                        : this.t('form.newDish')}
                    </h3>
                  </Col>
                  <Col md="2" className="text-right">
                    <Button
                      tag={Link}
                      outline
                      theme="danger"
                      className="mt-1"
                      to={{
                        pathname: this.props.location.state.fromNotApproved
                          ? '/NADishes'
                          : this.props.location.state.fromFullList
                          ? 'myMenu'
                          : '/dishes',
                        state: {
                          menuId: this.state.dish.menuId,
                          restaurantId: this.state.dish.restaurantId,
                          menuName: this.state.dish.menuName,
                          isRestaurant: this.state.isRestaurant,
                        },
                      }}
                    >
                      {this.t('back')}
                    </Button>
                    <Button
                      className="btn btn-danger mt-1 ml-2"
                      disabled={this.state.isRestaurant}
                      onClick={() =>
                        this.toggleDeleteModal(
                          0,
                          this.state.dish.id,
                          this.state.dish.nameEs
                        )
                      }
                    >
                      <i className="material-icons mr-1">delete</i>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Form onSubmit={this.handleSubmit}>
                <Row className="m-2">
                  <Col md="6">
                    <label htmlFor="name">
                      {this.t('dishForm.name')}
                      <span className="required-input"> *</span>
                    </label>
                    <FormInput
                      id="name"
                      onChange={this.handleChange}
                      required={true}
                      name="name"
                      value={this.state.dish.name}
                    />
                  </Col>
                  <Col md="6">
                    <label>{this.t('dishForm.image')}</label>
                    {this.state.dish.image && (
                      <span>
                        <button
                          className="menu-options ml-2"
                          onClick={(e) =>
                            this.setState((prev) => ({
                              ...prev,
                              dish: {
                                ...prev.dish,
                                image: '',
                              },
                            }))
                          }
                        >
                          <i className="material-icons">delete</i>
                        </button>
                      </span>
                    )}
                    <Dropzone
                      accept="image/jpeg, image/png"
                      multiple={false}
                      onDrop={this.onDropIcon}
                    >
                      {({ getRootProps, getInputProps }) => {
                        let src = null;
                        if (this.state.dish.image) {
                          if (typeof this.state.dish.image === 'string') {
                            src = `${MEDIA_FILES_APPEND}${this.state.dish.image}`;
                          } else if (
                            typeof this.state.dish.image === 'object'
                          ) {
                            src = window.URL.createObjectURL(
                              new File(this.state.dish.image, {
                                type: 'image/png',
                              })
                            );
                          }
                        }

                        let image = !!src && (
                          <img style={{ width: '50px' }} src={src} alt="" />
                        );

                        return (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {!image && (
                                <p className="dropzone-solid-blue">
                                  {this.t('uploadText')}
                                </p>
                              )}
                              {Array.isArray(this.state.dish.image) &&
                                this.state.dish.image.length === 0 && (
                                  <p className="dropzone-solid-blue">
                                    {this.t('uploadText')}
                                  </p>
                                )}
                              {image}
                            </div>
                          </section>
                        );
                      }}
                    </Dropzone>
                  </Col>
                </Row>
                <Row className="m-2">
                  <Col md="12">
                    <p>{this.t('dishForm.allergens')}</p>
                    <Query query={queries.allergens}>
                      {({ loading, error, data }) => {
                        if (loading) return 'Loading...';
                        if (error) return `Error ${error.message}`;
                        return data.allergens.map((allergen, index) => {
                          const indexOfAllergen = this.state.dish.allergens
                            .map((a) => a.name)
                            .indexOf(allergen.name);
                          return (
                            <FormCheckbox
                              key={index}
                              className="d-inline mr-2"
                              checked={indexOfAllergen > -1}
                              onChange={() =>
                                this.handleAllergenOnChange(
                                  allergen,
                                  indexOfAllergen
                                )
                              }
                            >
                              <img
                                className={
                                  indexOfAllergen > -1
                                    ? 'allergen-icon selected-allergen'
                                    : 'allergen-icon not-selected-img'
                                }
                                src={MEDIA_FILES_APPEND + allergen.icon}
                                alt=""
                              />
                            </FormCheckbox>
                          );
                        });
                      }}
                    </Query>
                  </Col>
                </Row>
                <Row className="m-2 mb-4 mt-4">
                  <Col md="6">
                    <label htmlFor="description">
                      {this.t('dishForm.description')}
                    </label>
                    <FormTextarea
                      id="description"
                      onChange={this.handleChange}
                      value={this.state.dish.description || ''}
                      name="description"
                    />
                  </Col>
                  {/* </Row>
                <Row className="m-2 mb-4 mt-4"> */}
                  {!this.state.isRestaurant && (
                    <Row>
                      <Col md="6" style={{ marginTop: 33 }}>
                        <FormCheckbox
                          disabled={
                            this.state.isRestaurant ||
                            this.state.userPlan === 'FRE'
                          }
                          toggle
                          checked={this.state.dish.suggested}
                          onChange={() =>
                            this.setState((prevState) => ({
                              dish: {
                                ...prevState.dish,
                                suggested: !this.state.dish.suggested,
                              },
                            }))
                          }
                        >
                          {this.t('dishForm.suggested')}
                        </FormCheckbox>
                      </Col>
                      <Col md="6" style={{ marginTop: 33 }}>
                        <FormCheckbox
                          disabled={
                            this.state.isRestaurant ||
                            this.state.userPlan === 'FRE'
                          }
                          checked={this.state.autoTranslate}
                          onChange={() =>
                            this.setState({
                              autoTranslate: !this.state.autoTranslate,
                            })
                          }
                        >
                          {this.t('dishForm.autoTranslate')}
                        </FormCheckbox>
                      </Col>
                    </Row>
                  )}
                </Row>
                <Row className="m-2">
                  <Col md="8">
                    <Card small>
                      <CardHeader>
                        <h6 className="float-left m-0">
                          {this.t('dishForm.prices')}
                        </h6>
                      </CardHeader>
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th key="size">{this.t('size')}</th>
                            <th key="price">{this.t('price')}</th>
                            { this.state.dish.restaurant.htLocalId && (
                              <th key="htDishId">{this.t('htDishId')}</th>
                            )}
                            <th key="actions">{this.t('actions')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dish.prices.map((price, index) => (
                            <tr key={index} className="table-hover">
                              <td>{price.nameEs}</td>
                              <td>{price.price}</td>
                              { this.state.dish.restaurant.htLocalId && (
                                <td>{price.htDishId}</td>
                              )}
                              <td>
                                {/* <button className="menu-options" onClick={() => this.toggleModal(true, index)}><i className="material-icons">edit</i></button> */}
                                <button
                                  className="menu-options"
                                  onClick={(e) =>
                                    this.removeFromPrices(e, index)
                                  }
                                >
                                  <i className="material-icons mr-1">delete</i>
                                </button>
                                <button
                                  className="menu-options"
                                  onClick={(e) =>
                                    this.toggleModal(e, true, price, index)
                                  }
                                >
                                  <i className="material-icons mr-1">edit</i>
                                </button>
                              </td>
                            </tr>
                          ))}
                          <tr key="new" className="table-hover">
                            <td></td>
                            <td></td>
                            <td>
                              <button
                                className="menu-options"
                                onClick={(e) => this.toggleModal(e)}
                              >
                                <i className="material-icons mr-1">add</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                    <PriceModalForm
                      t={this.t}
                      isEdit={this.state.isPriceEdit}
                      priceToEdit={this.state.toEditPrice}
                      modalToggled={this.state.modalToggled}
                      toggleModal={this.toggleModal}
                      dishId={this.state.dish.id}
                      editIndex={this.state.editIndex}
                      addPriceToList={this.addPriceToList}
                      htActive={this.state.dish.restaurant.htLocalId ? true: false}
                      pricesAdded={this.state.dish.prices.map(
                        (price) => price.name
                      )}
                      currencySymbol={
                        (this.state.currency && this.state.currency.symbol) ||
                        '€'
                      }
                      ordersActive={this.state.dish.restaurant.foodytOrders}
                    />
                  </Col>

                  <Col md="4">
                    <Card small>
                      <CardHeader className="border-bottom">
                        <h6 className="float-left m-0">
                          {this.t('dishForm.status')}
                        </h6>
                      </CardHeader>
                      <div className="m-2">
                        <FormRadio
                          name="status"
                          checked={this.state.dish.status === 'WA'}
                          onChange={() => this.changeStatus('WA')}
                        >
                          {this.t('waiting')}
                        </FormRadio>
                        <FormRadio
                          name="status"
                          checked={this.state.dish.status === 'AP'}
                          onChange={() => this.changeStatus('AP')}
                        >
                          {this.t('approved')}
                        </FormRadio>
                        <FormRadio
                          name="status"
                          checked={this.state.dish.status === 'RJ'}
                          onChange={() => this.changeStatus('RJ')}
                        >
                          {this.t('rejected')}
                        </FormRadio>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row className="m-2">
                  <Col md="12">
                    <Card small>
                      <CardHeader
                        onClick={() =>
                          this.setState({ collapse: !this.state.collapse })
                        }
                        className="cursor-pointer border-bottom"
                      >
                        <h6 className="float-left m-0">
                          {this.t('language.settings')}
                        </h6>
                        {!this.state.collapse && (
                          <i className="material-icons float-right">
                            expand_more
                          </i>
                        )}
                        {this.state.collapse && (
                          <i className="material-icons float-right">
                            expand_less
                          </i>
                        )}
                      </CardHeader>
                      <Collapse open={this.state.collapse}>
                        <CardBody>
                          <Row>
                            {dishesLanguagesFields.map((language, index) => {
                              const prop = `${language.field}${language.language}`; // name_en, description_cat
                              const snakeCaseLang = language.language
                                .split(/(?=[A-Z])/)
                                .join('-')
                                .toLowerCase();

                              return (
                                <Col key={index} md="6" className="mt-1">
                                  <label htmlFor={prop}>
                                    {this.t(`form.${prop}`)}
                                  </label>
                                  {language.field === 'name' && (
                                    <FormInput
                                      disabled={
                                        this.state.isRestaurant ||
                                        (this.state.userPlan === 'FRE' &&
                                          !this.state.avaibleLanguages.includes(
                                            snakeCaseLang
                                          ))
                                      }
                                      name={prop}
                                      id={prop}
                                      onChange={(e) =>
                                        this.handleLanguageChange(e, prop)
                                      }
                                      value={this.state.dish[prop] || ''}
                                    />
                                  )}
                                  {language.field === 'description' && (
                                    <FormTextarea
                                      disabled={
                                        this.state.isRestaurant ||
                                        (this.state.userPlan === 'FRE' &&
                                          !this.state.avaibleLanguages.includes(
                                            snakeCaseLang
                                          ))
                                      }
                                      name={prop}
                                      id={prop}
                                      onChange={(e) =>
                                        this.handleLanguageChange(e, prop)
                                      }
                                      value={this.state.dish[prop] || ''}
                                    />
                                  )}
                                </Col>
                              );
                            })}
                          </Row>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </Col>
                  
                </Row>
                <Row className="m-2">
                  <Col>
                    <Button type="submit">
                      {this.state.isEdit
                        ? this.t('form.update')
                        : this.t('form.create')}
                    </Button>
                    { this.state.dish.restaurant.franchise && (
                      <Button className="ml-2" theme="info" onClick={this.toggleFranchiseModal}>
                        {this.state.isEdit
                          ? this.t('form.updateAllFranchise')
                          : this.t('form.createAllFranchise')}
                      </Button>)
                    }
                  </Col>
                  
                </Row>
              </Form>
            </Card>
            { this.state.dish.restaurant.franchise && (<FranchiseModalForm
              t={this.t}
              restaurants={this.state.dish.restaurant.franchise.franchises.edges}
              modalToggled={this.state.franchiseModalToggled}
              toggleModal={this.toggleFranchiseModal}
              addRestaurantsToList={this.addRestaurantsToList}
            />)}
            <ConfirmDeleteModal
              cancelClickFunction={this.toggleDeleteModal}
              entity="Dish"
              open={this.state.modalOpened}
              toggleFunction={this.toggleDeleteModal}
              toDeleteId={this.state.toDeleteId}
              toDeleteName={this.state.toDeleteName}
              refetchFunction={this.redirectToMenu}
              t={this.t}
            />
            <Modal
              open={this.state.error}
              toggle={() => {
                this.setState({ error: '' });
              }}
              centered={true}
            >
              <ModalBody className="text-center">
                {' '}
                {this.state.error}{' '}
              </ModalBody>
            </Modal>
          </Container>
        );
      }
    } else {
      return (
        <div className="spinner-loader">
          <Spinner
            size={80}
            spinnerColor={'#007BFF'}
            spinnerWidth={2}
            visible={true}
          />
        </div>
      );
    }
  }
}

export default withTranslation()(DishForm);
