import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { Card, CardHeader, CardBody, Alert } from "shards-react";
import Spinner from "react-spinner-material";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import moment from 'moment';

class ViewsChart extends React.Component {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/Lrffmzfc/';

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  render() {
    const { title } = this.props;
    this.t = this.props.t;

    if (this.props.data.lenght === 0) return (
      <div className="spinner-loader-listing">
        <Spinner size={80} spinnerColor={"#007BFF"} spinnerWidth={2} visible={true} />
      </div>);
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <div style={{ width: '100%', height: 600, margin:"15px 0 0 0" }}>
            { (moment(this.props.fromDate, "YYYY-MM-DD") < moment("2019-10-02", "YYYY-MM-DD") ?<Alert className="alert-info mt-2 mb-4">Atención: Solo se muestrán datos de visualizaciones a partir del 2 de octubre.</Alert> : "")}
            
            <ResponsiveContainer height={530}>
              <AreaChart
                data={this.props.data}
                
                margin={{
                  top: 10, right: 0, left: 0, bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                <XAxis dataKey="name"/>
                <YAxis axisLine={false} />
                <Tooltip />
                <Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} />
                <Area dot={{ stroke: 'white', strokeWidth: 3, width: 8, fill: "#2577D5" }} name={this.t("dashboard.totalViews")} dataKey="totalViews" stroke="#2577D5" strokeWidth={2} fill="#ffffff" />
                <Area dot={{ stroke: 'white', strokeWidth: 3, width: 8, fill: "#EEC13E" }} name={this.t("dashboard.qrViews")} dataKey="qrViews" stroke="#EEC13E" strokeWidth={2} fill="#fdf9eb" />
                <Area dot={{ stroke: 'white', strokeWidth: 3, width: 8, fill: "#0DD1A9" }} name={this.t("dashboard.normalViews")} dataKey="normalViews" stroke="#0DD1A9" strokeWidth={2} fill="#e4f3e4" />
              </AreaChart>
            </ResponsiveContainer>
          </div>      
          
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => ({

})

const bindActions = dispatch => ({
})

export default connect(mapStateToProps, bindActions)(withTranslation()(ViewsChart));
