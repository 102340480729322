export default {
  searchBar: 'Search what you need',
  'username.placeholder': 'Email or username',
  'username.required': 'Username required',
  'password.placeholder': 'password',
  'password.required': 'Password required',
  login: 'Login',
  'table.edit': 'Edit',
  'table.delete': 'Delete',
  'table.options': 'Options',
  'cityTable.title': 'Cities',
  'currencyTable.title': 'Currencies',
  'serviceTable.title': 'Services',
  'form.newService': 'New service',
  'form.newCuisine': 'New cuisine',
  'cityTable.tabletitle': 'Cities',
  'cityTable.subtitle': 'All cities',
  'currencyTable.tabletitle': 'Currencies',
  'currencyTable.subtitle': 'All currencies',
  'allergenTable.title': 'Allergens',
  'table.name': 'Name',
  'table.icon': 'Icon',
  'table.code': 'Code',
  'table.symbol': 'Symbol',
  deleteModalHeader: 'Delete',
  'cuisineTable.title': 'Cuisines',
  'cityTable.state': 'State',
  'allergen.iconUpload': 'Icon (click on it to change)',
  uploadText: 'Drag and drop files here or click to select them',
  uploadRequired: 'You must submit an icon for the allergen!',
  'form.newAllergen': 'New allergen',
  'form.newCountry': 'New Country',
  'form.newCurrency': 'New Currency',
  'table.currency': 'Currency',
  'table.country': 'Country',
  'countryTable.title': 'Countries',
  'lifestyleTable.title': 'Lifestyles',
  'form.newLifestyle': 'New lifestyle',
  'stateTable.title': 'States',
  'form.editing': 'Editing',
  back: 'Back',
  'form.update': 'Update',
  'form.create': 'Create',
  'form.newState': 'New state',
  'form.selectOne': 'Select one of the following...',
  'form.newCity': 'New city',
  'table.state': 'State',
  'table.client': 'Client',
  'table.city': 'City',
  'table.views': 'Views',
  'restaurantTable.title': 'Restaurants',
  countryRequired: 'You must select a country to create a new state',
  stateRequired: 'You must select a state to create a city',
  new: 'New',
  'restaurantForm.info': 'Information',
  'restaurantForm.name': 'Restaurant name',
  'restaurantForm.description': 'Description',
  'restaurantForm.address': 'Address',
  'restaurantForm.email': 'Email',
  'restaurantForm.latitude': 'Latitude',
  'restaurantForm.longitude': 'Longitude',
  'restaurantForm.postalCode': 'Postal code',
  'restaurantForm.priceRange': 'Price range',
  'restaurantForm.user': 'User',
  'restaurantForm.cuisines': 'Cuisines',
  'restaurantForm.currency': 'Currency',
  'restaurantForm.lifestyle': 'Lifestyle',
  'restaurantForm.services': 'Services',
  'restaurantForm.logoImage': 'Restaurant logo',
  'restaurantForm.image': 'Cover image',
  'restaurantForm.phone': 'Phone',
  'restaurantForm.suggested': 'Restaurant suggested',
  'restaurantForm.dashboardAvailable': 'Access to dashboard',
  'restaurantForm.customStyleCollapse': 'Custom styles premium',
  'restaurantForm.mainColor': 'Main color',
  'restaurantForm.fontColor': 'Font color',
  'restaurantForm.link': 'Custom link',
  'restaurantForm.linkName': 'Custom link name',
  'restaurantForm.font': 'Tipo de fuente',
  'restaurantForm.fontSelect': 'Seleccione tipo de fuente',
  'restaurantForm.showSearch': 'Restaurant indexed in searchs of Foodyt',
  'restaurantForm.limitedToFreeUser': 'Limited to 1 for users with Free plan',
  'restaurantForm.ht_username': 'HostelTactil user restaurant',
  'restaurantForm.ht_password': 'HostelTactil password restaurant',
  'restaurantForm.agora_api_url': 'Agora API url',
  'restaurantForm.agora_api_token': 'Agora API token',
  'restaurantForm.foodytOrders': 'Orders with Foodyt',
  'restaurantForm.tenedorRestaurantId': 'Id The fork',
  'restaurantForm.ordersPostalCodes': 'Postal codes where restaurant delivers',
  'restaurantForm.ordersStopped': 'Orders deactivated',
  'hostelTactil.restaurant': 'Configuration HostelTactil restaurant',
  'agora.restaurant': 'Configuration system Agora',
  requiredFieldsEmpty:
    'Some of the required fields are empty, make sure you filled all inputs marked with *',
  'menuForm.name': "Menu's name",
  'modal.newMenu': 'New menu',
  cancel: 'Cancel',
  'menuForm.season': 'Season',
  spring: 'Spring',
  summer: 'Summer',
  fall: 'Fall',
  winter: 'Winter',
  'menuForm.newMenu': 'New menu',
  'searchPlaces.placeholder': 'Select your restaurant',
  'restaurantForm.menus': 'Restaurant menus',
  'tooltip.cantdeletemenu': 'This menu still has dishes!',
  dishesOfMenu: 'Dishes of',
  confirmDelete: 'Are you sure you want to delete the selected item?',
  'dishForm.suggested': 'Suggested by Foodyt',
  'form.newDish': 'New dish',
  'dishForm.image': 'Dish image',
  'dishForm.name': 'Name',
  'table.cuisine': 'Cuisine',
  'dishForm.allergens': 'Select the allergens',
  'dishForm.description': 'Description',
  'dishForm.prices': 'Prices for dish',
  size: 'Size',
  price: 'Price',
  actions: 'Calls/Reservations',
  'priceForm.newPrice': 'New price',
  'priceForm.name': 'Size',
  'priceForm.price': 'Price',
  tapa: 'Tapa',
  media: 'Half',
  racion: 'Full',
  plato: 'Dish',
  copa: 'Glass',
  botella: 'Bottle',
  'tooltip.cantdeleterestaurant': 'This restaurant still has menus!',
  'menu.nodishes':
    "This restaurant still has no menus! Press 'Create' to create a new one",
  'form.newRestaurant': 'New restaurant',
  'dishForm.status': 'Status',
  waiting: 'Waiting',
  approved: 'Approved',
  rejected: 'Rejected',
  'info.popoverheader': 'Find in google places!',
  'info.popoverbody':
    'Search and click the place you are looking for to fill some of the fields',
  'language.settings': 'Multilanguage settings',
  'form.nameEn': 'English name',
  'form.nameFr': 'French name',
  'form.nameIt': 'Italian name',
  'form.namePt': 'Portuguese name',
  'form.nameDe': 'German name',
  'form.nameCa': 'Catalonian name',
  'form.nameJa': 'Japanese name',
  'form.nameKo': 'Korean name',
  'form.nameZhCn': 'Chinese name',
  'form.nameRu': 'Russian name',
  'form.descriptionEn': 'English description',
  'form.descriptionFr': 'French description',
  'form.descriptionIt': 'Italian description',
  'form.descriptionPt': 'Portuguese description',
  'form.descriptionDe': 'German description',
  'form.descriptionCa': 'Catalonian description',
  'form.descriptionJa': 'Japanese description',
  'form.descriptionKo': 'Korean description',
  'form.descriptionZhCn': 'Chinese description',
  'form.descriptionRu': 'Russian description',
  'form.notUpdated': 'There was a problem. Changes not made.',
  'dishForm.autoTranslate': 'Auto translate',
  'menuForm.nameEn': "English menu's name",
  'restaurantForm.showPriceRange': 'Show price range',
  'restaurantForm.bookUrl': 'Booking URL',
  'menu.noSavedRestaurant':
    'You must save the restaurant before creating menus',
  loadMore: 'Load more',
  statusFilter: 'Show NOT approved',
  searchPlaceholder: 'Search',
  'usersTable.title': 'Users',
  'table.role': 'Role',
  'form.newUser': 'New user',
  'userForm.username': 'Username',
  'userForm.password': 'Password',
  'userForm.repeatPassword': 'Repeat password',
  'userForm.firstName': 'First name',
  'userForm.lastName': 'Last name',
  'userForm.email': 'Email',
  'userForm.isRestaurant': 'Mark as restaurant',
  'userForm.isStaff': 'Mark as STAFF',
  'userForm.avatar': 'Avatar',
  'userForm.plan': 'User plan',
  'userForm.basic': 'Basic',
  'userForm.pro': 'Pro',
  'userForm.free': 'Free',
  'userForm.premium': 'Premium',
  'userForm.ht_username': 'HostelTactil user username',
  'userForm.ht_password': 'HostelTactil user password',
  'userForm.ht_activo': 'HostelTactil user active',
  'userForm.agoraActive': 'Agora user active',
  'hosteltactil.user.config': 'Configuration user orders',
  passwordsNotEquals: "Passwords you provided don't match",
  'restaurantForm.nameRes': 'Restaurant',
  notApprovedDishes: 'Not approved dishes',
  'table.date': 'Date',
  'table.user': 'User',
  'table.title': 'Title',
  'table.comment': 'Comment',
  'table.approve': 'Approve',
  'notApprovedComments.title': 'Not approved comments',
  AP: 'Approved',
  WA: 'Waiting',
  RJ: 'Rejected',
  'language.avaibleLanguages': 'Available languages',
  close: 'Close',
  unsavedChanges: 'Are you sure?',
  confirmBack:
    'You have unsaved order changes, close and click update to save or press back again to quit',
  'form.selectOneRestaurant': 'Select one of your restaurants to change',
  'restaurantForm.urlPage': 'Permalink',
  'dashboard.selectOneRestaurant': 'All your restaurants',
  'dashboard.title': 'Dashboard of',
  'dashboard.views': 'Views',
  'dashboard.traffic': 'Traffic',
  'dashboard.dishes': 'Dishes',
  'dashboard.totalViews': 'Total views',
  'dashboard.normalViews': 'WEB views',
  'dashboard.qrViews': 'QR views',
  'dashboard.viewsLanguage': 'Views by language',
  'dashboard.viewsAllergen': 'Allergens filter use',
  'dashboard.mostViewed': 'Most viewed dishes',
  'dashboard.leastViewed': 'Least viewed dishes',
  'dashboard.lastMonth': 'Last 30 days',
  'dashboard.lastWeek': 'Last 7 days',
  'dashboard.thisMonth': 'This month',
  'dashboard.last3months': 'Last 90 days',
  'dashboard.thisYear': 'This year',
  'dashboard.selectDate': 'Select date',
  confirmClone: 'Are you sure you want to clone the selected restaurant?',
  'table.clone': 'Clone',
  cloneModalHeader: 'Clone',
  'action.accept': 'Aceptar',
  'action.cancel': 'Cancel',
  'upload_csv.title.success': 'Successful operation',
  'upload_csv.title.error': 'Unable to upload the file',
  'upload_csv.body.confirm': 'Are you sure you want to upload the {{file}}?',
  'upload_csv.body.error_format':
    'The selected file is invalid. \nA {{- expected_file }} was expected and a {{- selected_file }} was received.',
  'upload_csv.body.error_unespected':
    'Error importing the {{ file }}. \nCheck the file format and/or data.',
  'dashboard.daily_menu': 'Daily Menu',
  'dashboard.daily_menu.new': 'Add new menu of the day',
  'dashboard.daily_menu.edit': 'Change daily menu',
  'menuForm.newFullMenu': 'New menu',
  'dishForm.active': 'Active',
  'create.full.menu': 'Create new menu',
  'add.dish': 'Add dish',
  'select.dish': 'Select a dish',
  'fullMenuForm.name': 'Menu name',
  'userForm.lamb.weston': 'User Lamb Weston',
  dashboard: 'Dashboard',
  'digital.menu': 'My digital menu',
  'daily.menu': 'My daily menu',
  'my.restaurant': 'My restaurant',
  'dish.review': 'Dishes review',
  'comment.review': 'Comments review',
  Visitas: 'Visits',
  'Platos favoritos': 'Favourite dishes',
  'Filtro alérgenos': 'Allergen filters',
  Acciones: 'Actions',
  Seguidores: 'Followers',
  Compartidos: 'Shared',
  favoritos: 'Favourites',
  'no.info': 'Info not available',
  'all.restaurants': 'all restaurants',
  Español: 'Spanish',
  Inglés: 'English',
  Alemán: 'German',
  Francés: 'French',
  Italiano: 'Italian',
  Portugués: 'Portuguese',
  Chinese: 'Chinese',
  Catalonian: 'Catalonian',
  Rusia: 'Russian',
  Koreano: 'Korean',
  Japonés: 'Japanese',
  'append.currency': 'Currency symbol at the end',
  'ordersTable.title': 'Orders list',
  'table.clientName': 'Client name',
  'order.orderType': 'Order type',
  'table.total': 'Total',
  'table.created': 'Created',
  'table.restaurantName': 'Restaurant name',
  'dishForm.sala': 'Lounge',
  'dishForm.takeaway': 'Take away',
  'dishForm.delivery': 'Delivery',
  'dishForm.ordersType': 'Orders actives for',
  'dishForm.paymentMethod': 'Payment methods',
  'dishForm.card': 'Card',
  'dishForm.cash': 'Cash',
  'order.paymentType': 'Tipo de pago',
  'PP': 'PayPal',
  'CA': 'Cash',
  'CC': 'Card',
  'htDishId': 'Hosteltactil dish id',
  'download.orders': 'Download orders',
  'marketTable.title': 'Markets',
  'marketForm.name': 'Market name',
  'marketForm.restaurants': 'Shops in market',
  'marketForm.marketOrderConfigCollapse': 'Orders configurations',
  'marketForm.deliveryConfigCollapse': 'Delivery configurations',
  'marketForm.takeawayConfigCollapse': 'Takeaway configurations',
  'marketForm.loungeConfigCollapse': 'Lounge configurations',
  'marketForm.stripePublicKey': 'Stripe public key',
  'marketForm.stripePrivateKey': 'Stripe private key',
  'marketForm.minimumOrder': 'Minimum order',
  'marketForm.deliveryPrice': 'Delivery price',
  'marketForm.orderTime': 'Average preparation time',
  'marketForm.addOrderHours': 'Add opening hours period',
  'user.market': 'Market',
  'marketForm.askName': 'Ask name',
  'marketForm.askPhone': 'Ask phone',
  'marketForm.askEmail': 'Ask email',
  'create.table': 'Create table',
  'table.qr': "Download QR",
  'tableForm.code': "Code",
  'tableForm.name': "Name",
  'tableForm.zone': "Zone",
  'userForm.userMarket': 'Mercado',
  'franchiseForm.restaurants': 'Restaurants asociated',
  'franchiseTable.title': 'Franchises',
  'franchises': 'Franchises',
  'markets': 'Markets',
  'form.newFranchise': 'New franchise',
  'form.createAllFranchise': 'Create in all franchises',
  'form.updateAllFranchise': 'Update in all franchises',
  'marketForm.sendEmailClosed': 'Send email when order close',
  'table.timezone': 'Timezone',
  'table.codeTwo': 'Code alpha 2',
  'app.table': 'Table',
  'priceForm.weight': 'By weight',
  'priceForm.loungeActive': 'Active lounge',
  'priceForm.takeawayActive': 'Active take away',
  'priceForm.deliveryActive': 'Active delivery',
  'priceForm.loungePrice': 'Price lounge',
  'priceForm.takeawayPrice': 'Price take away',
  'priceForm.deliveryPrice': 'Price a delivery',
  'companyTable.title': 'Companies',
  'companies': 'Companies',
  'restaurantForm.company': 'Compañia',
  'userForm.rol': 'Rol',
  'userForm.admin': 'Administrador',
  'userForm.editor': 'Editor',
  'userForm.readOnly': 'Solo lectura',
  'userForm.restaurants': 'Restaurantes',
  'userForm.revoActive': 'Active in Revo'
};
