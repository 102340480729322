import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';

class HomeRedirector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      go: false,
      isStaff: false
    };
  }

  componentDidMount() {
    this.setState({ isStaff: this.props.isStaff, go: true });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isStaff: nextProps.isStaff, go: true });
  }

  render() {
    if (this.state.go) {
      return this.state.isStaff ? (<Redirect to="/dashboard" />) : (<Redirect to="/myMenu" />);
    } else {
      return (<div></div>)
    }
  }
}

const mapStateToProps = state => ({
  isStaff: state.user.isStaff
})

const bindActions = dispatch => ({
})

export default connect(mapStateToProps, bindActions)(HomeRedirector);