import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { Card, CardHeader, CardBody } from "shards-react";
import { withTranslation } from "react-i18next";

class AllergensUseChart extends PureComponent {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/Lrffmzfc/";

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  render() {
    const { title } = this.props;
    if (this.props.data.length === 0) {
      return (
        <Card small className="h-100">
          <CardHeader className="border-bottom">
            <h6 className="m-0">{title}</h6>
          </CardHeader>
          <CardBody className="pt-0">
            <div className="text-center" style={{ width: "100%", height: 570 }}>
              <strong className="centered error-text">
                {this.props.t("no.info")}
              </strong>
            </div>
          </CardBody>
        </Card>
      );
    } else {
      return (
        <Card small className="h-100">
          <CardHeader className="border-bottom">
            <h6 className="m-0">{title}</h6>
          </CardHeader>
          <CardBody className="pt-0">
            <div style={{ width: "100%", height: 570 }}>
              <ResponsiveContainer>
                <BarChart
                  layout="vertical"
                  data={this.props.data}
                  margin={{
                    top: 20,
                    right: 40,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="0 0" horizontal={false} />
                  <XAxis
                    axisLine={false}
                    type="number"
                    domain={[0, 100]}
                    unit="%"
                  />
                  <YAxis
                    axisLine={false}
                    dataKey="name"
                    type="category"
                    width={120}
                  />
                  <Tooltip />
                  <Bar
                    dataKey="value"
                    fill="#82ca9d"
                    maxBarSize={12}
                    unit="%"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </CardBody>
        </Card>
      );
    }
  }
}

export default withTranslation()(AllergensUseChart);
