export default function() {
  return [
    {
      title: "dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: "",
      staffOnly: true
    },
    {
      title: "countryTable.title",
      htmlBefore: '<i class="material-icons">explore</i>',
      to: "/countries",
      staffOnly: true
    },
    {
      title: "stateTable.title",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/states",
      staffOnly: true
    },
    {
      title: "cityTable.title",
      htmlBefore: '<i class="material-icons">location_city</i>',
      to: "/cities",
      staffOnly: true
    },
    {
      title: "allergenTable.title",
      htmlBefore: '<i class="material-icons">priority_high</i>',
      to: "/allergens",
      staffOnly: true
    },
    {
      title: "lifestyleTable.title",
      htmlBefore: '<i class="material-icons">label</i>',
      to: "/lifestyles",
      staffOnly: true
    },
    {
      title: "restaurantForm.services",
      htmlBefore: '<i class="material-icons">widgets</i>',
      to: "/services",
      staffOnly: true
    },
    {
      title: "restaurantForm.cuisines",
      htmlBefore: '<i class="material-icons">restaurant_menu</i>',
      to: "/cuisines",
      staffOnly: true
    },
    {
      title: "restaurantTable.title",
      htmlBefore: '<i class="material-icons">restaurant</i>',
      to: "/restaurants",
      staffOnly: true
    },
    {
      title: "currencyTable.title",
      htmlBefore: '<i class="material-icons">euro_symbol</i>',
      to: "/currencies",
      staffOnly: true
    },
    {
      title: "usersTable.title",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/users",
      staffOnly: true
    },
    {
      title: "dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      to: "/myDashboard",
      restaurantOnly: true,
      dashboardAvailable: true
    },
    {
      title: "digital.menu",
      htmlBefore: '<i class="material-icons">restaurant_menu</i>',
      to: "/myMenu",
      restaurantOnly: true
    },
    {
      title: "daily.menu",
      htmlBefore: '<i class="material-icons">menu_book</i>',
      to: "/myDailyMenu",
      restaurantOnly: true
    },
    {
      title: "my.restaurant",
      htmlBefore: '<i class="material-icons">restaurant</i>',
      to: "/myRestaurant",
      restaurantOnly: true
    },
    {
      title: "dish.review",
      htmlBefore: '<i class="material-icons">remove_red_eye</i>',
      to: "/NADishes",
      staffOnly: true
    },
    {
      title: "comment.review",
      htmlBefore: '<i class="material-icons">rate_review</i>',
      to: "/unapprovedComments",
      staffOnly: true
    },
    {
      title: "orders",
      htmlBefore: '<i class="material-icons">shopping_cart</i>',
      to: "/orders",
      staffOnly: true
    },
    {
      title: "markets",
      htmlBefore: '<i class="material-icons">store</i>',
      to: "/markets",
      staffOnly: true
    },
    {
      title: "franchises",
      htmlBefore: '<i class="material-icons">business</i>',
      to: "/franchises",
      staffOnly: true
    },
    {
      title: "companies",
      htmlBefore: '<i class="material-icons">group</i>',
      to: "/companies",
      staffOnly: true
    }
  ];
}
