import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Col, Row } from 'shards-react';
import { MEDIA_FILES_APPEND } from '../../config';

const FavoritesDishes = ({ title, dishes }) => (
  <Card small className="dish-favorite">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>

    <CardBody className="p-0">
      {dishes.map((dish, idx) => (
        <div key={idx} className="dish-favorite__item d-flex p-3">
          <Row>
            <Col xl="6" lg="12" md="12" sm="12">
              {/* Avatar */}
              <div className="dish-favorite__image">
                <img src={MEDIA_FILES_APPEND + dish.image} alt={dish.name} />
              </div>
            </Col>
            <Col xl="6" lg="12" md="12" sm="12">
              {/* Content */}
              <div className="dish-favorite__content">
                {/* Content :: Title */}
                <div className="dish-favorite__meta text-mutes">
                  <span className="text-secondary">{dish.name}</span>
                </div>
                {/* Content :: Body */}
                <p className="text-secondary">
                  {dish.numWishlist} <i className="material-icons">favorite</i>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </CardBody>
  </Card>
);

FavoritesDishes.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The discussions dataset.
   */
  dishes: PropTypes.array,
};

FavoritesDishes.defaultProps = {
  title: 'Favoritos',
  dishes: [],
};

export default FavoritesDishes;
