import { SET_USER_DATA, SET_LOGOUT } from "../actions/userActions";

const initialState = {
  username: '',
  email: '',
  id: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        username: action.payload.username,
        email: action.payload.email,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        isStaff: action.payload.is_staff,
        isRestaurant: action.payload.is_restaurant,
        dashboardAvailable: action.payload.dashboardAvailable,
        avatar: action.payload.avatar,
        done: true
      };
    case SET_LOGOUT:
      return {
        ...state,
        logout: action.logout
      };
    default:
      return {
        ...state
      };
  }
}
